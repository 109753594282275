import TestsTable from "./TestsTable";
import { Stack } from "@mui/system";
import { Button } from "@mui/material";
import { useState, useEffect } from "react";
import { getTest, getTests } from "../services/tests";
import TestForm from "./TestEdit";
import TableFrame from "./TableFrame";
import { DataTableContainer } from "./TableContainer";
import ButtonTray from "./ButtonTray";

export default function TestsPanel() {
    const initTest = {
        Ordering: 1,
        ReportTypeID: 1
    }
    const [view, setView] = useState("table");
    const [test, setTest] = useState();
    const [testDetails, setTestDetails] = useState(initTest);
    const [specimenTypes, setSpecimenTypes] = useState([]);
    const [tests, setTests] = useState([]);
    const [refresh, setRefresh] = useState(false);

    if (view === "table") {
        return (
            <>
                <TableFrame>
                    <DataTableContainer>
                        <TestsTable test={test} setTest={setTest} getTests={getTests} refresh={refresh} />
                    </DataTableContainer>
                    <ButtonTray>
                        <Button
                            variant="contained"
                            color="success"
                            onClick={() => {
                                setTests([]);
                                setSpecimenTypes([]);
                                setTestDetails(initTest);
                                setView("create");
                            }}
                        >
                            Create
                        </Button>
                        <Button
                            disabled={test === undefined}
                            variant="contained"
                            color="primary"
                            onClick={() => {
                                setView("edit");
                            }}
                        >
                            Edit
                        </Button>
                    </ButtonTray>
                </TableFrame>
            </>
        );
    } else if (view === "create") {
        const saved = () => { setView("table"); setRefresh((prevValue) => !prevValue) };

        return (
            <TestForm
                testDetails={testDetails}
                setTestDetails={setTestDetails}
                specimenTypes={specimenTypes}
                setSpecimenTypes={setSpecimenTypes}
                tests={tests}
                setTests={setTests}
                saved={saved}
            />
        );
    } else if (view === "edit") {
        const saved = () => { setView("table"); setRefresh((prevValue) => !prevValue) };
        return (
            <TestForm
                testId={test}
                saved={saved}
            />
        );
    }
}
