export async function getCollectors() {
        return fetch(`${process.env.REACT_APP_API_PROXY}/v1/collectors`, {credentials: "include"}).then((response) => response.json());
}

export async function getCollector(id) {
        return fetch(`${process.env.REACT_APP_API_PROXY}/v1/collectors/${id}`, {credentials: "include"});
}

export async function getCollectorsByFacility(facilityID) {
        return fetch(`${process.env.REACT_APP_API_PROXY}/v1/collectors/search?facility_id=` + facilityID, {credentials: "include"}).then((response) => response.json());
}

export async function addCollector(Collector, CollectorID) {
    let url = "";
    if (CollectorID) {
        url = `${process.env.REACT_APP_API_PROXY}/v1/collectors/` + CollectorID;
    } else {
        url = `${process.env.REACT_APP_API_PROXY}/v1/collectors`;
    }
    return fetch(url, {
        method: "POST",
            credentials: "include",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
        },
        body: JSON.stringify(Collector),
    }).then((response) => {
        if (response.ok) {
            return response.json();
        }
        return response.json().then((error) => {
            console.log("throwing");
            console.log(error.error);
            throw new Error(error.error);
        });
    });
}

