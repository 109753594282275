import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { FormatDateTime } from "../services/util";

export default function NotesTable({ notes }) {
    const header = () => {
        return (
            <TableHead>
                <TableRow>
                    <TableCell>Note</TableCell>
                    <TableCell>Date Added</TableCell>
                    <TableCell>Added By</TableCell>
                </TableRow>
            </TableHead>
        );
    };
    if (notes === null) {
        return <Table size="small">{header()}</Table>;
    }
    return (
        <Table size="small">
            {header()}
            <TableBody>
                {notes.map((row) => (
                    <TableRow key={row.ID}>
                        <TableCell>{row.Note}</TableCell>
                        <TableCell>{FormatDateTime(row.CreatedAt)}</TableCell>
                        <TableCell>{row.AddedBy.Email}</TableCell>
                    </TableRow>
                ))}
            </TableBody>
        </Table>
    );
}
