import {
    Button,
    TableContainer,
    Table,
    TableHead,
    TableRow,
    TableBody,
    TableCell,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useState, useContext } from "react";
import { SessionContext } from "../../hooks/getContext";
import { apiFetch } from "../../services/fetch";
import ConfirmDialog from "../ConfirmDialog";
import ErrorAlert from "../ErrorAlert";

function Profiles({ orderDetails, callback }) {
    const [confirmDialog, setConfirmDialog] = useState(false);
    const [profileId, setProfileId] = useState(null);
    const [profileName, setProfileName] = useState(null);
    const [error, setError] = useState(null);
    const session = useContext(SessionContext);

    const handleProfileDelete = (profileId, profileName) => {
        console.log(profileId)
        setProfileId(profileId)
        setProfileName(profileName)
        setConfirmDialog(true)
    }

    const delProfile = () => {
        const del = async () => {
            try {
                await apiFetch(`/orders/${orderDetails.ID}/profiles/${profileId}`, "DELETE")
                callback()
            } catch (e) {
                console.log(e)
                setError("Failed to delete profile.")
            }
        }

        del()
    }
    if (
        orderDetails.Profiles === undefined ||
        orderDetails.Profiles === null ||
        orderDetails.Profiles.length === 0
    ) {
        return <></>;
    }
    return (
        <>
            <ConfirmDialog
                title={"Are you sure?"}
                message={`You are about to delete profile '${profileName}'.`}
                open={confirmDialog}
                cancel={() => setConfirmDialog(false)}
                confirm={() => { delProfile(); setConfirmDialog(false) }}
            />
            <TableContainer style={{ maxHeight: 300, width: "100%" }}>
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            {session?.user?.Type === "Lab" &&
                                <TableCell size="small" sx={{ padding: "0" }}>
                                </TableCell>}
                            <TableCell>ID</TableCell>
                            <TableCell>Profile</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {orderDetails.Profiles.map((row) => (
                            <TableRow key={row.ID}>
                                {session?.user?.Type === "Lab" &&
                                    <TableCell size="small" sx={{ padding: "0" }}>
                                        <Button onClick={() => handleProfileDelete(row.ID, row.Name)}
                                            size="small"
                                            sx={{ minWidth: "12px" }}>
                                            <CloseIcon color="error" />
                                        </Button>
                                    </TableCell>}
                                <TableCell>{row.ID}</TableCell>
                                <TableCell>{row.Name}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <ErrorAlert error={error} />
        </>
    );
};

export default Profiles;
