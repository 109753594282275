import React from "react";
import {
    Button,
    Dialog,
    DialogActions,
    DialogTitle,
    DialogContent,
    Table,
    TableCell,
    TableHead,
    TableRow,
} from "@mui/material";
import { FormatDateTime } from "../../services/util";
import { apiFetch } from '../../services/fetch';
import ErrorAlert from "../ErrorAlert";

type ViewRejectionDialogProps = {
    rejectionId: number;
    setRejectionId: React.Dispatch<React.SetStateAction<number | null>>;
}


export default function ViewRejectionDialog({ rejectionId: rejectionSampleId, setRejectionId: setRejectionSampleId }: ViewRejectionDialogProps) {
    const [rejection, setRejection] = React.useState<any>(null);
    const [loading, setLoading] = React.useState(false);
    const [error, setError] = React.useState<null | string>(null);

    React.useEffect(() => {
        if (!rejectionSampleId) {
            return;
        }

        const init = async () => {
            setLoading(true)
            try {
                const response = await apiFetch(`/samples/${rejectionSampleId}/rejection`)
                setRejection(response)
                setError(null)
            } catch (e: any) {
                setError(e?.message)
            } finally {
                setLoading(false)
            }
        }

        init()
    }, [rejectionSampleId]);

    if (!rejectionSampleId) {
        return (
            <></>
        )
    }

    return (
        <Dialog open={!!rejectionSampleId}>
            <DialogTitle>Rejection</DialogTitle>
            <DialogContent>
                {loading ? "Loading..." :
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Reason</TableCell>
                                <TableCell>Date Rejected</TableCell>
                                <TableCell>Rejected By</TableCell>
                            </TableRow>
                            <TableRow key={rejection?.ID}>
                                <TableCell>{rejection?.Reason}</TableCell>
                                <TableCell>
                                    {FormatDateTime(rejection?.CreatedAt)}
                                </TableCell>
                                <TableCell>
                                    {rejection?.RejectedBy?.Email}
                                </TableCell>
                            </TableRow>
                        </TableHead>
                    </Table>}
                <ErrorAlert error={error} />
            </DialogContent>
            <DialogActions>
                <Button onClick={() => setRejectionSampleId(null)}>Close</Button>
            </DialogActions>
        </Dialog>
    );
}
