import { useMemo } from "react";
import DataTable from "../components/DataTable";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

String.prototype.toProperCase = function() {
    return this.replace(/\w\S*/g, function(txt) {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
};

function ProvidersTable({ providers, selected, setSelected }) {

    const toTitle = (str) => {
        return str.value.toProperCase();
    };

    const getCheckbox = (value) => {
        if (value.value) {
            return <CheckCircleIcon color="success" />;
        }
        return <CheckCircleIcon color="disabled" />;
    };

    const countFacilities = (value) => {
        return value.value.length;
    };

    const columns = useMemo(() => [
        { field: "ID", headerName: "ID", width: 45 },
        { field: "FirstName", headerName: "First Name", width: 175 },
        { field: "LastName", headerName: "Last Name", width: 175 },
        { field: "NPI", headerName: "NPI", width: 125 },
        { field: "Type", headerName: "Type", width: 125, valueGetter: toTitle },
        {
            field: "Facilities",
            headerName: "Facilities",
            width: 125,
            valueGetter: countFacilities,
        },
        {
            field: "SignatureOnFile",
            headerName: "Signature On File",
            width: 150,
            renderCell: getCheckbox,
        },
    ], []);


    return <DataTable
        selected={selected}
        setSelected={setSelected}
        data={providers}
        columns={columns}
        checkboxSelection
    />



}

export default ProvidersTable;
