import {
    Stack,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    Typography,
} from "@mui/material";

import SaveIcon from "@mui/icons-material/Save";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { FormatDate } from "../services/util";
import { GetSampleStatusChip } from "./StatusChips";
import { useState } from "react";
import { DataGridPro } from "@mui/x-data-grid-pro";

function SampleSelect({ samples, open, setOpen, update, results }) {
    const [selected, setSelected] = useState([]);

    const getCheckbox = (value) => {
        if (value.value) {
            return <CheckCircleIcon color="success" />;
        }
        return <CheckCircleIcon color="disabled" />;
    };


    const columns = [
        { field: "OrderID", headerName: "Order ID", width: 75 },
        { field: "Barcode", headerName: "Barcode", width: 175 },
        {
            field: "SpecimenType",
            headerName: "Specimen",
            width: 120,
            valueGetter: (row) => row.row.SpecimenType.Specimen,
        },
        {
            field: "Status",
            headerName: "Status",
            width: 120,
            renderCell: (params) => GetSampleStatusChip(params.row?.Status),
        },
        {
            field: "Fasting",
            headerName: "Fasting",
            width: 80,
            renderCell: getCheckbox,
        },
        {
            field: "DateCollected",
            headerName: "Date Collected",
            width: 120,
            valueGetter: (row) => FormatDate(row.row.DateCollected),
        },
        {
            field: "DateReceived",
            headerName: "Date Received",
            width: 120,
            valueGetter: (row) => FormatDate(row.row.DateReceived),
        },
    ];

    return (
        <Dialog open={open}>
            <DialogContent>
                <Stack direction="column" spacing={2}>
                    <Typography>Please select new sample for selected results</Typography>
                    <DataGridPro
                        hideFooterRowCount
                        getRowId={(row) => row.ID}
                        density="compact"
                        rowSelectionModel={selected}
                        onRowSelectionModelChange={setSelected}
                        rows={samples || []}
                        columns={columns}
                    />
                </Stack>
            </DialogContent>
            <DialogActions>
                <Button
                    color="primary"
                    variant="contained"
                    onClick={() => {
                        setOpen(false);
                    }}
                >
                    Cancel
                </Button>
                <Button
                    startIcon={<SaveIcon />}
                    loadingPosition="start"
                    color="success"
                    variant="contained"
                    disabled={selected.length < 1}
                    onClick={() => {
                        update(selected, results);
                        setOpen(false);
                    }}
                >
                    <span>Update</span>
                </Button>
            </DialogActions>
        </Dialog>

    )

}

export default SampleSelect;
