import React, { useState, useEffect, useContext } from "react";
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Chart from "./Chart";
import SmallMetric from "./SmallMetric";
import MainOrdersTable from "../tables/OrdersTable";
import AppBar from "./AppBar/AppBar";
import Copyright from "./Copyright";
import mdTheme from "./Theme";
import { getPatients } from "../services/patients";
import { getOrderMetrics, getResultMetrics, getSampleMetrics } from "../services/metrics";
import PatientsTable from "../tables/PatientsTable";
import { SessionContext } from "../hooks/getContext";
import Title from "./Title";
import OrderMetric from "./OrderMetric";
import { useNavigate } from "react-router";

function DashboardContent() {
    const [orderMetrics, setOrderMetrics] = useState({});
    const [sampleMetrics, setSampleMetrics] = useState({});
    const [resultMetrics, setResultMetrics] = useState({});
    const navigate = useNavigate();
    const { user } = useContext(SessionContext);

    useEffect(() => {
        if (user?.Type !== "Lab") {
            navigate("/orders");
        }

    }, [user, navigate])

    useEffect(() => {
        getOrderMetrics()
            .then((p) => {
                if (!p.ok) {
                    throw new Error("failed to get orders");
                }
                return p.json();
            })
            .then((p) => {
                setOrderMetrics(p);
                // setOrdersByDay(p.OrdersByDay);
                // setRecentOrders(p.Orders_0_30);
                // setOrdersByDay(p.OrdersByDay);
            })
            .catch();
        getResultMetrics()
            .then((p) => {
                if (!p.ok) {
                    throw new Error("failed to get orders");
                }
                return p.json();
            })
            .then((p) => {
                setResultMetrics(p);
            })
            .catch();
        getSampleMetrics()
            .then((p) => {
                if (!p.ok) {
                    throw new Error("failed to get orders");
                }
                return p.json();
            })
            .then((p) => {
                setSampleMetrics(p);
            })
            .catch();
    }, []);

    return (
        <ThemeProvider theme={mdTheme}>
            <Box sx={{ display: "flex" }}>
                <CssBaseline />
                <AppBar name="Dashboard" />
                <Box
                    component="main"
                    sx={{
                        backgroundColor: (theme) =>
                            theme.palette.mode === "light"
                                ? theme.palette.grey[100]
                                : theme.palette.grey[900],
                        flexGrow: 1,
                        height: "100vh",
                        overflow: "auto",
                    }}
                >
                    <Toolbar />
                    <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
                        <Grid container spacing={3}>
                            <Grid item xs={12} md={8} lg={9}>
                                <Paper
                                    sx={{
                                        p: 2,
                                        display: "flex",
                                        flexDirection: "column",
                                        height: 240,
                                    }}
                                >
                                    <Chart orders={orderMetrics?.OrdersByDay} />
                                </Paper>
                            </Grid>
                            <Grid item xs={12} md={4} lg={3}>
                                <SmallMetric value={orderMetrics?.OrdersToday} subText="Orders Today" title="Orders Today" />
                            </Grid>
                            <Grid item xs={12} md={4} lg={3}>
                                <SmallMetric value={orderMetrics?.RecentPatients} subText="Last 30 Days" title="New Patients" />
                            </Grid>
                            <Grid item xs={12} md={4} lg={3}>
                                <SmallMetric value={orderMetrics?.RecentFacilities} subText="Last 30 Days" title="New Facilities" />
                            </Grid>
                            <Grid item xs={12} md={6} lg={6}>
                                <OrderMetric orderValues={orderMetrics} title="Order Trends" />
                            </Grid>
                            <Grid item xs={12} md={4} lg={3}>
                                <SmallMetric value={sampleMetrics?.SamplesCollected} subText="Today" title="Samples Collected" />
                            </Grid>
                            <Grid item xs={12} md={4} lg={3}>
                                <SmallMetric value={sampleMetrics?.SamplesReceived} subText="Today" title="Samples Received" />
                            </Grid>
                            <Grid item xs={12} md={4} lg={3}>
                                <SmallMetric value={resultMetrics?.Pending} subText="Total" title="Results Pending" />
                            </Grid>
                            <Grid item xs={12} md={4} lg={3}>
                                <SmallMetric value={resultMetrics?.RecentlyResulted} subText="Today" title="Results Released" />
                            </Grid>
                            <Grid item xs={12}>
                                <Paper
                                    sx={{
                                        p: 2,
                                        display: "flex",
                                        flexDirection: "column",
                                    }}
                                >
                                    <Title>Recent Orders</Title>
                                    <Box height={370}>
                                        <MainOrdersTable
                                            url="/orders?limit=25"
                                        />
                                    </Box>
                                </Paper>
                            </Grid>
                            <Grid item xs={12}>
                                <Paper
                                    sx={{
                                        p: 2,
                                        display: "flex",
                                        flexDirection: "column",
                                    }}
                                >
                                    <Title>Recently Added Patients</Title>
                                    <Box height={350}>
                                        <PatientsTable
                                            getPatients={() => getPatients(25)}
                                        />
                                    </Box>
                                </Paper>
                            </Grid>
                        </Grid>
                        <Copyright sx={{ pt: 4 }} />
                    </Container>
                </Box>
            </Box>
        </ThemeProvider>
    );
}

export default function Dashboard() {
    return <DashboardContent />;
}
