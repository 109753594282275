import React, { useCallback } from "react";
import { SessionContext } from "../hooks/getContext";
import { useNavigate } from "react-router-dom";
import { useState, useMemo, useEffect, useRef, useContext } from "react";
import Title from "./Title";
import {
    Box,
    DialogContent,
    Stack,
    Button,
    Link,
    Tooltip,
    TextField,
    Popover,
    Dialog,
    IconButton,
} from "@mui/material";
import { alpha, styled, lighten } from "@mui/material/styles";
import {
    DataGridPro, gridClasses,
    GridToolbarContainer,
    GridToolbarColumnsButton,
    GridToolbarFilterButton,
    GridToolbarDensitySelector,
    GridToolbarExport,
    GridToolbarQuickFilter,
} from "@mui/x-data-grid-pro";
import { PictureAsPdf, Markunread, MarkAsUnread, ReceiptLong, Download, Draw } from "@mui/icons-material";
import { FormatDateUTC, FormatDate, FormatDateTime, FormatDateMMDDYYYY } from "../services/util";
import { updateOrderView } from "../services/orders";
import { GetOrderStatusChips, GetOrderVerificationChips } from "./StatusChips";
import { useFetch, apiFetch } from "../services/fetch";
import { FetchLoader } from "./FetchLoader";
import { ErrorSnackbar } from "./ErrorSnackbar";
import { useDataGridState } from "../hooks/useDataGridState";
import dayjs from "dayjs";
import utc from 'dayjs/plugin/utc';
import { paymentMethodIndex } from "../services/util";
import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd';
import { VerifierIndex } from "../constants/constants";
import { CopyButtonWithText } from "./CopyButton";

dayjs.extend(utc);

const ODD_OPACITY = 0.2;
const StyledDataGrid = styled(DataGridPro)(({ theme }) => ({
    [`& .${gridClasses.row}.notViewed`]: {
        backgroundColor: lighten(theme.palette.success.main, 0.75),
        "&:hover, &.Mui-hovered": {
            backgroundColor: alpha(theme.palette.primary.main, ODD_OPACITY),
            "@media (hover: none)": {
                backgroundColor: "transparent",
            },
        },
        "&.Mui-selected": {
            backgroundColor: alpha(
                theme.palette.primary.main,
                ODD_OPACITY + theme.palette.action.selectedOpacity
            ),
            "&:hover, &.Mui-hovered": {
                backgroundColor: alpha(
                    theme.palette.primary.main,
                    ODD_OPACITY +
                    theme.palette.action.selectedOpacity +
                    theme.palette.action.hoverOpacity
                ),
                // Reset on touch devices, it doesn't add specificity
                "@media (hover: none)": {
                    backgroundColor: alpha(
                        theme.palette.primary.main,
                        ODD_OPACITY + theme.palette.action.selectedOpacity
                    ),
                },
            },
        },
    },
    [`& .${gridClasses.row}.stat`]: {
        backgroundColor: lighten(theme.palette.error.main, 0.85),
        "&:hover, &.Mui-hovered": {
            backgroundColor: alpha(theme.palette.primary.main, ODD_OPACITY),
            "@media (hover: none)": {
                backgroundColor: "transparent",
            },
        },
        "&.Mui-selected": {
            backgroundColor: alpha(
                theme.palette.primary.main,
                ODD_OPACITY + theme.palette.action.selectedOpacity
            ),
            "&:hover, &.Mui-hovered": {
                backgroundColor: alpha(
                    theme.palette.primary.main,
                    ODD_OPACITY +
                    theme.palette.action.selectedOpacity +
                    theme.palette.action.hoverOpacity
                ),
                // Reset on touch devices, it doesn't add specificity
                "@media (hover: none)": {
                    backgroundColor: alpha(
                        theme.palette.primary.main,
                        ODD_OPACITY + theme.palette.action.selectedOpacity
                    ),
                },
            },
        },
    },
}));

const CustomToolbar = ({ handleBarcodeFilter, handleBarcodeClose, barcodeAnchor, setBarcodeFilter }) => {
    const open = Boolean(barcodeAnchor);
    const id = open ? 'simple-popover' : undefined;
    const { user } = useContext(SessionContext);

    const handleBarcodeFilterText = (e) => {
        let text = e.target.value
        const barcodes = text.replace(/[\r\n]+/g, ' ').split(' ').filter(barcode => barcode.trim() !== '');

        setBarcodeFilter(barcodes)
    }

    return (
        <GridToolbarContainer>
            <Stack direction="row" justifyContent="space-between" width="100%">
                <div>
                    <GridToolbarColumnsButton />
                    <GridToolbarFilterButton />
                    <GridToolbarDensitySelector />
                    {user.Roles && !user.Roles.includes("Sample Handler") && !user.Roles.includes("Sample Handler Manager") && <GridToolbarExport />}
                    <Button startIcon={<PlaylistAddIcon />} onClick={handleBarcodeFilter} size="small" id={id}>Barcodes</Button>
                    <Popover
                        id={id}
                        open={open}
                        anchorEl={barcodeAnchor}
                        onClose={handleBarcodeClose}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left',
                        }}
                    >
                        <TextField sx={{ p: 1 }} onChange={handleBarcodeFilterText} label="Input Barcodes" />
                    </Popover>
                </div>
                <GridToolbarQuickFilter />
            </Stack>
        </GridToolbarContainer>
    );
}


export default function OrdersTable({
    setOrder,
    order,
    title,
    ordersURL,
    autoHeight,
    showRequestedCollectionDate,
    deletedColumns,
}) {
    const [viewed, setViewed] = useState({});
    const apiRef = useRef();
    const [initState, setInitState] = useState();
    const [initialState, setInitialState] = useDataGridState({}, apiRef, 'dataGridTable-orders')
    const [downloading, setDownloading] = useState(false)
    const [downloadError, setDownloadError] = useState(null)
    const [restoreError, setRestoreError] = useState(null)
    const [paginationModel, setPaginationModel] = useState({ pageSize: 500, page: 0 })
    const [queryOptions, setQueryOptions] = useState({
        items: [], logicOperator: 'and', quickFilterExcludeHiddenColumns: false, quickFilterLogicOperator: 'and', quickFilterValues: [],
        sorts: [{ field: "ID", sort: "desc" }]
    });
    const [filterModel, setFilterModel] = useState({
        items: [],
    });
    const [columnVisibility, setColumnVisibility] = useState({
        RequestedCollectionDate: !!showRequestedCollectionDate,
    })
    const { user } = useContext(SessionContext);
    const [barcodeFilterAnchor, setBarcodeFilterAnchor] = useState(null)
    const navigate = useNavigate();

    useEffect(() => {
        setInitState(initialState)
    }, [initialState])

    let fetchURL = ordersURL + `?limit=${paginationModel.pageSize}&page=${paginationModel.page}`
    if (ordersURL.includes("?")) {
        fetchURL = ordersURL + `&limit=${paginationModel.pageSize}&page=${paginationModel.page}`
    }

    const { data = { Data: [], Count: 0 }, error, isLoading, refetch } = useFetch(fetchURL, "POST", queryOptions);

    useEffect(() => {
        console.log('refetching')
        refetch()
    }, [paginationModel, queryOptions])

    const handlePaginationModelChange = (newPaginationModel) => {
        console.log(paginationModel)
        setPaginationModel({ ...paginationModel, ...newPaginationModel })
    }

    /* @ts-ignore */
    const handleSortModelChange = useCallback((sortModel) => {
        /* @ts-ignore */
        setQueryOptions({ ...queryOptions, sorts: sortModel })
        console.log(sortModel)
    }, [queryOptions])

    console.log(queryOptions)
    /* @ts-ignore */
    const handleFilterModelChange = useCallback((filterModel) => {
        /* @ts-ignore */
        setFilterModel(filterModel)
        setQueryOptions({ ...queryOptions, ...filterModel })
    }, [queryOptions])


    const restoreOrder = async (id) => {
        try {
            await apiFetch(`/orders/restore/${id}`, 'POST', {})
            navigate(`/orders/${id}`)
        } catch (e) {
            setRestoreError("Failed to restore order")
        }
    }

    const downloadReport = async (orderId) => {
        setDownloading(true)
        try {
            const orderDetails = await apiFetch(`/orders/${orderId}`)
            const patientDetails = await apiFetch(`/patients/${orderDetails?.Patient?.ID}`)
            apiFetch(`/orders/${orderId}/resultReport`)
                .then((blob) => {
                    // Create a Blob from the PDF Stream
                    const file = new Blob([blob], { type: 'application/pdf' });

                    // Create an object URL for the Blob
                    const objectUrl = URL.createObjectURL(file);

                    // Create a temporary anchor element
                    const link = document.createElement('a');
                    link.href = objectUrl;
                    link.download = `${orderId}-${patientDetails?.LastName.toUpperCase()}-${patientDetails?.FirstName.toUpperCase()}-RESULT.pdf`;  // Set the filename here

                    // Append to the document and trigger the download
                    document.body.appendChild(link);
                    link.click();

                    // Clean up
                    document.body.removeChild(link);
                    URL.revokeObjectURL(objectUrl);
                }).finally(() => { setDownloading(false) })
        } catch (e) {
            setDownloadError(e)
        }
    }

    const toggleViewed = (row) => {
        const v = getViewed(row.row);
        setViewed({ ...viewed, [row.id]: !v });
        updateOrderView(row.id, v);
    };

    const renderMarkViewButton = (row) => {
        const v = getViewed(row.row);
        if (v) {
            return (
                <strong>
                    <IconButton onClick={() => toggleViewed(row)}>
                        <Markunread />
                    </IconButton>
                </strong>
            );
        } else {
            return (
                <strong>
                    <IconButton onClick={() => toggleViewed(row)}>
                        <MarkAsUnread />
                    </IconButton>
                </strong>
            );
        }
    };

    const GetSignature = React.memo(({ value }) => {
        console.log("v", value)
        if (!value?.ProviderSignatureID) {
            return <></>
        }
        return (
            <Tooltip title="Signed" placement="top-start">
                <Draw color="success" />
            </Tooltip>

        )

    })

    const GetFlags = React.memo(({ value }) => {
        return (
            <Stack direction="row" spacing={1}>
                <GetProviderNotesButton value={value} />
                <GetSignature value={value?.row} />
            </Stack>
        )
    });

    const getVerificationStatus = (row) => {
        if (row.row.Verification === "") {
            return "Verified"
        }
        return row.row?.Verification.split(',').map((v) => VerifierIndex[v]).join(", ")
    }

    let columns = useMemo(() => [
        {
            field: "Mark Viewed",
            headerName: "Read",
            width: 60,
            renderCell: (row) => renderMarkViewButton(row),
            filterable: false,
            sortable: false,
        },
        {
            field: "Result",
            headerName: "Result",
            width: 90,
            renderCell: (row) => renderResultButton(row),
            filterable: false,
            sortable: false,
        },
        { field: "ID", headerName: "ID", width: 60, type: 'number' },
        {
            field: "Samples", headerName: "Barcodes", width: 180
        },
        {
            field: "Facility",
            headerName: "Facility",
            width: 200,
            valueGetter: (row) => row.row.FacilityName,
        },
        {
            field: "Status",
            headerName: "Status",
            width: 230,
            renderCell: (row) => <GetOrderStatusChips order={row.row} />,
        },
        {
            field: "RequestedCollectionDate",
            headerName: "Requested Collection",
            width: 180,
            valueGetter: (row) =>
                FormatDateTime(row.row.RequestedCollectionDate),
        },
        {
            field: "PatientFirstName",
            headerName: "Patient First",
            width: 100,
            renderCell: ({ value }) => <CopyButtonWithText text={value} copyText={value} />
        },
        {
            field: "PatientLastName",
            headerName: "Patient Last",
            width: 100,
            renderCell: ({ value }) => <CopyButtonWithText text={value} copyText={value} />
        },
        {
            field: "PatientDOB",
            headerName: "Patient DOB",
            width: 120,
            type: "date",
            valueGetter: (params) => params?.row?.PatientDOB && dayjs.utc(params?.row.PatientDOB).toDate(),
            valueFormatter: ({ value }) => { return value && FormatDateUTC(value) },
            renderCell: (params) => (<CopyButtonWithText text={dayjs.utc(params?.row.PatientDOB).format('YYYY-MM-DD')} copyText={dayjs.utc(params?.row.PatientDOB).format('MM/DD/YYYY')} />)
        },
        {
            field: "PatientInsurance",
            headerName: "Patient Insurance",
            width: 120,
            valueGetter: (row) => row.row?.PatientInsurances,
        },
        {
            field: "PaymentMethod",
            headerName: "Payment Method",
            width: 120,
            valueGetter: (row) => paymentMethodIndex[row.value],
        },
        {
            field: "Collectors",
            headerName: "Collectors",
            width: 120,
        },
        {
            field: "Provider",
            headerName: "Provider",
            width: 120,
            valueGetter: (row) => row.row?.ProviderFirstName + " " + row.row?.ProviderLastName,
        },
        {
            field: "ProviderNPI",
            headerName: "Provider NPI",
            width: 120,
            valueGetter: (row) => row.row?.ProviderNPI,
        },
        {
            field: "Tests",
            headerName: "Tests",
            width: 120,
            valueGetter: (row) => row.row?.Tests,
        },
        {
            field: "DiagnosisCodes",
            headerName: "ICD10 Codes",
            width: 120,
            valueGetter: (row) => row.row?.DiagnosisCodes,
        },
        {
            field: "CreatedAt",
            headerName: "Date Submitted",
            width: 120,
            type: "date",
            valueGetter: (row) => dayjs.utc(row.row.CreatedAt).toDate(),
            valueFormatter: ({ value }) => { return value && FormatDate(value) },
        },
        {
            field: "DateCollected",
            headerName: "Date Collected",
            width: 120,
            valueFormatter: ({ value }) => { return value && FormatDate(value) },
        },
        {
            field: "DateReceived",
            headerName: "Date Received",
            width: 120,
            valueFormatter: ({ value }) => { return value && FormatDate(value) },
        },
        {
            field: "DateReported",
            headerName: "Date Reported",
            width: 120,
            type: "date",
            valueGetter: (row) => dayjs.utc(row.row.DateReported).toDate(),
            valueFormatter: ({ value }) => { if (value.toString() === "Invalid Date") { return "" } return value && FormatDate(value) },
        },
        {
            field: "Flags",
            headerName: "Flags",
            width: 100,
            renderCell: (row) => <GetFlags value={row} />,
            filterable: false,
            sortable: false,
        },
        {
            field: "Verification",
            headerName: "Verification Status",
            width: 250,
            valueGetter: (row) => getVerificationStatus(row),
            renderCell: (row) => <GetOrderVerificationChips verifiers={row.row.Verification} />,
            filterable: false,
            sortable: false,
        },
        {
            field: "Action",
            headerName: "Action",
            width: 100,
            filterable: false,
            sortable: false,
            renderCell: (row) => renderDetailsButton(row),
        },
    ], []);

    const getUniqueCollectedDate = (samples) => {
        const dates = samples.map((s) => FormatDateMMDDYYYY(s.DateCollected))
        return [...new Set(dates)].join(", ")
    }

    const getUniqueReceivedDate = (samples) => {
        const dates = samples.map((s) => FormatDateMMDDYYYY(s.DateReceived))
        return [...new Set(dates)].join(", ")
    }

    if (deletedColumns) {
        const newCols = columns.slice(2, 11)
        columns = [...newCols]

        if (user.Roles && user.Roles.includes("Admin")) {
            columns = [...newCols,
            {
                field: "Action",
                headerName: "Action",
                width: 100,
                renderCell: (row) => renderRestoreButton(row),
            },
            ]
        }
    }

    const renderResultButton = (params) => {
        let color = "error";
        if (!params.row.ResultsAvailable) {
            color = "disabled"
        }
        return (
            <>
                <Link href={"/orders/" + params.id + "/resultReport"} target="_blank">
                    <PictureAsPdf color={color} />{" "}
                </Link>
                <IconButton onClick={() => downloadReport(params.id)}>
                    <Download />
                </IconButton>
            </>
        );
    };
    const renderRestoreButton = (params) => {
        return (
            <strong>
                <Button
                    component="a"
                    variant="contained"
                    color="success"
                    size="small"
                    onClick={() => restoreOrder(params.id)}
                >
                    Restore
                </Button>
            </strong>
        );
    };

    const GetProviderNotesButton = ({ value }) => {
        if (!value?.row?.ProviderNotesRequired) {
            return <></>
        }
        let color = "error"
        if (value?.row?.ProviderNotesAdded) {
            color = "success"
        }

        return (
            <strong>
                <Link href={"/orders/" + value.id + "/chartNotes"} target="_blank">
                    <ReceiptLong color={color} />{" "}
                </Link>
            </strong>
        );
    };

    const renderDetailsButton = (params) => {
        return (
            <strong>
                <Button
                    component="a"
                    variant="contained"
                    color="primary"
                    size="small"
                    // style={{ marginLeft: 16 }}
                    href={`/orders/${params.id}`}
                    target="_blank"
                >
                    Details
                </Button>
            </strong>
        );
    };

    const handleBarcodeFilter = (event) => {
        setBarcodeFilterAnchor(event.currentTarget)
    }

    const handleBarcodeClose = (_) => {
        setBarcodeFilterAnchor(null)
    }

    const handleBarcodesFilter = (barcodeFilter) => {
        console.log("barcodeFilter", barcodeFilter)
        if (barcodeFilter.length < 1) {
            return
        }

        const filterItems = barcodeFilter.map((barcode) => ({
            field: "Barcodes",
            operator: "contains",
            value: barcode
        }))

        /* @ts-ignore */
        handleFilterModelChange({ ...queryOptions, items: filterItems, logicOperator: "or" })

        // const filtered: Result[] = allResults.filter((result) => barcodeFilter.includes(result.Sample.Barcode))
        // setAllResults(filtered)
    }


    const getViewed = (row) => {
        // check local 'cache' for viewChanges
        if (viewed.hasOwnProperty(row.ID)) {
            return viewed[row.ID];
        }

        return !row.ViewedSinceLastModified;
    };

    if (error !== null || isLoading) {
        return <FetchLoader error={error} isLoading={isLoading} />
    }

    return (
        <React.Fragment>
            <Dialog open={downloading}>
                <DialogContent>
                    <Box width="230px" height="100px" alignItems="center" display="flex" justifyContent="center">
                        <FetchLoader label={
                            <Stack direction="column" justifyContent="center">
                                <p style={{ textAlign: "center", margin: 0 }} >Downloading result report.</p>
                                <p style={{ textAlign: "center", margin: 0 }} >Please wait.</p>
                            </Stack>
                        } isLoading={downloading} error={downloadError} />
                    </Box>
                </DialogContent>
            </Dialog>
            <ErrorSnackbar error={restoreError} setError={setRestoreError} />
            <Title>{title}</Title>
            <StyledDataGrid
                apiRef={apiRef}
                getRowId={(row) => row.ID}
                getRowClassName={(params) => {
                    if (params.row.Stat) {
                        return "stat";
                    } else if (
                        getViewed(params.row) &&
                        (params.row.Status === "Completed" ||
                            params.row.Status === "Rejected")
                    ) {
                        return "notViewed";
                    }
                    return;
                }}
                initialState={{
                    ...initState
                }}
                density="compact"
                pagination
                paginationMode="server"
                paginationModel={paginationModel}
                onPaginationModelChange={handlePaginationModelChange}
                sortingMode="server"
                sortModel={queryOptions.sorts}
                onSortModelChange={handleSortModelChange}
                filterMode="server"
                filterModel={filterModel}
                onFilterModelChange={handleFilterModelChange}
                pageSizeOptions={[25, 50, 100, 500, 1000, 3000]}
                sx={{
                    "& .MuiDataGrid-columnHeaderCheckbox .MuiDataGrid-columnHeaderTitleContainer":
                    {
                        display: "none",
                    },
                    height: "95%",
                }}
                onRowSelectionModelChange={setOrder}
                columnVisibilityModel={columnVisibility}
                onColumnVisibilityModelChange={setColumnVisibility}
                keepNonExistentRowsSelected
                rowSelectionModel={order}
                components={{ Toolbar: CustomToolbar }}
                componentsProps={{
                    toolbar: {
                        showQuickFilter: true,
                        quickFilterProps: { debounceMs: 500 },
                        handleBarcodeFilter: handleBarcodeFilter,
                        handleBarcodeClose: handleBarcodeClose,
                        barcodeAnchor: barcodeFilterAnchor,
                        setBarcodeFilter: handleBarcodesFilter,
                    },
                }}
                autoHeight={autoHeight}
                rows={data?.Data || []}
                rowCount={data?.Count || 0}
                columns={columns}
            />
        </React.Fragment>
    );
}
