import { alpha, styled, lighten } from "@mui/material/styles";
import {
	DataGridPro, gridClasses,
} from "@mui/x-data-grid-pro";

const ODD_OPACITY = 0.2;
export const OrdersStyledDataGrid = styled(DataGridPro)(({ theme }) => ({
	[`& .${gridClasses.row}.notViewed`]: {
		backgroundColor: lighten(theme.palette.success.main, 0.75),
		"&:hover, &.Mui-hovered": {
			backgroundColor: alpha(theme.palette.primary.main, ODD_OPACITY),
			"@media (hover: none)": {
				backgroundColor: "transparent",
			},
		},
		"&.Mui-selected": {
			backgroundColor: alpha(
				theme.palette.primary.main,
				ODD_OPACITY + theme.palette.action.selectedOpacity
			),
			"&:hover, &.Mui-hovered": {
				backgroundColor: alpha(
					theme.palette.primary.main,
					ODD_OPACITY +
					theme.palette.action.selectedOpacity +
					theme.palette.action.hoverOpacity
				),
				// Reset on touch devices, it doesn't add specificity
				"@media (hover: none)": {
					backgroundColor: alpha(
						theme.palette.primary.main,
						ODD_OPACITY + theme.palette.action.selectedOpacity
					),
				},
			},
		},
	},
	[`& .${gridClasses.row}.stat`]: {
		backgroundColor: lighten(theme.palette.error.main, 0.85),
		"&:hover, &.Mui-hovered": {
			backgroundColor: alpha(theme.palette.primary.main, ODD_OPACITY),
			"@media (hover: none)": {
				backgroundColor: "transparent",
			},
		},
		"&.Mui-selected": {
			backgroundColor: alpha(
				theme.palette.primary.main,
				ODD_OPACITY + theme.palette.action.selectedOpacity
			),
			"&:hover, &.Mui-hovered": {
				backgroundColor: alpha(
					theme.palette.primary.main,
					ODD_OPACITY +
					theme.palette.action.selectedOpacity +
					theme.palette.action.hoverOpacity
				),
				// Reset on touch devices, it doesn't add specificity
				"@media (hover: none)": {
					backgroundColor: alpha(
						theme.palette.primary.main,
						ODD_OPACITY + theme.palette.action.selectedOpacity
					),
				},
			},
		},
	},
}));

