export async function addUser(user) {
    return fetch(`${process.env.REACT_APP_API_PROXY}/v1/users/`, {
        method: "POST",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
        },
        credentials: "include",
        body: JSON.stringify(user),
    });
}

export async function resetUser(userID) {
    return fetch(`${process.env.REACT_APP_API_PROXY}/v1/users/` + userID, {
        method: "PUT",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
        },
        credentials: "include",
            body: JSON.stringify({PasswordReset: true}),
    });
}

export async function deactivateUser(userID) {
    return fetch(`${process.env.REACT_APP_API_PROXY}/v1/users/` + userID, {
        method: "DELETE",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
        },
        credentials: "include",
        body: "{}",
    });
}

export async function reactivateUser(userID) {
    return fetch(`${process.env.REACT_APP_API_PROXY}/v1/users/` + userID, {
        method: "PUT",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
        },
        credentials: "include",
            body: JSON.stringify({IsActive: true}),
    });
}
export async function updateUser(user, userID) {
    return fetch(`${process.env.REACT_APP_API_PROXY}/v1/users/` + userID, {
        method: "POST",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
        },
        credentials: "include",
        body: JSON.stringify(user),
    });
}


export async function getUser(userID) {
    return fetch(`${process.env.REACT_APP_API_PROXY}/v1/users/${userID}`, {
        method: "GET",
        credentials: "include"
    })
}
export async function getUsers() {
    return fetch(`${process.env.REACT_APP_API_PROXY}/v1/users`, {
        method: "GET",
        credentials: "include"
    }).then((response) => response.json());
}
export async function getRoles() {
    return fetch(`${process.env.REACT_APP_API_PROXY}/v1/users/roles`, {
        method: "GET",
        credentials: "include"
    }).then((response) => response.json());
}
