import { InputAdornment } from "@mui/material";
import { CustomTextField } from "./CustomTextField";
import { useFormikContext } from "formik";

function TestFeeInput({ test, index }) {
    const { values } = useFormikContext();
    return (
        <CustomTextField
            label={test.Name}
            required
            name={`Fees.${index}.Fee`}
            value={values?.Fees?.[index]?.Fee}
            InputLabelProps={{ shrink: true }}
            InputProps={{
                required: true,
                startAdornment: (
                    <InputAdornment position="start">$</InputAdornment>
                ),
            }}
            inputProps={{ type: "number", min: 0, step: 0.01 }}
        />
    );
}

export default TestFeeInput;
