import React, { useRef, useState } from "react";
import {
    Alert,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import SaveIcon from "@mui/icons-material/Save";
import ErrorAlert from "./ErrorAlert";

type BulkEditProps = {
    open: boolean;
    setOpen: (open: boolean) => void;
}

function BulkEdit({ open, setOpen }: BulkEditProps) {
    const [processing, setProcessing] = useState(false);
    const [complete, setComplete] = useState(false);
    const [error, setError] = useState<string | null>(null);
    const [file, setFile] = useState<FileList | null>(null);

    const handleUpload = async () => {
        if (file) {
            const abortController = new AbortController(); // Create an instance of AbortController
            const signal = abortController.signal;

            setProcessing(true);
            try {
                const response = await fetch(`${process.env.REACT_APP_API_PROXY}/v1/orders/update`, {
                    credentials: "include",
                    method: "POST",
                    body: file.item(0),
                    signal: signal,
                });
                if (response.ok) {
                    setComplete(true);
                    setFile(null);
                    setError(null);
                } else {
                    const resp = await response.json()
                    setError(resp['error']);
                }
            } catch (error) {
                setError("Failed to submit edit request")
            } finally {
                setProcessing(false);
            }
        }
    };

    return (
        <Dialog open={open}>
            <DialogTitle>Bulk Edit</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    <p>Please upload the CSV file by selecting it below.</p>

                    <p>Ensure your CSV file includes the following column headers:</p>
                    <ul>
                        <li><strong>ID</strong> (Mandatory)</li>
                        <li>ICD10 Codes (Optional)</li>
                        <li>Notes (Optional)</li>
                    </ul>
                    <p><strong>Note:</strong> The <strong>ID</strong> column is compulsory for every entry. At least one additional column (either ICD10 Codes or Notes) is required for the upload to be successful.</p>

                    <p>This process is transactional. This means if an error occurs with any data row, no changes will be applied, and the entire upload will be reverted to ensure data integrity.</p>

                    <p>Additionally, each order is updated individually, so you may experience a delay if you are uploading a large number of changes.</p>

                </DialogContentText>
                <input
                    required
                    type="file"
                    accept=".csv"
                    onChange={(e) => {
                        console.log(e);
                        // @ts-ignore
                        setFile(e.target.files);
                    }}
                />
            </DialogContent>
            <ErrorAlert error={error} />
            {complete && <Alert sx={{ m: 2 }} severity="success">Success!</Alert>}
            <DialogActions>
                <Button
                    color="primary"
                    variant="contained"
                    disabled={processing}
                    onClick={() => {
                        setOpen(false);
                        setComplete(false);
                        setError(null);
                        setFile(null);
                    }}
                >
                    {complete ? "Close" : "Cancel"}
                </Button>
                {!complete &&
                    <LoadingButton
                        startIcon={<SaveIcon />}
                        color="success"
                        variant="contained"
                        loading={processing}
                        disabled={processing || !file}
                        onClick={handleUpload}
                    >
                        <span>Begin Upload</span>
                    </LoadingButton>}
            </DialogActions>
        </Dialog>
    )

}

export default BulkEdit;
