import { useEffect, useState } from "react";
import { Autocomplete, TextField } from "@mui/material";
import React from "react";
import { Facility } from "../../constants/types";
import { apiFetch } from "../../services/fetch";


type FacilityPickerProps = {
    facility: Facility | null;
    setFacility: (facility: Facility | null) => void;
}

function FacilityPicker({ facility, setFacility, ...props }: FacilityPickerProps) {
    const [facilities, setFacilities] = useState<Facility[]>([]);
    useEffect(() => {

        const fetchFacilities = async () => {
            const response = await apiFetch("/facilities");
            setFacilities(response);
        };

        fetchFacilities();
    }, []);
    return (
        <Autocomplete
            options={facilities.sort((a, b) => a?.Name.localeCompare(b?.Name))}
            getOptionLabel={(row) => row.Name || ""}
            value={facility || null}
            isOptionEqualToValue={(option, value) =>
                option.ID === value.ID
            }
            onChange={(_, newValue) => {
                setFacility(newValue)
            }}
            {...props}
            renderInput={(params) => {
                return (
                    <TextField
                        {...params}
                        label="Facility"
                        size="small"
                    />
                );
            }}
        />
    );
}

export default FacilityPicker;
