import React from "react";
import { RadioGroup, Box, FormControlLabel, Radio, Typography } from "@mui/material";

type OrderScheduleSampleCollectionProps = {
    disableScheduleCollection: boolean;
    disableCollectNow: boolean;
    sampleCollection: string;
    setSampleCollection: (sampleCollection: string) => void;
}

function OrderScheduleSampleCollection({ disableScheduleCollection, disableCollectNow, sampleCollection, setSampleCollection }: OrderScheduleSampleCollectionProps) {
    return (
        <RadioGroup
            aria-label="sampleCollection"
            name="sampleCollection"
            defaultValue={
                disableScheduleCollection
                    ? "collectNow"
                    : "scheduleCollection"
            }
            value={sampleCollection}
            onChange={(e) => setSampleCollection(e.target.value)}
        >
            {disableScheduleCollection ? null : (
                <Box mb={2}>
                    <FormControlLabel
                        value="scheduleCollection"
                        control={<Radio />}
                        label={
                            <div>
                                <Typography>
                                    Schedule Sample Collection
                                </Typography>
                                <Typography variant="caption">
                                    Select this option to arrange a
                                    future date and time for the
                                    laboratory to collect the
                                    sample.
                                </Typography>
                            </div>
                        }
                    />
                </Box>
            )}
            {disableCollectNow ? null : (
                <Box mb={2}>
                    <FormControlLabel
                        value="collectNow"
                        control={<Radio />}
                        label={
                            <div>
                                <Typography>
                                    Collect Sample Now
                                </Typography>
                                <Typography variant="caption">
                                    Choose this option if the
                                    sample(s) for the test(s) is
                                    ready for collection.
                                </Typography>
                            </div>
                        }
                    />
                </Box>
            )}
        </RadioGroup>
    )
}

export default OrderScheduleSampleCollection;
