export async function getMethodDetails(methodID) {
    if (methodID === undefined) {
        return;
    }
    return fetch(`${process.env.REACT_APP_API_PROXY}/v1/methods/` + methodID, {
        credentials: "include",
    }).then((data) => data.json());
}

export async function getInstrument(id) {
    return fetch(`${process.env.REACT_APP_API_PROXY}/v1/instruments/${id}`, {
        credentials: "include",
    })
}
export async function getInstruments() {
    return fetch(`${process.env.REACT_APP_API_PROXY}/v1/instruments`, {
        credentials: "include",
    })
}
export async function addInstrument(instrument) {
    return fetch(`${process.env.REACT_APP_API_PROXY}/v1/instruments`, {
        credentials: "include",
        method: "POST",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
        },
        body: JSON.stringify(instrument),
    });
}

export async function updateInstrument(id, instrument) {
    return fetch(`${process.env.REACT_APP_API_PROXY}/v1/instruments/${id}`, {
        credentials: "include",
        method: "POST",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
        },
        body: JSON.stringify(instrument),
    });
}

export async function getCalculations() {
    return fetch(`${process.env.REACT_APP_API_PROXY}/v1/methods/calculations`, {
        credentials: "include",
    })
}
export async function getMethods() {
    return fetch(`${process.env.REACT_APP_API_PROXY}/v1/methods`, {
        credentials: "include",
    })
}

export async function addMethod(method) {
    return fetch(`${process.env.REACT_APP_API_PROXY}/v1/methods`, {
        method: "POST",
        credentials: "include",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
        },
        body: JSON.stringify(method),
    })
}

export async function updateMethod(method, methodID) {
    return fetch(`${process.env.REACT_APP_API_PROXY}/v1/methods/` + methodID, {
        method: "POST",
        credentials: "include",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
        },
        body: JSON.stringify(method),
    })
}
