export async function searchPatients(facility_id, newValue) {
    return fetch(
        `${process.env.REACT_APP_API_PROXY}/v1/patients/search?facility_id=${facility_id}&first_name=${newValue}&last_name=${newValue}&dob=${newValue}`,
        {
            method: "GET",
            credentials: "include",
        }
    );
}

export async function searchPatientsByFacility(facility_id) {
    return fetch(
        `${process.env.REACT_APP_API_PROXY}/v1/patients/search?facility_id=${facility_id}`,
        {
            method: "GET",
            credentials: "include",
        }
    );
}

export async function getPatientRecentTest(id) {
    return fetch(`${process.env.REACT_APP_API_PROXY}/v1/patients/${id}/recentTests`, {
        credentials: "include",
    });
}

export async function getPatients(limit) {
    let l;
    if (limit !== undefined) {
        l = limit;
    } else {
        l = 1000;
    }
    return fetch(`${process.env.REACT_APP_API_PROXY}/v1/patients?limit=${l}`, {
        credentials: "include",
    });
}

export async function getPatient(patient_id) {
    return fetch(
        `${process.env.REACT_APP_API_PROXY}/v1/patients/` + patient_id,
        { credentials: "include" }
    );
}

export async function addPatient(patient) {
    return fetch(`${process.env.REACT_APP_API_PROXY}/v1/patients`, {
        method: "POST",
        credentials: "include",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
        },
        body: JSON.stringify(patient),
    });
}

export async function updatePatient(patientID, patient) {
    return fetch(
        `${process.env.REACT_APP_API_PROXY}/v1/patients/${patientID}`,
        {
            method: "POST",
            credentials: "include",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify(patient),
        }
    );
}

export async function addPatientInsurance(patient_id, insurance) {
    fetch(
        `${process.env.REACT_APP_API_PROXY}/v1/patients/` +
            patient_id +
            "/insurances",
        {
            method: "POST",
            credentials: "include",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify(insurance),
        }
    );
}

export async function addPatientAttachment(patientID, file, type) {
    var data = new FormData();
    data.append("File", file);
    data.append("Type", type);

    return fetch(
        `${process.env.REACT_APP_API_PROXY}/v1/patients/` +
            patientID +
            "/attachments",
        {
            method: "POST",
            credentials: "include",
            body: data,
        }
    );
}

export async function checkInsuranceEligibility(patient, patIns, dateOfService, reason) {
    return fetch(`${process.env.REACT_APP_API_PROXY}/v1/patients/${patient}/insurances/${patIns}`, {
        method: "POST",
        credentials: "include",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
        },
        body: JSON.stringify({DateOfService: dateOfService, Reason: reason}),
    });
}

