import React, { useState, useEffect } from "react";
import * as Yup from "yup";
import { Formik, Form } from "formik";
import Box from "@mui/material/Box";
import { Button, Grid, Stack, TextField } from "@mui/material";
import Typography from "@mui/material/Typography";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { addCollector } from "../services/collectors";
import Alert from "@mui/material/Alert";
import { useFetch } from "../services/fetch";

export default function CollectorInput({ reset, initialValues }) {
    const [error, setError] = useState();

    const { data: allFacilities = [], error: fetchError, isLoading } = useFetch("/facilities")
    if (fetchError) {
        setError(fetchError)
    }

    const validationSchema = Yup.object().shape({
        FirstName: Yup.string().required("First name is required"),
        LastName: Yup.string().required("Last name is required"),
        Email: Yup.string().email(),
        Phone: Yup.string().matches(
            /^(\+\d{1,2}\s?)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/,
            "Phone number is not valid"
        ),
        IsPhlebotomist: Yup.bool(),
        Facilities: Yup.array(),
    });

    const handleSubmit = (values) => {
        console.log(values);
        if (values.ID === undefined) {
            // create new facility
            console.log("createing");
            addCollector(values)
                .catch((err) => setError(err))
                .then(() => reset());
            // props.reset();
        } else {
            // update existing facility
            console.log("updating");
            addCollector(values, values.ID)
                .catch((err) => setError(err))
                .then(() => reset());
        }
    };

    const facilityColumns = [
        {
            field: "Name",
            headerName: "Name",
            width: 200,
        },
    ];

    const showError = () => {
        console.log(error);
        if (error) {
            return (
                <Alert sx={{ m: 2 }} severity="error">
                    {error.message}
                </Alert>
            );
        }
    };

    const CustomTextField = ({
        values,
        name,
        handleChange,
        label,
        onBlur,
        errors,
        touched,
        required,
        ...props
    }) => (
        <TextField
            required={required}
            name={name}
            label={label || name}
            helperText={touched?.[name] && errors?.[name]}
            error={touched?.[name] && Boolean(errors?.[name])}
            size="small"
            onBlur={onBlur}
            onChange={handleChange}
            value={values?.[name]}
            {...props}
        />
    );

    const CheckboxField = ({ values, name, handleChange, label }) => (
        <FormControlLabel
            control={
                <Checkbox
                    name={name}
                    checked={values?.[name]}
                    onChange={handleChange}
                />
            }
            label={label || name}
        />
    );
    return (
        <Box>
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
                enableReinitialize
            >
                {({
                    values,
                    handleChange,
                    errors,
                    touched,
                    setFieldValue,
                    handleBlur,
                }) => (
                    <Form>
                        <Typography sx={{ my: 2 }} variant="h5" gutterBottom>
                            Collector
                        </Typography>
                        <Stack
                            direction={{ sm: "column", md: "row" }}
                            spacing={2}
                        >
                            <CustomTextField
                                values={values}
                                onBlur={handleBlur}
                                required={true}
                                name="FirstName"
                                label="First Name"
                                handleChange={handleChange}
                                touched={touched}
                                errors={errors}
                            />
                            <CustomTextField
                                values={values}
                                onBlur={handleBlur}
                                required={true}
                                name="LastName"
                                label="Last Name"
                                handleChange={handleChange}
                                touched={touched}
                                errors={errors}
                            />
                            <CustomTextField
                                values={values}
                                onBlur={handleBlur}
                                name="Phone"
                                handleChange={handleChange}
                                touched={touched}
                                errors={errors}
                            />
                            <CustomTextField
                                values={values}
                                onBlur={handleBlur}
                                name="Email"
                                handleChange={handleChange}
                                touched={touched}
                                errors={errors}
                            />
                            <CheckboxField
                                values={values}
                                name="IsPhlebotomist"
                                lable="Is Phlebotomist"
                                handleChange={handleChange}
                            />
                        </Stack>
                        {showError()}

                        <Typography sx={{ my: 2 }} variant="h5" gutterBottom>
                            Facilities
                        </Typography>
                        <Box height="400px">
                            {allFacilities && <DataGrid
                                getRowId={(row) => row.ID}
                                loading={isLoading}
                                density="compact"
                                rows={allFacilities}
                                columns={facilityColumns}
                                pageSize={15}
                                components={{ Toolbar: GridToolbar }}
                                componentsProps={{
                                    toolbar: {
                                        showQuickFilter: true,
                                        quickFilterProps: { debounceMs: 500 },
                                    },
                                }}
                                rowSelectionModel={values?.Facilities}
                                onRowSelectionModelChange={(newValues) => {
                                    setFieldValue("Facilities", [...newValues]);
                                }}
                                pageSizeOptions={[15]}
                                keepNonExistentRowsSelected
                                checkboxSelection
                                disableRowSelectionOnClick
                            />}
                        </Box>
                        <Stack direction="row" justifyContent="space-between">
                            <Button
                                variant="outlined"
                                sx={{ my: 2 }}
                                onClick={reset}
                            >
                                Back
                            </Button>
                            <Button
                                type="submit"
                                variant="contained"
                                sx={{ my: 2 }}
                            >
                                Save
                            </Button>
                        </Stack>
                    </Form>
                )}
            </Formik>
        </Box>
    );
}
