import React, { useState, useEffect, useContext } from "react";
import {
    Table,
    FormControl,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Checkbox,
    TextField,
} from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { Input } from "@mui/material";
import { Stack } from "@mui/system";
import { addOrderAttachment, getOrderDetails } from "../services/orders";
import { addPatientAttachment } from "../services/patients";
import { getOrderResults } from "../services/orders";
import { Box } from "@mui/system";
import { getLaboratories } from "../services/tests";
import Alert from "@mui/material/Alert";
import { SessionContext } from "../hooks/getContext";
import ImageCapture from "./ImageCapture";
import ModalImage from "react-modal-image";

function base64ToBlob(base64, contentType) {
    // Decode Base64 string to a binary string
    const binaryString = window.atob(base64);

    // Create a Uint8Array from the binary string
    const length = binaryString.length;
    const bytes = new Uint8Array(length);

    for (let i = 0; i < length; i++) {
        bytes[i] = binaryString.charCodeAt(i);
    }

    // Generate a Blob from the Uint8Array
    return bytes
}

export default function AddAttachment({ open, setOpen, orderID, patientID, callback = () => { } }) {
    // If type is result report it needs to ask user what tests are contained on the report and if they were ran or TNPd
    const { user } = useContext(SessionContext);
    const [type, setType] = useState(
        orderID !== undefined ? "Requisition" : "Demographics"
    );
    const [selectedFile, setSelectedFile] = useState(null);
    const [results, setResults] = useState([]);
    const [laboratories, setLaboratories] = useState([]);
    const [laboratory, setLaboratory] = useState(null);
    const [error, setError] = useState(null);
    const [resultsEnabled, setResultsEnabled] = useState(false);
    const [updateResults, setUpdateResults] = useState(new Map());
    const [photoImg, setPhotoImg] = useState(null);
    const [loading, setLoading] = useState(false);
    const [saving, setSaving] = useState(false);
    const [updateableResults, setUpdateabledResults] = useState([]);
    const handleClose = () => {
        setOpen(false);
    };

    console.log(selectedFile)

    useEffect(() => {
        if (type === "Result") {
            getOrderResults(orderID)
                .then((p) => {
                    if (!p.ok) {
                        throw new Error("Failed load results");
                    }
                    return p.json();
                })
                .then((p) => {
                    setResults(p);
                    setUpdateabledResults(filterUpdatableResults(p));
                })
                .catch((e) => setError(e.message));
            getLaboratories()
                .then((p) => {
                    if (!p.ok) {
                        throw new Error("Failed to load laboratories.");
                    }
                    return p.json();
                })
                .then((p) => {
                    setLaboratories(p);
                })
                .catch((e) => setError(e.message));
        }
    }, [type, orderID]);

    useEffect(() => {
        if (laboratory) {
            setError(null)
        }
    }, [laboratory]);

    useEffect(() => {
        if (selectedFile) {
            setError(null);
        }
    }, [selectedFile]);

    useEffect(() => {
        if (!photoImg) {
            return
        }


        const contentType = photoImg.split(';')[0].split(':')[1];
        const preparedString = photoImg.split(',')[1]
        const b = base64ToBlob(preparedString, contentType)
        const f = new File([b], type, { type: contentType })

        setSelectedFile(f)
    }, [photoImg])

    useEffect(() => {
        if (!orderID) {
            return;
        }

        getOrderDetails(orderID)
            .then((p) => {
                if (!p.ok) {
                    throw new Error("Failed to load order");
                }
                return p.json();
            })
            .then((p) => {
                if (p?.Samples.length > 0) {
                    setResultsEnabled(true);
                }
            })
            .catch((e) => setError(e.message));
    }, [orderID]);

    const allowedToPostResults = () => {
        const requiredRoles = ["Admin", "Technician"]
        const requiredType = "Lab"
        const hasRole = user?.Roles.some((role) => requiredRoles.includes(role))
        const hasType = user?.Type === requiredType

        return hasRole && hasType
    }

    const addAttachment = () => {
        setSaving(true);
        if (selectedFile === null) {
            setError("You must select a file.");
            setSaving(false);
            return;
        }
        if (orderID) {
            addOrdersAttachment();
        } else if (patientID) {
            addPatientsAttachment();
        }
    };

    const addPatientsAttachment = () => {
        setSaving(true);
        if (selectedFile === null) {
            setError("You must select a file.");
            setSaving(false);
        }
        addPatientAttachment(patientID, selectedFile, type)
            .then((r) => {
                if (r.ok) {
                    setOpen(false);
                    callback();
                } else {
                    setError("Failed to save attachment.");
                    return;
                }
            })
            .finally(() => setSaving(false));
    };

    const addOrdersAttachment = () => {
        if (laboratory === null && type === "Result") {
            setError(
                "You must select a performing laboratory for the result report you are attaching."
            );
        }

        let resultData = {
            LaboratoryID: laboratory,
            Results: [],
        };

        if (type === "Result") {
            for (let [key, value] of updateResults.entries()) {
                resultData.Results.push({
                    ResultID: key,
                    Performed: value.performed,
                    Rejected: value.rejected,
                });
            }
        }

        // if type is Result Report we need to update the results with
        // whether it was performed or rejected
        addOrderAttachment(orderID, selectedFile, type, resultData).then(
            (r) => {
                if (r.ok) {
                    setOpen(false);
                    setSaving(false);
                    callback();
                } else {
                    setSaving(false);
                    setError("Failed to save attachment.");
                    return;
                }
            }
        );

    };

    let types;
    if (orderID !== undefined) {
        types = ["Requisition", "Demographics", "Photo ID", "Insurance Card"];
        if (resultsEnabled && allowedToPostResults()) {
            types.push("Result");
        }
    } else if (patientID !== undefined) {
        types = ["Demographics", "Photo ID", "Insurance Card"];
    }

    const checkAll = (event, propname) => {
        // if updateResults size is more than 0, we need to uncheck everything
        // otherwise check
        console.log(event);

        let u = new Map();
        for (let r of results) {
            u.set(r.ID, { result: r, [propname]: event.target.checked });
        }

        setUpdateResults(u);
    };

    const handleCheck = (resultID, result, e) => {
        // builds map with result ID and whether it was performed or rejected
        let u = new Map(updateResults);
        let propname = e.target.name;
        let check = e.target.checked;

        if (u.get(resultID) === undefined) {
            u.set(resultID, { [propname]: check, result: result });
        } else {
            u.get(resultID)[propname] = check;
        }

        setUpdateResults(u);
        console.log(u);
    };

    const filterUpdatableResults = (results) => {
        return results.filter(
            (row) =>
                row.Status !== "Validated" &&
                row.Status !== "Rejected" &&
                row.Status !== "Test not performed"
        );
    };

    const ResultRow = ({ row }) => {
        return (
            <>
                <TableRow key={row.ID}>
                    <TableCell>{row.Test.Name}</TableCell>
                    <TableCell
                        style={{
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            whiteSpace: "nowrap",
                        }}
                    >
                        <Checkbox
                            inputProps={{ "aria-label": "controlled" }}
                            name="performed"
                            disabled={updateResults.get(row.ID)?.rejected}
                            onChange={(e) => handleCheck(row.ID, row, e)}
                            checked={
                                updateResults.get(row.ID)?.performed || false
                            }
                        />
                    </TableCell>
                    <TableCell
                        style={{
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            whiteSpace: "nowrap",
                        }}
                    >
                        <Checkbox
                            inputProps={{ "aria-label": "controlled" }}
                            disabled={updateResults.get(row.ID)?.performed}
                            name="rejected"
                            onChange={(e) => handleCheck(row.ID, row, e)}
                            checked={
                                updateResults.get(row.ID)?.rejected || false
                            }
                        />
                    </TableCell>
                    <TableCell
                        style={{
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            whiteSpace: "nowrap",
                        }}
                    >
                        {row.Test.Laboratory.Name}
                    </TableCell>
                </TableRow>
            </>
        );
    };

    return (
        <div>
            <Backdrop
                sx={{
                    color: "#fff",
                    zIndex: (theme) => theme.zIndex.drawer + 1,
                }}
                open={loading}
                onClick={() => setLoading(false)}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
            <Dialog open={open} onClose={handleClose} maxWidth="md">
                <DialogTitle>Add Attachment</DialogTitle>
                <DialogContent>
                    <DialogContentText>Attachment</DialogContentText>
                    <Stack direction={["Photo ID", "Insurance Card"].includes(type) ? "column" : "row"} spacing={1}>
                        {["Photo ID", "Insurance Card"].includes(type) ?
                            <>
                                {!photoImg ?
                                    <ImageCapture image64={photoImg} setImage64={setPhotoImg} /> :
                                    <>
                                        <ModalImage
                                            hideZoom
                                            small={photoImg}
                                            medium={photoImg} />
                                        <Button onClick={() => { setPhotoImg(null) }} variant="outlined" color="error">Delete</Button>
                                    </>}
                            </>
                            :
                            <Input
                                required
                                type="file"
                                inputProps={{ accept: ["Photo ID", "Insurance Card"].includes(type) ? "image/jpeg, image/png, .pdf" : ".pdf" }}
                                onChange={(e) => {
                                    console.log(e);
                                    setSelectedFile(e.target.files[0]);
                                }}
                            />}
                        <FormControl sx={{ minWidth: 200, m: 1 }} size="small">
                            <TextField
                                required
                                select
                                size="small"
                                value={type}
                                name="Type"
                                label="Type"
                                onChange={(v) => setType(v.target.value)}
                            >
                                {types.map((value, index) => (
                                    <MenuItem key={index} value={value}>
                                        {value}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </FormControl>
                    </Stack>
                    {type === "Result" && allowedToPostResults() && (
                        <Box sx={{ mt: 4 }}>
                            <FormControl
                                sx={{ minWidth: 200, m: 1 }}
                                size="small"
                            >
                                <TextField
                                    required
                                    select
                                    size="small"
                                    value={laboratory}
                                    sx={{ width: "300px" }}
                                    name="LaboratoryID"
                                    label="Performing Laboratory"
                                    onChange={(v) =>
                                        setLaboratory(v.target.value)
                                    }
                                >
                                    {laboratories.map((value, index) => (
                                        <MenuItem key={index} value={value.ID}>
                                            {value.Name}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </FormControl>
                            {updateableResults.length > 0 && (
                                <Table size="small" sx={{ width: "100%" }}>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Test</TableCell>
                                            <TableCell
                                                style={{
                                                    overflow: "hidden",
                                                    textOverflow: "ellipsis",
                                                    whiteSpace: "nowrap",
                                                }}
                                            >
                                                <Stack
                                                    direction="row"
                                                    alignItems="center"
                                                >
                                                    <Checkbox
                                                        onChange={(e) =>
                                                            checkAll(
                                                                e,
                                                                "performed"
                                                            )
                                                        }
                                                    />
                                                    Was Performed
                                                </Stack>
                                            </TableCell>
                                            <TableCell
                                                style={{
                                                    overflow: "hidden",
                                                    textOverflow: "ellipsis",
                                                    whiteSpace: "nowrap",
                                                }}
                                            >
                                                <Stack
                                                    direction="row"
                                                    alignItems="center"
                                                >
                                                    <Checkbox
                                                        onChange={(e) =>
                                                            checkAll(
                                                                e,
                                                                "rejected"
                                                            )
                                                        }
                                                    />
                                                    Was Rejected
                                                </Stack>
                                            </TableCell>
                                            <TableCell
                                                style={{
                                                    overflow: "hidden",
                                                    textOverflow: "ellipsis",
                                                    whiteSpace: "nowrap",
                                                }}
                                            >
                                                Laboratory
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {updateableResults.map((row) =>
                                            <ResultRow row={row} />
                                        )}
                                    </TableBody>
                                </Table>
                            )}
                        </Box>
                    )}
                    {error != null ? (
                        <Alert sx={{ m: 1 }} severity="error">
                            {error}
                        </Alert>
                    ) : (
                        <></>
                    )}
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Cancel</Button>
                    <Button disabled={saving} onClick={addAttachment}>
                        Save
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
