import { Box, CircularProgress, Dialog, DialogContent, Typography } from "@mui/material";
import { Stack } from "@mui/system";


function ProgressOverlay({ progress, title }) {

    return (
        <Dialog open={progress !== 0}>
            <DialogContent>
                <Box width="400px" height={"300px"} alignItems="center" display={"flex"} justifyContent="center">
                    <Stack direction="column" spacing={2} alignItems="center" justifyContent="center">
                        <Typography variant="h4">{title}</Typography>
                        <CircularProgress />
                        <Typography variant="h4">{Math.round(progress)}% complete</Typography>
                        <Typography variant="subtitle1">Please be patient</Typography>
                    </Stack>
                </Box>

            </DialogContent>
        </Dialog>

    )

}

export default ProgressOverlay;
