import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router";
import {
    Grid,
    Paper,
    Avatar,
    TextField,
    Button,
} from "@mui/material";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Logo from "../images/logo.png";
import { Box, Stack } from "@mui/system";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import { activate, getInvite, checkPasswordStrength } from "../services/auth";
import { Alert } from "@mui/material";
import mdTheme from "../components/Theme";
import { useMediaQuery } from "@mui/material";

export default function Activate() {
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const queryParameters = new URLSearchParams(window.location.search);
    const invite = queryParameters.get("invite");
    const [error, setError] = useState("");
    const [strengthError, setStrengthError] = useState("");
    const [passMatchError, setPassMatchError] = useState("");
    const [username, setUsername] = useState("");
    const [fname, setFname] = useState("");
    const [lname, setLname] = useState("");
    const [entropy, setEntropy] = useState(0);

    const isMobile = useMediaQuery(mdTheme.breakpoints.down("sm"));
    const navigate = useNavigate();

    useEffect(() => {
        // check if invite is valid
        getInvite(invite)
            .then((response) => {
                if (!response.ok) {
                    throw new Error("invalid invitation");
                }
                return response.json();
            })
            .then((i) => {
                setUsername(i.User.Email);
                setFname(i.User.FirstName);
                setLname(i.User.LastName);
            })
            .catch(() =>
                setError(
                    "Invitation expired. Please request a new invitation."
                )
            );
    }, []);

    useEffect(() => {
        if (password == "") {
            return;
        }
        checkPasswordStrength(password)
            .then((response) => response.json())
            .then((m) => {
                console.log(m);
                setEntropy(m.Entropy);
                setStrengthError(m.Error);
            });
    }, [password]);

    const activateUser = () => {
        activate(password, invite)
            .then((response) => {
                if (!response.ok) {
                    throw new Error("Register failed. Please try again.");
                }
                return response.json();
            })
            .then(() => {
                navigate("/login");
            })
            .catch((e) => setError(e.message));
    };

    const desktopPaper = {
        padding: 20,
        height: '100%',
        width: 480,
        margin: "20px auto",
    };

    const mobilePaper = {
        width: '100%',
        padding: 8,
        height: '100%',
    }
    const paperStyle = isMobile ? mobilePaper : desktopPaper

    const avatarStyle = { backgroundColor: "#1bbd7e" };
    const btnstyle = { margin: "8px 0" };

    console.log(error);

    return (
        <ThemeProvider theme={mdTheme}>
            <CssBaseline />
            <Grid>
                <Paper elevation={10} style={paperStyle}>
                    <Grid align="center">
                        <img width={"85%"} src={Logo} />
                        <Avatar style={avatarStyle}>
                            <LockOutlinedIcon />
                        </Avatar>
                        <h2>Activate Account</h2>
                    </Grid>
                    <Box component="form" id="login-form">
                        <Stack direction="column" spacing={2}>
                            {error != "" ? (
                                <Alert
                                    variant="filled"
                                    sx={{ m: 1 }}
                                    severity="error"
                                >
                                    {error}
                                </Alert>
                            ) : (
                                <></>
                            )}
                            <TextField
                                disabled={true}
                                label="FirstName"
                                variant="outlined"
                                value={fname}
                                fullWidth
                                required
                            />
                            <TextField
                                disabled={true}
                                label="LastName"
                                variant="outlined"
                                value={lname}
                                fullWidth
                                required
                            />
                            <TextField
                                disabled={true}
                                label="Username"
                                variant="outlined"
                                value={username}
                                fullWidth
                                required
                            />
                            <TextField
                                label="Password"
                                disabled={!!error}
                                placeholder="Enter password"
                                type="password"
                                value={password}
                                onChange={(v) => setPassword(v.target.value)}
                                variant="outlined"
                                fullWidth
                                required
                            />
                            {strengthError != "" ? (
                                <Alert
                                    variant="filled"
                                    sx={{ m: 1 }}
                                    severity="error"
                                >
                                    {strengthError}
                                </Alert>
                            ) : (
                                <></>
                            )}
                            <TextField
                                disabled={!!error}
                                label="Confirm Password"
                                placeholder="Confirm password"
                                type="password"
                                onBlur={(v) =>
                                    setConfirmPassword(v.target.value)
                                }
                                variant="outlined"
                                fullWidth
                                required
                            />
                            {passMatchError != "" ? (
                                <Alert
                                    variant="filled"
                                    sx={{ m: 1 }}
                                    severity="error"
                                >
                                    {passMatchError}
                                </Alert>
                            ) : (
                                <></>
                            )}
                            <Button
                                disabled={!!error}
                                type="submit"
                                color="primary"
                                variant="contained"
                                onClick={(e) => {
                                    e.preventDefault();
                                    const ele =
                                        document.getElementById("login-form");
                                    const chk_status = ele.checkValidity();
                                    ele.reportValidity();
                                    if (chk_status && error == "") {
                                        if (password != confirmPassword) {
                                            setPassMatchError("passwords do not match");
                                            return;
                                        }
                                        setPassMatchError("");
                                        activateUser();
                                    }
                                }}
                                style={btnstyle}
                                fullWidth
                            >
                                Activate
                            </Button>
                        </Stack>
                    </Box>
                </Paper>
            </Grid>
        </ThemeProvider>
    );
}
