import * as React from "react";
import ViewPDFv2 from "./ViewPDFv2";
import { useParams } from "react-router";
import { Patient } from "../constants/types";
import { apiFetch } from "../services/fetch";


const ChartNotesPDF = () => {
	const { orderID } = useParams();
	const [patient, setPatient] = React.useState<Patient | null>(null)

	React.useEffect(() => {
		const init = async () => {
			const response = await apiFetch(`/orders/${orderID}`)
			const patient = await apiFetch(`/patients/${response.Patient?.ID}`)
			setPatient(patient)
		}

		init()
	}, [orderID])

	return <ViewPDFv2
		patient={patient}
		pdfType="chartNote"
		url={`/orders/${orderID}/chartNotesPDF`} />
}

export default ChartNotesPDF;
