import React, { useState, useContext } from 'react';
import { SessionContext } from "../hooks/getContext";
import {
	useGridApiContext,
	GridToolbarContainer,
	GridToolbarColumnsButton,
	GridToolbarFilterButton,
	GridToolbarDensitySelector,
	GridToolbarExport,
	GridToolbarQuickFilter,
	GridLogicOperator,
} from "@mui/x-data-grid-pro";
import {
	Stack,
	Button,
	TextField,
	Popover,
} from "@mui/material";
import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd';

type OrdersTableToolbarProps = {
	handleBarcodeFilter: (event: React.MouseEvent<HTMLButtonElement>) => void;
	handleBarcodeClose: () => void;
	barcodeAnchor: null | HTMLElement;
	setBarcodeFilter: (barcodes: string[]) => void;
}

export const OrdersTableToolbar = () => {
	const [barcodeAnchor, setBarcodeAnchor] = useState<null | HTMLElement>(null)
	const { user } = useContext(SessionContext);
	const apiRef = useGridApiContext()
	const open = Boolean(barcodeAnchor);
	const id = open ? 'simple-popover' : undefined;

	const handleBarcodeFilter = (event: React.MouseEvent<HTMLButtonElement>) => {
		setBarcodeAnchor(event.currentTarget)
	}

	const handleBarcodeFilterText = (e: any) => {
		let text = e.target.value
		const barcodes = text.replace(/[\r\n]+/g, ' ').split(' ').filter((barcode: string) => barcode.trim() !== '');
		// setBarcodeFilter(barcodes)
		console.log('calling upsert')
		apiRef.current.upsertFilterItems(barcodes.map((barcode: string, v: number) => ({
			id: v + 1,
			field: "Samples",
			operator: "contains",
			value: barcode
		})))
		// apiRef.current.setFilterModel({
		// logicOperator: GridLogicOperator.Or,
		// items: barcodes.map((barcode: string, v: number) => ({
		// id: v + 1,
		// field: "Barcodes",
		// operator: "contains",
		// value: barcode
		// }))
		// }, "upsertFilterItems")
	}

	console.log(user.Roles)

	return (
		<GridToolbarContainer>
			<Stack direction="row" justifyContent="space-between" width="100%">
				<div>
					<GridToolbarColumnsButton />
					<GridToolbarFilterButton />
					<GridToolbarDensitySelector />
					{ /* @ts-ignore */}
					{user.Roles && !user.Roles.includes("Sample Handler") && !user.Roles.includes("Sample Handler Manager") && <GridToolbarExport />}
					<Button startIcon={<PlaylistAddIcon />} onClick={handleBarcodeFilter} size="small" id={id}>Barcodes</Button>
					<Popover
						id={id}
						open={open}
						anchorEl={barcodeAnchor}
						onClose={() => setBarcodeAnchor(null)}
						anchorOrigin={{
							vertical: 'bottom',
							horizontal: 'left',
						}}
					>
						<TextField sx={{ p: 1 }} onChange={handleBarcodeFilterText} label="Input Barcodes" />
					</Popover>
				</div>
				<GridToolbarQuickFilter />
			</Stack>
		</GridToolbarContainer>
	);
}

