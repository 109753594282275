import { DataGridPro, GridToolbar } from "@mui/x-data-grid-pro";
import { useEffect, useState } from "react";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ErrorIcon from '@mui/icons-material/Error';
import {getNotifications} from "../services/notifications";
import { notificationEventIndex, FormatDateTime } from "../services/util";

export default function NotificationsTable({}) {
    const [notifications, setNotifications] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        setLoading(true);
        getNotifications()
            .then((p) => {
                if (!p.ok) {
                    throw new Error("Failed to load interface results");
                }
                return p.json();
            })
            .then((p) => {
                setLoading(false);
                setNotifications(p);
            })
            .catch(e => setError(e.message));
    }, []);

    const getCheckbox = (value) => {
        if (value.row?.Error === "") {
            return <CheckCircleIcon color="success" />;
        }
        return <ErrorIcon color="error" />;
    };

    const columns = [
        { field: "ID", headerName: "ID", width: 50 },
        { field: "CreatedAt", headerName: "Sent At", width: 175, valueGetter: row => FormatDateTime(row.row?.CreatedAt) },
        { field: "OrderID", headerName: "Order ID", width: 75, valueGetter: row => row?.row?.Notification?.OrderID },
        {
            field: "ServiceName",
            headerName: "Service",
            width: 85,
        },
        { field: "To", headerName: "To", width: 250, valueGetter: row => row?.row?.Notification?.To },
        { field: "Name", headerName: "Name", width: 250, valueGetter: row => row?.row?.Notification?.ToName },
        { field: "Event", headerName: "Event", width: 155, valueGetter: row => notificationEventIndex[row?.row?.Notification?.Event] },
        { field: "Success", headerName: "Success", width: 80, renderCell: getCheckbox },
        { field: "Error", headerName: "Error", width: 700 },
    ];

    return (
        <>
            <DataGridPro
                getRowId={(row) => row.ID}
                columns={columns}
                loading={loading}
                rows={notifications}
                error={error}
                initialState={{
                    sorting: {
                        sortModel: [{ field: "ID", sort: "desc" }],
                    },
                }}
                density="compact"
                sx={{
                    "& .MuiDataGrid-columnHeaderCheckbox .MuiDataGrid-columnHeaderTitleContainer":
                        {
                            display: "none",
                        },
                    height: "95%",
                }}
                components={{ Toolbar: GridToolbar }}
                componentsProps={{
                    toolbar: {
                        showQuickFilter: true,
                        quickFilterProps: { debounceMs: 500 },
                    },
                }}
                pageSize={100}
            />
        </>
    );
}
