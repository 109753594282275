import { reflexTypes } from "./util";

export async function getSpecimenTypes() {
    return fetch(`${process.env.REACT_APP_API_PROXY}/v1/specimentypes`, {
        credentials: "include",
    });
}

export async function addSpecimenType(specimenType) {
    return fetch(`${process.env.REACT_APP_API_PROXY}/v1/specimentypes`, {
        method: "POST",
        credentials: "include",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
        },
        body: JSON.stringify(specimenType),
    });
}

export async function updateSpecimenType(id, specimenType) {
    return fetch(`${process.env.REACT_APP_API_PROXY}/v1/specimentypes/${id}`, {
        method: "POST",
        credentials: "include",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
        },
        body: JSON.stringify(specimenType),
    });
}

export async function getSpecimenType(id) {
    return fetch(`${process.env.REACT_APP_API_PROXY}/v1/specimentypes/${id}`, {
        credentials: "include",
    });
}

async function getTestsFromProfile(profileID) {
    return await fetch(
        `${process.env.REACT_APP_API_PROXY}/v1/profiles/${profileID}`,
        {
            credentials: "include",
        }
    )
        .then((p) => {
            if (!p.ok) {
                throw new Error("Failed to load profile");
            }
            return p.json();
        })
        .catch(() => {
            return [];
        });
}

export async function getTestsFromReflexes(reflexDetails) {
    let tests = [];

    for (let reflex of reflexDetails) {
        switch (reflex.ReflexType) {
            case reflexTypes["Test2Test"]:
                // tests.push(reflex.SourceTest);
                tests.push(reflex.DestTest);
                break;
            case reflexTypes["Test2Profile"]:
                // tests.push(reflex.SourceTest);
                let t1 = await getTestsFromProfile(reflex.DestProfileID);
                console.log(t1);
                tests.push(...t1.AllTests);
                break;
            case reflexTypes["Profile2Profile"]:
                let t2 = await getTestsFromProfile(reflex.DestProfileID);
                tests.push(...t2.AllTests);
                break;
            case reflexTypes["Profile2Test"]:
                tests.push(reflex.DestTest);
                break;
        }
    }

    return tests;
}

// new object
// newObject = [
// {ID: 1, Type: "Serum", Container: "SST", Directions: "", reference: true, tests: [{ID: 1, Name: "Glucose"}]},
// {ID: 1, Type: "Serum", Container: "SST", Directions: "", reference: false, tests: [{ID: 1, Name: "Testosterone"}]},
// {ID: 2, Type: "Whole Blood", Container: "LAV", Directions: "", reference: true, tests: [{ID: 1, Name: "Glucose"}]},
// ]

function getNewObj(specTypes, specimenTypeID, reference) {
    let index = specTypes.findIndex(
        (s) => s.ID === specimenTypeID && s.Reference === reference
    );

    if (index !== -1) {
        // s and index are available here
        let spec = specTypes[index];
        return { spec, index };
    }
    return null;
}

function getNewSpecTypeObjects(tests) {
    let specTypes = [];
    for (let t of tests) {
        for (let s of t.SpecimenTypes) {
            const findExisingSpecType = getNewObj(
                specTypes,
                s.ID,
                t.Referenced
            );

            if (findExisingSpecType === null) {
                specTypes.push({ ...s, Reference: t.Referenced, Tests: [t] });
            } else {
                const { spec, index } = findExisingSpecType;
                specTypes.splice(index, 1, {
                    ...spec,
                    Tests: [...spec.Tests, t],
                });
            }
        }
    }

    // add id for datagrid
    specTypes = specTypes.map((s, i) => { return { ...s, id: i + 1 } })

    console.log(specTypes);
    return specTypes;
}


function coversAllTests(uniqueTests, combinationTests) {
    const testSet = new Set(uniqueTests.map(test => test.ID));
    for (let test of combinationTests) {
        testSet.delete(test.ID);
        if (testSet.size === 0) {
            return true;
        }
    }
    return false;
}

export async function determineNeededSpecimenTypes(tests) {
    // given a set of tests it will determine the necessary specimen types
    // tests should be divided by referenced true/false such that if two tests
    // require the same specimentype but one is referenced, that specimen type should
    // show a quantity of 2 required.


    return getNewSpecTypeObjects(tests);

    // split samples into groups of referenced and not
    const [referencedTests, inHouseTests] = splitTestsOnReference(tests);

    // first process all tests with a single available specimen type. this allows us to
    // pick an already selected specimen type when looking at a test with multiple
    // available spec types
    let referencedSpecTypes = getSingleSpecimenTypes(referencedTests);
    referencedSpecTypes = getMultipleSpecimenTypes(
        referencedTests,
        referencedSpecTypes
    );

    let inHouseSpecTypes = getSingleSpecimenTypes(inHouseTests);
    inHouseSpecTypes = getMultipleSpecimenTypes(inHouseTests, inHouseSpecTypes);

    const neededSpecimenTypes = increaseQuantityIfRefandHouse(
        inHouseSpecTypes,
        referencedSpecTypes
    );

    return neededSpecimenTypes;
}

function increaseQuantityIfRefandHouse(inHouseSpecTypes, referencedSpecTypes) {
    // if a spec type exists in both lists, sum their required quantitys and add a single spec
    // type to the final array

    //helper function for checking if contains
    const specContains = (specType, listSpecTypes) => {
        for (let s of listSpecTypes) {
            if (s.ID === specType.ID) {
                return true;
            }
        }
        return false;
    };

    const getSpecFromRef = (specType, listSpecTypes) => {
        for (let s of listSpecTypes) {
            if (s.ID === specType.ID) {
                return s;
            }
        }
        return null;
    };

    const finalSpecTypes = [];

    // first loop through referenced specimens and add any that do not exist in inHouse,
    // ensures we do not miss any spec types
    for (let rS of referencedSpecTypes) {
        if (!specContains(rS, inHouseSpecTypes)) {
            finalSpecTypes.push(rS);
        }
    }

    for (let iH of inHouseSpecTypes) {
        // if contained in reference list double the qty and add to finalSpecTypes
        if (specContains(iH, referencedSpecTypes)) {
            // use the referencedTest to get QTY so we do not continue to double
            // in case the user goes back and forth from tests to samples
            const referenceSpec = getSpecFromRef(iH, referencedSpecTypes);
            iH.QTY = referenceSpec.QTY * 2;
        }
        finalSpecTypes.push(iH);
    }
    return finalSpecTypes;
}

function splitTestsOnReference(tests) {
    const referencedTests = [];
    const inHouseTests = [];

    for (let t of tests) {
        if (t.Referenced) {
            referencedTests.push(t);
        } else {
            inHouseTests.push(t);
        }
    }

    return [referencedTests, inHouseTests];
}

function getMultipleSpecimenTypes(tests, neededSpecimenTypes) {
    // neededSpecimenTypes should contain a list of already needed types
    // from the tests that have only a single spec type available

    for (let t of tests) {
        let found = false;
        if (t.SpecimenTypes.length === 1) {
            continue;
        }
        for (let s of t.SpecimenTypes) {
            if (s in neededSpecimenTypes) {
                // available specimen type is already selected as necessary, break
                found = true;
            }
        }
        // if we don't find above, add the first defined specimentype to the needed list
        if (!found) {
            neededSpecimenTypes.push(t.SpecimenTypes[0]);
        }
    }
    return neededSpecimenTypes;
}

function getSingleSpecimenTypes(tests) {
    // returns a unique specimentypes array for tests that have a
    // single specimentype available ONLY
    const s = [];
    for (let t of tests) {
        if (t.SpecimenTypes.length === 1) {
            s.push(t.SpecimenTypes[0]);
        }
    }
    return s.filter(onlyUnique);
}
function onlyUnique(value, index, self) {
    return self.findIndex((v) => v.ID === value.ID) === index;
}
