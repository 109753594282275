import { GridLogicOperator, GridPaginationModel, GridSortModel, GridFilterModel, GridInitialState, GridColumnVisibilityModel, useGridApiRef } from '@mui/x-data-grid-pro';
import React, { useState, useEffect, useMemo, useCallback, useRef } from 'react';
import { Button, IconButton, Link, Stack, Tooltip } from "@mui/material";
import { PictureAsPdf, Markunread, MarkAsUnread, ReceiptLong, Download, Draw } from "@mui/icons-material";
import { OrdersStyledDataGrid } from "./OrdersTableStyle";
import { OrdersTableToolbar } from './OrdersTableToolbar';
import { apiFetch } from '../services/fetch';
import { updateOrderView } from '../services/orders';
import dayjs from "dayjs";
import utc from 'dayjs/plugin/utc';
import { CopyButtonWithText } from "../components/CopyButton";
import { VerifierIndex } from "../constants/constants";
import { GetOrderStatusChips, GetOrderVerificationChips } from "../components/StatusChips";
import { FormatDateUTC, FormatDate, FormatDateTime, FormatDateMMDDYYYY } from "../services/util";
import { paymentMethodIndex } from "../services/util";
import { useDataGridState } from '../hooks/useDataGridState';
import { useNavigate } from "react-router-dom";
import { CircularProgress } from '@mui/material';
import Title from "../components/Title";

dayjs.extend(utc);

export type OrdersTableProps = {
	url: string;
	autoHeight?: boolean;
	showRequestedCollectionDate: boolean;
	deletedColumns: boolean;
	checkboxes: boolean;
}

const defaultGridState: GridInitialState = {
	filter: {
		filterModel: {
			items: [],
			logicOperator: GridLogicOperator.And,
			quickFilterExcludeHiddenColumns: false,
			quickFilterLogicOperator: GridLogicOperator.And,
			quickFilterValues: []
		},
	},
	sorting: { sortModel: [{ field: "ID", sort: "desc" }] },
	pagination: { paginationModel: { page: 0, pageSize: 500 } }
}

const getVerificationStatus = (row: any) => {
	if (row.row.Verification === "") {
		return "Verified"
	}
	return row.row?.Verification.split(',').map((v: any) => VerifierIndex[v]).join(", ")
}

const RestoreOrder = async (id: any) => {
	const navigate = useNavigate()
	try {
		// @ts-ignore
		await apiFetch(`/orders/restore/${id}`, 'POST', {})
		navigate(`/orders/${id}`)
	} catch (e) {
		console.log(e)
	}
}

const renderDetailsButton = (params: any, deletedColumns: boolean) => {
	if (deletedColumns) {
		return (
			<strong>
				<Button
					component="a"
					variant="contained"
					color="success"
					size="small"
					onClick={() => RestoreOrder(params.id)}
				>
					Restore
				</Button>
			</strong>
		)
	} else {
		return (
			<strong>
				<Button
					component="a"
					variant="contained"
					color="primary"
					size="small"
					// style={{ marginLeft: 16 }}
					href={`/orders/${params.id}`}
					target="_blank"
				>
					Details
				</Button>
			</strong>
		);
	}
};

// @ts-ignore
const GetProviderNotesButton = ({ value }) => {
	if (!value?.row?.ProviderNotesRequired) {
		return <></>
	}
	let color = "error"
	if (value?.row?.ProviderNotesAdded) {
		color = "success"
	}

	return (
		<strong>
			<Link href={"/orders/" + value.id + "/chartNotes"} target="_blank">
				{/* @ts-ignore */}
				<ReceiptLong color={color} />{" "}
			</Link>
		</strong>
	);
};


// @ts-ignore
const GetSignature = ({ value }) => {
	if (!value?.ProviderSignatureID) {
		return <></>
	}
	return (
		<Tooltip title="Signed" placement="top-start">
			<Draw color="success" />
		</Tooltip>

	)
}

// @ts-ignore
const GetFlags = ({ value }) => {
	return (
		<Stack direction="row" spacing={1}>
			<GetProviderNotesButton value={value} />
			<GetSignature value={value?.row} />
		</Stack>
	)
}


export default function MainOrdersTable(
	{
		url,
		autoHeight,
		deletedColumns,
		checkboxes
	}: OrdersTableProps) {
	// const initState = defaultGridState;

	const [tableState, setTableState] = useState<GridInitialState | null>(null);
	const apiRef = useGridApiRef();
	const [loading, setLoading] = useState(false);
	const [viewed, setViewed] = useState({});
	const [data, setData] = useState<any>({});
	const [orders, setOrders] = useState<any>([]);
	const [refresh, setRefresh] = useState(false);

	const saveSnapshot = React.useCallback(() => {
		if (apiRef?.current?.exportState && localStorage) {
			const currentState = apiRef.current.exportState();
			localStorage.setItem('dataGridTable-orders', JSON.stringify(currentState));
		}
	}, [apiRef]);

	React.useLayoutEffect(() => {
		const stateFromLocalStorage = localStorage?.getItem('dataGridTable-orders');
		// @ts-ignore
		const stateObj = JSON.parse(stateFromLocalStorage)
		if (checkboxes && stateObj?.columns?.orderedFields[0] !== "__check__") {
			stateObj?.columns?.orderedFields.splice(0, 0, "__check__");
		}

		setTableState(stateFromLocalStorage ? stateObj : {});

		// handle refresh and navigating away/refreshing
		window.addEventListener('beforeunload', saveSnapshot);

		return () => {
			// in case of an SPA remove the event-listener
			window.removeEventListener('beforeunload', saveSnapshot);
			saveSnapshot();
		};
	}, [saveSnapshot]);

	useEffect(() => {
		if (!tableState) { return }

		const init = async () => {
			try {
				setLoading(true)
				// @ts-ignore
				const response = await apiFetch(url, "POST", tableState)
				setData({ Data: response.Data, Count: response.Count });
			} catch (error) {
				console.log(error)
			} finally {
				setLoading(false)
			}
		}

		init()
	}, [url, tableState, refresh]);

	const handlePaginationModelChange = (model: GridPaginationModel) => {
		setTableState((state: any) => ({
			...state,
			pagination: { paginationModel: model },
		}));
	};

	const handleSortModelChange = (model: GridSortModel) => {
		setTableState((state: any) => ({
			...state,
			sorting: { sortModel: model },
		}));
	}

	const handleFilterModelChange = (model: GridFilterModel) => {
		console.log('filter model', model)
		setTableState((state: any) => ({
			...state,
			filter: { filterModel: model },
		}));
	}

	const toggleViewed = (row: any) => {
		const v = getViewed(row.row);
		setViewed({ ...viewed, [row.id]: !v });
		updateOrderView(row.id, v);
	};

	const getViewed = (row: any) => {
		// check local 'cache' for viewChanges
		if (viewed.hasOwnProperty(row.ID)) {
			// @ts-ignore
			return viewed[row?.ID];
		}

		return !row.ViewedSinceLastModified;
	};

	const renderMarkViewButton = (row: any) => {
		const v = getViewed(row.row);
		if (v) {
			return (
				<strong>
					<IconButton onClick={() => toggleViewed(row)}>
						<Markunread />
					</IconButton>
				</strong>
			);
		} else {
			return (
				<strong>
					<IconButton onClick={() => toggleViewed(row)}>
						<MarkAsUnread />
					</IconButton>
				</strong>
			);
		}
	};

	const downloadReport = async (orderId: string) => {
		try {
			const orderDetails = await apiFetch(`/orders/${orderId}`)
			const patientDetails = await apiFetch(`/patients/${orderDetails?.Patient?.ID}`)
			apiFetch(`/orders/${orderId}/resultReport`)
				.then((blob) => {
					// Create a Blob from the PDF Stream
					const file = new Blob([blob], { type: 'application/pdf' });

					// Create an object URL for the Blob
					const objectUrl = URL.createObjectURL(file);

					// Create a temporary anchor element
					const link = document.createElement('a');
					link.href = objectUrl;
					link.download = `${orderId}-${patientDetails?.LastName.toUpperCase()}-${patientDetails?.FirstName.toUpperCase()}-RESULT.pdf`;  // Set the filename here

					// Append to the document and trigger the download
					document.body.appendChild(link);
					link.click();

					// Clean up
					document.body.removeChild(link);
					URL.revokeObjectURL(objectUrl);
				})
		} catch (error) {
			console.log(error)
		}
	}

	const signOrders = async () => {
		try {
			setLoading(true)
			// @ts-ignore
			await apiFetch(`/orders/sign`, 'POST', { orderIds: orders })
			setRefresh(!refresh)
		} catch (e) {
			console.log(e)
		} finally {
			setLoading(false)
		}
	}

	const renderResultButton = useCallback((params: any) => {
		let color = "error";
		if (!params.row.ResultsAvailable) {
			color = "disabled"
		}
		return (
			<>
				<Link href={"/orders/" + params.id + "/resultReport"} target="_blank">
					{ /* @ts-ignore */}
					<PictureAsPdf color={color} />{" "}
				</Link>
				<IconButton onClick={() => downloadReport(params.id)}>
					<Download />
				</IconButton>
			</>
		);
	}, []);


	let columns = useMemo(() => [
		{
			field: "Viewed",
			headerName: "Read",
			width: 60,
			renderCell: (row: any) => renderMarkViewButton(row),
			filterable: false,
			sortable: false,
		},
		{
			field: "Result",
			headerName: "Result",
			width: 90,
			renderCell: (row: any) => renderResultButton(row),
			filterable: false,
			sortable: false,
		},
		{
			field: "Action",
			headerName: "Action",
			width: 100,
			filterable: false,
			sortable: false,
			renderCell: (row: any) => renderDetailsButton(row, deletedColumns),
		},
		{ field: "ID", headerName: "ID", width: 60, type: 'number' },
		{
			field: "Samples", headerName: "Barcodes", width: 180
		},
		{
			field: "PatientFirstName",
			headerName: "Patient First",
			width: 100,
			renderCell: ({ value }: any) => <CopyButtonWithText text={value} copyText={value} />
		},
		{
			field: "PatientLastName",
			headerName: "Patient Last",
			width: 100,
			renderCell: ({ value }: any) => <CopyButtonWithText text={value} copyText={value} />
		},
		{
			field: "PatientDOB",
			headerName: "Patient DOB",
			width: 120,
			type: "date",
			valueGetter: (params: any) => params?.row?.PatientDOB && dayjs.utc(params?.row.PatientDOB).toDate(),
			valueFormatter: ({ value }: { value: any }) => { return value && FormatDateUTC(value) },
			renderCell: (params: any) => (<CopyButtonWithText text={dayjs.utc(params?.row.PatientDOB).format('YYYY-MM-DD')} copyText={dayjs.utc(params?.row.PatientDOB).format('MM/DD/YYYY')} />)
		},
		{
			field: "Facility",
			headerName: "Facility",
			width: 200,
			valueGetter: (row: any) => row.row.FacilityName,
		},
		{
			field: "Status",
			headerName: "Status",
			width: 230,
			renderCell: (row: any) => <GetOrderStatusChips order={row.row} />,
		},
		{
			field: "RequestedCollectionDate",
			headerName: "Requested Collection",
			width: 180,
			valueGetter: (row: any) =>
				FormatDateTime(row.row.RequestedCollectionDate),
		},
		{
			field: "PatientInsurance",
			headerName: "Patient Insurance",
			width: 120,
			valueGetter: (row: any) => row.row?.PatientInsurances,
		},
		{
			field: "PaymentMethod",
			headerName: "Payment Method",
			width: 120,
			// @ts-ignore
			valueGetter: (row: any) => paymentMethodIndex[row.value],
		},
		{
			field: "Collectors",
			headerName: "Collectors",
			width: 120,
		},
		{
			field: "Provider",
			headerName: "Provider",
			width: 120,
			valueGetter: (row: any) => row.row?.ProviderFirstName + " " + row.row?.ProviderLastName,
		},
		{
			field: "ProviderNPI",
			headerName: "Provider NPI",
			width: 120,
			valueGetter: (row: any) => row.row?.ProviderNPI,
		},
		{
			field: "Tests",
			headerName: "Tests",
			width: 120,
			valueGetter: (row: any) => row.row?.Tests,
		},
		{
			field: "DiagnosisCodes",
			headerName: "ICD10 Codes",
			width: 120,
			valueGetter: (row: any) => row.row?.DiagnosisCodes,
		},
		{
			field: "CreatedAt",
			headerName: "Date Submitted",
			width: 120,
			type: "date",
			valueGetter: (row: any) => dayjs.utc(row.row.CreatedAt).toDate(),
			valueFormatter: ({ value }: { value: any }) => { return value && FormatDate(value) },
		},
		{
			field: "DateCollected",
			headerName: "Date Collected",
			width: 120,
			valueFormatter: ({ value }: { value: any }) => { return value && FormatDate(value) },
		},
		{
			field: "DateReceived",
			headerName: "Date Received",
			width: 120,
			valueFormatter: ({ value }: { value: any }) => { return value && FormatDate(value) },
		},
		{
			field: "DateReported",
			headerName: "Date Reported",
			width: 120,
			type: "date",
			valueGetter: (row: any) => dayjs.utc(row.row.DateReported).toDate(),
			valueFormatter: ({ value }: { value: any }) => { if (value.toString() === "Invalid Date") { return "" } return value && FormatDate(value) },
		},
		{
			field: "Flags",
			headerName: "Flags",
			width: 100,
			renderCell: (row: any) => <GetFlags value={row} />,
			filterable: false,
			sortable: false,
		},
		{
			field: "Verification",
			headerName: "Verification Status",
			width: 250,
			valueGetter: (row: any) => getVerificationStatus(row),
			// @ts-ignore
			renderCell: (row: any) => <GetOrderVerificationChips verifiers={row.row.Verification} />,
			filterable: false,
			sortable: false,
		},
	], [deletedColumns, renderResultButton]);

	if (!tableState) {
		return <CircularProgress />;
	}

	return (
		<React.Fragment>
			{checkboxes && <Stack justifyContent="space-between" direction="row">
				<Title>Orders Missing Signature</Title>
				<Button
					size="small"
					variant="contained"
					disabled={!orders.length || loading}
					color="primary"
					onClick={signOrders}>Sign Orders</Button>
			</Stack>}
			<OrdersStyledDataGrid
				apiRef={apiRef}
				getRowId={(row) => row.ID}
				getRowClassName={(params) => {
					if (params.row.Stat) {
						return "stat";
					} else if (
						getViewed(params.row) &&
						(params.row.Status === "Completed" ||
							params.row.Status === "Rejected")
					) {
						return "notViewed";
					}
					return "";
				}}
				initialState={{ ...tableState }}
				density="compact"
				pagination
				paginationMode="server"
				// @ts-ignore
				paginationModel={tableState?.pagination?.paginationModel}
				onPaginationModelChange={handlePaginationModelChange}
				sortingMode="server"
				sortModel={tableState?.sorting?.sortModel}
				onSortModelChange={handleSortModelChange}
				filterMode="server"
				filterModel={tableState?.filter?.filterModel}
				onFilterModelChange={handleFilterModelChange}
				pageSizeOptions={[25, 50, 100, 500, 1000, 3000]}
				sx={{
					height: "95%",
				}}
				onRowSelectionModelChange={setOrders}
				keepNonExistentRowsSelected
				rowSelectionModel={orders}
				slots={{ toolbar: OrdersTableToolbar }}
				slotProps={{
					toolbar: {
						showQuickFilter: true,
						quickFilterProps: { debounceMs: 500 },
					},
				}}
				autoHeight={autoHeight}
				rows={data?.Data || []}
				rowCount={data?.Count || 0}
				columns={columns}
				checkboxSelection={checkboxes}
			/>
		</React.Fragment>
	);
}
