import React from 'react';
import { apiFetch } from '../services/fetch';
import { EMRMessage } from '../constants/types';
import { DataGridPro, GridToolbar } from '@mui/x-data-grid-pro';
import ErrorAlert from '../components/ErrorAlert';
import { Button, Stack } from '@mui/material';

function EMRResults() {
	const [loading, setLoading] = React.useState<boolean>(true);
	const [error, setError] = React.useState<string>('');
	const [messages, setMessages] = React.useState<EMRMessage[]>([]);
	const [refresh, setRefresh] = React.useState<boolean>(false);

	React.useEffect(() => {
		const init = async () => {
			try {
				setLoading(true);
				const response = await apiFetch('/integrations/emr/results')
				setMessages(response)
			} catch (error) {
				setError('Failed to load EMR results')
			} finally {
				setLoading(false);
			}
		}

		init()
	}, [refresh]);

	const handleDownload = async (uuid: string, filename: string) => {
		if (filename === "") {
			filename = uuid
		}
		try {
			const data = await apiFetch(`/attachments/${uuid}`)
			const url = window.URL.createObjectURL(data);
			const link = document.createElement("a");
			link.href = url;
			link.download = filename;
			link.click();
			// It's necessary to revoke the object URL to avoid memory leaks
			window.URL.revokeObjectURL(url);
		} catch (error) {
			console.error("Fetch operation failed: " + error);
		}
	};

	const handleRetry = async (id: number) => {
		try {
			setLoading(true);
			await apiFetch(`/integrations/emr/results/${id}/retry`, 'POST')
		} catch (error) {
			console.error("Fetch operation failed: " + error);
		} finally {
			setRefresh((refresh) => !refresh)
			setLoading(false);
		}

	}

	const getDownloadButton = (params: any) => {
		const attachmentUUID = params.row.Attachment.UUID
		const messageID = params.row.MessageID

		return (<>
			<Button disabled={attachmentUUID === "" || loading} size="small" variant="contained" color="primary" onClick={() => handleDownload(attachmentUUID, messageID)}>Download</Button>
		</>)
	}

	const getRetryButton = (params: any) => {
		const id = params.row.ID

		return (<>
			<Stack direction="row" spacing={1} justifyContent="space-between" alignItems="center" width={"100%"}>
				<div>{params.row.Status}</div>
				<Button disabled={loading} size="small" variant="contained" color="warning" onClick={() => handleRetry(id)}>Retry</Button>
			</Stack>
		</>)
	}

	const columns = [
		{ field: 'ID', headerName: 'ID', width: 70 },
		{ field: 'OrderID', headerName: 'Order ID', width: 130 },
		{ field: 'MessageID', headerName: 'Message ID', width: 130 },
		{ field: 'MessageType', headerName: 'Message Type', width: 130 },
		{ field: 'IntegrationID', headerName: 'Integration ID', width: 130 },
		{ field: 'AttachmentID', headerName: 'Message', width: 130, renderCell: getDownloadButton },
		{ field: 'Status', headerName: 'Status', width: 150, renderCell: getRetryButton, valueGetter: (params: any) => params.row.Status },
		{ field: 'Error', headerName: 'Error', width: 400 },
	]

	return (<>
		<DataGridPro
			hideFooterPagination
			getRowId={(row: EMRMessage) => row.ID}
			columns={columns}
			loading={loading}
			rows={messages}
			initialState={{
				sorting: {
					sortModel: [{ field: "ID", sort: "desc" }],
				},
			}}
			density="compact"
			sx={{
				"& .MuiDataGrid-columnHeaderCheckbox .MuiDataGrid-columnHeaderTitleContainer":
				{
					display: "none",
				},
				height: "95%",
			}}
			components={{ Toolbar: GridToolbar }}
			componentsProps={{
				toolbar: {
					showQuickFilter: true,
					quickFilterProps: { debounceMs: 500 },
				},
			}}
		/>
		<ErrorAlert error={error} />
	</>)
}

export default EMRResults;
