import React, { useEffect, useState } from 'react';
import { Grid, FormLabel, RadioGroup, FormControlLabel, Radio } from "@mui/material";

import { Answer, DiagnosisCode, DiagnosisQuestion } from "../../constants/types";

type DiagnosisQuestionsProps = {
    diagnosisQuestions: DiagnosisQuestion[],
    setDiagnosis: (answers: Answer[], diagnosisCodes: DiagnosisCode[]) => void
}

function DiagnosisQuestions({ diagnosisQuestions, setDiagnosis }: DiagnosisQuestionsProps) {
    const [answers, setAnswers] = useState<Answer[]>([])
    const [diagnosisCodes, setDiagnosisCodes] = useState<DiagnosisCode[]>([])

    console.log(diagnosisCodes)

    useEffect(() => {
        if (diagnosisCodes && diagnosisCodes.length > 0) {
            setDiagnosis(answers, diagnosisCodes)
        }
    }, [diagnosisCodes, answers])

    const handleChange = (questionId: number, code: DiagnosisCode, value: string) => {
        const answer: Answer = {
            ID: questionId,
            Answer: value === "yes" ? "True" : "False"
        }

        const a = [...answers.filter((a) => a.ID !== answer.ID), answer]
        setAnswers(a)
        if (value === "yes" && !diagnosisCodes.includes(code)) {
            setDiagnosisCodes([...diagnosisCodes, code])
        } else if (value === "no") {
            setDiagnosisCodes(diagnosisCodes.filter((dC: DiagnosisCode) => dC.Code !== code.Code))
        }
    }

    if (!diagnosisQuestions) {
        return <></>
    }

    return (
        <>
            <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>
                {diagnosisQuestions.map((question: DiagnosisQuestion, index: number) => (
                    <Grid item xs={4} sm={4} md={4} key={index}>
                        <>
                            <FormLabel>{question?.Question}</FormLabel>
                            <RadioGroup
                                row
                                onChange={(_, v) => handleChange(question?.ID, question?.DiagnosisCode, v)}
                            >
                                <FormControlLabel
                                    control={
                                        <Radio />
                                    }
                                    value="yes"
                                    label="Yes"
                                    name={question?.DiagnosisCodeID}
                                />
                                <FormControlLabel
                                    control={
                                        <Radio />
                                    }
                                    value="no"
                                    label="No"
                                    name={question?.DiagnosisCodeID}
                                />
                            </RadioGroup>
                        </>
                    </Grid>
                ))}
            </Grid>
        </>
    )
}

export default DiagnosisQuestions;
