import React, { useState } from "react";
import {
	Grid,
	Paper,
	Avatar,
	TextField,
	Alert,
} from "@mui/material";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
// @ts-ignore
import Logo from "../images/logo.png";
import { Box, Stack } from "@mui/system";
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import mdTheme from "../components/Theme";
import { useMediaQuery } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import ErrorAlert from "../components/ErrorAlert";
import * as Yup from "yup";
import { Form, Formik, FormikProps } from "formik";
import { CustomTextField } from "../components/CustomTextField";
import { apiFetch } from "../services/fetch";

const validationSchema = Yup.object().shape({
	Email: Yup.string().email().required("Email is required"),
});

const initValues = {
	Email: "",
}

export default function ForgotPassword() {
	const [error, setError] = useState("");
	const [successMsg, setSuccessMsg] = useState("");
	const [loading, setLoading] = useState(false);
	const [submitted, setSubmitted] = useState(false);

	const isMobile = useMediaQuery(mdTheme.breakpoints.down("sm"));

	const desktopPaper = {
		padding: 20,
		minHeight: 600,
		width: 480,
		margin: "20px auto",
	};

	const mobilePaper = {
		width: "100%",
		padding: 8,
		height: "100vh",
	};
	const paperStyle = isMobile ? mobilePaper : desktopPaper;

	const handleSubmit = async (values: typeof initValues) => {
		setSuccessMsg("");
		try {
			setLoading(true);
			// @ts-ignore
			await apiFetch('/user/forgotPassword', 'POST', values, true)
			setError("")
			setSuccessMsg("Email sent. Please check your inbox for further instructions.");
			setSubmitted(true);
		} catch (e) {
			setError("Failed to send email. Please try again.");
		} finally {
			setLoading(false);
		}
	}

	const avatarStyle = { backgroundColor: "#1bbd7e" };
	const btnstyle = { margin: "8px 0" };
	return (
		<ThemeProvider theme={mdTheme}>
			<CssBaseline />
			<Grid>
				<Paper elevation={10} style={paperStyle}>
					{/* @ts-ignore */}
					<Grid align="center">
						<img width={"85%"} src={Logo} alt="diagnoseLogo" />
						<Avatar style={avatarStyle}>
							<LockOutlinedIcon />
						</Avatar>
						<h2>Forgot Password</h2>
						<p>Enter your email to reset your password.
							If you have an account, we will send you an email with instructions to reset your password.</p>
					</Grid>
					<Formik
						initialValues={initValues}
						validationSchema={validationSchema}
						onSubmit={handleSubmit}
					>
						{({ setFieldValue, values }) => (
							<Form>
								<Box>
									<Stack direction="column" spacing={2}>
										<CustomTextField
											name="Email"
											disabled={submitted}
											placeholder="Enter email"
											type="email"
											variant="outlined"
											value={values.Email}
											onChange={(v: any) => setFieldValue('Email', v.target.value)}
											fullWidth
											required
										/>
									</Stack>
									<ErrorAlert error={error} />
									{successMsg && <Alert sx={{ m: 2 }} severity="success">{successMsg}</Alert>}
									<LoadingButton
										type="submit"
										loading={loading}
										loadingIndicator="Sending email..."
										disabled={loading || submitted}
										color="primary"
										variant="contained"
										onClick={(_) => { }}
										style={btnstyle}
										fullWidth
									>
										Send Password Reset Link
									</LoadingButton>
								</Box>
							</Form>
						)}
					</Formik>
				</Paper>
			</Grid>
		</ThemeProvider>
	);
}
