import React, { useState } from "react";
import { ThemeProvider } from "@mui/material/styles";
import { Toolbar, CssBaseline, Stack, Button, Divider } from "@mui/material";
import { Container, Box } from "@mui/system";
import AppBar from "../components/AppBar/AppBar";
import mdTheme from "../components/Theme";
import OrderForm from "../components/OrderSteps/OrderForm";
import OrderFinish from "../components/OrderFinish";

function NewOrder2() {
	const [orderId, setOrderId] = useState<null | number>(null)
	const [reset, setReset] = useState(false)

	return (
		<ThemeProvider theme={mdTheme}>
			<Box sx={{ display: "flex" }}>
				<CssBaseline />
				<AppBar name={"New Order"} HideSpeedDial={true} />
				<Box
					component="main"
					sx={{
						backgroundColor: (theme) =>
							theme.palette.mode === "light"
								? theme.palette.grey[100]
								: theme.palette.grey[900],
						flexGrow: 1,
						height: "100vh",
						overflow: "auto",
					}}
				>
					<Toolbar />
					<Container
						maxWidth={false}
						sx={{ maxWidth: "1600px", padding: { xs: "0px", md: "16px" }, height: "calc(100vh - 512px)" }}

					>
						<Box sx={{ height: "calc(100vh - 512px)" }}>
							{orderId ?
								<Stack direction="column" spacing={2} mb={2}>
									<OrderFinish orderSubmitResponse={orderId} />
									<Divider sx={{ m: 2 }} />
									<Button
										onClick={() => setOrderId(null)}
										variant="contained"
										color="secondary"
										sx={{ color: 'white' }}
									>Create New Order</Button>
								</Stack>
								:
								<OrderForm setOrderId={setOrderId} reset={reset} />
							}
						</Box>
					</Container>
				</Box>
			</Box >
		</ThemeProvider >
	)
}

export default NewOrder2;
