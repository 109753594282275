import * as React from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import TabPanel, { a11yProps } from "../components/TabPanel";
import Box from "@mui/material/Box";
import { Typography } from "@mui/material";
import CriticalsTable from "../tables/CriticalsTable";
import {
    getCompletedCriticals,
    getPendingCriticals,
} from "../services/criticals";
import Framework from "../components/Framework";
import { useTabState } from "../components/useTabState";
import TableFrame from "../components/TableFrame";
import { DataTableContainerNoButton } from "../components/TableContainer";

export default function Criticals() {
    const [value, setValue] = useTabState(0);

    const handleChange = (_, newValue) => {
        setValue(newValue);
    };

    return (
        <Framework table>
            <Box sx={{ pl: 2, pr: 2, pt: 1 }}>
                <Typography variant="h5">Criticals</Typography>
                <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                    <Tabs value={value} onChange={handleChange}>
                        <Tab label="Pending" {...a11yProps(0)} />
                        <Tab label="Completed" {...a11yProps(0)} />
                    </Tabs>
                </Box>
            </Box>
            <TabPanel value={value} index={0}>
                <TableFrame>
                    <DataTableContainerNoButton>
                        <CriticalsTable getCriticals={getPendingCriticals} completed={false} />
                    </DataTableContainerNoButton>
                </TableFrame>
            </TabPanel>
            <TabPanel value={value} index={1}>
                <TableFrame>
                    <DataTableContainerNoButton>
                        <CriticalsTable getCriticals={getCompletedCriticals} completed={true} />
                    </DataTableContainerNoButton>
                </TableFrame>
            </TabPanel>
        </Framework>
    );
}
