import React from "react";
import jwt_decode from "jwt-decode";
import Cookies from "js-cookie";

export const getSessionCookie = () => {
    const sessionCookie = Cookies.get("jot");

    const emptySession = {
        loading: true,
        user: {
            FirstName: '',
            LastName: '',
            Email: '',
            Roles: [],
            Facilities: [],
        }
    }

    if (sessionCookie === undefined) {
        return emptySession;
    }

    try {
        const token = jwt_decode(sessionCookie);
        return { user: token, loading: false };
    } catch (e) {
        return emptySession;
    }
};

export const decodeCookie = () => {
    try {
        const token = jwt_decode(Cookies.get('jot'));
        return token;
    } catch (e) {
        return null;
    }

}

export const SessionContext = React.createContext({
    loading: true,
    setLoading: () => { },
    user: {
        FirstName: '',
        LastName: '',
        Email: '',
        Roles: [],
        Facilities: [],
        IsProvider: false,
        ProviderID: null,
        Type: ""
    },
    setUser: () => { }
});

