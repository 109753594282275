import Title from "./Title";
import React, { useState } from "react";
import { useFetch } from "../services/fetch";
import DataTable from "./DataTable";
import { downloadAttachment } from "../services/util";
import ErrorAlert from "./ErrorAlert";
import { Button } from "@mui/material";
import TableFrame from "./TableFrame";
import { MiniTableContainer } from "./TableContainer";
import ButtonTray from "./ButtonTray";
import { FormatDateTime } from "../services/util";

export default function InstrumentResultFilesTable(props) {
    const [resultFile, setResultFile] = useState([]);
    const [error, setError] = useState(null)


    const { data, isLoading, error: fetchError } = useFetch("/instruments/interfaces/files/?type=resultFile")

    const handleDownload = () => {
        if (!Array.isArray(data)) {
            setError("Download failed.")
            return
        }

        const selectedFile = data.filter((f) => f.ID === resultFile[0])

        if (selectedFile.length !== 1) {
            setError("Download failed.")
            return
        }

        const uuid = selectedFile[0]?.Attachment?.UUID
        const filename = selectedFile[0]?.Attachment?.Name

        downloadAttachment(uuid, filename, setError)
    }

    const columns = [
        { field: "ID", headerName: "ID", width: 45 },
        { field: "CreatedAt", headerName: "Created", width: 150, valueGetter: row => FormatDateTime(row.row.CreatedAt) },
        { field: "File", headerName: "File", width: 200, valueGetter: row => row?.row?.Attachment?.Name },

    ];

    return (
        <React.Fragment>
            <TableFrame>
                <MiniTableContainer>
                    <DataTable
                        data={data}
                        loading={isLoading}
                        error={fetchError}
                        columns={columns}
                        selected={resultFile}
                        setSelected={setResultFile}
                    />
                </MiniTableContainer>
                <ErrorAlert error={error} />
                <ButtonTray>
                    <Button variant="contained" color="secondary" sx={{ color: "white" }} onClick={handleDownload}>Download</Button>
                </ButtonTray>
            </TableFrame>
        </React.Fragment>
    );
}
