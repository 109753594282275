import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@mui/material';
import React from 'react';
import { apiFetch } from '../../services/fetch';
import ErrorAlert from '../ErrorAlert';

type DisablePatientDialogProps = {
    patientId: number;
    setPatientId: React.Dispatch<React.SetStateAction<number | null>>;
}

function DisablePatientDialog({ patientId, setPatientId }: DisablePatientDialogProps) {
    const [disableReason, setDisableReason] = React.useState<string>("");
    const [loading, setLoading] = React.useState(false);
    const [error, setError] = React.useState<null | string>(null);


    const save = async () => {
        setLoading(true)
        try {
            // @ts-ignore
            await apiFetch(`/patients/${patientId}/disable`, "POST", { Reason: disableReason })
            setError(null)
            setPatientId(null)
        } catch (e: any) {
            console.log(e)
            setError("Failed to disable patient")
        } finally {
            setLoading(false)
        }
    }

    return (
        <Dialog open={!!patientId}>
            <DialogTitle>Note</DialogTitle>
            <DialogContent>
                <TextField
                    label="Disable Reason"
                    multiline
                    value={disableReason}
                    onChange={(e) => setDisableReason(e.target.value)}
                />
                <ErrorAlert error={error} />
            </DialogContent>
            <DialogActions>
                <Button onClick={() => setPatientId(null)}>Close</Button>
                <Button onClick={() => save()} disabled={loading || disableReason === ""}>Save</Button>
            </DialogActions>
        </Dialog>
    );
}

export default DisablePatientDialog;
