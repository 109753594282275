import { useState, useEffect } from "react";
import {
    Autocomplete,
    Stack,
    TextField,
    FormControlLabel,
    Checkbox,
    InputLabel
} from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

import { stateAbbreviations } from "../services/util";
import { getCityState } from "../services/usps";
import dayjs from "dayjs";
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);

function PatientFields({ formik, requireContact = false }) {
    const [zipCode, setZipCode] = useState("");

    useEffect(() => {
        if (zipCode.length === 5 || zipCode.length === 10) {
            getCityState(zipCode)
                .then((data) => {
                    formik.setFieldValue("Address.City", data.city);
                    formik.setFieldValue("Address.State", data.state);
                })
                .catch((err) => {
                    formik.setFieldError("Address.ZipCode", err.message);
                });
        }
    }, [zipCode]);

    const handleSSN = (e) => {
        let value = e.target.value;
        value = value.replace(/\D/g, ''); // Remove any non-digit characters
        if (value.length > 3 && value.length <= 5) {
            value = value.slice(0, 3) + '-' + value.slice(3);
        } else if (value.length > 5) {
            value = value.slice(0, 3) + '-' + value.slice(3, 5) + '-' + value.slice(5, 9);
        }
        formik.setFieldValue("SSN", value);
    }

    const updatePatientInsuranceFields = (e) => {
        console.log(e);
        console.log(formik.values)
        if (!formik.values?.PatientInsurances || formik.values?.PatientInsurances.length < 1) {
            return
        }

        for (let i = 0; i < formik.values?.PatientInsurances.length; i++) {
            if (formik.values?.PatientInsurances[i].Relationship === "Self") {
                copyPatientToSubscriber(i, e.target.name, e.target.value);
            }
        }
    }

    const copyPatientToSubscriber = (index, field, value) => {
        formik.setFieldValue(`PatientInsurances[${index}].Subscriber.${field}`, value)
        formik.setTouched({ ...formik.touched, [`PatientInsurances[${index}].Subscriber.${field}`]: true })
    }


    const handleEthnicityChange = (_, value) => {
        console.log(value)
        if (value.length === 1) {
            if (value.toUpperCase() === 'N') {
                value = 'Not Hispanic or Latino';
            } else if (value.toUpperCase() === 'H') {
                value = 'Hispanic or Latino';
            } else if (value.toUpperCase() === 'U') {
                value = 'Unknown';
            }
        }

        formik.setFieldValue('Ethnicity', value);
    };


    const handleSexChange = (_, value) => {
        console.log(value)
        if (value.length === 1) {
            if (value.toUpperCase() === 'M') {
                value = 'Male';
            } else if (value.toUpperCase() === 'F') {
                value = 'Female';
            }
        }

        formik.setFieldValue('Sex', value);
    };

    return (
        <>
            <Stack
                direction={{ sm: "column", md: "row" }}
                spacing={{ xs: 1, sm: 2, md: 2 }}
            >
                <TextField
                    required
                    size="small"
                    onChange={formik.handleChange}
                    error={
                        formik.touched.FirstName &&
                        Boolean(formik.errors.FirstName)
                    }
                    value={formik.values.FirstName}
                    onBlur={(e) => { formik.handleBlur(e); updatePatientInsuranceFields(e) }}
                    helperText={
                        formik.touched.FirstName &&
                        formik.errors.FirstName
                    }
                    name="FirstName"
                    label="First Name"
                    variant="outlined"
                />
                <TextField
                    required
                    size="small"
                    onChange={formik.handleChange}
                    error={
                        formik.touched.LastName &&
                        Boolean(formik.errors.LastName)
                    }
                    value={formik.values.LastName}
                    onBlur={(e) => { formik.handleBlur(e); updatePatientInsuranceFields(e) }}
                    helperText={
                        formik.touched.LastName &&
                        formik.errors.LastName
                    }
                    name="LastName"
                    label="Last Name"
                    variant="outlined"
                />
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                        disableFuture
                        label="Date of Birth"
                        name="DOB"
                        timezone="utc"
                        value={formik.values.DOB}
                        onChange={(date) => {
                            const formattedDate = dayjs(date).format('YYYY-MM-DD');
                            console.log('setting dob', dayjs.utc(formattedDate));

                            formik.setFieldValue("DOB", dayjs.utc(formattedDate));
                            updatePatientInsuranceFields({ target: { name: "DOB", value: dayjs.utc(formattedDate) } })
                        }
                        }
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                required
                                size="small"
                                helperText={
                                    (formik.touched.DOB &&
                                        formik.errors.DOB) ? formik.errors.DOB : "mm/dd/yyyy"
                                }
                                error={
                                    formik.touched.DOB &&
                                    Boolean(formik.errors.DOB)
                                }
                            />
                        )}
                    />
                </LocalizationProvider>
                <Autocomplete
                    disablePortal
                    required
                    options={["Male", "Female"]}
                    size="small"
                    onChange={handleSexChange}
                    onInputChange={handleSexChange}
                    inputValue={formik.values.Sex}
                    value={formik.values.Sex}
                    onBlur={formik.handleBlur}
                    name="Sex"
                    sx={{ minWidth: "223px", maxWidth: "100%" }}
                    variant="outlined"
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            required
                            error={
                                formik.touched.Sex &&
                                Boolean(formik.errors.Sex)
                            }
                            helperText={
                                formik.touched.Sex &&
                                formik.errors.Sex
                            }
                            name="Sex"
                            size="small"
                            label="Sex"
                        />
                    )}
                />
            </Stack>
            <Stack
                direction={{ sm: "column", md: "row" }}
                spacing={{ xs: 1, sm: 2, md: 2 }}
            >
                <TextField
                    size="small"
                    name="SSN"
                    label="SSN"
                    variant="outlined"
                    placeholder="XXX-XX-XXXX"
                    inputProps={{ maxLength: 11 }}
                    onChange={handleSSN}
                    error={
                        formik.touched.SSN && Boolean(formik.errors.SSN)
                    }
                    value={formik.values.SSN}
                    onBlur={formik.handleBlur}
                    helperText={formik.touched.SSN && formik.errors.SSN}
                />
                <Autocomplete
                    required
                    size="small"
                    autoComplete="off"
                    disablePortal
                    options={["Not Hispanic or Latino", "Hispanic or Latino", "Unknown"]}
                    onChange={handleEthnicityChange}
                    value={formik.values.Ethnicity}
                    onInputChange={handleEthnicityChange}
                    inputValue={formik.values.Ethnicity}
                    onBlur={formik.handleBlur}
                    name="Ethnicity"
                    sx={{ minWidth: "223px", maxWidth: "100%" }}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            required
                            error={
                                formik.touched.Ethnicity &&
                                Boolean(formik.errors.Ethnicity)
                            }
                            helperText={
                                formik.touched.Ethnicity &&
                                formik.errors.Ethnicity
                            }
                            name="Ethnicity"
                            size="small"
                            label="Ethnicity"
                        />
                    )}
                >
                </Autocomplete>
            </Stack>
            <InputLabel>Address</InputLabel>
            <Stack
                direction={{ sm: "column", md: "row" }}
                spacing={{ xs: 1, sm: 2, md: 2 }}
            >
                <TextField
                    required
                    size="small"
                    onChange={formik.handleChange}
                    error={
                        formik.touched.Address?.Address1 &&
                        Boolean(formik.errors.Address?.Address1)
                    }
                    value={formik.values.Address?.Address1}
                    onBlur={formik.handleBlur}
                    helperText={
                        formik.touched.Address?.Address1 &&
                        formik.errors.Address?.Address1
                    }
                    name="Address.Address1"
                    label="Address"
                    variant="outlined"
                />
                <TextField
                    size="small"
                    onChange={formik.handleChange}
                    error={
                        formik.touched.Address?.Address2 &&
                        Boolean(formik.errors.Address?.Address2)
                    }
                    value={formik.values.Address?.Address2}
                    onBlur={formik.handleBlur}
                    helperText={
                        formik.touched.Address?.Address2 &&
                        formik.errors.Address?.Address2
                    }
                    name="Address.Address2"
                    label="Address 2"
                    variant="outlined"
                />
            </Stack>
            <Stack
                direction={{ sm: "column", md: "row" }}
                spacing={{ xs: 1, sm: 2, md: 2 }}
            >
                <TextField
                    required
                    size="small"
                    onChange={(e) => {
                        formik.handleChange(e);
                        setZipCode(e.target.value);
                    }}
                    error={
                        formik.touched.Address?.ZipCode &&
                        Boolean(formik.errors.Address?.ZipCode)
                    }
                    value={formik.values.Address?.ZipCode}
                    onBlur={formik.handleBlur}
                    helperText={
                        formik.touched.Address?.ZipCode &&
                        formik.errors.Address?.ZipCode
                    }
                    name="Address.ZipCode"
                    label="ZipCode"
                    variant="outlined"
                />
                <TextField
                    required
                    size="small"
                    onChange={formik.handleChange}
                    error={
                        formik.touched.Address?.City &&
                        Boolean(formik.errors.Address?.City)
                    }
                    value={formik.values.Address?.City}
                    onBlur={formik.handleBlur}
                    helperText={
                        formik.touched.Address?.City &&
                        formik.errors.Address?.City
                    }
                    name="Address.City"
                    label="City"
                    variant="outlined"
                />
                <Autocomplete
                    disablePortal
                    required
                    options={stateAbbreviations}
                    sx={{ minWidth: "223px", maxWidth: "100%" }}
                    name="Address.State"
                    value={formik.values.Address?.State}
                    onChange={(_, value) => {
                        formik.setFieldValue("Address.State", value);
                    }}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            required
                            error={
                                formik.touched.Address?.State &&
                                Boolean(formik.errors.Address?.State)
                            }
                            helperText={
                                formik.touched.Address?.City &&
                                formik.errors.Address?.City
                            }
                            name="State"
                            size="small"
                            label="State"
                        />
                    )}
                />
            </Stack>
            <InputLabel>Contact</InputLabel>
            <Stack
                direction={{ sm: "column", md: "row" }}
                spacing={{ xs: 1, sm: 2, md: 2 }}
            >
                <TextField
                    inputProps={{ type: "email" }}
                    size="small"
                    name="Email"
                    onChange={formik.handleChange}
                    error={
                        formik.touched.Email &&
                        Boolean(formik.errors.Email)
                    }
                    value={formik.values.Email}
                    onBlur={formik.handleBlur}
                    helperText={
                        formik.touched.Email && formik.errors.Email
                    }
                    label="Email"
                    variant="outlined"
                />
                {/* Make email and phone field enable the checkbox fields if entered */}
                <TextField
                    required={requireContact && formik.values.Email === "" && !Boolean(formik.errors.Email)}
                    size="small"
                    name="Phone"
                    label="Phone"
                    variant="outlined"
                    onChange={formik.handleChange}
                    error={
                        formik.touched.Phone &&
                        Boolean(formik.errors.Phone)
                    }
                    value={formik.values.Phone}
                    onBlur={formik.handleBlur}
                    helperText={
                        formik.touched.Phone && formik.errors.Phone
                    }
                />
                <TextField
                    size="small"
                    name="Fax"
                    label="Fax"
                    variant="outlined"
                    onChange={formik.handleChange}
                    error={
                        formik.touched.Fax && Boolean(formik.errors.Fax)
                    }
                    value={formik.values.Fax}
                    onBlur={formik.handleBlur}
                    helperText={formik.touched.Fax && formik.errors.Fax}
                />
            </Stack>
            <InputLabel>Notification Method</InputLabel>
            <Stack
                direction={{ sm: "column", md: "row" }}
                spacing={{ xs: 1, sm: 2, md: 2 }}
            >
                <FormControlLabel
                    sx={{ marginLeft: 0 }}
                    control={
                        <Checkbox
                            checked={formik.values?.EmailResults}
                            disabled={
                                formik.values.Email === "" ||
                                formik.values.Email === undefined ||
                                Boolean(formik.errors.Email)
                            }
                            onChange={formik.handleChange}
                        />
                    }
                    name="EmailResults"
                    label="Email"
                ></FormControlLabel>
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={formik.values?.TextResults}
                            disabled={
                                formik.values.Phone === "" ||
                                formik.values.Phone === undefined ||
                                Boolean(formik.errors.Phone) || requireContact
                            }
                            onChange={formik.handleChange}
                        />
                    }
                    name="TextResults"
                    label="Text"
                ></FormControlLabel>
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={formik.values?.FaxResults}
                            disabled={
                                formik.values.Fax === "" ||
                                formik.values.Fax === undefined ||
                                Boolean(formik.errors.Fax)
                            }
                            onChange={formik.handleChange}
                        />
                    }
                    label="Fax"
                    name="FaxResults"
                ></FormControlLabel>
            </Stack>
        </>
    )
}

export default PatientFields;
