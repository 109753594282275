import React, { useState } from "react";
import Box from "@mui/system/Box";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import TabPanel, { a11yProps } from "../components/TabPanel";
import { DataTableContainerNoButton } from "../components/TableContainer";
import TestCodeMappings from "../tables/testcodemapping";
import InsuranceMappings from "../tables/insurancemappings";
import FacilityMappings from "../tables/facilitiesmappings";

export default function MappingTabs() {
	const [value, setValue] = useState(0);

	const tabs = [
		{ label: "Test Codes", component: <TestCodeMappings /> },
		{ label: "Insurances", component: <InsuranceMappings /> },
		{ label: "Facilities", component: <FacilityMappings /> }
	]

	const handleChange = (_: any, newValue: any) => {
		setValue(newValue);
	};

	return (
		<>
			<Box sx={{ borderBottom: 1, borderColor: "divider" }}>
				<Tabs value={value} onChange={handleChange}>
					{tabs.map((tab, index) => (
						<Tab label={tab.label} {...a11yProps(index)} />
					)
					)}
				</Tabs>
			</Box>
			{tabs.map((tab, index) => (
				<TabPanel value={value} index={index}>
					<DataTableContainerNoButton>
						{tab.component}
					</DataTableContainerNoButton>
				</TabPanel>
			))}
		</>
	);
}
