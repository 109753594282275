import {
    Button,
    TableContainer,
    Table,
    TableHead,
    TableRow,
    TableBody,
    TableCell,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useState, useContext } from "react";
import { SessionContext } from "../../hooks/getContext";
import { apiFetch } from "../../services/fetch";
import ConfirmDialog from "../ConfirmDialog";
import ErrorAlert from "../ErrorAlert";


function Reflexes({ orderDetails, callback }) {
    const [confirmDialog, setConfirmDialog] = useState(false);
    const [reflexId, setReflexId] = useState(null);
    const [reflexName, setReflexName] = useState(null);
    const [error, setError] = useState(null);
    const session = useContext(SessionContext);

    const handleReflexDelete = (reflexId, reflexDesc) => {
        console.log(reflexId)
        setReflexId(reflexId)
        setReflexName(reflexDesc)
        setConfirmDialog(true)
    }

    const delReflex = () => {
        const del = async () => {
            try {
                await apiFetch(`/orders/${orderDetails.ID}/reflexes/${reflexId}`, "DELETE")
                callback()
            } catch (e) {
                setError("Failed to delete reflex.")
            }
        }

        del()
    }


    if (
        orderDetails.Reflexes === undefined ||
        orderDetails.Reflexes === null
    ) {
        return <></>;
    }
    return (
        <>
            <ConfirmDialog
                title={"Are you sure?"}
                message={`You are about to delete reflex '${reflexName}'.`}
                open={confirmDialog}
                cancel={() => setConfirmDialog(false)}
                confirm={() => { delReflex(); setConfirmDialog(false) }}
            />
            <TableContainer style={{ maxHeight: 300, width: "100%" }}>
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            {session?.user?.Type === "Lab" &&
                                <TableCell size="small" sx={{ padding: "0" }}>
                                </TableCell>}
                            <TableCell>Description</TableCell>
                            <TableCell>Source</TableCell>
                            <TableCell>Reflexes To</TableCell>
                            <TableCell>Reflexes When</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {orderDetails.Reflexes.map((row) => (
                            <TableRow key={row.ID}>
                                {session?.user?.Type === "Lab" &&
                                    <TableCell size="small" sx={{ padding: "0" }}>
                                        <Button onClick={() => handleReflexDelete(row.ID, row.Description)}
                                            size="small"
                                            sx={{ minWidth: "12px" }}>
                                            <CloseIcon color="error" />
                                        </Button>
                                    </TableCell>}
                                <TableCell>{row.Description}</TableCell>
                                <TableCell>
                                    {row.SourceTest?.Name ||
                                        row.SourceProfile?.Name}
                                </TableCell>
                                <TableCell>
                                    {row.DestTest?.Name || row.DestProfile?.Name}
                                </TableCell>
                                <TableCell>{row.ReflexRangeType}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <ErrorAlert error={error} />
        </>
    );
};

export default Reflexes;
