import { useMediaQuery } from "@mui/material";
import { Box } from "@mui/system";
import mdTheme from "../components/Theme";

const GLOBAL_OFFSET = 285;

function DataTableContainer({ children, ...props }) {
    const isMobile = useMediaQuery(mdTheme.breakpoints.down("sm"));
    let offset = 53;

    if (isMobile) {
        offset = 0
    }

    return (
        <Box height={`calc(100vh - ${GLOBAL_OFFSET + offset}px)`} {...props}>
            {children}
        </Box>
    )

}

function DataTableContainerNoButton({ children, ...props }) {
    const isMobile = useMediaQuery(mdTheme.breakpoints.down("sm"));
    console.log('is_mobile', isMobile)
    let offset = 0

    if (isMobile) {
        offset = -50
    }

    return (
        <Box id="datatablecontainerNoButton" height={`calc(100vh - ${GLOBAL_OFFSET + offset}px)`} {...props}>
            {children}
        </Box>
    )

}

function MiniTableContainer({ children, ...props }) {
    let offset = 150
    return (
        <Box height={`calc(100vh - ${GLOBAL_OFFSET + offset}px)`} {...props}>
            {children}
        </Box>
    )

}


export { DataTableContainerNoButton, DataTableContainer, MiniTableContainer };
