import React, { useState, useEffect } from 'react';
import {
    Button,
    Divider,
    Stack,
    Typography,
} from "@mui/material";
import { formatAddress } from "../../services/util";
import { Facility, Order } from '../../constants/types';
import { apiFetch } from '../../services/fetch';
import ErrorAlert from '../ErrorAlert';
import ModalImage from "react-modal-image";
import { FormatDateTime } from '../../services/util';

type SignatureResponse = {
    SignatureName: string,
    SignatureImg: string,
    SignedAt: string
}

type AllowedResponse = {
    Allowed: boolean
}

function FacilityMeta({ orderDetails, facility }: { orderDetails: Order, facility: Facility }) {
    const [signature, setSignature] = useState<SignatureResponse | null>(null)
    const [allowedToSign, setAllowedToSign] = useState(false)
    const [viewSig, setViewSig] = useState(false)
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState<null | string>(null)
    const [refresh, setRefresh] = useState(false)

    useEffect(() => {
        const init = async () => {
            //check if allowed to sign
            try {
                const allowedResponse: AllowedResponse = await apiFetch(`/orders/${orderDetails.ID}/sign/allowed`, 'GET')
                console.log(allowedResponse)
                setAllowedToSign(allowedResponse?.Allowed)

                const response: SignatureResponse = await apiFetch(`/orders/${orderDetails.ID}/sign`, 'GET')
                console.log(response)
                setSignature(response)
            } catch (e) {
                setError('Failed to load signature')
            }
        }

        init()
    }, [refresh, orderDetails])

    const signOrder = async (action: string) => {
        setLoading(true)

        let method = 'POST'
        if (action === "delete") {
            method = 'DELETE'
        }

        try {
            apiFetch(`/orders/${orderDetails?.ID}/sign`, method)
            setRefresh(!refresh)
        } catch (e) {
            if (action === "delete") {
                setError('Failed to remove signature')
            } else {
                setError('Failed to sign order')
            }
        }
    }

    if (orderDetails.Facility === undefined) {
        return <></>;
    }
    return (
        <>
            <Typography variant="h6">
                {orderDetails.Facility.Name}
            </Typography>
            <Typography variant="body2">
                Address:{" "}
                {formatAddress(
                    facility?.Address?.Address1,
                    facility?.Address?.Address2,
                    facility?.Address?.City,
                    facility?.Address?.State,
                    facility?.Address?.ZipCode
                )}
            </Typography>
            {facility?.Phone !== "" ? (
                <Typography variant="body1">
                    Phone: {facility?.Phone}
                </Typography>
            ) : null}
            <Divider sx={{ m: 1 }} />
            <Typography variant="body1">
                Provider:{" "}
                {orderDetails.Provider.FirstName +
                    " " +
                    orderDetails.Provider.LastName}{" "}
            </Typography>
            <Typography variant="body1">
                Provider NPI: {orderDetails.Provider.NPI}
            </Typography>
            <ErrorAlert error={error} />
            {signature?.SignatureImg &&
                <Stack direction="column" spacing={0}>
                    <Divider orientation='horizontal' />
                    {viewSig &&
                        <ModalImage small={signature.SignatureImg} />}
                    <Typography variant="body1">Signed By: {signature.SignatureName}</Typography>
                    <Typography variant="body1">Signed At: {FormatDateTime(signature.SignedAt)}</Typography>
                    <Button
                        variant="outlined"
                        onClick={() => setViewSig(!viewSig)}
                    >{viewSig ? "Hide" : "View Signature"}</Button>
                </Stack>
            }
            {allowedToSign &&
                <>
                    <Stack direction="column" spacing={2}>
                        <Divider orientation='horizontal' />
                        <Stack direction="row" spacing={0} justifyContent="space-between" width={"100%"}>
                            <Button variant="contained" color="success" disabled={!!signature?.SignatureImg || loading} onClick={() => {
                                signOrder("sign")
                            }}>Sign</Button>
                            <Button variant="contained" color="error" disabled={!signature?.SignatureImg || loading} onClick={() => {
                                signOrder("delete")
                            }}
                            >Remove</Button>
                        </Stack >
                    </Stack >
                </>
            }
        </>
    );
};

export default FacilityMeta;
