import React from "react";
import { useFormikContext } from "formik";
import { ReportServices, ReportService } from "../constants/constants";
import {
    Box,
    Table, TableHead, TableBody, TableRow, TableCell,
    TextField,
    Checkbox,
} from "@mui/material";

type ReportServiceDetails = {
    ReportServiceID: number;
    ReportServiceMetadata: string;
    Enabled: boolean;
}


// displays a static list of defined report services
function TestEditReportService() {
    const { setFieldValue, values, setFieldTouched } = useFormikContext();

    const existsInReportServiceDetails = (reportService: ReportService) => {
        // @ts-ignore
        if (!values?.ReportServices) {
            return false
        }

        // @ts-ignore
        return values?.ReportServices.some((rsd: any) => rsd?.ReportServiceID === reportService.ID);
    }

    const getFieldIndex = (reportService: ReportService) => {
        // @ts-ignore
        if (!values?.ReportServices) {
            return false
        }
        // @ts-ignore
        return values?.ReportServices.findIndex((rsd: ReportServiceDetails) => rsd?.ReportServiceID === reportService.ID);
    }

    const getFieldValue = (reportService: ReportService, name: string): string => {
        // @ts-ignore
        if (!values?.ReportServices) {
            return "";
        }

        const index = getFieldIndex(reportService)
        // check if index exists in reportservice details
        // @ts-ignore
        if (index < 0 || values?.ReportServices[index] === undefined) {
            return "";
        }
        // @ts-ignore
        return values?.ReportServices[index][name];
    }


    const getBooleanFieldValue = (reportService: ReportService, name: string): boolean => {
        // @ts-ignore
        if (!values?.ReportServices) {
            return false;
        }

        const index = getFieldIndex(reportService);
        // @ts-ignore
        if (index < 0 || values?.ReportServices[index] === undefined) {
            return false;
        }
        // @ts-ignore
        return values?.ReportServices[index][name];
    }

    const handleEnabledFieldChange = (reportService: ReportService, event: React.ChangeEvent<HTMLInputElement>) => {
        if (existsInReportServiceDetails(reportService)) {
            const index = getFieldIndex(reportService);
            // delete index from values ReportServices
            // we cannot just set the index to undefined. it must be removed
            // from the array
            // @ts-ignore
            setFieldValue(`ReportServices`, values?.ReportServices.filter((rsd: ReportServiceDetails, i: number) => i !== index));
            return
        }
        // @ts-ignore
        let reportServices = values?.ReportServices || [];
        reportServices.push({ ReportServiceID: reportService.ID, ReportServiceMetadata: '', Default: false })
        setFieldValue(`ReportServices`, reportServices);
    }

    const handleBooleanFieldChange = (reportService: ReportService, event: React.ChangeEvent<HTMLInputElement>) => {
        if (existsInReportServiceDetails(reportService)) {
            const index = getFieldIndex(reportService);
            setFieldValue(`ReportServices[${index}].${event.target.name}`, event.target.checked);
            setFieldTouched(`ReportServices[${index}].${event.target.name}`)
            return
        }
        // @ts-ignore
        let reportServices = values?.ReportServices || [];
        reportServices.push({ ReportServiceID: reportService.ID, ReportServiceMetadata: '', Default: false })
        setFieldValue(`ReportServices`, reportServices);
        const index = getFieldIndex(reportService);
        setFieldValue(`ReportServices[${index}].${event.target.name}`, event.target.checked);
        setFieldTouched(`ReportServices[${index}].${event.target.name}`)
    }

    const handleFieldChange = (reportService: ReportService, event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        if (existsInReportServiceDetails(reportService)) {
            const index = getFieldIndex(reportService);
            console.log(index);
            setFieldValue(`ReportServices[${index}].${event.target.name}`, event.target.value);
            setFieldTouched(`ReportServices[${index}].${event.target.name}`)
            return
        }

        // @ts-ignore
        let reportServices = values?.ReportServices || [];
        reportServices.push({ ReportServiceID: reportService.ID, ReportServiceMetadata: '', Enabled: false, Default: false })
        setFieldValue(`ReportServices`, reportServices);
        const index = getFieldIndex(reportService);
        setFieldValue(`ReportServices[${index}].${event.target.name}`, event.target.value);
        setFieldTouched(`ReportServices[${index}].${event.target.name}`)
    }

    return <Box maxWidth="600px">
        <Table>
            <TableHead>
                <TableRow>
                    <TableCell>Report Service</TableCell>
                    <TableCell>Metadata</TableCell>
                    <TableCell>Enabled</TableCell>
                    <TableCell>Default</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {ReportServices.map((reportService, index: number) => (
                    <TableRow>
                        <TableCell>{reportService.Name}</TableCell>
                        <TableCell>
                            <TextField
                                name="ReportServiceMeta"
                                label="Metadata"
                                size="small"
                                value={getFieldValue(reportService, "ReportServiceMeta")}
                                onChange={(e) => handleFieldChange(reportService, e)}
                            />
                        </TableCell>
                        <TableCell>
                            <Checkbox
                                name={"Enabled"}
                                checked={existsInReportServiceDetails(reportService)}
                                onChange={(e) => handleEnabledFieldChange(reportService, e)}
                            />
                        </TableCell>
                        <TableCell>
                            <Checkbox
                                name={"Default"}
                                checked={getBooleanFieldValue(reportService, "Default")}
                                onChange={(e) => handleBooleanFieldChange(reportService, e)}
                            />
                        </TableCell>

                    </TableRow>
                ))}
            </TableBody>
        </Table>
    </Box>;
}

export default TestEditReportService;
