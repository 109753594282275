import React, { useState, useEffect } from 'react';
import { Grid, Paper, Stack, Divider, InputLabel, Box, Button, Typography } from '@mui/material';
import { FormikErrors } from '../ErrorAlert';
import { getFacilities } from "../../services/facilities";
import { getProvidersByFacility } from "../../services/providers";
import { getPatient, searchPatientsByFacility } from "../../services/patients";
import FacilityProviderSelect from '../FacilityProviderSelect';
import PatientSearch from '../PatientSearch';
import OrderPatientDisplay from '../OrderPatientDisplay';
import { useFormikContext } from 'formik';
import { Facility, Patient, Provider } from '../../constants/types';
import { useSearchParams } from 'react-router-dom';
import ImageCapture from "../ImageCapture";
import Title from "../Title";
import { OrderValues } from './OrderForm';
import ModalImage from 'react-modal-image';
import PreviousPatientOrdersSummary from '../PreviousPatientOrdersSummary';
import PatientSignatureDialog from '../PatientSignature';
import DrawIcon from '@mui/icons-material/Draw';

function CustomPaper({ children }: { children: React.ReactNode }) {
    return (
        <Paper
            elevation={5}
            sx={{
                p: 2,
                display: "flex",
                flexDirection: "column",
                m: 2,
            }}
        >
            {children}
        </Paper>
    )
}

function IDCapture({ fieldName }: { fieldName: string }) {
    const { values, setFieldValue } = useFormikContext<OrderValues>();

    const value = values?.[fieldName];

    return (
        <>
            {value ?
                <Stack direction="column" spacing={2}>
                    <ModalImage
                        hideZoom
                        small={value}
                        medium={value} />
                    <Button onClick={() => { setFieldValue(fieldName, null) }} variant="outlined" color="error">Delete</Button>
                </Stack>
                :
                <>
                    <Box alignItems={"center"} display="flex" flexDirection={"column"}>
                        <ImageCapture
                            image64={value}
                            setImage64={(img: string) => { setFieldValue(fieldName, img); }}
                            disableUpload />
                    </Box>
                </>
            }
        </>
    )
}

function PatientSignatureDisplay({ sig, patient }: { sig: string, patient: Patient | null }) {
    return (
        <Stack direction="column" spacing={0}>
            <ModalImage
                hideZoom
                small={sig}
                medium={sig} />
            <Typography variant="subtitle1">Name: {patient?.FirstName} {patient?.LastName}</Typography>
        </Stack>
    )
}

function FacilityProviderPatient() {
    const [searchParams, setSearchParams] = useSearchParams();
    const [facilities, setFacilities] = useState([]);
    const [providers, setProviders] = useState([]);
    const [patients, setPatients] = useState([]);
    const [openSigDialog, setOpenSigDialog] = useState(false);
    const { values, errors, touched, setFieldValue, setValues } = useFormikContext<OrderValues>();

    useEffect(() => {
        getFacilities().then((p) => {
            setFacilities(p.sort((a: Facility, b: Facility) => a.Name.localeCompare(b.Name)))
        });
    }, []);

    useEffect(() => {
        let patientID = searchParams.get("patient");
        if (patientID) {
            getPatient(patientID)
                .then((p) => {
                    if (!p.ok) {
                        throw new Error("Failed to load patient");
                    }
                    return p.json();
                })
                .then((p) => {
                    console.log('set patient', p);
                    setValues({ ...values, Patient: p, Facility: p.Facility });
                })
        }
    }, [searchParams]);


    useEffect(() => {
        let newValues = {};
        if (values.Facility?.ID) {
            getProvidersByFacility(values.Facility?.ID).then((p) => {
                setProviders(p.filter((p: Provider) => p.Enabled).sort((a: Provider, b: Provider) => a.FirstName.localeCompare(b.FirstName)))
            });
            searchPatientsByFacility(values.Facility?.ID)
                .then((p) => {
                    return p.json();
                })
                .then((p) => {
                    setPatients(p.sort((a: Patient, b: Patient) => a.LastName.localeCompare(b.LastName)))
                })
        } else {
            setProviders([]);
            setPatients([]);
        }
        if (values?.Patient && values?.Patient?.FacilityID !== values?.Facility?.ID) {
            newValues = { ...newValues, Patient: null };
        }
        newValues = { ...newValues, Provider: null };
        setValues({ ...values, ...newValues });

    }, [values.Facility?.ID]);

    return (
        <Grid container>
            <PatientSignatureDialog open={openSigDialog} setOpen={setOpenSigDialog} sig={values.PatientSignatureIMG} setSig={(v: any) => setFieldValue("PatientSignatureIMG", v)} />
            <Grid item xs={12} md={5} lg={4}>
                <Paper
                    sx={{
                        p: 2,
                        display: "flex",
                        flexDirection: "column",
                        // maxWidth: "95%",
                        m: 2,
                    }}
                >
                    <Stack direction="column" spacing={4}>
                        <FacilityProviderSelect
                            /* @ts-ignore */
                            providers={providers}
                            facilities={facilities}
                        // onFacilityChange={() => setOrder({ ...order, Patient: null })}
                        />
                        <Divider />
                        <PatientSearch
                            patients={patients}
                            patient={values?.Patient}
                            setPatient={(p: any) => setFieldValue('Patient', p)}
                        />
                    </Stack>
                </Paper>
                {values?.Patient?.ID &&
                    <Paper
                        sx={{
                            p: 2,
                            display: "flex",
                            flexDirection: "column",
                            // maxWidth: "95%",
                            m: 2,
                        }}
                    >
                        <PreviousPatientOrdersSummary patientID={values?.Patient?.ID} />
                    </Paper>
                }
                {values?.PatientSignatureIMG &&
                    <Paper
                        sx={{
                            p: 2,
                            display: "flex",
                            flexDirection: "column",
                            // maxWidth: "95%",
                            m: 2,
                        }}
                    >
                        <Title>Patient Signature</Title>
                        <PatientSignatureDisplay sig={values.PatientSignatureIMG} patient={values.Patient} />
                        <Button
                            onClick={() => setOpenSigDialog(true)}
                            variant="contained"
                            color="secondary"
                            sx={{ color: 'white' }}
                        >Resign</Button>
                    </Paper>
                }
            </Grid>
            <Grid item xs={12} md={7} lg={8}>
                <Stack direction="column" spacing={0}>
                    {values.Patient?.ID && <CustomPaper><OrderPatientDisplay patient={values.Patient?.ID} /></CustomPaper>}
                    {values.Patient?.ID && !values?.PatientSignatureIMG && <Button
                        onClick={() => setOpenSigDialog(true)}
                        variant="contained"
                        color="secondary"
                        endIcon={<DrawIcon />}
                        sx={{ color: 'white', p: 2, m: 2 }}
                    >Capture Patient Signature</Button>}
                    {values?.Patient?.ID &&
                        values?.Facility?.RequireIDCapture &&
                        <Box width="100%">
                            <CustomPaper>
                                <Title>Patient ID Capture</Title>
                                <InputLabel>Front</InputLabel>
                                <IDCapture fieldName="PhotoIDIMG" />
                            </CustomPaper>
                        </Box>
                    }

                    {values?.Patient?.ID &&
                        values?.Facility?.RequireInsuranceCapture &&
                        <>
                            <CustomPaper>
                                <Stack direction="column" spacing={2} divider={<Divider />}>
                                    <>
                                        <Title>Insurance Card ID Capture</Title>
                                        <InputLabel>Front</InputLabel>
                                        <IDCapture fieldName="InsuranceCardFrontIMG" />
                                    </>
                                    <>
                                        <InputLabel>Back</InputLabel>
                                        <IDCapture fieldName="InsuranceCardBackIMG" />
                                    </>
                                </Stack>
                            </CustomPaper>
                        </>
                    }
                    {values?.Patient?.ID &&
                        values?.Facility?.RequirePatientPhotoCapture &&
                        <Box width="100%">
                            <CustomPaper>
                                <Title>Patient Photo Capture</Title>
                                <IDCapture fieldName="PatientPhotoIMG" />
                            </CustomPaper>
                        </Box>
                    }
                </Stack>
            </Grid>
            <Grid item xs={12} md={5} lg={4}>
            </Grid>
        </Grid>
    )
}

export default FacilityProviderPatient;
