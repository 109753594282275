import {
    TableContainer,
    Table,
    TableHead,
    TableRow,
    TableBody,
    TableCell,
} from "@mui/material";

function AddOns({ orderDetails }) {
    if (orderDetails.AddOns === undefined || orderDetails.AddOns === null) {
        return <></>;
    }
    return (
        <TableContainer style={{ maxHeight: 300, width: "100%" }}>
            <Table size="small">
                <TableHead>
                    <TableRow>
                        <TableCell>Code</TableCell>
                        <TableCell>Test</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {orderDetails.AddOns.map((row) => (
                        <TableRow key={row.Code}>
                            <TableCell>{row.Code}</TableCell>
                            <TableCell>{row.Name}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
};


export default AddOns;
