export async function addOrderAttachment(orderID, file, type, report) {
    var data = new FormData();
    data.append("File", file);
    data.append("Type", type);
    data.append("Report", JSON.stringify(report)); // holds result data for referenceReports

    return fetch(
        `${process.env.REACT_APP_API_PROXY}/v1/orders/` +
        orderID +
        "/attachments",
        {
            method: "POST",
            credentials: "include",
            body: data,
        }
    );
}

export async function addBulkOrderResultAttachment(orderID, file, laboratoryId, action) {
    var data = new FormData();
    data.append("File", file);
    data.append("LaboratoryID", laboratoryId);
    data.append("Action", action);

    return fetch(
        `${process.env.REACT_APP_API_PROXY}/v1/orders/` +
        orderID +
        "/attachments/create",
        {
            method: "POST",
            credentials: "include",
            body: data,
        }
    );
}



export async function validateDiagnosis(cptCodes, diagnosisCodes) {
    return fetch(
        `${process.env.REACT_APP_API_PROXY}/v1/orders/diagnosis`,
        {
            method: "POST",
            credentials: "include",
            body: JSON.stringify({ CPTCodes: cptCodes, DiagnosisCodes: diagnosisCodes }),
        }
    );
}

export async function getDiagnosisRules(search = "", limit = "", page = "") {
    const params = new URLSearchParams({
        search,
        limit,
        page,
    });

    return fetch(
        `${process.env.REACT_APP_API_PROXY
        }/v1/orders/diagnosis/rules?${params.toString()}`,
        {
            method: "GET",
            credentials: "include",
        }
    );
}
export async function getDiagnosisCodes(search = "", limit = "", page = "") {
    const params = new URLSearchParams({
        search,
        limit,
        page,
    });

    return fetch(
        `${process.env.REACT_APP_API_PROXY
        }/v1/orders/diagnosis?${params.toString()}`,
        {
            method: "GET",
            credentials: "include",
        }
    );
}

export async function getBillings() {
    return fetch(`${process.env.REACT_APP_API_PROXY}/v1/orders/billing`, {
        method: "GET",
        credentials: "include",
    });
}
export async function getReadyBillings() {
    return fetch(
        `${process.env.REACT_APP_API_PROXY}/v1/orders/billing?status=Ready To Bill`,
        {
            method: "GET",
            credentials: "include",
        }
    );
}
export async function getNotReadyBillings() {
    return fetch(
        `${process.env.REACT_APP_API_PROXY}/v1/orders/billing?status=Not Ready To Bill`,
        {
            method: "GET",
            credentials: "include",
        }
    );
}
export async function getNoBillBillings() {
    return fetch(
        `${process.env.REACT_APP_API_PROXY}/v1/orders/billing?status=No Bill`,
        {
            method: "GET",
            credentials: "include",
        }
    );
}

export async function createBillingExport(orders, markAsBilled) {
    return fetch(
        `${process.env.REACT_APP_API_PROXY}/v1/orders/billing/export`,
        {
            method: "POST",
            credentials: "include",
            body: JSON.stringify({ Orders: orders, MarkAsBilled: markAsBilled })
        }
    );
}

export async function getBillingExport(id) {
    return fetch(
        `${process.env.REACT_APP_API_PROXY}/v1/orders/billing/export/${id}`,
        {
            method: "GET",
            credentials: "include",
        }
    );
}

export async function getBilledBillings() {
    return fetch(
        `${process.env.REACT_APP_API_PROXY}/v1/orders/billing?status=Billed`,
        {
            method: "GET",
            credentials: "include",
        }
    );
}

export async function getOrderResults(orderID) {
    return fetch(
        `${process.env.REACT_APP_API_PROXY}/v1/orders/` + orderID + "/results",
        {
            credentials: "include",
        }
    )
}

export async function getOrderDetails(orderID) {
    return fetch(`${process.env.REACT_APP_API_PROXY}/v1/orders/` + orderID, {
        credentials: "include",
    })
}

export async function updateOrderView(id, viewed = true) {
    return fetch(`${process.env.REACT_APP_API_PROXY}/v1/orders/${id}`, {
        method: "PUT",
        credentials: "include",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
        },
        body: JSON.stringify({ Viewed: viewed }),
    });

}
export async function addOrderAddOn(id, addOns) {
    return fetch(`${process.env.REACT_APP_API_PROXY}/v1/orders/${id}/addOns`, {
        method: "POST",
        credentials: "include",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
        },
        body: JSON.stringify(addOns),
    });
}
export async function addOrderNote(id, note) {
    return fetch(`${process.env.REACT_APP_API_PROXY}/v1/orders/${id}/notes`, {
        method: "POST",
        credentials: "include",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
        },
        body: JSON.stringify(note),
    });
}
export async function addOrder(order) {
    return fetch(`${process.env.REACT_APP_API_PROXY}/v1/orders/create`, {
        method: "POST",
        credentials: "include",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
        },
        body: JSON.stringify(order),
    });
}

export async function addProviderNotes(notes) {
    return fetch(`${process.env.REACT_APP_API_PROXY}/v1/providers/notes`, {
        method: "POST",
        credentials: "include",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
        },
        body: JSON.stringify(notes),
    });
}
