import { useState, useEffect } from 'react';

function useTabState(defaultValue) {
  // use the page URL as the key
  const key = window.location.pathname;
  
  const [tab, setTab] = useState(() => {
    const stickyValue = window.sessionStorage.getItem(key);
    return stickyValue !== null
      ? JSON.parse(stickyValue)
      : defaultValue;
  });

  useEffect(() => {
    window.sessionStorage.setItem(key, JSON.stringify(tab));
  }, [key, tab]);

  return [tab, setTab];
}

export { useTabState }; 
