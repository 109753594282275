const xml2js = require('xml2js');

async function getCityState(zip) {
  const userId = '6Q08ANIMA5969';  // Replace with your USPS User ID

  const requestXml = `
    <CityStateLookupRequest USERID="${userId}">
        <ZipCode ID="0">
            <Zip5>${zip}</Zip5>
        </ZipCode>
    </CityStateLookupRequest>
  `;

  const response = await fetch('https://production.shippingapis.com/ShippingAPI.dll?API=CityStateLookup&XML=' + encodeURIComponent(requestXml));
  const responseText = await response.text();

  const parser = new xml2js.Parser();
  const result = await parser.parseStringPromise(responseText);
  
  if (result.CityStateLookupResponse.ZipCode[0].Error) {
    throw new Error(result.CityStateLookupResponse.ZipCode[0].Error[0].Description[0]);
  }

  return {
    city: result.CityStateLookupResponse.ZipCode[0].City[0],
    state: result.CityStateLookupResponse.ZipCode[0].State[0]
  };
}


export { getCityState };
