export async function getFacilities() {
        return fetch(`${process.env.REACT_APP_API_PROXY}/v1/facilities`, {credentials: "include"})
        .then((response) => response.json());
}

export async function getFacility(id) {
        return fetch(`${process.env.REACT_APP_API_PROXY}/v1/facilities/${id}`, {credentials: "include"})
}

export async function updateFacility(facility, id) {
    return fetch(`${process.env.REACT_APP_API_PROXY}/v1/facilities/` + id, {
        method: "POST",
            credentials: "include",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
        },
        body: JSON.stringify(facility),
    })
        .then((response) => response.json())
}

export async function addFacility(facility) {
    return fetch(`${process.env.REACT_APP_API_PROXY}/v1/facilities`, {
        method: "POST",
            credentials: "include",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
        },
        body: JSON.stringify(facility),
    })
        .then((response) => response.json())
}

