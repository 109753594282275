import React, { useState } from 'react';
import { TextField, Button, List, ListItem, ListItemText, ListItemSecondaryAction, IconButton } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';

function ItemListInput({ items = [], setItems, label, disabled, ...props }) {
    const [newItem, setNewItem] = useState('');

    const handleAddItem = () => {
        if (newItem) {
            setItems([...items, newItem]);
            setNewItem('');
        }
    };

    const handleDeleteItem = (index) => {
        const newItems = items.filter((_, i) => i !== index);
        setItems(newItems);
    };

    return (
        <div>
            <TextField
                label={label}
                size="small"
                disabled={disabled}
                value={newItem}
                onChange={(e) => setNewItem(e.target.value)}
                onKeyPress={(e) => e.key === 'Enter' && handleAddItem()}
                {...props}
            />
            <Button onClick={handleAddItem} {...props}>Add</Button>

            <List>
                {items.map((item, index) => (
                    <ListItem key={index}>
                        <ListItemText primary={item} color={"red"} />
                        <ListItemSecondaryAction>
                            <IconButton edge="end" aria-label="delete" disabled={disabled} onClick={() => handleDeleteItem(index)}>
                                <DeleteIcon />
                            </IconButton>
                        </ListItemSecondaryAction>
                    </ListItem>
                ))}
            </List>
        </div>
    );
}

export default ItemListInput;

