import React, { useEffect, useState } from "react";
import { DataGridPro } from "@mui/x-data-grid-pro";
import { GridToolbar } from "@mui/x-data-grid-pro";
import {getFeeSchedule} from "../services/feeschedules";
import {formatFee} from "../services/util";
import { Button,Typography } from "@mui/material";

export default function FeesTable({feeScheduleID, reset}) {
        const [feeSchedule, setFeeSchedule] = useState();
        const [fees, setFees] = useState([]);

        const columns = [
        {
            field: "Test Code",
            headerName: "Test Code",
            width: 200,
            valueGetter: (row) => row.row.Test.Code,
        },
        {
            field: "Test Name",
            headerName: "Test Name",
            width: 200,
            valueGetter: (row) => row.row.Test.Name,
        },
        {
            field: "CPT Code",
            headerName: "CPT Code",
            width: 200,
            valueGetter: (row) => row.row.Test.CPTCode,
        },
        {
            field: "Fee",
            headerName: "Fee",
            width: 200,
            valueGetter: (row) => formatFee.format(row.row.Fee / 100),
        },

        ]

    useEffect(() => {
        getFeeSchedule(feeScheduleID).then((t) => {
                setFeeSchedule(t);
                setFees(t.Fees);
        });
    }, [feeScheduleID]);

        console.log(fees);
        console.log(feeSchedule);

    if (feeSchedule === undefined) {
            return <></>
    }

    return (
            <>
        <Typography variant="h4">{feeSchedule.Name}</Typography>
        <DataGridPro
            getRowId={(row) => row.ID}
            density="compact"
            sx={{
                "& .MuiDataGrid-columnHeaderCheckbox .MuiDataGrid-columnHeaderTitleContainer":
                    {
                        display: "none",
                    },
            }}
            components={{ Toolbar: GridToolbar }}
            componentsProps={{
                toolbar: {
                    showQuickFilter: true,
                    quickFilterProps: { debounceMs: 500 },
                },
            }}
            keepNonExistentRowsSelected
            autoHeight={true}
            rows={fees}
            columns={columns}
        />
                    <Button
                        variant="contained"
                        color="primary"
                            sx={{ m:2 }}
                        onClick={() => reset()}
                    >
                        Back
                    </Button>
            </>
    );
}
