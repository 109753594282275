import React from "react";
import {
    TableContainer,
    Table,
    TableHead,
    TableRow,
    TableBody,
    TableCell,
    IconButton,
    Stack,
} from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ErrorIcon from "@mui/icons-material/Error";
import { OrderVerificationResult } from "../../constants/types";
import { VerifierIndex } from "../../constants/constants";
import { apiFetch } from "../../services/fetch";
import ReplayIcon from '@mui/icons-material/Replay';
import ErrorAlert from "../ErrorAlert";
import { FormatDateTime } from "../../services/util";


type OrderVerificationProps = {
    verificationResults: OrderVerificationResult[];
    setVerificationResults: (verificationResults: OrderVerificationResult[]) => void;
}

const getCheckbox = (b: boolean) => {
    if (b) {
        return <CheckCircleIcon color="success" />;
    }
    return <ErrorIcon color="error" />;
};

function OrderVerification({ verificationResults, setVerificationResults }: OrderVerificationProps) {
    const [loading, setLoading] = React.useState(false);
    const [error, setError] = React.useState<null | string>(null);

    const rerunVerification = async (orderId: number, verifier: number) => {
        setLoading(true);
        try {
            const response = await apiFetch(`/orders/${orderId}/verification/${verifier}`, 'POST', null)
            const newResults = verificationResults.map((v) => {
                if (v.Verifier === verifier && response.length === 1) {
                    return response[0]
                }
                return v
            })
            console.log(newResults)
            setVerificationResults([...newResults])
        } catch (e) {
            setError("Failed to rerun verification")
        } finally {
            setLoading(false)
        }
    }

    return (
        <TableContainer>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>Rerun</TableCell>
                        <TableCell>Verification Step</TableCell>
                        <TableCell>Last Checked</TableCell>
                        <TableCell>Error</TableCell>
                        <TableCell>Resolved</TableCell>
                        <TableCell>Resolved By</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {verificationResults.map((v) => (
                        <TableRow>
                            <TableCell><IconButton
                                disabled={loading}
                                onClick={() => rerunVerification(v.OrderID, v.Verifier)}
                            ><ReplayIcon />
                            </IconButton></TableCell>
                            <TableCell><Stack direction="row" spacing={2}>
                                <div>
                                    {getCheckbox(v.Verified)}
                                </div>
                                <div>
                                    {VerifierIndex[Number(v.Verifier)]}
                                </div>
                            </Stack></TableCell>
                            <TableCell>{FormatDateTime(v.UpdatedAt)}</TableCell>
                            <TableCell>{v.Error}</TableCell>
                            <TableCell>{v.Resolved}</TableCell>
                            <TableCell>{v.Resolved && v.ResolvedByUser?.Email}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
            <ErrorAlert error={error} />
        </TableContainer>
    )

}

export default OrderVerification;
