import FeeScheduleTable from "../tables/FeeScheduleTable";
import dayjs from "dayjs";
import { useState, useEffect } from "react";
import FeesTable from "../tables/FeesTable";
import CreateEditButtons from "./CreateEditButtons";
import { apiFetch, useFetch } from "../services/fetch";
import FeeScheduleForm from "./FeeScheduleForm";
import TableFrame from "./TableFrame";
import { DataTableContainer } from "./TableContainer";

export default function FeeSchedulePanel() {
    const [state, setState] = useState({
        view: "table",
        feeSchedule: null,
        feeScheduleDetails: {
            Name: "",
            FinancialClass: 0,
            DateEffective: dayjs("2023-01-01T00:00:00-05:00"),
            DateDiscontinue: null,
            Multiplier: 1.0,
            Fees: [],
        },
    });
    const [shouldRefetch, setShouldRefetch] = useState(false)

    const { data: feeSchedules = [], refetch } = useFetch("/feeschedules");

    const { data: tests = [] } = useFetch("/tests");

    useEffect(() => {
        if (shouldRefetch) {
            refetch();
        }
        setShouldRefetch(false)

    }, [state.view, refetch, shouldRefetch]);

    useEffect(() => {
        if (state.feeSchedule === null) {
            return;
        }

        let feeScheduleDetails;

        const getFeeSchedule = async () => {
            feeScheduleDetails = await apiFetch(
                "/feeschedules/" + state.feeSchedule[0]
            );
            setState((prevState) => ({
                ...prevState,
                feeScheduleDetails: feeScheduleDetails,
            }));
        };

        getFeeSchedule();
    }, [state.feeSchedule]);

    const editProps = { disabled: state.feeSchedule === null };

    const content = {
        table: (
            <>
                <TableFrame>
                    <DataTableContainer>
                        <FeeScheduleTable
                            feeSchedules={feeSchedules}
                            selected={state.feeSchedule}
                            setSelected={(v) =>
                                setState((prevState) => ({
                                    ...prevState,
                                    feeSchedule: v,
                                }))
                            }
                            viewFeeSchedule={(params) => {
                                setState((prevState) => ({
                                    ...prevState,
                                    feeSchedule: params.ID,
                                    view: "details",
                                }));
                            }}
                        />
                    </DataTableContainer>
                    <CreateEditButtons
                        editProps={editProps}
                        onEdit={() =>
                            setState((prevState) => ({
                                ...prevState,
                                view: "form",
                            }))
                        }
                        onCreate={() =>
                            setState((prevState) => ({
                                ...prevState,
                                view: "form",
                                feeSchedule: null,
                            }))
                        }
                    />
                </TableFrame>
            </>
        ),
        details: (
            <FeesTable
                feeScheduleID={state.feeSchedule}
                reset={() => {
                    setShouldRefetch(true)
                    setState((prevState) => ({ ...prevState, view: "table" }))
                }
                }
            />
        ),
        form: (
            // You can add your form component here
            <FeeScheduleForm
                tests={tests}
                initValues={state.feeScheduleDetails}
                id={state.feeSchedule}
                reset={() => {
                    setShouldRefetch(true)
                    setState((prevState) => ({ ...prevState, view: "table" }))
                }
                }
            />
        ),
    };

    return content[state.view] || null;
}
