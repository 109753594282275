import Title from "./Title";
import React, { useEffect, useState } from "react";
import { DataGridPro } from "@mui/x-data-grid-pro";
import { GridToolbar } from "@mui/x-data-grid-pro";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import {placeOfService} from "../services/util";

export default function FacilitiesTable({
    getFacilities,
    title,
    checkboxes,
    selectedFacilities,
    setSelectedFacilities,
    refresh,
}) {
    const [facilities, setFacilities] = useState([]);

    useEffect(() => {
        getFacilities().then((p) => {
            setFacilities(p);
        });
    }, [refresh]);

    const getCheckbox = (value) => {
        if (value.value) {
            return <CheckCircleIcon color="success" />;
        }
        return <CheckCircleIcon color="disabled" />;
    };

    const countProviders = (value) => {
        return value.value.length;
    };

    const getPOSValue = (params) => {
        const posCode = params.row.POS;

        for (let pos of placeOfService) {
            if (pos.Code === params.row.POS) {
                return pos.Name
            }
        }

        return posCode
    }

    const columns = [
        { field: "ID", headerName: "ID", width: 45 },
        { field: "Name", headerName: "Name", width: 225 },
        {
            field: "Providers",
            headerName: "Providers",
            width: 90,
            valueGetter: countProviders,
        },
        {
            field: "CanSelfPay",
            headerName: "Self Pay",
            width: 90,
            renderCell: getCheckbox,
        },
        {
            field: "CanClientBill",
            headerName: "Client Bill",
            width: 90,
            renderCell: getCheckbox,
        },
        {
            field: "CanInsurance",
            headerName: "Insurance",
            width: 90,
            renderCell: getCheckbox,
        },
        {
            field: "CanNoBill",
            headerName: "No Bill",
            width: 90,
            renderCell: getCheckbox,
        },
        { field: "POS", headerName: "POS", width: 250, valueGetter: getPOSValue },
    ];

    return (
        <React.Fragment>
            <Title>{title}</Title>
            <DataGridPro
                hideFooterRowCount
                density="compact"
                getRowId={(row) => row.ID}
                sx={{
                    "& .MuiDataGrid-columnHeaderCheckbox .MuiDataGrid-columnHeaderTitleContainer":
                        {
                            display: "none",
                        },
                }}
                components={{ Toolbar: GridToolbar }}
                componentsProps={{
                    toolbar: {
                        showQuickFilter: true,
                        quickFilterProps: { debounceMs: 500 },
                    },
                }}
                rowSelectionModel={selectedFacilities}
                onRowSelectionModelChange={setSelectedFacilities}
                rows={facilities}
                columns={columns}
                checkboxSelection={checkboxes}
            />
        </React.Fragment>
    );
}
