import React, { useState, useEffect, useRef } from "react";
import * as Yup from "yup";
import dayjs from "dayjs";
import { useFormik } from "formik";
import {
    Button,
    Grid,
    Paper,
    Avatar,
    Typography,
    InputLabel,
    Alert,
} from "@mui/material";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Logo from "../images/logo.png";
import { Box, Stack } from "@mui/system";
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import mdTheme from "../components/Theme";
import { useMediaQuery } from "@mui/material";
import { apiFetch } from "../services/fetch";
import PatientFields from "../components/PatientFields";
import ErrorAlert from "../components/ErrorAlert";
import PatientOrderAOEs from "../components/PatientOrderAOEs";
import ProgressMobileStepper from "../components/MobileProgressStepper";
import HorizontalLinearStepper from "../components/HorizontalLinearStepper";
import PatientOrderDiagnosisQuestions from "../components/PatientOrderDiagnosisQuestions";
import InsuranceInput from "../components/InsuranceInput";
import ImageCapture from "../components/ImageCapture";
import ModalImage from "react-modal-image";
import { FetchLoader } from "../components/FetchLoader";
import SignatureCanvas from 'react-signature-canvas';

export default function PatientOrder() {
    const [error, setError] = useState(null);
    const queryParameters = new URLSearchParams(window.location.search);
    const [disabled, setDisabled] = useState(false);
    const [submitting, setSubmitting] = useState(false);
    const [loading, setLoading] = useState(true);
    const id = queryParameters.get("id");
    const [insurances, setInsurances] = useState([]);
    const [patientOrderForm, setPatientOrderForm] = useState(null);
    const aoeFormikRef = useRef(null)
    const diagnosisFormikRef = useRef(null)
    const aoeAnswers = useRef({})
    const diagnosisAnswers = useRef({})
    const [step, setStep] = useState(0)
    const submitFuncs = useRef({
        "aoe": async () => { },
        "diagnosis": async () => { },
    });
    const [photoImg, setPhotoImg] = useState(null)
    const [frontInsuranceImg, setFrontInsuranceImg] = useState(null)
    const [backInsuranceImg, setBackInsuranceImg] = useState(null)
    const [today] = useState(dayjs().format('YYYY-MM-DD'));
    const [newOrderId, setNewOrderId] = useState(null)
    const [disableStepper, setDisableStepper] = useState(false);
    const [qualificationError, setQualificationError] = useState(null);
    const [imageErrors, setImageErrors] = useState({
        photoID: null,
        frontInsuranceImg: null,
        backInsuranceImg: null,
    })
    const signatureRef = useRef(null)
    const [sig, setSig] = useState(null)

    console.log(today)

    const isMobile = useMediaQuery(mdTheme.breakpoints.down("sm"));

    useEffect(() => {
        if (id === "" || id === null || id === undefined) {
            setError("Link has expired.");
            setDisabled(true);
            return
        }

        const getPatientOrder = async () => {
            try {
                const response = await apiFetch(`/user/patientOrder/${id}`, 'GET', null, true);
                setPatientOrderForm(response)
            }
            catch (e) {
                setError("Link has expired.");
                setDisabled(true);
            } finally {
                setLoading(false);
            };
        }

        getPatientOrder()
    }, [id]);

    useEffect(() => {
        if (!patientOrderForm) {
            return
        }

        const getInsurances = async () => {
            try {
                const response = await apiFetch(`/user/insurances`, 'GET', null, true);
                setInsurances(response)
            }
            catch (e) {
                setError("Failed to load insurances.");
                setDisabled(true);
            } finally {
                setLoading(false);
            };
        }

        getInsurances()


    }, [patientOrderForm])

    useEffect(() => {
        window.scrollTo(0, 0)

        if (step === 1) {
            for (let i = 0; i < submitFuncs.current.length; i++) {
                submitFuncs.current[i]()
            }
        }

    }, [step])

    const hasTrue = () => {
        return Object.values(diagnosisFormikRef.current.values).some((val) => val === true)
    };

    const insuranceDefaultValues = {
        Insurance: {},
        InsuranceID: null,
        DateEffective: today,
        Relationship: "Self",
        DateDiscontinue: null,
        MemberNumber: "",
        GroupNumber: "",
        Active: true,
        Primary: true,
        Secondary: false,
        Subscriber: {
            FirstName: "",
            LastName: "",
            DOB: null,
        },
    };

    const initValues = {
        FirstName: "",
        LastName: "",
        DOB: null,
        Sex: "",
        Address: {
            Address1: "",
            Address2: "",
            City: "",
            State: "",
            ZipCode: "",
        },
        Medications: [],
        SSN: "",
        PatientInsurances: [insuranceDefaultValues],
        PaymentMethod: "2",
        Ethnicity: "",
        TextResults: true,
        FaxResults: false,
        EmailResults: false,
        Phone: "",
        Fax: "",
        Email: "",
    };

    const handleSubmit = (values) => {
        let patientJSON = validationSchema.cast(values)

        const aoe = Object.entries(aoeAnswers.current).map(([qId, ans]) => ({ QuestionID: Number(qId), Answer: ans }))
        const diag = Object.entries(diagnosisAnswers.current).map(([qId, ans]) => ({ QuestionID: Number(qId), Answer: ans }))
        let data = {
            Patient: patientJSON,
            AOEAnswers: aoe,
            DiagnosisAnswers: diag,
            PatientSignatureIMG: sig,
            PhotoIDIMG: photoImg,
            InsuranceCardFrontIMG: frontInsuranceImg,
            InsuranceCardBackIMG: backInsuranceImg,
        }

        const submit = async () => {
            try {
                setDisableStepper(true)
                setSubmitting(true)
                const response = await apiFetch(`/user/patientOrder/${id}`, "POST", data, true)
                setNewOrderId(response.OrderID)
            } catch (e) {
                console.log(e)
                setError(e.message)
            } finally {
                setSubmitting(false)
            }
        }

        submit()
    };

    const desktopPaper = {
        padding: 20,
        width: "80%",
        maxWidth: "1050px",
        margin: "20px auto",
    };

    const mobilePaper = {
        width: "100%",
        height: "99%",
        padding: 8,
    };
    const paperStyle = isMobile ? mobilePaper : desktopPaper;

    const avatarStyle = { backgroundColor: "#1bbd7e" };

    const validationSchema = Yup.object().shape({
        FirstName: Yup.string().required("First Name is required"),
        LastName: Yup.string().required("Last Name is required"),
        Ethnicity: Yup.string()
            .oneOf(["Hispanic or Latino", "Not Hispanic or Latino", "Unknown"], "Invalid ethnicity")
            .required("Ethnicity is required"),
        DOB: Yup.date().required("Date of birth is required").max(new Date(), 'DOB must be a previous date'),
        Fax: Yup.string().matches(
            /^(\+\d{1,2}\s?)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/,
            "Fax number is not valid"
        ),
        Phone: Yup.string().matches(
            /^(\+\d{1,2}\s?)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/,
            "Phone number is not valid"
        ).required(),
        Email: Yup.string().email("Invalid email"),
        TextResults: Yup.bool().oneOf([true], "Text must be checked").required(),
        EmailResults: Yup.bool(),
        FaxResults: Yup.bool(),
        Sex: Yup.string()
            .oneOf(["Male", "Female"], "Invalid sex")
            .required("Sex is required"),
        Address: Yup.object({
            Address1: Yup.string().required("Address 1 is required"),
            Address2: Yup.string(),
            City: Yup.string().required("City is required"),
            State: Yup.string().required("State is required"),
            ZipCode: Yup.string().required("ZipCode is required"),
        }),
        Medications: Yup.array()
            .of(
                Yup.object().shape({
                    RxNormID: Yup.number().required(),
                    Name: Yup.string().required(),
                    DrugClasses: Yup.array()
                        .of(
                            Yup.object().shape({
                                Name: Yup.string(),
                                RxNormID: Yup.string(),
                            })
                        )
                        .required(),
                })
            )
            .notRequired(),
        SSN: Yup.string().matches(
            /^(?!666|000|9\d{2})\d{3}-(?!00)\d{2}-(?!0{4})\d{4}$/,
            "SSN is not valid"
        ),
        PatientInsurances: Yup.array().of(
            Yup.object({
                InsuranceID: Yup.number().required("Insurance is required"),
                MemberNumber: Yup.string().required(
                    "Member number is required"
                ),
                GroupNumber: Yup.string(),
                DateEffective: Yup.date().required(
                    "Date effective is required"
                ),
                Relationship: Yup.string()
                    .oneOf(["Self"])
                    .required("Relationship is required"),
                DateDiscontinue: Yup.date().nullable(),
                Primary: Yup.boolean().required("Type is required"),
                Subscriber: Yup.object({
                    FirstName: Yup.string().required("Subscriber First Name is required"),
                    LastName: Yup.string().required("Subscriber Last Name is required"),
                    DOB: Yup.date().required("Subscriber DOB is required"),
                }).required(),
            })
        ),
    })
    const handleRegisterSubmit = (key, func) => {
        submitFuncs.current[key] = func
    }


    const formik = useFormik({
        initialValues: initValues,
        validationSchema: validationSchema,
        onSubmit: handleSubmit,
        enableReinitialize: true,
    });

    const verifyImages = () => {
        var hasError = false;
        var errors = {}
        if (!photoImg) {
            errors = { ...errors, photoID: "Photo ID is required." }
            hasError = true
        }
        if (!frontInsuranceImg) {
            errors = { ...errors, frontInsuranceImg: "Front of insurance card is required." }
            hasError = true
        }
        if (!backInsuranceImg) {
            errors = { ...errors, backInsuranceImg: "Back of insurance card is required." }
            hasError = true
        }

        setImageErrors({ ...errors })
        return hasError
    }

    console.log(step);

    const stepValidator = async (prevStep, newStep) => {
        if (prevStep === 0 && newStep === 1) {
            aoeAnswers.current = aoeFormikRef.current.values
            diagnosisAnswers.current = diagnosisFormikRef.current.values

            if (!hasTrue()) {
                setQualificationError("Sorry, based on your responses to the questionnaire, this test is not medically necessary.")
                return false
            }
            setQualificationError("")
            return true
        }

        if (prevStep === 1 && newStep === 2) {
            if (verifyImages()) {
                return false
            }
            // validate form
            await formik.submitForm()
            // Check if there are no errors
            if (Object.keys(formik.errors).length === 0 && formik.isValid) {
                return true;
            } else {
                window.scrollTo(0, 0)
                return false;
            }
        }

        return true
    }

    const handleStepReset = () => {
        formik.resetForm()
        aoeAnswers.current = {}
        diagnosisAnswers.current = {}
        setPhotoImg(null)
        setFrontInsuranceImg(null)
        setBackInsuranceImg(null)
        setStep(0)
    }

    const renderStepZero = () => {
        return (
            <Box>
                <PatientOrderAOEs
                    innerRef={aoeFormikRef}
                    aoes={patientOrderForm?.AOEs}
                    registerSubmit={(func) => handleRegisterSubmit("aoe", func)} />
                <PatientOrderDiagnosisQuestions
                    innerRef={diagnosisFormikRef}
                    questions={patientOrderForm?.DiagnosisQuestions}
                    registerSubmit={(func) => handleRegisterSubmit("diagnosis", func)} />
            </Box>

        )
    }

    const renderStepOne = () => {
        return (
            <Box>
                <form onSubmit={formik.handleSubmit}>
                    <Stack direction="column" spacing={2}>
                        <Typography variant="body2">
                            To request this testing, kindly provide the requested details below. Once the provided information is verified and approved, you will be notified.
                        </Typography>
                        <PatientFields formik={formik} requireContact={true} />
                        <InsuranceInput formik={formik} insurances={insurances} hideDiscontinue hideRemove />
                        <InputLabel>Capture Photo ID</InputLabel>
                        <ErrorAlert error={imageErrors?.photoID} />
                        <Box alignItems={"center"} display="flex" flexDirection={"column"}>
                            {!photoImg ?
                                <ImageCapture image64={photoImg} setImage64={(img) => { setPhotoImg(img); setImageErrors({ ...imageErrors, photoID: null }) }} disableUpload />
                                :
                                <>
                                    <ModalImage
                                        hideZoom
                                        small={photoImg}
                                        medium={photoImg} />
                                    <Button onClick={() => { setPhotoImg(null) }} variant="outlined" color="error">Delete</Button>
                                </>
                            }
                        </Box>
                        <InputLabel>Capture Insurance Card</InputLabel>
                        <InputLabel>Front of Card</InputLabel>
                        <ErrorAlert error={imageErrors?.frontInsuranceImg} />
                        <Box alignItems={"center"} display="flex" flexDirection={"column"}>
                            {!frontInsuranceImg ?
                                <ImageCapture image64={frontInsuranceImg} setImage64={(img) => { setFrontInsuranceImg(img); setImageErrors({ ...imageErrors, frontInsuranceImg: null }) }} disableUpload />
                                :
                                <>
                                    <ModalImage
                                        hideZoom
                                        small={frontInsuranceImg}
                                        medium={frontInsuranceImg} />
                                    <Button onClick={() => { setFrontInsuranceImg(null) }} variant="outlined" color="error">Delete</Button>
                                </>
                            }
                        </Box>
                        <InputLabel>Back of Card</InputLabel>
                        <ErrorAlert error={imageErrors?.backInsuranceImg} />
                        <Box alignItems={"center"} display="flex" flexDirection={"column"}>
                            {!backInsuranceImg ?
                                <ImageCapture image64={backInsuranceImg} setImage64={(img) => { setBackInsuranceImg(img); setImageErrors({ ...imageErrors, backInsuranceImg: null }) }} disableUpload />
                                :
                                <>
                                    <ModalImage
                                        hideZoom
                                        small={backInsuranceImg}
                                        medium={backInsuranceImg} />
                                    <Button onClick={() => { setBackInsuranceImg(null) }} variant="outlined" color="error">Delete</Button>
                                </>
                            }
                        </Box>
                        <InputLabel>Consent</InputLabel>
                        <Typography variant="body2">{patientOrderForm?.Disclaimer}</Typography>
                        <Box alignItems="center" display="flex" flexDirection="column">
                            <Typography variant="caption">Please sign in the grey box below</Typography>
                            <Box height={200} width={500} >
                                <SignatureCanvas
                                    ref={(r) => signatureRef.current = r}
                                    backgroundColor={'rgb(176, 176, 176)'}
                                    canvasProps={{ width: 500, height: 200, className: 'sigCanvas' }}
                                    onEnd={() => {
                                        if (signatureRef.current) {
                                            setSig(signatureRef.current.toDataURL('image/png'))
                                        }
                                    }}
                                />
                            </Box>
                            <Button onClick={() => { signatureRef.current?.clear() }}>Clear</Button>
                        </Box>
                    </Stack>
                </form>
            </Box>
        )
    }

    const renderStepTwo = () => {
        return (
            <Box>
                <Stack direction="column" alignItems={"center"} spacing={2}>
                    <Typography variant="h6">Thank you for your order. Your confirmation number is:</Typography>
                    <Typography variant="h4">#{newOrderId}</Typography>
                    <Typography variant="body1">We'll keep you updated. Expect notifications upon order approval and again when your results are ready.</Typography>
                    <Typography variant="body1">You'll be notified at <b>{formik?.values?.Phone}</b>
                        {formik?.values?.Email && formik.values.EmailResults && (
                            <>
                                {" and "}
                                <b>{formik?.values?.Email}</b>
                            </>
                        )}. If this is incorrect, please inform our staff for correction.</Typography>
                </Stack>

            </Box>
        )
    }

    const renderStepper = () => {
        return (
            isMobile ?
                <ProgressMobileStepper
                    step={step}
                    setStep={setStep}
                    steps={3}
                    stepValidator={stepValidator}
                    disabled={disableStepper}
                />
                :
                <HorizontalLinearStepper
                    step={step}
                    setStep={setStep}
                    handleReset={handleStepReset}
                    steps={['Questionnaire', 'Patient Details']}
                    stepValidator={stepValidator}
                />
        )
    }

    const title = ["Verify Qualification", "Enter Your Details", "Order Submission"]
    return (
        <ThemeProvider theme={mdTheme}>
            <CssBaseline />
            <Grid >
                <Paper elevation={10} style={paperStyle}>
                    <Stack direction="column" spacing={2}>
                        <>
                            <Grid align="center">
                                <img width={"40%"} src={Logo} alt="logo" />
                                <Avatar style={avatarStyle}>
                                    <LockOutlinedIcon />
                                </Avatar>
                                <h2>{title[step]}</h2>
                            </Grid>
                            <ErrorAlert error={error} />
                            <FetchLoader isLoading={loading || submitting} label={loading ? "Loading..." : "Submitting Order"} />
                            {!error && !loading && !submitting && step === 0 && renderStepZero()}
                            {!error && !loading && !submitting && step === 1 && renderStepOne()}
                            {!error && !loading && !submitting && step === 2 && renderStepTwo()}
                            {qualificationError && <Alert sx={{ m: 2 }} severity="warning">{qualificationError}</Alert>}
                        </>
                        {renderStepper()}
                    </Stack>
                </Paper>
            </Grid >
        </ThemeProvider >
    );
}
