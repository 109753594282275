export async function getPendingCriticals() {
        return fetch(`${process.env.REACT_APP_API_PROXY}/v1/criticals/search?status=pending`, {credentials: "include"}).then((response) => response.json());
}
export async function getCompletedCriticals() {
        return fetch(`${process.env.REACT_APP_API_PROXY}/v1/criticals/search?status=completed`, {credentials: "include"}).then((response) => response.json());
}

export async function updateCritical(criticalID, critical) {
    return fetch(`${process.env.REACT_APP_API_PROXY}/v1/criticals/` + criticalID, {
        method: "POST",
            credentials: "include",
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json"
        },
        body: JSON.stringify(critical)
    })
}

