import React from "react";
import {
    Button,
    TableContainer,
    Table,
    TableHead,
    TableRow,
    TableBody,
    TableCell,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useState, useContext } from "react";
import { SessionContext } from "../../hooks/getContext";
import { apiFetch } from "../../services/fetch";
import ConfirmDialog from "../ConfirmDialog";
import ErrorAlert from "../ErrorAlert";

function Tests({ orderDetails, callback }) {
    const [confirmDialog, setConfirmDialog] = useState(false);
    const [testId, setTestId] = useState(null);
    const [testName, setTestName] = useState(null);
    const [error, setError] = useState(null);

    const handleTestDelete = (testId, testName) => {
        console.log(testId)
        setTestId(testId)
        setTestName(testName)
        setConfirmDialog(true)
    }

    const delTest = () => {
        const del = async () => {
            try {
                await apiFetch(`/orders/${orderDetails.ID}/tests/${testId}`, "DELETE")
                callback()
            } catch (e) {
                setError("Failed to delete test.")
            }
        }

        del()
    }

    const session = useContext(SessionContext);
    if (orderDetails.Tests === undefined || orderDetails.Tests === null) {
        return <></>;
    }
    return (
        <>
            <ConfirmDialog
                title={"Are you sure?"}
                message={`You are about to delete test '${testName}'.`}
                open={confirmDialog}
                cancel={() => setConfirmDialog(false)}
                confirm={() => { delTest(); setConfirmDialog(false) }}
            />
            <TableContainer style={{ maxHeight: 300, width: "100%" }}>
                <Table size="small" stickyHeader>
                    <TableHead>
                        <TableRow>
                            {session?.user?.Type === "Lab" &&
                                <TableCell size="small" sx={{ padding: "0" }}>
                                </TableCell>}
                            <TableCell>Code</TableCell>
                            <TableCell>Test</TableCell>
                            <TableCell>CPT</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {orderDetails.Tests.map((row) => (
                            <TableRow key={row.Code}>
                                {session?.user?.Type === "Lab" &&
                                    <TableCell size="small" sx={{ padding: "0" }}>
                                        <Button onClick={() => handleTestDelete(row.ID, row.Name)}
                                            size="small"
                                            sx={{ minWidth: "12px" }}>
                                            <CloseIcon color="error" />
                                        </Button>
                                    </TableCell>}
                                <TableCell>{row.Code}</TableCell>
                                <TableCell>{row.Name}</TableCell>
                                <TableCell>{row.CPTCode}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <ErrorAlert error={error} />
        </>
    );
};


export default Tests;
