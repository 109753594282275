import { DataGridPro, GridToolbar } from "@mui/x-data-grid-pro";
import { getInterfaceResults } from "../services/samples";
import { useEffect, useState } from "react";
import { FormatDateTime } from "../services/util";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

export default function InstrumentInterfacesTable() {
    const [interfaceResults, setInterfaceResults] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        setLoading(true);
        getInterfaceResults()
            .then((p) => {
                if (!p.ok) {
                    throw new Error("Failed to load interface results");
                }
                return p.json();
            })
            .then((p) => {
                setLoading(false);
                setInterfaceResults(p.InterfaceResults);
            })
            .catch(e => setError(e.message));
    }, []);

    const getCheckbox = (params) => {
        if (params.row?.Processed) {
            return <CheckCircleIcon color="success" />;
        }
        return <CheckCircleIcon color="disabled" />;
    };

    const columns = [
        { field: "ID", headerName: "ID", width: 85 },
        { field: "CreatedAt", headerName: "Time", width: 185, valueGetter: row => FormatDateTime(row.row?.CreatedAt) },
        { field: "Instrument", headerName: "Instrument", width: 125, valueGetter: row => row.row?.Instrument?.Name },
        { field: "SampleBarcode", headerName: "Barcode", width: 125 },
        {
            field: "TestCode",
            headerName: "Instrument Test Code",
            width: 185,
        },
        { field: "Value", headerName: "Result Value", width: 115 },
        { field: "ResultID", headerName: "ResultID", width: 105 },
        { field: "Processed", headerName: "Processed", width: 100, renderCell: getCheckbox },
        { field: "Error", headerName: "Error", width: 700 },
    ];
    return (
        <>
            <DataGridPro
                getRowId={(row) => row.ID}
                columns={columns}
                loading={loading}
                rows={interfaceResults}
                error={error}
                initialState={{
                    sorting: {
                        sortModel: [{ field: "ID", sort: "desc" }],
                    },
                }}
                density="compact"
                sx={{
                    "& .MuiDataGrid-columnHeaderCheckbox .MuiDataGrid-columnHeaderTitleContainer":
                    {
                        display: "none",
                    },
                }}
                components={{ Toolbar: GridToolbar }}
                componentsProps={{
                    toolbar: {
                        showQuickFilter: true,
                        quickFilterProps: { debounceMs: 500 },
                    },
                }}
                pageSize={100}
            />
        </>
    );
}
