import React, { useEffect, useState, useContext } from "react";
import Box from "@mui/material/Box";
import {
    Paper,
    Typography,
    InputLabel,
    Stack,
    RadioGroup,
    Radio,
    FormControlLabel,
} from "@mui/material";
import { SessionContext } from "../hooks/getContext";
import {
    determineNeededSpecimenTypes,
    getTestsFromReflexes,
} from "../services/specimentypes";
import { ShipmentOptions } from "./ShipmentOptions";
import {
    CollectNowForm,
    ScheduleCollectionForm,
} from "./ScheduleCollectionForm";
import { apiFetch } from "../services/fetch";

function CustPaper({ children }) {
    return (
        <Paper
            elevation={10}
            sx={{
                p: 2,
                m: 2,
                display: "block",
                flexDirection: "column",
                maxWidth: "100%",
            }}
        >
            {children}
        </Paper>
    );
}

export default function OrderSamples({
    facility,
    collector,
    setCollector,
    samples,
    setSamples,
    setSampleDetails,
    sampleDetails,
    fasting,
    stat,
    setStat,
    setFasting,
    reflexDetails,
    dateCollected,
    setDateCollected,
    sampleCollection,
    setSampleCollection,
    requestedCollectionDate,
    setRequestedCollectionDate,
    disableScheduleCollection,
    disableCollectNow,
    tests,
    shipmentOption,
    setShipmentOption,
    shipmentForm,
    reset,
}) {
    let tomorrow = new Date();
    tomorrow.setDate(tomorrow.getDate() + 1);
    const [neededSpecimenTypes, setNeededSpecimenTypes] = useState([]);
    const [error, setError] = useState(null);
    const session = useContext(SessionContext);
    const [allTests, setAllTests] = useState([]);
    const [specimens, setSpecimens] = useState([]);
    const [showAllSpecimens, setShowAllSpecimens] = useState(false);

    useEffect(() => {
        console.log("use4");
        if (sampleCollection === "scheduleCollection") {
            setShipmentOption("noShipment");
        }
    }, [sampleCollection]);

    useEffect(() => {
        console.log("use3");
        setSpecimens([]);
        setAllTests([]);
        setNeededSpecimenTypes([]);
    }, [reset]);

    useEffect(() => {
        if (showAllSpecimens) {
            const getSpecimens = async () => {
                try {
                    let specs = await apiFetch('/specimentypes', 'GET')
                    specs = specs.map((s) => {
                        s.id = s.ID
                        return s
                    })
                    setNeededSpecimenTypes(specs)
                } catch (e) {
                    console.log(e)
                }
            }

            getSpecimens()
        }

        if (!showAllSpecimens) {

            console.log("use1");
            const parseTestsForSpecimenType = async () => {
                let reflexTests = [];
                if (reflexDetails !== undefined && reflexDetails.length !== 0) {
                    reflexTests = await getTestsFromReflexes(reflexDetails);
                }

                let _allTests = [...reflexTests, ...tests];

                setAllTests(_allTests);

                determineNeededSpecimenTypes(_allTests).then((data) => {
                    const specimenTypes = data;
                    setNeededSpecimenTypes(specimenTypes);
                });
            };

            parseTestsForSpecimenType();
        }
    }, [showAllSpecimens]);

    console.log("rerender");

    return (
        <Box key="sampleForm" sx={{ minWidth: "100%" }}>
            <CustPaper>
                <Stack direction="column" spacing={2}>
                    <InputLabel>Sample Collection Options</InputLabel>
                    <RadioGroup
                        aria-label="sampleCollection"
                        name="sampleCollection"
                        defaultValue={
                            disableScheduleCollection
                                ? "collectNow"
                                : "scheduleCollection"
                        }
                        value={sampleCollection}
                        onChange={(e) => setSampleCollection(e.target.value)}
                    >
                        {disableScheduleCollection ? null : (
                            <Box mb={2}>
                                <FormControlLabel
                                    value="scheduleCollection"
                                    control={<Radio />}
                                    label={
                                        <div>
                                            <Typography variant="body">
                                                Schedule Sample Collection
                                            </Typography>
                                            <br />
                                            <Typography variant="caption">
                                                Select this option to arrange a
                                                future date and time for the
                                                laboratory to collect the
                                                sample.
                                            </Typography>
                                        </div>
                                    }
                                />
                            </Box>
                        )}
                        {disableCollectNow ? null : (
                            <Box mb={2}>
                                <FormControlLabel
                                    value="collectNow"
                                    control={<Radio />}
                                    label={
                                        <div>
                                            <Typography variant="body">
                                                Collect Sample Now
                                            </Typography>
                                            <br />
                                            <Typography variant="caption">
                                                Choose this option if the
                                                sample(s) for the test(s) is
                                                ready for collection.
                                            </Typography>
                                        </div>
                                    }
                                />
                            </Box>
                        )}
                    </RadioGroup>
                </Stack>
            </CustPaper>
            {sampleCollection === "scheduleCollection" ? (
                <CustPaper>
                    <ScheduleCollectionForm
                        requestedCollectionDate={requestedCollectionDate}
                        setRequestedCollectionDate={setRequestedCollectionDate}
                        stat={stat}
                        setStat={setStat}
                        fasting={fasting}
                        setFasting={setFasting}
                    />
                </CustPaper>
            ) : (
                <>
                    <CustPaper>
                        <CollectNowForm
                            facility={facility}
                            collector={collector}
                            setCollector={setCollector}
                            dateCollected={dateCollected}
                            setDateCollected={setDateCollected}
                            showAllSpecimens={showAllSpecimens}
                            setShowAllSpecimens={setShowAllSpecimens}
                            fasting={fasting}
                            setFasting={setFasting}
                            stat={stat}
                            setStat={setStat}
                            neededSpecimenTypes={neededSpecimenTypes}
                            setSpecimens={setSpecimens}
                            samples={samples}
                            sampleDetails={sampleDetails}
                            specimens={specimens}
                            setSampleDetails={setSampleDetails}
                            setSamples={setSamples}
                            allTests={allTests}
                        />
                    </CustPaper>
                    <CustPaper>
                        <InputLabel>Shipment Options</InputLabel>
                        <RadioGroup
                            aria-label="shipmentOption"
                            name="shipmentOption"
                            value={shipmentOption}
                            onChange={(e) => setShipmentOption(e.target.value)}
                        >
                            <FormControlLabel
                                value="noShipment"
                                sx={{ my: 1 }}
                                control={<Radio />}
                                label={
                                    <div>
                                        <Typography variant="body">
                                            Do Not Record Shipment
                                        </Typography>
                                        <br />
                                        <Typography variant="caption">
                                            Select this option if the sample(s)
                                            will not be shipped immediately.
                                        </Typography>
                                    </div>
                                }
                            />
                            <FormControlLabel
                                value="createShipment"
                                sx={{ my: 1 }}
                                control={<Radio />}
                                label={
                                    <div>
                                        <Typography variant="body">
                                            Create Shipment
                                        </Typography>
                                        <br />
                                        <Typography variant="caption">
                                            Choose this option if the sample(s)
                                            is ready for shipment and will be
                                            shipped immediately.
                                        </Typography>
                                    </div>
                                }
                            />
                            <FormControlLabel
                                value="receiveShipment"
                                disabled={session?.user?.Type !== "Lab"}
                                sx={{ my: 1 }}
                                control={<Radio />}
                                label={
                                    <div>
                                        <Typography variant="body">
                                            Create and Receive Shipment
                                        </Typography>
                                        <br />
                                        <Typography variant="caption">
                                            Choose this option if the sample(s)
                                            has been shipped and will be
                                            received immediately.
                                        </Typography>
                                    </div>
                                }
                            />
                        </RadioGroup>
                        {shipmentOption !== "noShipment" &&
                            <Stack direction="column" spacing={2}>
                                {shipmentForm}
                            </Stack>}
                    </CustPaper>
                </>
            )}
        </Box>
    );
}
