import React, { useEffect, useState } from 'react';
import { useFormikContext } from 'formik';
import {
    TextField,
    MenuItem,
    FormControl,
    FormLabel,
    Divider,
    Stack
} from '@mui/material';
import AdapterDayjs from '@mui/lab/AdapterDayjs';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers';
import { CustomTextField } from './CustomTextField';
import { getLaboratories } from "../services/tests";

function MileageFields({ calcMethod }) {
    const { setFieldValue, values } = useFormikContext();
    return (
        <>
            <CustomTextField
                type="number"
                disabled={calcMethod === "total"}
                required={calcMethod === "startend"}
                size="small"
                label="Start Mileage"
                value={values?.start}
                onChange={(e) => setFieldValue('StartMileage', e.target.value)}
            />
            <CustomTextField
                disabled={calcMethod === "total"}
                required={calcMethod === "startend"}
                type="number"
                size="small"
                label="End Mileage"
                value={values?.end}
                onChange={(e) => setFieldValue('EndMileage', e.target.value)}
            />
            <CustomTextField
                type="number"
                disabled={calcMethod === "startend"}
                required={calcMethod === "total"}
                size="small"
                label="Total Mileage"
                value={values?.EndMileage - values?.StartMileage}
            />
        </>
    );
};

function CourierFields() {
    const { setFieldValue, values } = useFormikContext();

    return (
        <>
            <CustomTextField
                size="small"
                label="Courier Name"
                required
                value={values?.Tracking}
                onChange={(e) => setFieldValue('Tracking', e.target.value)}
            />
            {values?.Type === "courier" &&
                <MileageFields
                    calcMethod={"startend"} />}
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DateTimePicker
                    disableFuture
                    label={
                        values?.Courier !== "UPS" && values?.Courier !== "FedEx"
                            ? "Date Picked Up"
                            : "Date Shipped"
                    }
                    value={values?.DateShipped}
                    onChange={(e) => setFieldValue('DateShipped', e.target.value)}
                    renderInput={(params) => (
                        <TextField {...params} required size="small" />
                    )}
                />
            </LocalizationProvider>
        </>
    );
};

function WalkInFields() {
    const { setFieldValue, values } = useFormikContext();
    return (
        <>
            <FormControl>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DateTimePicker
                        disableFuture
                        label={
                            values?.Courier !== "UPS" && values?.Courier !== "FedEx"
                                ? "Date Picked Up"
                                : "Date Shipped"
                        }
                        value={values?.DateShipped}
                        onChange={(e) => setFieldValue('DateShipped', e)}
                        renderInput={(params) => (
                            <TextField {...params} required size="small" />
                        )}
                    />
                </LocalizationProvider>
            </FormControl>
        </>
    );
};

function UPSFields() {
    const { setFieldValue, values } = useFormikContext();
    return (
        <>
            <CustomTextField
                size="small"
                label="Tracking Number"
                required
                hidden
                value={values?.Tracking}
                onChange={(e) => setFieldValue('Tracking', e.target.value)}
            />
            <FormControl>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DateTimePicker
                        disableFuture
                        label={
                            values?.Courier !== "UPS" && values?.Courier !== "FedEx"
                                ? "Date Picked Up"
                                : "Date Shipped"
                        }
                        value={values?.DateShipped}
                        onChange={(e) => setFieldValue('DateShipped', e)}
                        renderInput={(params) => (
                            <TextField {...params} required size="small" />
                        )}
                    />
                </LocalizationProvider>
            </FormControl>
        </>
    );
};

function ShipmentFormFields({ filter }) {
    const [laboratories, setLaboratories] = useState([]);
    const [couriers, setCouriers] = useState([]);
    const { setFieldValue, values, errors, touched } = useFormikContext();

    useEffect(() => {
        let c = [];
        c.push({
            Name: "Walk-In",
            Reference: false,
            Mileage: false,
        });
        c.push({
            Name: "UPS",
            Reference: false,
            Mileage: false,
        });
        c.push({
            Name: "FedEx",
            Reference: false,
            Mileage: false,
        });
        for (let l of laboratories) {
            if (l.Reference === false) {
                l.Mileage = true;
            } else {
                l.Mileage = false;
            }
            c.push(l);
        }
        console.log(c);
        setCouriers(c);
    }, [laboratories]);


    useEffect(() => {
        getLaboratories()
            .then((p) => {
                if (!p.ok) {
                    throw new Error("Failed to load laboratories");
                }
                return p.json();
            })
            .then((p) => setLaboratories(p));
    }, []);
    return (
        <>
            <Divider sx={{ m: 2 }} />
            {console.log(values)}
            {console.log(errors)}

            <Stack direction="column" spacing={2}>
                <FormControl sx={{ width: "100%" }} size="small">
                    <TextField
                        size="small"
                        fullWidth
                        select
                        required
                        label="Laboratory"
                        error={
                            touched.DestinationID &&
                            Boolean(errors.DestinationID)
                        }
                        helperText={
                            touched.DestinationID && errors.DestinationID
                        }
                        value={
                            values?.DestinationID || ""
                        }
                        name="LaboratoryID"
                        key="Laboratory"
                        onChange={(v) =>
                            setFieldValue('DestinationID', v.target.value)
                        }
                    >
                        {laboratories.filter((lab) => {
                            if (filter === "Collected") {
                                return !lab.Reference;
                            } else {
                                return true;
                            }
                        }).map((option) => (
                            <MenuItem key={option.ID} value={option.ID}>
                                {option.Name}
                            </MenuItem>
                        ))}
                    </TextField>
                </FormControl>
                <FormControl>
                    <FormLabel>Transport Details</FormLabel>
                    <Divider sx={{ m: 2 }} />
                    <TextField
                        size="small"
                        select
                        required
                        label="Transport"
                        error={
                            touched.Courier &&
                            Boolean(errors.Courier)
                        }
                        helperText={
                            touched.Courier && errors.Courier
                        }
                        value={
                            values?.Courier || ""
                        }
                        name="Courier"
                        key="Courier"
                        onChange={(v) => {
                            const courier = couriers.find((c) => c.Name === v.target.value)

                            if (courier?.Mileage) {
                                setFieldValue('Type', "courier")
                            } else {
                                setFieldValue('Type', "package")
                            }
                            setFieldValue('Courier', v.target.value)
                        }}
                    >
                        {couriers.map((option, i) => (
                            <MenuItem key={i} value={option.Name}>
                                {option.Name}
                            </MenuItem>
                        ))}
                    </TextField>
                </FormControl>
                {values?.Type === "courier"
                    ? <CourierFields />
                    : values?.Courier === "Walk-In" ? <WalkInFields /> : <UPSFields />
                }
            </Stack>
        </>
    )

}

export default ShipmentFormFields;
