import { useState, useEffect, useCallback } from "react";
import {
    DataGridPro,
    GridEditInputCell,
    useGridApiContext,
} from "@mui/x-data-grid-pro";
import {
    FormLabel,
    FormGroup,
    TextField,
    FormControlLabel,
    FormControl,
    Stack,
    Checkbox,
    Typography,
    MenuItem,
    Chip,
    Divider,
    Box,
} from "@mui/material";
import { getCollectorsByFacility } from "../services/collectors";
import { DateTimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";

function SpecimenRequiredVerification({ sampleDetails, allTests }) {
    // provide feedback to user so they know when enough specimenTypes
    // have been selected
    const coveredTests = allTests.filter((t) => {
        return t.SpecimenTypes.some((s) => {
            return sampleDetails?.some(
                (samp) => samp.Reference === t.Referenced && samp.ID === s.ID
            );
        });
    });
    const awaitingTests = allTests.filter((t) => {
        return !t.SpecimenTypes.some((s) => {
            return sampleDetails?.some(
                (samp) => samp.Reference === t.Referenced && samp.ID === s.ID
            );
        });
    });

    return (
        <Stack direction={{ sm: "column", md: "row" }} spacing={2} divider={<Divider orientation="vertical" variant="middle" flexItem />} justifyContent="space-evenly">
            <Stack direction="column" spacing={2} maxWidth={{ md: "50%", sm: "100%" }}>
                <Typography variant="h6">
                    Tests Awaiting Specimen Selection
                </Typography>
                <Stack direction="row" spacing={1} useFlexGap flexWrap="wrap" alignItems="center">
                    {awaitingTests.map((t) => (
                        <Box m="1">
                            <Chip color="warning" margin="1" label={t.Name} />
                        </Box>
                    ))}
                    {awaitingTests.length === 0 ? <p>All tests covered!</p> : null}
                </Stack>
            </Stack>

            <Stack direction="column" spacing={2} maxWidth={{ md: "50%", sm: "100%" }}>
                <Typography variant="h6">
                    Tests Covered By Selected Specimen(s)
                </Typography>
                <Stack direction="row" spacing={1} useFlexGap flexWrap="wrap" alignItems="center">
                    {coveredTests.map((t) => (
                        <Box sx={{ m: 1 }}>
                            <Chip color="success" label={t.Name} />
                        </Box>
                    ))}
                    {coveredTests.length === 0 ? (
                        <p>No specimens selected.</p>
                    ) : null}
                </Stack>
            </Stack>
        </Stack>
    );
}

function ScheduleCollectionForm({
    requestedCollectionDate,
    setRequestedCollectionDate,
    stat,
    setStat,
}) {
    const now = new Date();

    const disableTime = (time, view) => {
        if (view === "hours" && (time < 8 || time > 17)) {
            return true;
        }
        return false;
    };
    const disableDay = (date) => {
        const daysToDisable = [0, 6]; // Sunday & Saturday
        return daysToDisable.includes(date.day());
    };
    return (
        <>
            <Stack direction={{ xs: "column", md: "row" }} spacing={2}>
                <FormControl sx={{ minWidth: 150, width: "230px" }}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DateTimePicker
                            minDateTime={dayjs(
                                now.setHours(now.getHours() + 4)
                            )}
                            disablePast
                            label="Requested Collection Date"
                            shouldDisableDate={disableDay}
                            shouldDisableTime={disableTime}
                            value={requestedCollectionDate}
                            onChange={setRequestedCollectionDate}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    helperText="Collection must be scheduled at least 4 hours in advance."
                                    required
                                    size="small"
                                />
                            )}
                        />
                    </LocalizationProvider>
                </FormControl>
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={stat}
                            onChange={(e) => setStat(e.target.checked)}
                            color="primary"
                        />
                    }
                    label="Stat - Order is urgent"
                />
            </Stack>
        </>
    );
}

function GetSpecimenList({
    specimens,
    neededSpecimenTypes,
    setSamples,
    sampleDetails,
    setSampleDetails,
    setSpecimens,
}) {
    const [barcodes, setBarcodes] = useState({})
    const getDefaultEditMode = () => {
        const cellMode = {}
        for (let spec of neededSpecimenTypes) {
            cellMode[spec.id] = { "Barcode": { mode: "edit" } }
        }
        return cellMode
    }

    const [cellModesModel, setCellModesModel] = useState(getDefaultEditMode())

    const handleSelect = (v, _) => {
        // v does not contain specIds but temp unique ids
        // must fetch original specimenType based on id
        const specimens = neededSpecimenTypes.filter((s) => {
            return v.includes(s.id)
        });
        const specWBarcode = specimens.map((s) => {
            if (barcodes.hasOwnProperty(s.id)) {
                s.Barcode = barcodes[s.id]
                return s
            }
            s.Barcode = ""
            return s
        })


        setSampleDetails(specWBarcode);

        setSamples(specimens.map((s) => s.ID));
        setSpecimens(v);
    };
    console.log('sampleDetails', sampleDetails)
    console.log('specimen List', neededSpecimenTypes)

    const getNeededFor = (row) => {
        if (row.row?.Tests) {
            return row.row?.Tests.map((test) => test.Name).join(", ")
        }
        return ""

    }

    const RenderValueCell = (params) => {
        return (
            <GridEditInputCell
                {...params}
                onValueChange={(e, v) => {
                    console.log(v)
                    setBarcodes({ ...barcodes, [params.id]: v })
                    setSampleDetails(sampleDetails.map((s) => {
                        if (s.id === params.id) {
                            s.Barcode = v
                        }
                        return s
                    }))
                }}
            />
        );
    }



    // props accepts ordered tests
    // it uses those to display the required specimen types
    const columns = [
        { field: "QTY", headerName: "QTY", width: 20 },
        { field: "Barcode", headerName: "Custom Barcode", editable: true, width: 150, renderEditCell: RenderValueCell },
        { field: "Container", headerName: "Container", width: 200 },
        { field: "Specimen", headerName: "Specimen", width: 120 },
        { field: "Directions", headerName: "Directions", width: 600 },
        {
            field: "Tests",
            headerName: "Needed For",
            width: 600,
            valueGetter: getNeededFor,
        },
    ];

    const handleCellEdit = (row, e) => {
        console.log(row, e)
        setBarcodes({ ...barcodes, [row.id]: row.Barcode })
        setSampleDetails(sampleDetails.map((s) => {
            if (s.id === row.id) {
                s.Barcode = row.Barcode
            }
            return s
        }))
    }

    const handleCellModesModelChange = useCallback((newModel) => {
        console.log(newModel)
        setCellModesModel(newModel);
    }, []);

    return (
        <DataGridPro
            disableColumnFilter
            disableColumnSelector
            disableDensitySelector
            hideFooterRowCount
            hideFooterSelectedRowCount
            density="compact"
            rowSelectionModel={specimens}
            onRowSelectionModelChange={handleSelect}
            cellModesModel={cellModesModel}
            onCellModesModelChange={handleCellModesModelChange}
            // processRowUpdate={handleCellEdit}
            // onProcessRowUpdateError={(e) => console.log(e)}
            checkboxSelection={true}
            autoHeight={true}
            rows={neededSpecimenTypes}
            columns={columns}
        />
    );
}

function CollectNowForm({
    facility,
    collector,
    setCollector,
    dateCollected,
    setDateCollected,
    fasting,
    setFasting,
    stat,
    setStat,
    neededSpecimenTypes,
    setSpecimens,
    specimens,
    sampleDetails,
    setSampleDetails,
    setSamples,
    allTests,
    showAllSpecimens,
    setShowAllSpecimens,
}) {
    const [allCollectors, setAllCollectors] = useState([]);

    useEffect(() => {
        getCollectorsByFacility(facility.ID).then((c) => setAllCollectors(c));
    }, []);

    return (
        <>
            <Stack spacing={2} direction="column">
                <FormLabel component="legend">Select Collector</FormLabel>
                <Stack direction={{ sm: "column", md: "row" }} spacing={2}>
                    <FormControl sx={{ minWidth: 150, width: "220px" }}>
                        <TextField
                            required
                            select
                            value={collector}
                            label="Collector"
                            size="small"
                            onChange={(e) => setCollector(e.target.value)}
                        >
                            {allCollectors.map((c) => (
                                <MenuItem key={c.ID} value={c.ID}>
                                    {c.FirstName} {c.LastName}
                                </MenuItem>
                            ))}
                        </TextField>
                    </FormControl>
                    <FormControl sx={{ minWidth: 150, width: "230px" }}>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DateTimePicker
                                sx={{ width: "30%" }}
                                disableFuture
                                label="Date Collected"
                                value={dateCollected}
                                onChange={setDateCollected}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        required
                                        size="small"
                                    />
                                )}
                            />
                        </LocalizationProvider>
                    </FormControl>
                </Stack>
                <Stack direction="row" spacing={2}>
                    <FormGroup>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={fasting}
                                    onChange={(event) =>
                                        setFasting(event.target.checked)
                                    }
                                />
                            }
                            label="Patient is fasting"
                        />
                    </FormGroup>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={stat}
                                onChange={(e) => setStat(e.target.checked)}
                                color="primary"
                            />
                        }
                        label="Stat - Order is urgent"
                    />
                </Stack>
                <FormLabel component="legend">
                    Select Collected Samples
                </FormLabel>
                <Typography variant="body1">
                    Based on the ordered tests, the following specimen types are
                    required:
                </Typography>
                <Stack direction="column" spacing={2}>
                    <GetSpecimenList
                        specimens={specimens}
                        neededSpecimenTypes={neededSpecimenTypes}
                        setSpecimens={setSpecimens}
                        setSampleDetails={setSampleDetails}
                        sampleDetails={sampleDetails}
                        setSamples={setSamples}
                    />
                    <FormControlLabel label="View All Specimen Types" control={<Checkbox
                        onChange={(e) => setShowAllSpecimens(e.target.checked)}
                        checked={showAllSpecimens}
                    />} />
                    {!showAllSpecimens &&
                        <SpecimenRequiredVerification
                            sampleDetails={sampleDetails}
                            allTests={allTests}
                        />}
                </Stack>
            </Stack>
        </>
    );
}

export { ScheduleCollectionForm, CollectNowForm };
