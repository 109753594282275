import React, { useEffect, useState } from "react";
import { Box } from "@mui/material";
import { DataGridPro } from "@mui/x-data-grid-pro";
import { HoursToHumanReadable } from "../../services/util";
import { getPatientRecentTest } from "../../services/patients";

type RecentTestTableProps = {
    patientId: number | undefined,
    setSelection: (t: number[]) => void,
    recentTests: any[]
    setRecentTests: (t: any[]) => void
}

function RecentTestTable({ patientId, setSelection, recentTests, setRecentTests }: RecentTestTableProps) {
    const [error, setError] = useState(null)

    useEffect(() => {
        if (!patientId) {
            return
        }

        getPatientRecentTest(patientId)
            .then((p: any) => {
                if (!p.ok) {
                    throw new Error("Failed to load recent tests");
                }
                return p.json();
            })
            .then((p: any) => {
                setRecentTests(p.Tests);
            })
            .catch((e: any) => setError(e.message));
    }, [patientId]);

    const columns = [
        { field: "Code", headerName: "Code", width: 70 },
        { field: "Name", headerName: "Name", width: 220 },
        { field: "Description", headerName: "Description", width: 220 },
        { field: "CPTCode", headerName: "CPT Code", width: 100 },
        {
            field: "MaxTAT", headerName: "Max TAT (h)", width: 120,
            valueGetter: (row: any) => HoursToHumanReadable(row.row.MaxTAT),
        },
    ];

    let visibleRows = recentTests.length > 3 ? 3 : recentTests.length
    let height = (visibleRows * 36) + 39 + 52;

    return (
        <Box sx={{ height: `${height}px` }}>
            <DataGridPro
                disableColumnFilter
                disableColumnSelector
                disableDensitySelector
                // hideFooterRowCount
                getRowId={(row: any) => row.ID}
                density="compact"
                sx={{
                    "& .MuiDataGrid-columnHeaderCheckbox .MuiDataGrid-columnHeaderTitleContainer":
                    {
                        display: "none",
                    }
                }}
                // components={{ Toolbar: GridToolbar }}
                initialState={{
                    pagination: { paginationModel: { pageSize: 5 } },
                }}
                // paginationMode
                pagination
                pageSizeOptions={[3, 5, 10, 15]}
                // rowsPerPageOptions
                componentsProps={{
                    toolbar: {
                        showQuickFilter: true,
                        quickFilterProps: { debounceMs: 500 },
                    },
                }}
                keepNonExistentRowsSelected
                // @ts-ignore
                onRowSelectionModelChange={setSelection}
                checkboxSelection={true}
                rows={recentTests}
                columns={columns}
            />
        </Box>
    );
};

export default RecentTestTable;
