import { Stack } from "@mui/system";
import {
    Alert,
    Autocomplete,
    Button,
    FormControl,
    MenuItem,
    TextField,
    InputLabel,
} from "@mui/material";
import { useState, useEffect } from "react";
import Box from "@mui/system/Box";
import * as Yup from "yup";
import { Formik, Form } from "formik";
import { getDepartments } from "../services/tests";
import { getInstrumentInterfaces } from "../services/samples";
import { getLaboratories } from "../services/tests";
import {
    addInstrument,
    updateInstrument,
    getInstruments,
    getInstrument,
} from "../services/methods";
import { DataGridPro, GridToolbar } from "@mui/x-data-grid-pro";
import TableFrame from "./TableFrame";
import { DataTableContainer } from "./TableContainer";
import ButtonTray from "./ButtonTray";

export default function InstrumentPanel() {
    const initValues = {
        Name: "",
        Location: "",
        Department: {},
        LaboratoryID: 1,
    };

    const [id, setId] = useState();
    const [instruments, setInstruments] = useState([]);
    const [interfaces, setInterfaces] = useState([]);
    const [laboratories, setLaboratories] = useState([]);
    const [initialValues, setInitialValues] = useState(initValues);
    const [create, setCreate] = useState(false);
    const [departments, setDepartments] = useState([]);
    const [error, setError] = useState(null);
    const [refresh, setRefresh] = useState(false);
    useEffect(() => {
        if (id) {
            fetchFormData();
        }
    }, [id]);

    useEffect(() => {
        getInstrumentInterfaces()
            .then((p) => {
                if (!p.ok) {
                    throw new Error("Failed to load interfaces");
                }
                return p.json();
            })
            .then((p) => {
                setInterfaces(p);
            })
            .catch((e) => setError(e.message));
    }, []);

    const fetchFormData = async () => {
        getInstrument(id)
            .then((p) => {
                if (!p.ok) {
                    throw new Error("Failed to load instrument");
                }
                return p.json();
            })
            .then((p) => {
                console.log(p);
                setInitialValues({
                    Name: p.Name,
                    Location: p.Location,
                    Department: p.Department,
                    Interface: p.Interface,
                    InstrumentIdentifier: p.InstrumentIdentifier,
                    LaboratoryID: p.LaboratoryID,
                    CoriellQCGroupID: p.CoriellQCGroupID,
                });
            });
    };

    useEffect(() => {
        getLaboratories()
            .then((p) => {
                if (!p.ok) {
                    throw new Error("Failed to load laboratories");
                }
                return p.json();
            })
            .then((p) => {
                p = p.filter((lab) => !lab.Reference);
                setLaboratories(p)
            });

        getDepartments()
            .then((p) => {
                if (!p.ok) {
                    throw new Error("Failed to load departments");
                }
                return p.json();
            })
            .then((p) => setDepartments(p));
        getInstruments()
            .then((p) => {
                if (!p.ok) {
                    throw new Error("Failed to load instruments");
                }
                return p.json();
            })
            .then((p) => setInstruments(p));
    }, [refresh]);

    const validationSchema = Yup.object().shape({
        Name: Yup.string().required("Name is required"),
        Location: Yup.string().required("Code is required"),
        Interface: Yup.object({}).nullable(),
        Department: Yup.object({
            ID: Yup.number().required(),
        }).required("Department is required"),
        InstrumentIdentifier: Yup.string().required(),
        LaboratoryID: Yup.number().required(),
        CoriellQCGroupID: Yup.string(),
    });

    const handleSubmit = (values) => {
        console.log(values);
        if (id) {
            updateInstrument(id, values)
                .then((p) => {
                    if (!p.ok) {
                        throw new Error("Failed to load instruments");
                    }
                    setError(null);
                    setRefresh((prevValue) => !prevValue);
                    setCreate(false);
                    return p.json();
                })
                .catch((e) => setError(e.message));
        } else {
            addInstrument(values)
                .then((p) => {
                    if (!p.ok) {
                        throw new Error("Failed to load instruments");
                    }
                    setError(null);
                    setRefresh((prevValue) => !prevValue);
                    setCreate(false);
                    return p.json();
                })
                .catch((e) => setError(e.message));
        }
    };

    const instrumentForm = () => {
        return (
            <>
                <Box>
                    <Formik
                        initialValues={initialValues}
                        validationSchema={validationSchema}
                        onSubmit={handleSubmit}
                        enableReinitialize
                    >
                        {({
                            values,
                            handleChange,
                            handleBlur,
                            setFieldValue,
                            errors,
                            touched,
                        }) => (
                            <Form>
                                {console.log(values)}
                                <Stack direction="column" spacing={2}>
                                    <InputLabel>General</InputLabel>
                                    <Stack
                                        direction={{ sm: "column", md: "row" }}
                                        spacing={{ xs: 1, sm: 2, md: 4 }}
                                    >
                                        <TextField
                                            name="Name"
                                            value={values.Name}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            error={
                                                touched.Name &&
                                                Boolean(errors.Name)
                                            }
                                            label="Name"
                                            size="small"
                                            helperText={
                                                touched.Name && errors.Name
                                            }
                                            required
                                        />
                                        <TextField
                                            name="Location"
                                            value={values.Location}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            error={
                                                touched.Location &&
                                                Boolean(errors.Location)
                                            }
                                            label="Location"
                                            size="small"
                                            helperText={
                                                touched.Location &&
                                                errors.Location
                                            }
                                            required
                                        />
                                        <Autocomplete
                                            required
                                            size="small"
                                            onChange={(_, value) =>
                                                setFieldValue(
                                                    "Department",
                                                    value
                                                )
                                            }
                                            error={
                                                touched.Department &&
                                                Boolean(errors.Department)
                                            }
                                            value={values?.Department}
                                            keepNonExistentRowsSelected
                                            options={departments}
                                            getOptionLabel={(option) =>
                                                option.Name || ''
                                            }
                                            isOptionEqualToValue={(
                                                option,
                                                value
                                            ) => option.ID === value.ID}
                                            // onBlur={handleBlur}
                                            helperText={
                                                touched.Department &&
                                                errors.Department
                                            }
                                            name="Department"
                                            sx={{
                                                minWidth: "223px",
                                                maxWidth: "100%",
                                            }}
                                            label="Department"
                                            variant="outlined"
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    required
                                                    error={
                                                        touched.Department &&
                                                        Boolean(
                                                            errors.Department
                                                        )
                                                    }
                                                    helperText={
                                                        touched.Department &&
                                                        errors.Department
                                                    }
                                                    name="Department"
                                                    size="small"
                                                    label="Department"
                                                />
                                            )}
                                        />
                                    </Stack>
                                    <Stack
                                        direction={{ sm: "column", md: "row" }}
                                        spacing={{ xs: 1, sm: 2, md: 4 }}
                                    >
                                        <Autocomplete
                                            size="small"
                                            onChange={(_, value) => {
                                                console.log(value);
                                                setFieldValue(
                                                    "Interface",
                                                    value
                                                );
                                                setFieldValue(
                                                    "InterfaceID",
                                                    value?.ID || null
                                                )
                                            }
                                            }
                                            error={
                                                touched.Interface &&
                                                Boolean(errors.Interface)
                                            }
                                            value={values.Interface}
                                            clearOnBlur
                                            keepNonExistentRowsSelected
                                            options={interfaces}
                                            getOptionLabel={(option) =>
                                                option.Name
                                            }
                                            isOptionEqualToValue={(
                                                option,
                                                value
                                            ) => option.ID === value.ID}
                                            // onBlur={handleBlur}
                                            helperText={
                                                touched.Interface &&
                                                errors.Interface
                                            }
                                            name="Interface"
                                            sx={{
                                                minWidth: "223px",
                                                maxWidth: "100%",
                                            }}
                                            label="Interface"
                                            variant="outlined"
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    error={
                                                        touched.Interface &&
                                                        Boolean(
                                                            errors.Interface
                                                        )
                                                    }
                                                    helperText={
                                                        touched.Interface &&
                                                        errors.Interface
                                                    }
                                                    name="Interface"
                                                    size="small"
                                                    label="Interface"
                                                />
                                            )}
                                        />
                                        <TextField
                                            name="InstrumentIdentifier"
                                            label="Identifier"
                                            value={values.InstrumentIdentifier}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            error={
                                                touched.InstrumentIdentifier &&
                                                Boolean(errors.InstrumentIdentifier)
                                            }
                                            size="small"
                                            helperText={
                                                touched.InstrumentIdentifier && errors.InstrumentIdentifier
                                            }
                                            required
                                        />
                                        <FormControl sx={{ minWidth: 200, m: 1 }} size="small">
                                            <TextField
                                                size="small"
                                                select
                                                required
                                                label="Laboratory"
                                                error={
                                                    touched.LaboratoryID &&
                                                    Boolean(errors.LaboratoryID)
                                                }
                                                helperText={
                                                    touched.LaboratoryID && errors.LaboratoryID
                                                }
                                                value={
                                                    values.LaboratoryID === undefined
                                                        ? ""
                                                        : values.LaboratoryID
                                                }
                                                name="LaboratoryID"
                                                key="Laboratory"
                                                onChange={(v) =>
                                                    setFieldValue('LaboratoryID', v.target.value)
                                                }
                                            >
                                                {laboratories.map((option) => (
                                                    <MenuItem key={option.ID} value={option.ID}>
                                                        {option.Name}
                                                    </MenuItem>
                                                ))}
                                            </TextField>
                                        </FormControl>
                                        <TextField
                                            name="CoriellQCGroupID"
                                            value={values.CoriellQCGroupID}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            error={
                                                touched.CoriellQCGroupID &&
                                                Boolean(errors.CoriellQCGroupID)
                                            }
                                            label="Coriell QC Group ID"
                                            size="small"
                                            helperText={
                                                touched.CoriellQCGroupID && errors.CoriellQCGroupID
                                            }
                                        />
                                    </Stack>
                                    {error !== null ? (
                                        <Alert severity="error">{error}</Alert>
                                    ) : null}
                                    <Button type="submit">
                                        {id ? "Save" : "Create"}
                                    </Button>
                                </Stack>
                            </Form>
                        )}
                    </Formik>
                </Box>
            </>
        );
    };

    const columns = [
        { field: "Name", headerName: "Name", width: 175 },
        { field: "Location", headerName: "Location", width: 100 },
        {
            field: "Department",
            headerName: "Department",
            width: 175,
            valueGetter: (row) => row.row.Department?.Name,
        },
    ];

    if (create) {
        return instrumentForm();
    }

    return (
        <>
            <TableFrame>
                <DataTableContainer>
                    <DataGridPro
                        getRowId={(row) => row.ID}
                        density="compact"
                        sx={{
                            "& .MuiDataGrid-columnHeaderCheckbox .MuiDataGrid-columnHeaderTitleContainer":
                            {
                                display: "none",
                            },
                        }}
                        components={{ Toolbar: GridToolbar }}
                        componentsProps={{
                            toolbar: {
                                showQuickFilter: true,
                                quickFilterProps: { debounceMs: 500 },
                            },
                        }}
                        keepNonExistentRowsSelected
                        onRowSelectionModelChange={setId}
                        rowSelectionModel={id}
                        rows={instruments}
                        columns={columns}
                    />
                </DataTableContainer>
                <ButtonTray>
                    <Button
                        variant="contained"
                        color="success"
                        onClick={() => {
                            setId(undefined);
                            setInitialValues(initValues);
                            setCreate(true);
                        }}
                    >
                        Create
                    </Button>
                    <Button
                        disabled={!id}
                        variant="contained"
                        color="primary"
                        onClick={() => setCreate(true)}
                    >
                        Edit
                    </Button>
                </ButtonTray>
            </TableFrame>
        </>
    );
}
