import * as React from "react";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import { Divider, Paper } from "@mui/material";
import Title from "./Title";
import { Stack } from "@mui/system";
import { Box } from "@mui/system";
import TrendingDownIcon from '@mui/icons-material/TrendingDown';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import TrendingFlatIcon from '@mui/icons-material/TrendingFlat';

function preventDefault(event) {
    event.preventDefault();
}


export default function OrderMetric({ orderValues, title }) {
    
        const getDiffIcon = (newVal, prevVal) => {
            if (prevVal === 0) {
                    return <></>
            }


            const percChange = ((newVal / prevVal) - 1) * 100;

            let icon;

            if (percChange > 1) {
                    icon = <TrendingUpIcon color="success"/>;
            } else if (percChange == 0) {
                    icon = <TrendingFlatIcon color="primary"/>;
            } else {
                    icon = <TrendingDownIcon color="error"/>;
            }

            return (
                    <p>{icon} {percChange.toFixed(2)}%</p>
            )
        }


    return (
        <Paper
            sx={{
                p: 2,
                display: "flex",
                flexDirection: "column",
                height: 240,
            }}
        >
            <Title>{title}</Title>
                <Stack direction={{ xs: "column", md: "row"}} divider={<Divider orientation="vertical" />} spacing={2}>
                <Box>
                    <Typography component="p" variant="h4">
                        {orderValues?.Orders_0_30}
                    </Typography>
                    <Typography color="text.secondary" sx={{ flex: 1 }}>
                        0 to 30 Days {getDiffIcon(orderValues?.Orders_0_30, orderValues?.Orders_30_60)}
                    </Typography>
                </Box>
                <Box>
                    <Typography component="p" variant="h4">
                        {orderValues?.Orders_30_60}
                    </Typography>
                    <Typography color="text.secondary" sx={{ flex: 1 }}>
                        30 to 60 Days{getDiffIcon(orderValues?.Orders_30_60, orderValues?.Orders_60_90)}
                    </Typography>
                </Box>
                <Box>
                    <Typography component="p" variant="h4">
                        {orderValues?.Orders_60_90}
                    </Typography>
                    <Typography color="text.secondary" sx={{ flex: 1 }}>
                        60 to 90 Days {getDiffIcon(orderValues?.Orders_60_90, orderValues?.Orders_90_120)} </Typography>
                </Box>
                <Box>
                    <Typography component="p" variant="h4">
                        {orderValues?.Orders_90_120}
                    </Typography>
                    <Typography color="text.secondary" sx={{ flex: 1 }}>
                        90 to 120 Days 
                    </Typography>
                </Box>
            </Stack>
            <div>
                <Link color="primary" href="#" onClick={preventDefault}></Link>
            </div>
        </Paper>
    );
}
