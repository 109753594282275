import React, { useState, useEffect } from "react";
import Webcam from "react-webcam";
import AddAPhotoIcon from '@mui/icons-material/AddAPhoto';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import { styled } from '@mui/material/styles';
import {
    Button,
    Stack,
    Divider,
    IconButton,
} from "@mui/material";
import { useMediaQuery } from "@mui/material";
import mdTheme from "../components/Theme";
import { Buffer } from "buffer";
import ErrorAlert from "./ErrorAlert";
import { Cameraswitch } from "@mui/icons-material";

const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
});

function ImageCapture({ image64, setImage64, disableUpload }) {
    const [upload, setUpload] = useState(false)
    const [capture, setCapture] = useState(false)
    const [error, setError] = useState(null)
    const [videoConstraints, setVideoConstraints] = useState({ facingMode: "environment" })
    const [hasUserFacingCamera, setHasUserFacingCamera] = useState(false)
    const isMobile = useMediaQuery(mdTheme.breakpoints.down("sm"));

    const uploadImage = async (selectedFile) => {
        setUpload(true)

        if (!["image/png", "image/jpeg"].includes(selectedFile.type)) {
            setError("Invalid file format.")
            setUpload(false)
        }

        const buf = await selectedFile.arrayBuffer()

        const blob = new Blob([buf], { type: selectedFile.type });
        const url = URL.createObjectURL(blob);

        const img = new Image();
        img.onload = () => {
            // Calculate the new dimensions while maintaining the aspect ratio
            const aspectRatio = img.width / img.height;
            let newWidth = img.width;
            let newHeight = img.height;

            // Adjust width to 720px if original width is greater, and calculate the corresponding height
            if (newWidth > 720) {
                newWidth = 720;
                newHeight = newWidth / aspectRatio;
            }

            const canvas = document.createElement('canvas');
            const ctx = canvas.getContext('2d');

            // Set canvas size to the new dimensions
            canvas.width = newWidth;
            canvas.height = newHeight;

            // Draw the image onto the canvas with the new dimensions
            ctx.drawImage(img, 0, 0, newWidth, newHeight);

            // Convert the canvas to a Base64 string
            const image64 = canvas.toDataURL(selectedFile.type);

            setImage64(image64);
            setUpload(false); // Update upload status after processing

            // Free up memory by revoking the Object URL
            URL.revokeObjectURL(url);
        };

        img.onerror = () => {
            setError("There was an error processing the image.");
            setUpload(false);
            URL.revokeObjectURL(url); // Free up memory in case of error
        };

        img.src = url;

        // var b = Buffer.from(buf, 'binary')
        // if (selectedFile.type === "image/png") {
        // setImage64(`data:image/png;base64,${b.toString('base64')}`)
        // } else if (selectedFile.type === "image/jpeg") {
        // setImage64(`data:image/jpeg;base64,${b.toString('base64')}`)
        // }
    }

    const toggleCamera = () => {
        setVideoConstraints({ facingMode: videoConstraints.facingMode === "environment" ? "user" : "environment" })
    }

    return (
        <>
            {!capture && !upload &&
                <Stack divider={<Divider orientation="vertical" flexItem />} direction={"row"} spacing={1}>
                    <Button onClick={() => setCapture(true)} startIcon={<AddAPhotoIcon />}>Capture Photo</Button>
                    {!disableUpload && <Button
                        component="label"
                        // onClick={() => { setUpload(true) }}
                        startIcon={<FileUploadIcon />}>
                        Upload Photo
                        <VisuallyHiddenInput
                            type="file"
                            accept={"image/jpeg, image/png"}
                            onChange={(e) => { console.log(e); uploadImage(e.target.files[0]) }}
                        />
                    </Button>}
                </Stack>}
            <ErrorAlert error={error} />
            {capture &&
                <Webcam audio={false}
                    height={"80%"}
                    videoConstraints={videoConstraints}
                    screenshotFormat="image/jpeg"
                    forceScreenshotSourceSize="true"
                    width={"80%"}>
                    {({ getScreenshot }) => (
                        <Stack direction="row" spacing={1}>
                            <Button
                                sx={{ mt: 2, mb: 2 }}
                                variant="contained"
                                onClick={() => {
                                    const imageSrc = getScreenshot()
                                    setImage64(imageSrc)
                                    setCapture(false)
                                }}
                            >
                                Capture photo
                            </Button>
                            <IconButton
                                onClick={toggleCamera}
                            >
                                <Cameraswitch />
                            </IconButton>
                        </Stack>
                    )}
                </Webcam>
            }
        </>
    )
}

export default ImageCapture;
