import React, { useContext, useRef } from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { SessionContext } from "../hooks/getContext";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Box from "@mui/material/Box";
import {
    Dialog,
    Divider,
    Stack,
    Typography,
} from "@mui/material";
import SamplesTable from "../components/SamplesTable";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Button from "@mui/material/Button";
import { useState } from "react";
import { addShipment } from "../services/shipments";
import { useNavigate } from "react-router";
import { getCollectedSamples, getReceivedSamples } from "../services/samples";
import Framework from "../components/Framework";
import { MiniTableContainer } from "../components/TableContainer";
import ErrorAlert from "../components/ErrorAlert";
import ShipmentFormFields from "../components/ShipmentFormFields";
import SampleNotesDialog from "../components/Dialogs/SampleNotesDialog";


function ShipmentForm({ shipment, validationSchema, handleSubmit, filter, innerRef }) {
    console.log(shipment);

    return (
        <>
            <Formik
                initialValues={shipment}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
                enableReinitialize
                innerRef={innerRef}
            >
                {() => (
                    <Form>
                        <ShipmentFormFields filter={filter} />
                    </Form>)
                }
            </Formik>

        </>)
}


function CreateDialog({ open, setOpen, handleSubmit, children }) {
    return (
        <Dialog open={open}>
            <DialogTitle>Create Shipment</DialogTitle>
            <DialogContent type="form">
                <DialogContentText>
                    Destination
                </DialogContentText>
                {children}
            </DialogContent>
            <DialogActions>
                <Button onClick={() => setOpen(false)}>Cancel</Button>
                <Button onClick={handleSubmit}>Save</Button>
            </DialogActions>
        </Dialog>)
};

const validationSchema = Yup.object().shape({
    "NewOrderShipment": Yup.boolean(),
    "Tracking": Yup.string().when(
        "Courier", { is: (courier) => courier !== "Walk-In", then: () => Yup.string().required() }
    ),
    "Type": Yup.string().oneOf(["package", "courier"]), // package or courier
    "Samples": Yup.array().of(Yup.number()).when("NewOrderShipment", {
        is: false, then: Yup.array().min(1).required()
    }),
    "DateShipped": Yup.date().required(),
    "Courier": Yup.string().required(),
    "StartMileage": Yup.number().nullable(),
    "EndMileage": Yup.number().nullable(),
    "DestinationID": Yup.number().required(),
})


export default function NewShipment() {
    const [open, setOpen] = useState(false);
    const [notesSampleId, setNotesSampleId] = useState(null);
    const formRef = useRef();

    const [shipment, setShipment] = useState({
        "Tracking": "",
        "Type": "package", // package or courier
        "Samples": [],
        "DateShipped": new Date(),
        "Courier": "",
        "StartMileage": null,
        "EndMileage": null,
        "DestinationID": null,
    })

    const [refresh, setRefresh] = useState(0);
    const navigate = useNavigate();
    const [error, setError] = useState("");
    const [filter, setFilter] = useState("Collected");
    const session = useContext(SessionContext);

    let getSamples;
    switch (filter) {
        case "Collected":
            getSamples = getCollectedSamples;
            break;
        case "Received":
            getSamples = getReceivedSamples;
            break;
    }

    const handleCreate = (shipment) => {

        console.log('creating');
        console.log(shipment);

        addShipment(validationSchema.cast(shipment))
            .then((p) => {
                if (!p.ok) {
                    throw new Error(
                        "Failed to save shipment. Please try again."
                    );
                } else {
                    return p.json();
                }
            })
            .then((p) => {
                setOpen(false);
                reset();
                navigate("/shipments/" + p.ID);
            })
            .catch((e) => setError(e.message));
    };

    const reset = () => {
        setRefresh(refresh + 1);
    };

    const handleViewNotes = (sampleId) => {
        setNotesSampleId(sampleId);
    };

    const handleSubmit = () => {
        console.log(formRef.current);
        if (formRef?.current) {
            console.log('submitting');
            formRef.current.submitForm();
        }
    }

    return (
        <Framework name="New Shipment">
            <Box sx={{ m: 2 }}>
                <SampleNotesDialog
                    sampleId={notesSampleId}
                    setSampleId={setNotesSampleId}
                />
                <CreateDialog open={open} setOpen={setOpen} handleSubmit={handleSubmit}>
                    <ShipmentForm
                        filter={filter}
                        innerRef={formRef}
                        validationSchema={validationSchema}
                        handleSubmit={handleCreate}
                        shipment={shipment}
                    />
                </CreateDialog>

                <Typography variant="h5">New Shipment</Typography>
                <Typography variant="body1">
                    Select samples contained in the shipment.
                </Typography>
                <Divider sx={{ m: 2 }} />
                <RadioGroup
                    row
                    alignSelf="center"
                    label=""
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="row-radio-buttons-group"
                    value={filter}
                    onChange={(_, v) => {
                        setRefresh(refresh + 1);
                        setFilter(v);
                    }}
                >
                    <FormControlLabel
                        value="Collected"
                        disabled={session.user.Type !== "Lab"}
                        control={<Radio />}
                        label="Collected"
                    />
                    <FormControlLabel
                        value="Received"
                        disabled={session.user.Type !== "Lab"}
                        control={<Radio />}
                        label="Received"
                    />
                </RadioGroup>
                <Divider sx={{ m: 2 }} />
                <MiniTableContainer>
                    <SamplesTable
                        viewNote={(notes) => handleViewNotes(notes)}
                        showActions={false}
                        samples={shipment.Samples}
                        setSamples={(s) => setShipment({ ...shipment, Samples: s })}
                        refresh={refresh}
                        getSamples={getSamples}
                    />
                </MiniTableContainer>
                <ErrorAlert error={error} />
                <Stack m={1} direction={"row"} justifyContent={"flex-end"}>
                    <Button
                        variant="contained"
                        disabled={shipment?.Samples.length === 0}
                        color="success"
                        onClick={() => setOpen(true)}
                    >
                        Create
                    </Button>
                </Stack>
            </Box>
        </Framework >
    );
}

export { ShipmentForm, validationSchema }
