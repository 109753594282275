import React, { useContext } from "react";
import { GridToolbarColumnsButton, GridToolbarContainer, GridToolbarDensitySelector, GridToolbarExport, GridToolbarFilterButton, GridToolbarQuickFilter } from "@mui/x-data-grid";
import { Stack } from "@mui/material";
import { SessionContext } from "../hooks/getContext";

const CustomToolbar = () => {
    const { user } = useContext(SessionContext);

    return (
        <GridToolbarContainer>
            <Stack direction="row" justifyContent="space-between" width="100%">
                <div>
                    <GridToolbarColumnsButton />
                    <GridToolbarFilterButton />
                    <GridToolbarDensitySelector />
                    { /*@ts-ignore*/}
                    {user.Roles && !user.Roles.includes("Sample Handler") && !user.Roles.includes("Sample Handler Manager") && <GridToolbarExport />}
                </div>
                <GridToolbarQuickFilter />
            </Stack>
        </GridToolbarContainer>
    );
}

export default CustomToolbar;
