import { useState, useEffect } from "react";
import { Autocomplete, TextField } from "@mui/material";

const AllergysEdit = ({ allergys, setAllergys }) => {
    const [allergyList, setAllergyList] = useState([]);
    const [error, setError] = useState(null);
    const [allergyInput, setAllergyInput] = useState("");

    useEffect(() => {
        fetch(
            `https://clinicaltables.nlm.nih.gov/api/drug_ingredients/v3/search?terms=${allergyInput}&df=code,name&maxList=25`
        )
            .then((p) => {
                if (!p.ok) {
                    throw new Error("Failed to load allergy list.");
                }

                return p.json();
            })
            .then((p) => {
                let meds = p[3].map((row) => {
                    return { RxNormID: row[0], Name: row[1] };
                });

                setAllergyList(meds);
            })
            .catch((e) => setError(e.message));
    }, [allergyInput]);

    if (allergys === undefined) {
        return <></>;
    }

    return (
        <>
            <Autocomplete
                multiple
                key="allergy_autocomplete"
                id="allergy_autocomplete"
                error={error !== null ? error : ""}
                options={allergyList}
                sx={{
                    minWidth: "223px",
                    maxWidth: "100%",
                }}
                getOptionLabel={(option) => option.Name}
                isOptionEqualToValue={(option, value) =>
                    option.RxNormID === value.RxNormID
                }
                value={allergys}
                onChange={(_, value) => {
                    setAllergys(value);
                }}
                renderInput={(params) => {
                    return (
                        <TextField
                            {...params}
                            size="small"
                            onChange={(e) => setAllergyInput(e.target.value)}
                            label="Allergies"
                        />
                    );
                }}
            />
        </>
    );
};

export { AllergysEdit };
