import { Button } from "@mui/material";
import { Stack } from "@mui/system";

function CreateEditButtons({ onEdit = () => { }, onCreate = () => { }, editProps = {}, createProps = {} }) {
    const direction = { sm: "column", md: "row" }
    return (
        <Stack direction={direction} spacing={{ xs: 2, sm: 2, md: 2, lg: 2, xl: 2 }}>
            <Button
                variant="contained"
                color="success"
                onClick={onCreate}
                {...createProps}
            >
                Create
            </Button>
            <Button
                variant="contained"
                color="primary"
                onClick={onEdit}
                {...editProps}
            >
                Edit
            </Button>
        </Stack>
    );
}

export default CreateEditButtons;
