import { Tabs, Tab, Box, Typography } from "@mui/material";
import { a11yProps } from "../components/TabPanel";



function CustomTabs({ tab, setTab, tabs, title = "" }) {
    return (
        <Box sx={{ pl: 2, pr: 2, pt: 1 }}>
            {title && <Typography variant="h5">{title}</Typography>}
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <Tabs value={tab} onChange={(_, v) => setTab(v)}>
                    {tabs.map((t) => (<Tab label={t} {...a11yProps(0)} />))}
                </Tabs>
            </Box>
        </Box>
    )
}

export default CustomTabs;
