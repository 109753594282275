import React, { useState, useRef, useEffect } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
    Button,
    FormControlLabel,
    FormLabel,
    Grid,
    Radio,
    RadioGroup,
    Stack,
    TextField,
} from "@mui/material";
import { apiFetch } from '../services/fetch';
import {
    addExtPlate,
    updateExtPlate,
    extPlateWellsByRow,
    extPlateWellsByColumn,
    _384byRow,
    _384byColumn,
    getExtPlates,
} from "../services/molecular";
import ButtonTray from "./ButtonTray";

function getTodayDateInYYYYMMDD() {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, '0');
    const day = String(today.getDate()).padStart(2, '0');
    const hour = String(today.getHours()).padStart(2, '0');
    const min = String(today.getMinutes()).padStart(2, '0');

    return `${year}${month}${day}_${hour}${min}`;
}

type Well = {
    Position: string;
    Barcode: string;
}

function ScanPlate({ onSave, plateId }: { onSave: () => void, plateId?: number }) {
    const initValues = {
        Name: getTodayDateInYYYYMMDD(),
        Type: "96",
        Wells: [],
    }

    const [values, setValues] = useState(initValues);
    const [scanByRow, setScanByRow] = useState(false);
    const inputRefs = useRef<HTMLInputElement[]>([]);

    useEffect(() => {
        if (plateId) {
            (async () => {
                try {
                    const response = await apiFetch("/plates/" + plateId)
                    // @ts-ignore
                    setValues(response)
                } catch (e) {
                    console.error(e)
                }
            })()
        }

    }, [plateId])

    const validationSchema = Yup.object().shape({
        Name: Yup.string().required("Name is required"),
        Wells: Yup.array().of(Yup.object({
            Position: Yup.string().required("Well position is required"),
            SampleBarcode: Yup.string().required("Barcode is required"),
        })).min(1).required("At least one well must be assigned a sample"),
    })

    const wellMap = values.Type === "96" ? extPlateWellsByRow : _384byRow;

    const handleSubmit = async (values: any) => {
        try {
            if (plateId) {
                await apiFetch("/plates/" + plateId, "POST", values);
            } else {
                // @ts-ignore
                await apiFetch("/plates/create", "POST", values);
            }
            onSave()
        } catch (e) {
            console.error(e);
        }
    }

    const onKeyDown = (e: any) => {
        const well = e.target.id;
        if (well === "name") {
            return
        }

        const wellMap = scanByRow ? extPlateWellsByRow : extPlateWellsByColumn;
        if (e.key === "Enter" || e.key === "Tab") {
            e.preventDefault();
            const index = wellMap.indexOf(well)
            const nextIndex = index + 1;
            if (nextIndex < wellMap.length) {
                const nextWell = wellMap[nextIndex];
                for (let inp of inputRefs.current) {
                    if (inp.id === nextWell) {
                        inp.focus();
                        return
                    }
                }
            } else {
                inputRefs.current[0].focus()
            }
        }
    }

    const handleScanChange = (_: any, v: string) => {
        if (v === "row") {
            setScanByRow(true)
        } else {
            setScanByRow(false)
        }
    }

    const formik = useFormik({
        initialValues: values,
        validationSchema: validationSchema,
        onSubmit: handleSubmit,
        enableReinitialize: true,
    });


    console.log(formik.errors)
    console.log(formik.values.Wells)

    return (
        <Grid container spacing={1} columns={values?.Type === "384" ? 24 : 12} onKeyDown={onKeyDown}>
            <Grid item xs={values?.Type === "384" ? 24 : 12}>
                <Stack spacing={2} direction={{ sm: "column", md: "row" }} alignItems={{ sm: "left", md: "center" }}>
                    <TextField
                        required
                        key="name"
                        id="name"
                        size="small"
                        onChange={formik.handleChange}
                        error={
                            formik.touched.Name &&
                            Boolean(formik.errors.Name)
                        }
                        value={formik.values.Name}
                        onBlur={formik.handleBlur}
                        helperText={
                            formik.touched.Name &&
                            formik.errors.Name
                        }
                        name="Name"
                        label="Name"
                        variant="outlined"
                    />
                    <FormLabel>Plate Size</FormLabel>
                    <RadioGroup row aria-label="position" value={values.Type} onChange={(_, value) => (setValues({ ...values, Type: value }))}>
                        <FormControlLabel value="96" control={<Radio />} label="96" />
                        <FormControlLabel value="384" control={<Radio />} label="384" />
                    </RadioGroup>
                    <FormLabel>Scan By</FormLabel>
                    <RadioGroup row defaultValue="column" onChange={handleScanChange}
                    >
                        <FormControlLabel key="col" value="column" control={<Radio />} label="Column" />
                        <FormControlLabel key="row" value="row" control={<Radio />} label="Row" />
                    </RadioGroup>
                </Stack>
            </Grid>
            {wellMap.map((well, index) => {
                return (
                    <Grid key={well} item xs={values?.Type === "384" ? 24 : 12} xl={1}>
                        <TextField
                            id={well}
                            key={index}
                            InputLabelProps={{ shrink: !!values.Wells.find((w: Well) => w?.Position === well) }}
                            inputRef={(el) => inputRefs.current[index] = el}
                            size="small"
                            color="success"
                            sx={{ background: values.Wells.find((w: Well) => w?.Position === well) ? "rgba(0, 128, 0, 0.4)" : null }}
                            variant="filled"
                            onChange={(v) => {
                                let w = values.Wells;
                                // @ts-ignore
                                w[index] = { Position: well, SampleBarcode: v.target.value };
                                setValues({ ...values, Wells: w })
                            }}
                            // @ts-ignore
                            value={values.Wells.find((w: Well) => w?.Position === well)?.SampleBarcode || ""}
                            name={well}
                            label={well}
                        />
                    </Grid>
                );
            })}
            <Grid item xs={12}>
                <ButtonTray>
                    <></>
                    <Button variant="outlined" color="primary" onClick={() => onSave()}>Back</Button>
                    <Button variant="contained" color="primary" onClick={formik.submitForm}>Save</Button>
                </ButtonTray>
            </Grid>
        </Grid>
    )
}



export default ScanPlate;
