export async function getOrderMetrics() {
    return fetch(`${process.env.REACT_APP_API_PROXY}/v1/metrics/orders` , {
        method: "GET",
        credentials: "include"
    });
}
export async function getSampleMetrics() {
    return fetch(`${process.env.REACT_APP_API_PROXY}/v1/metrics/samples` , {
        method: "GET",
        credentials: "include"
    });
}
export async function getResultMetrics() {
    return fetch(`${process.env.REACT_APP_API_PROXY}/v1/metrics/results` , {
        method: "GET",
        credentials: "include"
    });
}
