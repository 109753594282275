import { DataGridPro, GridRowId, GridRowSelectionModel, GridToolbar } from "@mui/x-data-grid-pro";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { FormatDateTime, FormatDateUTC } from "../services/util";
import { apiFetch } from '../services/fetch';
import ErrorAlert from "./ErrorAlert";
import TableFrame from "./TableFrame";
import { DataTableContainer, MiniTableContainer } from "./TableContainer";
import ButtonTray from "./ButtonTray";
import { Button, Box, Tabs, Tab, Dialog, DialogContent, Stack, Input, Typography, DialogActions, TextField, MenuItem, Grid, TableContainer, Table, TableBody, TableCell, TableHead, TableRow, IconButton, Tooltip } from "@mui/material";
import TabPanel, { a11yProps } from "../components/TabPanel";
import ScanPlate from "./ScanPlate";
import Framework from "./Framework";
import { LoadingButton } from "@mui/lab";
import SaveIcon from "@mui/icons-material/Save";
import { addTemplateFile } from "../services/samples";
import { extPlateWellsByRow, _384byRow } from "../services/molecular";
import CircleTwoToneIcon from '@mui/icons-material/CircleTwoTone';
import RemoveCircleTwoToneIcon from '@mui/icons-material/RemoveCircleTwoTone';
import RemoveCircleTwoTone from "@mui/icons-material/RemoveCircleTwoTone";
import { RenderOrderButton } from "./ResultsTableStyles";
import CloseIcon from "@mui/icons-material/Close";
import ReceiptIcon from "@mui/icons-material/Receipt";
import { GetSampleStatusChip } from "./StatusChips";

function TemplateTable({ templates, selected, setSelected, setRefresh }: { templates: any, setRefresh: Dispatch<SetStateAction<boolean>>, selected: GridRowId[], setSelected: Dispatch<SetStateAction<GridRowId[]>> }) {

    const columns = [
        { field: "ID", headerName: "ID", width: 45 },
        { field: "Name", headerName: "Name", width: 250 },
        { field: "CreatedAt", headerName: "Created", width: 220, valueGetter: (params: any) => FormatDateTime(params.row?.CreatedAt) },
    ];

    return (
        <DataGridPro
            hideFooterRowCount
            getRowId={(row) => row.ID}
            density="compact"
            sx={{
                "& .MuiDataGrid-columnHeaderCheckbox .MuiDataGrid-columnHeaderTitleContainer":
                {
                    display: "none",
                },
            }}
            components={{ Toolbar: GridToolbar }}
            componentsProps={{
                toolbar: {
                    showQuickFilter: true,
                    quickFilterProps: { debounceMs: 500 },
                },
            }}
            rowSelectionModel={selected}
            onRowSelectionModelChange={setSelected}
            rows={templates}
            columns={columns}
        />
    )
}


function PlateTable({ plates, setRefresh, selected, setSelected }: { plates: any, setRefresh: Dispatch<SetStateAction<boolean>>, setSelected: Dispatch<SetStateAction<GridRowSelectionModel>>, selected: GridRowId[] }) {

    const columns = [
        { field: "ID", headerName: "ID", width: 45 },
        { field: "Name", headerName: "Name", width: 250 },
        { field: "CreatedAt", headerName: "Created", width: 220, valueGetter: (params: any) => FormatDateTime(params.row?.CreatedAt) },
        { field: "UpdatedAt", headerName: "Updated", width: 220, valueGetter: (params: any) => FormatDateTime(params.row?.UpdatedAt) },
        {
            field: "Samples",
            headerName: "Samples",
            width: 150,
            valueGetter: (row: any) => row.row.Wells.length,
        },
        { field: "CreatedBy", headerName: "Created By", width: 220, valueGetter: (params: any) => params.row?.CreatedBy?.Email },
    ];

    return (
        <DataGridPro
            hideFooterRowCount
            getRowId={(row) => row.ID}
            density="compact"
            sx={{
                "& .MuiDataGrid-columnHeaderCheckbox .MuiDataGrid-columnHeaderTitleContainer":
                {
                    display: "none",
                },
            }}
            initialState={{
                sorting: {
                    sortModel: [{ field: "ID", sort: "desc" }],
                },
            }}
            components={{ Toolbar: GridToolbar }}
            componentsProps={{
                toolbar: {
                    showQuickFilter: true,
                    quickFilterProps: { debounceMs: 500 },
                },
            }}
            rowSelectionModel={selected}
            onRowSelectionModelChange={setSelected}
            rows={plates}
            columns={columns}
        />
    )
}

function Plates() {
    const [plates, setPlates] = useState([]);
    const [refresh, setRefresh] = useState(false);
    const [error, setError] = useState("");
    const [view, setView] = useState("table");
    const [selected, setSelected] = useState<GridRowId[]>([]);

    useEffect(() => {
        const init = async () => {
            try {
                const response = await apiFetch("/plates");
                // @ts-ignore
                setPlates(response);
                setError("")
            } catch (e) {
                console.error(e);
                setError("Error loading plates")
            }
        }

        init()
    }, [refresh]);

    return (
        <TableFrame>
            {view === "create" && <ScanPlate onSave={() => { setView("table"); setRefresh((ref) => !ref) }} />}
            {view === "edit" && <ScanPlate onSave={() => { setView("table"); setRefresh((ref) => !ref) }} plateId={Number(selected[0])} />}
            {view === "view" && <ViewPlate onBack={() => { setView("table") }} plateId={Number(selected[0])} />}
            {view === "table" && (
                <>
                    <MiniTableContainer>
                        <PlateTable plates={plates} setRefresh={setRefresh} selected={selected} setSelected={setSelected} />
                    </MiniTableContainer>
                    <ErrorAlert error={error} />
                    <ButtonTray>
                        <Button variant="contained" color="primary" onClick={() => setView("create")}>Create</Button>
                        <Button variant="contained" color="secondary" sx={{ color: "white" }} onClick={() => setView("edit")} disabled={selected.length !== 1}>Edit</Button>
                        <Button variant="contained" color="success" onClick={() => setView("view")}>View</Button>
                    </ButtonTray>
                </>
            )}
        </TableFrame>

    )

}

function ViewPlate({ plateId, onBack }: { plateId: number, onBack: () => void }) {
    const [plate, setPlate] = useState<any>({
        Type: "96",
        Wells: [],
    });
    const [sample, setSample] = useState<any>(null)
    const [error, setError] = useState("");

    const wellMap = plate.Type === "96" ? extPlateWellsByRow : _384byRow;

    useEffect(() => {
        if (plateId) {
            (async () => {
                try {
                    const response = await apiFetch("/plates/" + plateId)
                    // @ts-ignore
                    setPlate(response)
                } catch (e) {
                    console.error(e)
                }
            })()
        }

    }, [plateId])

    const findWell = (position: string) => {
        const w = plate.Wells.find((w: any) => w?.Position === position)
        if (w) {
            return w.SampleBarcode
        }
        return ""
    }

    const loadSampleByWell = async (well: string) => {
        const barcode = findWell(well)
        try {
            const response = await apiFetch("/samples/find?barcode=" + barcode)
            setSample(response)
        } catch (e) {
            console.error(e)
            setError("Failed to find sample: " + barcode)
        }

    }

    const RenderOrderButton = ({ OrderID }: { OrderID: number }) => {
        return (
            <>
                <IconButton
                    href={`/orders/${OrderID}`}
                    target="_blank"
                >
                    <ReceiptIcon
                        sx={{ "&:hover": { color: "black" } }}
                        color="primary"
                    /></IconButton>
            </>
        );
    };
    return (
        <>
            <Grid container spacing={0} columnSpacing={0} columns={plate?.Type === "384" ? 25 : 13} justifyContent="center" alignItems="center" display="flex">
                {wellMap.map((well, index) => {
                    // if the well is A1, B1, C1, etc, then add a column with the letter
                    return (
                        <>
                            {index === 0 && plate.Type === "96" && (
                                [...Array(13).keys()].map((i) => (
                                    <Grid key={well + index + "header"} item xs={1} display="flex" justifyContent="center">
                                        {i > 0 && i}
                                    </Grid>
                                ))
                            )}
                            {well.match(/^[A-Z]1$/) && (
                                <Grid key={well + index} item xs={1} display="flex" justifyContent="right" width="20px">
                                    {well[0]}
                                </Grid>
                            )}
                            <Grid key={well} item xs={1} display="flex" justifyContent="center">
                                <Tooltip title={well + ": " + findWell(well)} placement="top-start">
                                    {findWell(well) !== "" ?
                                        <CircleTwoToneIcon color="primary" onClick={() => loadSampleByWell(well)} /> : <RemoveCircleTwoTone color="disabled" />
                                    }
                                </Tooltip>
                            </Grid>
                        </>
                    )
                })}
            </Grid>
            <ErrorAlert error={error} />

            {sample && (
                <TableContainer>
                    <Table>
                        <TableHead>
                            <TableCell size="small" sx={{ padding: "0" }}></TableCell>
                            <TableCell>Barcode</TableCell>
                            <TableCell>Status</TableCell>
                            <TableCell>Patient Last</TableCell>
                            <TableCell>Patient First</TableCell>
                            <TableCell>Patient DOB</TableCell>
                            <TableCell>Facility</TableCell>
                        </TableHead>
                        <TableBody>
                            {[sample].slice().reverse().map((sample, index) => (
                                <TableRow key={index}>
                                    <TableCell size="small" sx={{ padding: "0" }}>
                                        <Stack direction="row" spacing={0}>
                                            <RenderOrderButton OrderID={sample.OrderID} />
                                        </Stack>
                                    </TableCell>
                                    <TableCell style={{ whiteSpace: "nowrap" }}>{sample.Barcode}</TableCell>
                                    <TableCell style={{ whiteSpace: "nowrap" }}>{GetSampleStatusChip(sample.Status)}</TableCell>
                                    <TableCell style={{ whiteSpace: "nowrap" }}>{sample.Order.Patient.LastName}</TableCell>
                                    <TableCell style={{ whiteSpace: "nowrap" }}>{sample.Order.Patient.FirstName}</TableCell>
                                    <TableCell style={{ whiteSpace: "nowrap" }}>{FormatDateUTC(sample.Order.Patient.DOB)}</TableCell>
                                    <TableCell style={{ whiteSpace: "nowrap" }}>{sample.Order.Facility.Name}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            )}
            <Button variant="contained" color="primary" onClick={onBack}>Back</Button>

        </>
    )

}

function GenerateTemplateDialog({ open, setOpen, onClose }: { open: boolean, setOpen: Dispatch<SetStateAction<boolean>>, onClose: () => void }) {
    const [processing, setProcessing] = useState(false);
    const [plates, setPlates] = useState<any>([]);
    const [templates, setTemplates] = useState<any>([]);
    const [error, setError] = useState("");
    const [refresh, setRefresh] = useState(false);

    const values = {
        name: "",
        templateId: "",
        plate1: "",
        plate2: "",
        plate3: "",
        plate4: "",
    }

    const validationSchema = Yup.object().shape({
        name: Yup.string().min(1).required(),
        templateId: Yup.number().required(),
    })

    useEffect(() => {
        const init = async () => {
            try {
                let response = await apiFetch("/plates");
                // @ts-ignore
                setPlates(response);
                response = await apiFetch("/plates/templates");
                setTemplates(response);
                setError("")

            } catch (e) {
                console.error(e);
                setError("Error loading plates")
            }
        }

        if (open) {
            init()
        }
    }, [open, refresh]);

    const handleSubmit = async (values: any) => {
        try {
            const template = templates.find((t: any) => t.ID === values.templateId);
            const templateExtension = template.Attachment.Name.split('.').pop();
            const filename = values.name + "." + templateExtension;

            setProcessing(true);
            if (values.plate1 === "") {
                values.plate1 = null;
            }
            if (values.plate2 === "") {
                values.plate2 = null;
            }
            if (values.plate3 === "") {
                values.plate3 = null;
            }
            if (values.plate4 === "") {
                values.plate4 = null;
            }
            const data = await apiFetch("/plates/generate", "POST", values)
            const url = window.URL.createObjectURL(data);
            const link = document.createElement("a");
            link.href = url;
            link.download = filename;
            link.click();
            // It's necessary to revoke the object URL to avoid memory leaks
            window.URL.revokeObjectURL(url);
        } catch (e) {
            console.error(e);
        } finally {
            setProcessing(false);
        }
    }

    return (
        <Dialog open={open}>
            <Formik
                initialValues={values}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
                enableReinitialize
            >
                {({
                    values,
                    handleChange,
                    handleBlur,
                    errors,
                    setFieldValue,
                }) => (
                    <Form>
                        { /* @ts-ignore */}
                        <DialogContent>
                            <Stack direction="column" spacing={2}>
                                <TextField
                                    required
                                    select
                                    size="small"
                                    value={values?.templateId}
                                    name="templateId"
                                    label="Template"
                                    sx={{ minWidth: "200px" }}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                >
                                    {templates.map((value: any, index: number) => (
                                        <MenuItem key={index} value={value.ID}>
                                            {value.Name}
                                        </MenuItem>
                                    ))}
                                </TextField>
                                <TextField
                                    required
                                    size="small"
                                    value={values?.name}
                                    name="name"
                                    label="Run Name"
                                    sx={{ minWidth: "200px" }}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                />
                                <TextField
                                    select
                                    size="small"
                                    value={values?.plate1}
                                    name="plate1"
                                    label="Plate 1"
                                    sx={{ minWidth: "200px" }}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                >
                                    {plates.map((value: any, index: number) => (
                                        <MenuItem key={index} value={value.ID}>
                                            {value.Name}
                                        </MenuItem>
                                    ))}
                                </TextField>
                                <TextField
                                    select
                                    size="small"
                                    value={values?.plate2}
                                    name="plate2"
                                    label="Plate 2"
                                    sx={{ minWidth: "200px" }}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                >
                                    {plates.map((value: any, index: number) => (
                                        <MenuItem key={index} value={value.ID}>
                                            {value.Name}
                                        </MenuItem>
                                    ))}
                                </TextField>
                                <TextField
                                    select
                                    size="small"
                                    value={values?.plate3}
                                    name="plate3"
                                    label="Plate 3"
                                    sx={{ minWidth: "200px" }}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                >
                                    {plates.map((value: any, index: number) => (
                                        <MenuItem key={index} value={value.ID}>
                                            {value.Name}
                                        </MenuItem>
                                    ))}
                                </TextField>
                                <TextField
                                    select
                                    size="small"
                                    value={values?.plate4}
                                    name="plate4"
                                    label="Plate 4"
                                    sx={{ minWidth: "200px" }}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                >
                                    {plates.map((value: any, index: number) => (
                                        <MenuItem key={index} value={value.ID}>
                                            {value.Name}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </Stack>
                        </DialogContent>
                        <DialogActions>
                            <Button
                                color="primary"
                                variant="contained"
                                disabled={processing}
                                onClick={() => {
                                    setOpen(false);
                                }}
                            >
                                Cancel
                            </Button>
                            <LoadingButton
                                startIcon={<SaveIcon />}
                                color="success"
                                variant="contained"
                                loading={processing}
                                disabled={processing}
                                type="submit"
                            >
                                <span>Generate</span>
                            </LoadingButton>
                        </DialogActions>
                    </Form>
                )}
            </Formik>
        </Dialog>
    )
}

function TemplateUpload({ open, setOpen, onClose }: { open: boolean, setOpen: Dispatch<SetStateAction<boolean>>, onClose: () => void }) {
    const [values, setValues] = useState<any>({
        Name: "",
        File: null,
    });
    const [processing, setProcessing] = useState(false);

    const validationSchema = Yup.object().shape({
        File: Yup.mixed().required(),
        Name: Yup.string().min(1).required(),
    })

    const handleSubmit = async (values: any) => {
        setProcessing(true);
        try {
            // @ts-ignore
            await addTemplateFile(values.File, values.Name);
            setOpen(false);
            onClose();
        } catch (e) {
            console.error(e);
        } finally {
            setProcessing(false);
        }
    }

    return (
        <Dialog open={open}>
            <Formik
                initialValues={values}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
                enableReinitialize
            >
                {({
                    values,
                    handleChange,
                    handleBlur,
                    errors,
                    setFieldValue,
                }) => (
                    <Form>
                        { /* @ts-ignore */}
                        <DialogContent>
                            {console.log(errors)}
                            <Stack direction="column" spacing={2}>
                                <Typography variant="body1">Select export template to upload.</Typography>
                                <TextField
                                    required
                                    size="small"
                                    value={values?.Name}
                                    name="Name"
                                    label="Name"
                                    sx={{ minWidth: "200px" }}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                />
                                <Input
                                    required
                                    type="file"
                                    inputProps={{ accept: [".csv", ".txt", ".tsv", "text/csv", "text/plain"] }}
                                    onChange={(e) => {
                                        // @ts-ignore
                                        setFieldValue("File", e.target.files[0]);
                                    }}
                                />
                            </Stack>
                        </DialogContent>
                        <DialogActions>
                            <Button
                                color="primary"
                                variant="contained"
                                disabled={processing}
                                onClick={() => {
                                    setOpen(false);
                                }}
                            >
                                Cancel
                            </Button>
                            <LoadingButton
                                startIcon={<SaveIcon />}
                                color="success"
                                variant="contained"
                                loading={processing}
                                disabled={processing}
                                type="submit"
                            >
                                <span>Save</span>
                            </LoadingButton>
                        </DialogActions>
                    </Form>
                )}
            </Formik>
        </Dialog>

    )
}

function Templates() {
    const [templates, setPlates] = useState([]);
    const [refresh, setRefresh] = useState(false);
    const [error, setError] = useState("");
    const [upload, setUpload] = useState(false);
    const [selected, setSelected] = useState<GridRowId[]>([]);

    useEffect(() => {
        const init = async () => {
            try {
                const response = await apiFetch("/plates/templates");
                // @ts-ignore
                setPlates(response);
            } catch (e) {
                console.error(e);
                setError("Error loading templates")
            }
        }

        init()
    }, [refresh]);

    const del = async () => {
        try {
            await apiFetch("/plates/templates/" + selected[0], "DELETE");
            setRefresh((ref) => !ref);
            setError("")
        } catch (e) {
            console.error(e);
            setError("Error deleting templates")
        }

    }

    return (
        <TableFrame>
            <TemplateUpload open={upload} setOpen={setUpload} onClose={() => setRefresh((ref) => !ref)} />
            <MiniTableContainer>
                <TemplateTable templates={templates} setRefresh={setRefresh} selected={selected} setSelected={setSelected} />
            </MiniTableContainer>
            <ErrorAlert error={error} />
            <ButtonTray>
                <Button variant="contained" color="primary" onClick={() => setUpload(true)}>Upload</Button>
                <Button variant="contained" color="error" onClick={() => del()}
                    disabled={selected.length === 0}
                >Delete</Button>
            </ButtonTray>
        </TableFrame>

    )

}

function PlateSetup() {
    const [tab, setTab] = useState(0);
    const [generate, setGenerate] = useState(false);

    const tabs = [
        { label: "Plates", content: <Plates /> },
        { label: "Templates", content: <Templates /> },
    ]

    return (
        <>
            <GenerateTemplateDialog open={generate} setOpen={setGenerate} onClose={() => setTab(0)} />
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <Stack direction="row" spacing={2} justifyContent="space-between">
                    <Tabs
                        value={tab}
                        onChange={(_, t) => setTab(t)}
                        aria-label="basic tabs example"
                        variant="scrollable"
                    >
                        {tabs.map((tab, index) => <Tab label={tab.label} key={index} {...a11yProps(index)} />)}
                    </Tabs>
                    <Button variant="contained" color="primary"
                        onClick={() => setGenerate(true)}
                        sx={{ maxHeight: "35px", minWidth: "145px" }}
                        size="small"
                    >Generate Run</Button>
                </Stack>
            </Box>
            {tabs.map((t, index) => <TabPanel key={index} value={tab} index={index}>{t.content}</TabPanel>)}
        </>
    )


}

export default PlateSetup;
