export async function getPendingResults() {
    return fetch(
        `${process.env.REACT_APP_API_PROXY}/v1/results?filter=pending`,
        { credentials: "include" }
    ).then((data) => data.json());
}

export async function getValidatedResults() {
    return fetch(
        `${process.env.REACT_APP_API_PROXY}/v1/results?filter=validated`,
        { credentials: "include" }
    ).then((data) => data.json());
}

export async function getTNPResults() {
    return fetch(
        `${process.env.REACT_APP_API_PROXY}/v1/results?filter=tnp`,
        { credentials: "include" }
    ).then((data) => data.json());
}


export async function getPendingApprovalResults() {
    return fetch(
        `${process.env.REACT_APP_API_PROXY}/v1/results?filter=pending_approval`,
        { credentials: "include" }
    ).then((data) => data.json());
}

export async function getWaitingOnSampleResults() {
    return fetch(
        `${process.env.REACT_APP_API_PROXY}/v1/results?filter=waiting_on_sample`,
        { credentials: "include" }
    ).then((data) => data.json());
}

export async function saveResults(results, sampleID) {
    console.log(results);
    return fetch(
        `${process.env.REACT_APP_API_PROXY}/v1/samples/${sampleID}/results`,
        {
            method: "POST",
            credentials: "include",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify(results),
        }
    );
}
export async function saveResult(result, sampleID, resultID) {
    return fetch(
        `${process.env.REACT_APP_API_PROXY}/v1/samples/` +
        sampleID +
        "/results/" +
        resultID,
        {
            method: "POST",
            credentials: "include",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify(result),
        }
    );
}

export async function getResultDetails(sampleID) {
    return fetch(
        `${process.env.REACT_APP_API_PROXY}/v1/sample/` + sampleID + "/results",
        { credentials: "include" }
    ).then((data) => data.json());
}

export async function getPatientResult(id) {
    return fetch(`${process.env.REACT_APP_API_PROXY}/user/result?id=${id}`, {
        credentials: "include",
    });
}

export async function getPatientResultReport(id, patientData) {
    return fetch(`${process.env.REACT_APP_API_PROXY}/user/result?id=${id}`, {
        method: "POST",
        credentials: "include",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
        },
        body: JSON.stringify(patientData),
    });
}
