import React, { useState, useEffect } from "react";
import ProvidersTable from "../components/ProvidersTable";
import { Button, Stack } from "@mui/material";
import ProviderInput from "../components/ProviderInput";
import ButtonTray from "./ButtonTray";
import TableFrame from "./TableFrame";
import { DataTableContainer } from "./TableContainer";
import { apiFetch } from "../services/fetch";

export default function ProviderSetup() {
    const initValues = {
        FirstName: "",
        LastName: "",
        NPI: "",
        SignatureOnFile: false,
        Type: "",
        Facilities: [],
        Contacts: [],
    }

    const [provider, setProvider] = useState({});
    const [editMode, setEditMode] = useState(false);
    const [createMode, setCreateMode] = useState(false);
    const [refresh, setRefresh] = useState(false);
    const [initialValues, setInitialValues] = useState(initValues);

    console.log(provider)

    useEffect(() => {
        let ignore = false;

        if (createMode && !ignore) {
            setInitialValues(initValues)
            return
        }

        if (editMode && !ignore) {
            let newProv = { ...provider }

            let contacts = [];
            for (let p of provider.Contacts) {
                contacts.push(p.ID);
            }
            newProv.Contacts = contacts

            let facilities = [];
            for (let p of provider.Facilities) {
                facilities.push(p.ID);
            }
            newProv.Facilities = facilities
            setInitialValues(newProv)
            return
        }

        return () => { ignore = true }

    }, [createMode, editMode, provider]);

    const toggleEnabled = async (enabled) => {
        try {
            let req = ["/providers/" + provider.ID, "PUT", {}]
            if (!enabled) {
                req = ["/providers/" + provider.ID, "DELETE", {}]
            }

            await apiFetch(req[0], req[1], {})
            setProvider({ ...provider, Enabled: enabled })
            setRefresh((ref) => !ref)
        } catch (err) {
            console.log(err)
        }


    }

    const reset = () => {
        setEditMode(false);
        setCreateMode(false);
        setProvider({});
        setRefresh(!refresh);
    };


    if (editMode || createMode) {
        return (
            <ProviderInput
                initialValues={initialValues}
                setProvider={setProvider}
                reset={reset}
            />
        );
    }

    return (
        <>
            <TableFrame>
                <DataTableContainer>
                    <ProvidersTable
                        refresh={refresh}
                        Title=""
                        setProvider={setProvider}
                        setProviders={() => { }}
                    />
                </DataTableContainer>
                <ButtonTray>
                    <Button
                        variant="contained"
                        color="success"
                        onClick={() => setCreateMode(true)}
                    >
                        Create
                    </Button>
                    <Button
                        variant="contained"
                        color="primary"
                        // disabled={
                        // Object.keys(provider).length === 0
                        // }
                        onClick={() => setEditMode(true)}
                    >
                        Edit
                    </Button>
                    {provider?.Enabled ? (
                        <Button
                            variant="contained"
                            onClick={() => toggleEnabled(false)}
                            // disabled={
                            // Object.keys(selectedProvider).length === 0
                            // }
                            color="error"
                        >
                            Deactivate
                        </Button>) : (
                        <Button
                            disabled={!provider?.ID}
                            variant="contained"
                            onClick={() => toggleEnabled(true)}
                            color="success"
                        >
                            Activate
                        </Button>)}
                </ButtonTray>
            </TableFrame>
        </>
    );
}
