import React, { useEffect, useState } from "react";
import pLimit from 'p-limit';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    FormControlLabel,
    FormLabel,
    MenuItem,
    Radio,
    RadioGroup,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
    Typography,
} from "@mui/material";
import * as Yup from "yup";
import { Form, Formik } from "formik";
import { LoadingButton } from "@mui/lab";
import SaveIcon from "@mui/icons-material/Save";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import NotInterestedIcon from "@mui/icons-material/NotInterested";
import { Laboratory } from "../constants/types";
import { getLaboratories } from "../services/tests";
import { apiFetch } from "../services/fetch";
import { addBulkOrderResultAttachment } from "../services/orders";

type BulkResultUploadProps = {
    open: boolean;
    setOpen: (open: boolean) => void;
}

type UploadSideEffect = "AttachOnly" | "AttachAndValidate" | "AttachAndReject";

type BulkResultRequest = {
    OrderID: number;
    Action: UploadSideEffect;
    LaboratoryID: number | null;
    File: File | null;
}

type StatusMap = {
    [key: string]: string;
}

function BulkResultUpload({ open, setOpen }: BulkResultUploadProps) {
    const [processing, setProcessing] = useState(false);
    const [complete, setComplete] = useState(false);
    const [errorMap, setErrorMap] = useState<StatusMap>({})
    const [inProcessMap, setInProcessMap] = useState<StatusMap>({})
    const [successMap, setSuccessMap] = useState<StatusMap>({})
    const [allLaboratories, setAllLaboratories] = useState<Laboratory[]>([])

    useEffect(() => {
        getLaboratories()
            .then((p) => {
                if (!p.ok) {
                    throw new Error("Failed to load laboratories");
                }
                return p.json();
            })
            .then((p: Laboratory[]) => {
                p = p.filter((lab: Laboratory) => lab.Reference);
                setAllLaboratories(p)
            });

    }, []);

    const createResultAttachment = async (req: BulkResultRequest) => {
        return addBulkOrderResultAttachment(req.OrderID, req.File, req.LaboratoryID, req.Action)
    }

    const saveResultFilesWithLimit = async (requests: BulkResultRequest[], max_concurrent_requests: number) => {
        console.log("saving results with limit", max_concurrent_requests)
        const limit = pLimit(max_concurrent_requests)

        const limitedPromises = requests.map(req => {
            /* @ts-ignore */
            return limit(() => {
                /* @ts-ignore */
                setInProcessMap((prev) => { return { ...prev, [req.File.name]: "Uploading" } });
                return createResultAttachment(req).then((response) => {
                    if (!response.ok && req.File !== null) {
                        // @ts-ignore
                        setErrorMap((prev) => { return { ...prev, [req.File.name]: "Failed to save result." } })
                    } else {
                        // @ts-ignore
                        setSuccessMap((prev) => { return { ...prev, [req.File.name]: "Success" } })
                    }
                })
            });
        });

        try {
            await Promise.allSettled(limitedPromises);

        } catch (error: any) {
            console.log(error)
        } finally {
            setProcessing(false)
            setComplete(true)
        }
    }

    const validationSchema = Yup.object().shape({
        Files: Yup.mixed(),
        Action: Yup.string().oneOf(["AttachOnly", "AttachAndValidate", "AttachAndReject"]),
        LaboratoryID: Yup.number().required("Laboratory is required")
    })

    type ResultUploadValues = {
        Files: FileList | null;
        Action: UploadSideEffect | null;
        LaboratoryID: number | null;
    }

    const initialValues: ResultUploadValues = {
        Files: null,
        Action: "AttachAndValidate",
        LaboratoryID: null,
    }

    const handleSubmit = (values: any) => {
        setProcessing(true)
        console.log(values);
        const requests = []
        for (let i = 0; i < values.Files.length; i++) {
            const file = values.Files.item(i);
            if (!file || !isValidPdfFileName(file.name)) { continue; }
            requests.push({
                OrderID: parseInt(file.name.split(".")[0]),
                Action: values.Action,
                LaboratoryID: values.LaboratoryID,
                File: file
            })
        }

        saveResultFilesWithLimit(requests, 5)
            .then(() => {
                // setProcessing(false)
                // setOpen(false)
            })
    };

    const getFileStatus = (file: File) => {
        if (!file || !isValidPdfFileName(file.name)) { return "Invalid" }

        if (errorMap[file.name]) {
            return <b>Failed</b>
        }

        if (successMap[file.name]) {
            return "Success"
        }

        if (inProcessMap[file.name]) {
            return "Uploading"
        }
        return !processing ? "Pending" : "Queued"
    }

    console.log(errorMap);

    const getFileTable = (values: ResultUploadValues) => {
        if (!values.Files) { return <></> }

        const tableRows = []
        for (let i = 0; i < values.Files.length; i++) {
            const file = values.Files.item(i);
            if (!file) { continue; }

            tableRows.push(
                <TableRow key={file.name}>
                    <TableCell>{file.name}</TableCell>
                    <TableCell>{getFileStatus(file)}</TableCell>
                    <TableCell>{isValidPdfFileName(file.name) ? <CheckCircleIcon color="success" /> : <NotInterestedIcon color="error" />}</TableCell>
                </TableRow>)
        }

        return tableRows;
    }

    function isValidPdfFileName(fileName: string) {
        const regex = /^\d+\.pdf$/i;
        return regex.test(fileName);
    }


    return (
        <Dialog open={open}>
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
                enableReinitialize
            >
                {({
                    values,
                    touched,
                    errors,
                    setFieldValue,
                    resetForm,
                }) => {
                    console.log('errors', errors); return (
                        <Form>
                            <DialogTitle>Bulk Result Upload</DialogTitle>
                            <DialogContent>
                                <Stack direction="column" spacing={2}>
                                    <Typography variant="body1">Select the result PDFs you would like to upload and the action to be performed on each order.</Typography>
                                    <Typography variant="caption">The file name <b>must</b> be in the format of <b>[Order Number].pdf</b>.</Typography>
                                    <Stack direction="row" spacing={2}>
                                        <input
                                            multiple
                                            required
                                            type="file"
                                            accept=".pdf"
                                            onChange={(e) => {
                                                console.log(e);
                                                // @ts-ignore
                                                setFieldValue("Files", e.target.files);
                                            }}
                                        />
                                    </Stack>
                                    <FormControl
                                    >
                                        <FormLabel id="demo-radio-buttons-group-label">Upload Action</FormLabel>
                                        <RadioGroup
                                            aria-labelledby="demo-radio-buttons-group-label"
                                            defaultValue="AttachAndValidate"
                                            name="radio-buttons-group"
                                            onChange={(e) => { setFieldValue("Action", e.target.value) }}
                                        >
                                            <FormControlLabel value="AttachAndValidate" control={<Radio />} label="Attach and validate results for selected laboratory." />
                                            <FormControlLabel value="AttachAndReject" control={<Radio />} label="Attach and reject results for selected laboratory." />
                                            <FormControlLabel value="AttachOnly" control={<Radio />} label="Attach only." />
                                        </RadioGroup>
                                    </FormControl>
                                    <FormControl sx={{ minWidth: 200, m: 1 }} size="small">
                                        <TextField
                                            size="small"
                                            select
                                            required
                                            label="Laboratory"
                                            error={
                                                touched.LaboratoryID &&
                                                Boolean(errors.LaboratoryID)
                                            }
                                            helperText={
                                                touched.LaboratoryID && errors.LaboratoryID
                                            }
                                            value={
                                                values.LaboratoryID === undefined
                                                    ? ""
                                                    : values.LaboratoryID
                                            }
                                            name="LaboratoryID"
                                            key="Laboratory"
                                            onChange={(v) =>
                                                setFieldValue('LaboratoryID', v.target.value)
                                            }
                                        >
                                            {allLaboratories.map((option) => (
                                                <MenuItem key={option.ID} value={option.ID}>
                                                    {option.Name}
                                                </MenuItem>
                                            ))}
                                        </TextField>
                                    </FormControl>
                                    {values?.Files &&
                                        <TableContainer>
                                            <Table>
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell>File</TableCell>
                                                        <TableCell>Upload Status</TableCell>
                                                        <TableCell>Valid Filename</TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {getFileTable(values)}
                                                </TableBody>
                                            </Table>
                                        </TableContainer>}
                                </Stack>
                            </DialogContent>
                            <DialogActions>
                                <Button
                                    color="primary"
                                    variant="contained"
                                    disabled={processing}
                                    onClick={() => {
                                        setOpen(false);
                                        setComplete(false);
                                        setInProcessMap({});
                                        setErrorMap({});
                                        setSuccessMap({});
                                        resetForm();
                                    }}
                                >
                                    {complete ? "Close" : "Cancel"}
                                </Button>
                                {!complete &&
                                    <LoadingButton
                                        startIcon={<SaveIcon />}
                                        color="success"
                                        variant="contained"
                                        loading={processing}
                                        disabled={processing}
                                        type="submit"
                                    >
                                        <span>Begin Upload</span>
                                    </LoadingButton>}
                            </DialogActions>
                        </Form>
                    )
                }}
            </Formik>
        </Dialog>
    )
}
export default BulkResultUpload;
