import React, { useState } from "react";
import { Box } from "@mui/system";
import { DataGridPro } from "@mui/x-data-grid-pro";
import { GridToolbar } from "@mui/x-data-grid-pro";
import { useFetch, apiFetch } from "../services/fetch";
import { Alert, Button, Stack } from "@mui/material";
import { useNavigate } from "react-router";
import LoadingButton from "@mui/lab/LoadingButton";
import { FormatDate, paymentMethodIndex } from "../services/util";
import { GetOrderStatusChips } from "./StatusChips";
import { FetchLoader } from "./FetchLoader";
import ReceiptIcon from "@mui/icons-material/Receipt";

export default function PatientOrdersTable({ patient }) {
    const navigate = useNavigate();
    const [resending, setResending] = useState([]);
    const [resendingErrors, setResendingErrors] = useState([]);
    const { data, error, isLoading } = useFetch(
        `/patients/${patient}/orders`
    );

    if (error !== null || isLoading) {
        return <FetchLoader error={error} isLoading={isLoading} />
    }

    const resend = (orderId) => {
        setResending([...resending, orderId]);
        apiFetch("/notifications/", "POST", { OrderID: orderId })
            .catch(() => setResendingErrors([...resendingErrors, orderId]))
            .finally(() =>
                setResending(resending.filter((item) => item !== orderId))
            );
    };

    const renderResendButton = (params) => {
        return (
            <strong>
                <LoadingButton
                    variant="contained"
                    loading={resending.includes(params.id)}
                    disabled={resending.includes(params.id) || resendingErrors.includes(params.id)}
                    error={resendingErrors.includes(params.id)}
                    loadingIndicator="Resending..."
                    color="secondary"
                    size="small"
                    sx={{ color: "white" }}
                    // style={{ marginLeft: 16 }}
                    onClick={() => {
                        resend(params.id);
                    }}
                >
                    Resend Notification
                </LoadingButton>
            </strong>
        );
    };
    const renderDetailsButton = (params) => {
        return (
            <strong>
                <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    // style={{ marginLeft: 16 }}
                    onClick={() => {
                        navigate("/orders/" + params.id);
                    }}
                >
                    Details
                </Button>
            </strong>
        );
    };

    const columns = [
        { field: "ID", headerName: "ID", width: 20 },
        {
            field: "CreatedAt",
            headerName: "Date Submitted",
            width: 150,
            valueGetter: (row) => FormatDate(row.row.CreatedAt),
        },
        {
            field: "Status",
            headerName: "Status",
            width: 225,
            renderCell: (params) => <GetOrderStatusChips order={params.row} />,
        },
        {
            field: "PaymentMethod",
            headerName: "PaymentMethod",
            width: 150,
            valueGetter: (row) => paymentMethodIndex[row.row.PaymentMethod],
        },
        {
            field: "View",
            headerName: "View",
            width: 110,
            renderCell: (row) => renderDetailsButton(row),
        },
        {
            field: "Resend",
            headerName: "Resend Notification",
            width: 220,
            renderCell: (row) => renderResendButton(row),
        },
    ];

    return (
        <React.Fragment>
            <Stack direction="column" spacing={2}>
                <Box width="100%">
                    <DataGridPro
                        loading={isLoading}
                        disableColumnFilter
                        disableColumnSelector
                        disableDensitySelector
                        sx={{
                            "& .MuiDataGrid-columnHeaderCheckbox .MuiDataGrid-columnHeaderTitleContainer":
                            {
                                display: "none",
                            },
                            height: "500px",
                        }}
                        getRowId={(row) => row.ID}
                        density="compact"
                        rows={data || []}
                        pagination
                        initialState={{
                            sorting: {
                                sortModel: [{ field: "ID", sort: "desc" }],
                            },
                        }}
                        columns={columns}
                        componentsProps={{
                            toolbar: {
                                showQuickFilter: true,
                                quickFilterProps: { debounceMs: 500 },
                            },
                        }}
                        components={{
                            Toolbar: GridToolbar,
                        }}
                    />
                </Box>
                {error !== null ? (
                    <Alert severity="error">{error}</Alert>
                ) : null}
            </Stack>
        </React.Fragment>
    );
}
