import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Link,
    CircularProgress,
} from "@mui/material";

const MedicationsTable = ({ medications, overflow }) => {
    if (medications === undefined || medications.length === 0) {
        return <></>;
    }
    console.log(medications);

    const getDrugClassNames = (med) => {
        const drugClassNames = med.DrugClasses.map((dC) => dC.Name);
        return drugClassNames.join(", ");
    };

    return (
        <TableContainer>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>RxNorm ID</TableCell>
                        <TableCell>Medication</TableCell>
                        <TableCell>Drug Classes</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {medications.map((med) => (
                        <TableRow key={med.RxNormID}>
                            <TableCell>
                                <Link
                                    href={`https://mor.nlm.nih.gov/RxNav/search?searchBy=RXCUI&searchTerm=${med.RxNormID}`}
                                    target="_blank"
                                >
                                    {med.RxNormID}
                                </Link>
                            </TableCell>
                            <TableCell>{med.Name}</TableCell>
                            <TableCell
                                style={overflow ?{
                                    width: "200px",
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                    whiteSpace: "nowrap",
                                }: {}}
                            >
                                {med?.DrugClasses === undefined ? (
                                    <CircularProgress />
                                ) : (
                                    getDrugClassNames(med)
                                )}
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

export default MedicationsTable;
