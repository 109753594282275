import { useMemo } from "react";
import DataTable from "../components/DataTable";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { financialClassIndex } from "../services/util";

function InsurancesTable({ insurances, selected, setSelected }) {

    const getCheckbox = (value) => {
        if (value.value) {
            return <CheckCircleIcon color="success" />;
        }
        return <CheckCircleIcon color="disabled" />;
    };

    const columns = useMemo(() => [
        { field: "Code", headerName: "Code", width: 100 },
        { field: "Name", headerName: "Name", width: 175 },
        {
            field: "FinancialClass",
            headerName: "Financial Class",
            width: 175,
            valueGetter: (row) => financialClassIndex[row.row.FinancialClass],
        },
        {
            field: "InNetwork",
            headerName: "In Network",
            width: 175,
            renderCell: (row) => getCheckbox(row.row.InNetwork),
        },
        {
            field: "PVerifyCode",
            headerName: "Eligibility Code",
            width: 175,
        },
        { field: "Phone", headerName: "Phone", width: 175 },
    ], []);


    return <DataTable
        selected={selected}
        setSelected={setSelected}
        data={insurances}
        columns={columns}
        checkboxSelection
    />



}

export default InsurancesTable;
