import React from "react";
import { DataGridPro } from "@mui/x-data-grid-pro";
import { GridToolbar } from "@mui/x-data-grid-pro";

function DataTable({ data, selected, setSelected, columns, error, loading, ...props }) {
    return (
        <DataGridPro
            hideFooterRowCount
            error={error}
            loading={loading}
            getRowId={(row) => row.ID}
            density="compact"
            components={{ Toolbar: GridToolbar }}
            componentsProps={{
                toolbar: {
                    showQuickFilter: true,
                    quickFilterProps: { debounceMs: 500 },
                },
            }}
            rowSelectionModel={selected}
            onRowSelectionModelChange={setSelected}
            rows={data || []}
            columns={columns}
            {...props}
        />
    )
}

export default DataTable;
