import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Button,
} from "@mui/material";
import { FormatDateTime } from "../services/util";
import { useState, useEffect } from "react";
import { apiFetch } from "../services/fetch";
import ErrorAlert from "./ErrorAlert";
import { Buffer } from "buffer";
import ModalImage from "react-modal-image";

export default function PatientAttachmentsTable({ patientDetails }) {
    const [viewImage, setImageView] = useState(null);
    const [imageB64, setImageB64] = useState({});
    const [loadingImage, setLoadingImage] = useState(false);
    const [imageError, setImageError] = useState(null);

    const isImage = (row) => {
        return ["image/png", "image/jpeg"].includes(row?.ContentType)
    }

    useEffect(() => {
        if (!viewImage) {
            return
        }

        const getImage = async () => {
            try {
                setLoadingImage(true)
                const data = await apiFetch(`/attachments/${viewImage}`)
                const buf = await data.arrayBuffer()
                console.log(data);
                var b = Buffer.from(buf, 'binary')
                if (data.type === "image/png") {
                    setImageB64({ ...imageB64, [viewImage]: `data:image/png;base64,${b.toString('base64')}` })
                } else if (data.type === "image/jpeg") {
                    setImageB64({ ...imageB64, [viewImage]: `data:image/jpeg;base64,${b.toString('base64')}` })
                }
            } catch (e) {
                console.log(e)
                setImageError("Failed to load image.")
            } finally {
                setLoadingImage(false)
            }
        }

        getImage()

    }, [viewImage])
    const header = () => {
        return (
            <TableHead>
                <TableRow>
                    <TableCell>Name</TableCell>
                    <TableCell>Type</TableCell>
                    <TableCell>Date Added</TableCell>
                    <TableCell>Added By</TableCell>
                </TableRow>
            </TableHead>
        );
    };
    if (patientDetails.Attachments === null) {
        return <Table size="small">{header()}</Table>;
    }
    return (
        <>
            <Table size="small">
                {header()}
                <TableBody>
                    {patientDetails.Attachments.map((row) => {
                        const lnk = "/patients/" + patientDetails.ID + "/attachment/" + row.UUID
                        return (
                            <>
                                <TableRow key={row.ID}>
                                    <TableCell>
                                        {isImage(row) ?
                                            <Button
                                                disabled={loadingImage}
                                                variant="outlined"
                                                onClick={() => {
                                                    if (viewImage !== row?.UUID) {
                                                        setImageView(row?.UUID)
                                                    } else {
                                                        const newB = { ...imageB64 }
                                                        delete newB[row?.UUID]
                                                        setImageView(null); setImageB64({ ...newB })
                                                    }
                                                }}>{Object.getOwnPropertyNames(imageB64).includes(row?.UUID) ? "Hide" : "View"}</Button> :
                                            <a href={lnk}>{row.Name}</a>}
                                    </TableCell>
                                    <TableCell>{row.Type}</TableCell>
                                    <TableCell>{FormatDateTime(row.CreatedAt)}</TableCell>
                                    <TableCell>{row.AddedBy.Email}</TableCell>
                                </TableRow>
                                {Object.getOwnPropertyNames(imageB64).includes(row?.UUID) &&
                                    <TableRow>
                                        <TableCell colSpan={4} >
                                            <ModalImage small={imageB64[row?.UUID]} medium={imageB64[row?.UUID]} showRotate />
                                        </TableCell>
                                    </TableRow>}
                            </>
                        )
                    })}
                </TableBody>
            </Table>
            <ErrorAlert error={imageError} />
        </>
    );
}
