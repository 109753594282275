import PatientInput from "../components/PatientInput";
import { Typography } from "@mui/material";
import Framework from "../components/Framework";
import Stack from "@mui/material/Stack";
import { useParams } from "react-router";
import Box from "@mui/material/Box";

export default function PatientEdit() {
    let { patientID } = useParams();

    if (patientID === undefined) {
        return <></>;
    }

    return (
        <Framework>
            <Box sx={{ m: 2 }}>
                <Stack direction="row" justifyContent="space-between">
                    <Typography variant="h5">Patient</Typography>
                </Stack>
                <PatientInput id={patientID} />
            </Box>
        </Framework>
    );
}
