import { Autocomplete, TextField } from "@mui/material";
import React from "react";
import { SampleStatus, SampleStatuses } from "../../constants/constants";


type SampleStatusPickerProps = {
    status: SampleStatus[] | null;
    setSampleStatus: (status: SampleStatus[] | []) => void;
}

function SampleStatusPicker({ status, setSampleStatus, ...props }: SampleStatusPickerProps) {

    return (
        <Autocomplete
            multiple={true}
            options={SampleStatuses}
            value={status || []}
            isOptionEqualToValue={(option, value) =>
                option === value
            }
            onChange={(_, newValue) => {
                setSampleStatus(newValue)
            }}
            renderInput={(params) => {
                return (
                    <TextField
                        {...params}
                        label="Sample Statuses"
                        size="small"
                        {...props}
                    />
                );
            }}
        />
    );
}

export default SampleStatusPicker;
