import React from 'react';
import { Grid, FormLabel, RadioGroup, FormControlLabel, Radio } from "@mui/material";

import { Answer, AOE } from "../../constants/types";

type AOEsProps = {
    aoes: AOE[],
    answers: Answer[],
    setAnswers: (answers: Answer[]) => void
}


function OrderAOEs({ aoes, answers, setAnswers }: AOEsProps) {
    const handleChange = (aoeId: number, ans: string) => {
        const answer: Answer = {
            ID: aoeId,
            Answer: ans
        }
        const a = [...answers.filter((a) => a.ID !== answer.ID), answer]
        setAnswers(a)
    }

    const findAnswer = (aoeId: number) => {
        const a = answers.find((ans) => ans.ID === aoeId)
        if (a) {
            return a.Answer
        }
        return null
    }

    return (
        <>
            <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>
                {aoes.map((a: AOE, index: number) => (
                    <Grid item xs={4} sm={4} md={4} key={index}>
                        <>
                            <FormLabel>{a?.Question}</FormLabel>
                            <RadioGroup
                                row
                                value={findAnswer(a.ID)}
                                onChange={(_, v) => handleChange(a?.ID, v)}
                            >
                                <FormControlLabel
                                    control={
                                        <Radio />
                                    }
                                    value="True"
                                    label="Yes"
                                    name={String(a?.ID)}
                                />
                                <FormControlLabel
                                    control={
                                        <Radio />
                                    }
                                    value="False"
                                    label="No"
                                    name={String(a?.ID)}
                                />
                            </RadioGroup>
                        </>
                    </Grid>
                ))}
            </Grid>
        </>
    )
}

export default OrderAOEs;
