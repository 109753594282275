import React, { useEffect, useState } from "react";
import { alpha, styled, lighten } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { DataGridPro, gridClasses } from "@mui/x-data-grid-pro";
import { GridToolbar } from "@mui/x-data-grid-pro";
import { Button, Box, stepLabelClasses, Table, Tooltip } from "@mui/material";
import { Stack } from "@mui/system";
import Divider from "@mui/material/Divider";
import NotesDialog from "../components/NotesDialog";
import { updateCritical } from "../services/criticals";
import { FormatDateTime, FormatDate } from "../services/util";
import ConfirmDialog from "../components/ConfirmDialog";
import { useNavigate } from "react-router";
import ReceiptIcon from "@mui/icons-material/Receipt";
import { GetCriticalStatusChip } from "../components/StatusChips";

export default function CriticalsTable({ getCriticals, completed }) {
    const [criticals, setCriticals] = useState([]);
    const [selectedCritical, setSelectedCritical] = useState([]);
    const [view, setView] = useState(false);
    const [refresh, setRefresh] = useState(false);
    const [addNote, setAddNote] = useState(false);
    const [critical, setCritical] = useState();
    const [complete, setComplete] = useState(false);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const navigate = useNavigate();

    const handleAddNote = (note) => {
        setLoading(true);
        updateCritical(critical.ID, {
            Status: "Updated",
            Note: note,
        })
            .then((p) => {
                if (!p.ok) {
                    throw new Error("Failed to save note.");
                }
                setAddNote(false);
                setRefresh(!refresh);
                return p.json();
            })
            .catch((e) => setError(e.message))
            .finally(() => setLoading(false))
    };

    const handleComplete = () => {
        setLoading(true);
        updateCritical(critical.ID, {
            Status: "Completed",
        })
            .then((p) => {
                if (!p.ok) {
                    throw new Error("Failed to complete critical.");
                }
                setComplete(false);
                setRefresh(!refresh);
                return p.json();
            })
            .catch((e) => setError(e.message))
            .finally(() => setLoading(false));
    };

    const renderButtons = (params) => {
        return (
            <Stack
                divider={<Divider flexItem orientation="vertical" />}
                direction="row"
                spacing={1}
            >
                <strong>
                    <Button
                        variant="contained"
                        color="primary"
                        size="small"
                        // style={{ marginLeft: 16 }}
                        onClick={() => {
                            setCritical(params.row);
                            setView(true);
                        }}
                    >
                        View
                    </Button>
                </strong>
                <strong>
                    <Button
                        variant="contained"
                        color="secondary"
                        sx={{ color: "white" }}
                        size="small"
                        // style={{ marginLeft: 16 }}
                        onClick={() => {
                            setCritical(params.row);
                            setAddNote(true);
                        }}
                    >
                        Add Note
                    </Button>
                </strong>
                <strong>
                    <Tooltip
                        title={
                            params.row?.Notes.length === 0
                                ? "You must add a note before completing."
                                : ""
                        }
                    >
                        <span>
                            <Button
                                variant="contained"
                                disabled={
                                    params.row?.Notes.length === 0 ||
                                    params.row?.Status === "Completed"
                                }
                                color="success"
                                size="small"
                                // style={{ marginLeft: 16 }}
                                onClick={() => {
                                    setCritical(params.row);
                                    setComplete(true);
                                }}
                            >
                                Complete
                            </Button>
                        </span>
                    </Tooltip>
                </strong>
            </Stack>
        );
    };

    const renderOrderButton = (params) => {
        return (
            <ReceiptIcon
                sx={{ "&:hover": { color: "black" } }}
                color="primary"
                onClick={() =>
                    navigate("/orders/" + params.row.Result?.OrderID)
                }
            />
        );
    };

    const columns = [
        {
            field: "Action",
            headerName: "Action",
            width: 320,
            renderCell: renderButtons,
        },
        {
            field: "CreatedAt",
            headerName: "Created On",
            width: 120,
            valueGetter: (row) => FormatDateTime(row.row.CreatedAt),
        },
        {
            field: "OrderID",
            headerName: "Order ID",
            width: 105,
        },
        {
            field: "Order",
            headerName: "View Order",
            width: 105,
            renderCell: renderOrderButton,
        },
        { field: "Status", headerName: "Status", width: 120, renderCell: (params) => GetCriticalStatusChip(params.row?.Status) },
        {
            field: "Facility",
            headerName: "Facility",
            width: 100,
            valueGetter: (row) => row.row.Result.Order.Facility.Name,
        },
        {
            field: "Patient Name",
            headerName: "Patient Name",
            width: 150,
            valueGetter: (row) => {
                return (
                    row.row.Result.Order.Patient.FirstName +
                    " " +
                    row.row.Result.Order.Patient.LastName
                );
            },
        },
        {
            field: "DOB",
            headerName: "DOB",
            width: 110,
            valueGetter: (row) => FormatDate(row.row.Result.Order.Patient.DOB),
        },
        {
            field: "Test",
            headerName: "Test",
            width: 100,
            valueGetter: (row) => row.row.Result.Test.Name,
        },
        {
            field: "Result",
            headerName: "Result",
            width: 100,
            valueGetter: (row) => row.row.Result.FinalValue,
        },
        {
            field: "CompletedOn",
            headerName: "Completed On",
            width: 120,
            valueGetter: (row) => row.row.CompletedOn,
        },
        {
            field: "CompletedBy",
            headerName: "Completed By",
            width: 150,
            valueGetter: (row) => row.row.CompletedBy.Email,
        },
        {
            field: "Contact",
            headerName: "Contact",
            width: 100,
            valueGetter: (row) => {
                return (
                    row.row.Contact.FirstName + " " + row.row.Contact.LastName
                );
            },
        },
    ];

    if (!completed) {
        columns.splice(9, 2);
    }

    useEffect(() => {
        getCriticals().then((p) => {
            setCriticals(p);
            setLoading(false);
        });
    }, [refresh]);

    const ODD_OPACITY = 0.2;
    const StyledDataGrid = styled(DataGridPro)(({ theme }) => ({
        [`& .${gridClasses.row}.abnormal`]: {
            backgroundColor: lighten(theme.palette.warning.main, 0.85),
            "&:hover, &.Mui-hovered": {
                backgroundColor: alpha(theme.palette.primary.main, ODD_OPACITY),
                "@media (hover: none)": {
                    backgroundColor: "transparent",
                },
            },
            "&.Mui-selected": {
                backgroundColor: alpha(
                    theme.palette.primary.main,
                    ODD_OPACITY + theme.palette.action.selectedOpacity
                ),
                "&:hover, &.Mui-hovered": {
                    backgroundColor: alpha(
                        theme.palette.primary.main,
                        ODD_OPACITY +
                        theme.palette.action.selectedOpacity +
                        theme.palette.action.hoverOpacity
                    ),
                    // Reset on touch devices, it doesn't add specificity
                    "@media (hover: none)": {
                        backgroundColor: alpha(
                            theme.palette.primary.main,
                            ODD_OPACITY + theme.palette.action.selectedOpacity
                        ),
                    },
                },
            },
        },
        [`& .${gridClasses.row}.critical`]: {
            backgroundColor: lighten(theme.palette.error.main, 0.75),
            "&:hover, &.Mui-hovered": {
                backgroundColor: alpha(theme.palette.primary.main, ODD_OPACITY),
                "@media (hover: none)": {
                    backgroundColor: "transparent",
                },
            },
            "&.Mui-selected": {
                backgroundColor: alpha(
                    theme.palette.primary.main,
                    ODD_OPACITY + theme.palette.action.selectedOpacity
                ),
                "&:hover, &.Mui-hovered": {
                    backgroundColor: alpha(
                        theme.palette.primary.main,
                        ODD_OPACITY +
                        theme.palette.action.selectedOpacity +
                        theme.palette.action.hoverOpacity
                    ),
                    // Reset on touch devices, it doesn't add specificity
                    "@media (hover: none)": {
                        backgroundColor: alpha(
                            theme.palette.primary.main,
                            ODD_OPACITY + theme.palette.action.selectedOpacity
                        ),
                    },
                },
            },
        },
    }));

    return (
        <>
            <ConfirmDialog
                title="Complete critical?"
                message={"Are you sure?"}
                loading={loading}
                confirm={handleComplete}
                cancel={() => setComplete(false)}
                open={complete}
            />
            <Dialog open={addNote}>
                <NotesDialog
                    handleSave={handleAddNote}
                    loading={loading}
                    handleClose={() => setAddNote(false)}
                    error={error}
                    helperText="This text will appear on the result report."
                />
            </Dialog>
            {view ? (
                <Dialog open={view} maxWidth="xl">
                    <DialogTitle>Critical Details</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            <Table>
                                <TableRow>
                                    <TableHead>
                                        <TableCell>Patient</TableCell>
                                    </TableHead>
                                    <TableCell>
                                        {
                                            critical?.Result?.Order?.Patient
                                                ?.FirstName
                                        }{" "}
                                        {
                                            critical?.Result?.Order?.Patient
                                                ?.LastName
                                        }{" "}
                                        {FormatDate(
                                            critical?.Result?.Order?.Patient
                                                ?.DOB
                                        )}
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableHead>
                                        <TableCell>Facility</TableCell>
                                    </TableHead>
                                    <TableCell>
                                        {
                                            critical?.Result?.Order?.Facility
                                                ?.Name
                                        }
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableHead>
                                        <TableCell>Contact</TableCell>
                                    </TableHead>
                                    <TableCell>
                                        {critical?.Contact?.FirstName + " " + critical?.Contact?.LastName}
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableHead>
                                        <TableCell>Phone 1</TableCell>
                                    </TableHead>
                                    <TableCell>
                                        {critical?.Contact?.Phone1}
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableHead>
                                        <TableCell>Phone 2</TableCell>
                                    </TableHead>
                                    <TableCell>
                                        {critical?.Contact?.Phone2}
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableHead>
                                        <TableCell>Fax</TableCell>
                                    </TableHead>
                                    <TableCell>
                                        {critical?.Contact?.Fax}
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableHead>
                                        <TableCell>Email</TableCell>
                                    </TableHead>
                                    <TableCell>
                                        {critical?.Contact?.Email}
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableHead>
                                        <TableCell>Test</TableCell>
                                    </TableHead>
                                    <TableCell>
                                        {critical?.Result?.Test?.Name}
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableHead>
                                        <TableCell
                                            sx={{
                                                fontWeight: "bold",
                                                color: "red",
                                            }}
                                        >
                                            Result
                                        </TableCell>
                                    </TableHead>
                                    <TableCell
                                        sx={{
                                            fontWeight: "bold",
                                            color: "red",
                                        }}
                                    >
                                        {critical?.Result?.FinalValue}{" "}
                                        {critical?.Result?.Units}
                                    </TableCell>
                                </TableRow>
                            </Table>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Notes</TableCell>
                                        <TableCell>Added By</TableCell>
                                        <TableCell>Added On</TableCell>
                                    </TableRow>
                                </TableHead>
                                {critical.Notes.map((note) => (
                                    <TableRow>
                                        <TableCell>{note.Note}</TableCell>
                                        <TableCell>
                                            {note.AddedBy?.Email}
                                        </TableCell>
                                        <TableCell>
                                            {FormatDateTime(note.CreatedAt)}
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </Table>
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => setView(false)}>Close</Button>
                    </DialogActions>
                </Dialog>
            ) : (
                <></>
            )}
            <StyledDataGrid
                hideFooterRowCount
                getRowId={(row) => row.ID}
                density="compact"
                loading={loading}
                pagination
                components={{ Toolbar: GridToolbar }}
                initialState={{
                    sorting: {
                        sortModel: [{ field: "OrderID", sort: "asc" }],
                    },
                }}
                getRowClassName={(params) => {
                    if (params.row.Status === "New") {
                        return "critical";
                    } else if (params.row.Status === "Updated") {
                        return "abnormal";
                    }
                    return "";
                }}
                componentsProps={{
                    toolbar: {
                        showQuickFilter: true,
                        quickFilterProps: { debounceMs: 500 },
                    },
                }}
                selectionModel={selectedCritical}
                onSelectionModelChange={setSelectedCritical}
                rows={criticals}
                columns={columns}
            />
        </>
    );
}
