import React, { useState, useCallback } from "react";
import { DataGridPro, GridEditInputCell } from "@mui/x-data-grid-pro";
import { Sample, SpecimenType, Test } from "../../constants/types";

type OrderSpecimenListProps = {
    neededSpecimenTypes: SpecimenType[],
    setSamples: (samples: any) => void,
    samples: Sample[],
    dateCollected: Date | null,
    fasting: boolean,
    collectorId: number | null | undefined,
}

function OrderSpecimenList({
    neededSpecimenTypes,
    samples,
    setSamples,
    dateCollected,
    fasting,
    collectorId,
}: OrderSpecimenListProps) {
    const getDefaultEditMode = () => {
        const cellMode = {}
        for (let spec of neededSpecimenTypes) {
            // @ts-ignore
            cellMode[spec.id] = { Barcode: { mode: "edit" } }
        }
        return cellMode
    }

    const [cellModesModel, setCellModesModel] = useState(getDefaultEditMode())

    console.log('cellmodes', cellModesModel)

    const getNeededFor = (row: any) => {
        if (row.row?.Tests) {
            return row.row?.Tests.map((test: Test) => test.Name).join(", ")
        }
        return ""

    }


    const handleSelect = (v: number[]) => {
        // if the array contains the sample
        // update the barcode

        // if v contains more than samples, we add
        // otherwise remove
        //

        if (v.length > samples.length) {
            // find the number in v that is not in samples.id
            const newId = v.find((id) => !samples.find((s: any) => s.id === id))
            const specimenType = neededSpecimenTypes.find((s: any) => s.id === newId)
            setSamples([...samples, {
                id: newId,
                Barcode: "",
                DateCollected: dateCollected,
                SpecimenTypeID: specimenType?.ID,
                CollectorID: collectorId,
                Fasting: fasting,
                // @ts-ignore
                Reference: specimenType.Reference,
            }])
        } else {
            setSamples(samples.filter((s: any) => v.includes(s.id)))
        }

    }

    const RenderValueCell = (params: any) => {
        return (
            <GridEditInputCell
                {...params}
                onValueChange={(_: any, v: any) => {
                    console.log(v)
                    console.log(params)
                    const barcode = v.trim();

                    // if the array contains the sample
                    // update the barcode
                    const index = samples.findIndex((s: any) => s.id === params.id)
                    // @ts-ignore
                    const specimenType = neededSpecimenTypes.find((s) => s.id === params.id)
                    if (index === -1) {
                        setSamples([...samples, {
                            id: params.id,
                            Barcode: barcode,
                            DateCollected: dateCollected,
                            SpecimenTypeID: specimenType?.ID,
                            CollectorID: collectorId,
                            Fasting: fasting,
                            // @ts-ignore
                            Reference: specimenType.Reference,
                        }])
                    } else {
                        samples[index] = {
                            ...samples[index],
                            Barcode: barcode,
                        }
                        setSamples([...samples])
                    }

                }}
            />
        );
    }



    // props accepts ordered tests
    // it uses those to display the required specimen types
    const columns = [
        { field: "QTY", headerName: "QTY", width: 20 },
        { field: "Barcode", headerName: "Custom Barcode", editable: true, width: 150, renderEditCell: RenderValueCell },
        { field: "Container", headerName: "Container", width: 200 },
        { field: "Specimen", headerName: "Specimen", width: 120 },
        { field: "Directions", headerName: "Directions", width: 600 },
        {
            field: "Tests",
            headerName: "Needed For",
            width: 600,
            valueGetter: getNeededFor,
        },
    ];

    const handleCellModesModelChange = useCallback((newModel: any) => {
        console.log(newModel)
        setCellModesModel(newModel);
    }, []);

    return (
        <DataGridPro
            disableColumnFilter
            disableColumnSelector
            disableDensitySelector
            hideFooterRowCount
            hideFooterSelectedRowCount
            density="compact"
            rowSelectionModel={samples.map((s: any) => s.id)}
            // @ts-ignore
            onRowSelectionModelChange={handleSelect}
            cellModesModel={cellModesModel}
            onCellModesModelChange={handleCellModesModelChange}
            checkboxSelection={true}
            autoHeight={true}
            rows={neededSpecimenTypes}
            columns={columns}
        />
    );
}

export default OrderSpecimenList
