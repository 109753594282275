import { DataGridPro, gridClasses } from "@mui/x-data-grid-pro";
import { alpha, styled, lighten } from "@mui/material/styles";

const ODD_OPACITY = 0.2;
export const StyledOrdersResultsTable = styled(DataGridPro)(({ theme }) => ({
    [`& .${gridClasses.row}.critical`]: {
        backgroundColor: lighten(theme.palette.error.main, 0.55),
        "&:hover, &.Mui-hovered": {
            backgroundColor: alpha(
                theme.palette.primary.main,
                ODD_OPACITY
            ),
            "@media (hover: none)": {
                backgroundColor: "transparent",
            },
        },
        "&.Mui-selected": {
            backgroundColor: alpha(
                theme.palette.primary.main,
                ODD_OPACITY + theme.palette.action.selectedOpacity
            ),
            "&:hover, &.Mui-hovered": {
                backgroundColor: alpha(
                    theme.palette.primary.main,
                    ODD_OPACITY +
                    theme.palette.action.selectedOpacity +
                    theme.palette.action.hoverOpacity
                ),
                // Reset on touch devices, it doesn't add specificity
                "@media (hover: none)": {
                    backgroundColor: alpha(
                        theme.palette.primary.main,
                        ODD_OPACITY +
                        theme.palette.action.selectedOpacity
                    ),
                },
            },
        },
    },
    [`& .${gridClasses.row}.abnormal`]: {
        backgroundColor: lighten(theme.palette.warning.main, 0.85),
        "&:hover, &.Mui-hovered": {
            backgroundColor: alpha(
                theme.palette.primary.main,
                ODD_OPACITY
            ),
            "@media (hover: none)": {
                backgroundColor: "transparent",
            },
        },
        "&.Mui-selected": {
            backgroundColor: alpha(
                theme.palette.primary.main,
                ODD_OPACITY + theme.palette.action.selectedOpacity
            ),
            "&:hover, &.Mui-hovered": {
                backgroundColor: alpha(
                    theme.palette.primary.main,
                    ODD_OPACITY +
                    theme.palette.action.selectedOpacity +
                    theme.palette.action.hoverOpacity
                ),
                // Reset on touch devices, it doesn't add specificity
                "@media (hover: none)": {
                    backgroundColor: alpha(
                        theme.palette.primary.main,
                        ODD_OPACITY +
                        theme.palette.action.selectedOpacity
                    ),
                },
            },
        },
    },
    [`& .${gridClasses.row}.tnp`]: {
        backgroundColor: lighten(theme.palette.error.main, 0.75),
        "&:hover, &.Mui-hovered": {
            backgroundColor: alpha(
                theme.palette.primary.main,
                ODD_OPACITY
            ),
            "@media (hover: none)": {
                backgroundColor: "transparent",
            },
        },
        "&.Mui-selected": {
            backgroundColor: alpha(
                theme.palette.primary.main,
                ODD_OPACITY + theme.palette.action.selectedOpacity
            ),
            "&:hover, &.Mui-hovered": {
                backgroundColor: alpha(
                    theme.palette.primary.main,
                    ODD_OPACITY +
                    theme.palette.action.selectedOpacity +
                    theme.palette.action.hoverOpacity
                ),
                // Reset on touch devices, it doesn't add specificity
                "@media (hover: none)": {
                    backgroundColor: alpha(
                        theme.palette.primary.main,
                        ODD_OPACITY +
                        theme.palette.action.selectedOpacity
                    ),
                },
            },
        },
    },
}));
