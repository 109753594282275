import Table from "@mui/material/Table";
import { TableContainer } from "@mui/material";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

export default function DiagnosisCodeTable({ diagnosisCodes }) {
    const style = { whiteSpace: "nowrap", overflow: "nowrap", overflow: "hidden", textOverflow: "ellipsis"}

    return (
        <TableContainer>
            <Table style={{ width: "auto", tableLayout: "auto" }}>
                <TableHead>
                    <TableRow>
                        <TableCell size="small">Order</TableCell>
                        <TableCell size="small">Code</TableCell>
                        <TableCell size="small" sx={style}>Description</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {diagnosisCodes.map((d, index) => {
                        return (
                            <TableRow>
                                <TableCell size="small">{index + 1}</TableCell>
                                <TableCell size="small">{d.Code}</TableCell>
                                <TableCell size="small" sx={style}>{d.Description}</TableCell>
                            </TableRow>
                        );
                    })}
                </TableBody>
            </Table>
        </TableContainer>
    );
}
