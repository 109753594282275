import React, { useState, useEffect } from "react";
import {
    Divider,
    Stack,
    Table,
    TableCell,
    TableRow,
    Typography
} from "@mui/material"
import { DataGridPro, GridToolbar } from "@mui/x-data-grid-pro";
import { getProfiles } from "../../services/profiles";
import { Profile } from "../../constants/types";

type OrderTestsProfileTableProps = {
    selected: number[],
    setSelected: React.Dispatch<React.SetStateAction<number[]>>,
    setProfileDetails: React.Dispatch<React.SetStateAction<Profile[]>>,
}

function OrderTestsProfileTable({ setSelected, selected, setProfileDetails }: OrderTestsProfileTableProps) {
    const [profiles, setProfiles] = useState([])

    useEffect(() => {
        getProfiles().then((p) => {
            setProfiles(p);
            setProfileDetails(p);
        });

    }, [setProfileDetails])

    const columns = [
        { field: "Code", headerName: "ID", width: 75 },
        { field: "Name", headerName: "Name", width: 320 },
    ];

    const displayTests = (tests: any, profiles: any) => {
        if (
            tests === undefined ||
            profiles === undefined
        ) {
            return;
        }
        return (
            <Stack
                direction="column"
                sx={{ p: 1, height: "100%", boxSizing: "border-box" }}
            >
                {profiles.length > 0 ? (
                    <>
                        <Typography variant="h6">SubProfiles</Typography>
                        <Stack direction="column" alignItems="center">
                            {profiles.map((p: any) => (
                                <Typography variant="body1" key={p.ID}>
                                    {p.Name}
                                </Typography>
                            ))}
                        </Stack>
                    </>
                ) : null}
                {profiles.length > 0 && tests.length > 0 ? (
                    <Divider sx={{ m: 3 }} />
                ) : (
                    <></>
                )}
                {tests.length > 0 ? (
                    <>
                        <Typography variant="h6">Tests</Typography>
                        <Stack direction="column" alignItems="center">
                            <Table size="small">
                                {tests.map((t: any) => (
                                    <TableRow key={t.ID} sx={{ height: "5px" }}>
                                        <TableCell sx={{ padding: "6px" }}>
                                            {t.Code}
                                        </TableCell>
                                        <TableCell sx={{ padding: "6px" }}>
                                            {t.Name}
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </Table>
                        </Stack>
                    </>
                ) : null}
            </Stack>
        );
    };


    return (
        <DataGridPro
            disableColumnFilter
            disableColumnSelector
            disableDensitySelector
            hideFooterRowCount
            getRowId={(row: any) => row.ID}
            density="compact"
            sx={{
                "& .MuiDataGrid-columnHeaderCheckbox .MuiDataGrid-columnHeaderTitleContainer":
                {
                    display: "none",
                },
            }}
            getDetailPanelContent={(row) =>
                displayTests(row.row.Tests, row.row.SubProfiles)
            }
            getDetailPanelHeight={() => "auto"}
            components={{ Toolbar: GridToolbar }}
            componentsProps={{
                toolbar: {
                    showQuickFilter: true,
                    quickFilterProps: { debounceMs: 500 },
                },
            }}
            keepNonExistentRowsSelected
            // @ts-ignore
            onRowSelectionModelChange={setSelected}
            rowSelectionModel={selected}
            checkboxSelection={true}
            rows={profiles}
            columns={columns}
        />
    );
};

export default OrderTestsProfileTable;
