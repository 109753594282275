
export async function getNotifications() {
    return fetch(
        `${process.env.REACT_APP_API_PROXY}/v1/notifications`,
        {
            method: "GET",
            credentials: "include",
        }
    )
}
export async function resendNotifications(orderId) {
    return fetch(
        `${process.env.REACT_APP_API_PROXY}/v1/notifications`,
        {
            method: "POST",
            credentials: "include",
            body: JSON.stringify({OrderID: orderId}),
        }
    )
}

