import React, { useState, useEffect, useContext } from "react";
import { useNavigate, useLocation } from "react-router";
import {
    Grid,
    Paper,
    Avatar,
    TextField,
    Typography,
    Link,
    Button,
} from "@mui/material";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Logo from "../images/logo.png";
import { Box, Stack } from "@mui/system";
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import { login } from "../services/auth";
import { Alert } from "@mui/material";
import { decodeCookie, SessionContext } from "../hooks/getContext";
import mdTheme from "../components/Theme";
import { useMediaQuery } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";

export default function Login() {
    const state = useLocation().state;
    const [username, setUsername] = useState();
    const [password, setPassword] = useState();
    const [error, setError] = useState("");
    const [loggingIn, setLoggingIn] = useState(false);
    const navigate = useNavigate();
    const { user, setUser, setLoading } = useContext(SessionContext);

    const isMobile = useMediaQuery(mdTheme.breakpoints.down("sm"));

    useEffect(() => {
        console.log("login useeffect")
        if (!decodeCookie()) {
            console.log("missing cookies")
            return
        }

        if (user !== null) {
            console.log("user not null, redirecting")
            console.log(user)
            if (user?.Type !== "Lab") {
                navigate("/orders");
            } else {
                navigate("/");
            }
        }

    }, [user, navigate]);

    const loginUser = () => {
        login(username, password)
            .then((response) => {
                if (!response.ok) {
                    throw new Error("Login failed. Please try again.");
                }
                return response.json();
            })
            .then(() => {
                const u = decodeCookie()
                console.log("Logged in, setting user: " + u)
                setUser(u);
                if (state?.from) {
                    navigate(state?.from);
                } else {
                    if (u?.Type !== "Lab") {
                        navigate("/orders");
                    } else {
                        navigate("/");
                    }
                }
            })
            .catch((e) => setError(e.message))
            .finally(() => setLoggingIn(false))

    };

    const desktopPaper = {
        padding: 20,
        height: 750,
        width: 480,
        margin: "20px auto",
    };

    const mobilePaper = {
        width: "100%",
        padding: 8,
        height: "100vh",
    };
    const paperStyle = isMobile ? mobilePaper : desktopPaper;

    const avatarStyle = { backgroundColor: "#1bbd7e" };
    const btnstyle = { margin: "8px 0" };
    return (
        <ThemeProvider theme={mdTheme}>
            <CssBaseline />
            <Grid>
                <Paper elevation={10} style={paperStyle}>
                    <Grid align="center">
                        <img width={"85%"} src={Logo} alt="diagnoseLogo" />
                        <Avatar style={avatarStyle}>
                            <LockOutlinedIcon />
                        </Avatar>
                        <h2>Sign In</h2>
                    </Grid>
                    <Box component="form" id="login-form">
                        <Stack direction="column" spacing={2}>
                            <TextField
                                label="Email"
                                placeholder="Enter email"
                                type="email"
                                variant="outlined"
                                onChange={(v) => setUsername(v.target.value)}
                                fullWidth
                                required
                            />
                            <TextField
                                label="Password"
                                placeholder="Enter password"
                                type="password"
                                onChange={(v) => setPassword(v.target.value)}
                                variant="outlined"
                                fullWidth
                                required
                            />
                        </Stack>
                        <FormControlLabel
                            control={
                                <Checkbox name="checkedB" color="primary" />
                            }
                            label="Remember me"
                        />
                        {error !== "" && (
                            <Alert variant="filled" severity="error">
                                {error}
                            </Alert>
                        )}
                        <LoadingButton
                            type="submit"
                            loading={loggingIn}
                            loadingIndicator="Logging in..."
                            disabled={loggingIn}
                            color="primary"
                            variant="contained"
                            onClick={(e) => {
                                setLoggingIn(true);
                                e.preventDefault();
                                const ele =
                                    document.getElementById("login-form");
                                const chk_status = ele.checkValidity();
                                ele.reportValidity();
                                if (chk_status) {
                                    setError("");
                                    setLoading(true);
                                    loginUser();
                                } else {
                                    setLoggingIn(false);
                                }
                            }}
                            style={btnstyle}
                            fullWidth
                        >
                            Sign in
                        </LoadingButton>
                    </Box>
                    <Button
                        onClick={() => navigate("/forgotPassword")}
                        fullWidth
                        color="secondary"
                    >Forgot password?</Button>

                </Paper>
            </Grid>
        </ThemeProvider>
    );
}
