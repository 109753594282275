import DataTable from "./DataTable";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

function DiagnosisQuestionsTable({ diagnosisQuestions, selected, setSelected }) {

    const getCheckbox = (value) => {
        if (value.value) {
            return <CheckCircleIcon color="success" />;
        }
        return <CheckCircleIcon color="disabled" />;
    };

    const columns = [
        { field: "ID", headerName: "ID", width: 50 },
        { field: "Enabled", headerName: "Enabled", width: 100, renderCell: getCheckbox },
        { field: "Question", headerName: "Question", width: 700, },
        { field: "DiagnosisCodeID", headerName: "Diagnosis Code", width: 300 },
    ]

    return <DataTable
        selected={selected}
        setSelected={setSelected}
        data={diagnosisQuestions}
        columns={columns}
        checkboxSelection
    />



}

export default DiagnosisQuestionsTable;
