import React, { useEffect, useState } from "react";
import {
    Autocomplete,
    Stack,
    TextField,
    Typography,
    MenuItem,
    FormControl,
    FormLabel,
    FormControlLabel,
    Checkbox,
    FormGroup
} from "@mui/material";
import { DateTimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { Collector, SpecimenType, Test } from "../../constants/types";
import { getCollectorsByFacility } from "../../services/collectors";
import OrderSpecimenList from "./OrderSpecimenList";
import SpecimenRequiredVerification from "./OrderSpecimenRequiredVerification";
import { useFormikContext } from "formik";
import { OrderValues } from "./OrderForm";
import { apiFetch } from "../../services/fetch";
import { getTestsFromReflexes, determineNeededSpecimenTypes } from "../../services/specimentypes";

function CollectNowForm() {
    const [allCollectors, setAllCollectors] = useState<Collector[]>([]);
    const { values, setFieldValue } = useFormikContext<OrderValues>();
    const [dateCollected, setDateCollected] = useState<Date | null>(new Date());
    const [showAllSpecimens, setShowAllSpecimens] = useState(false);
    const [fasting, setFasting] = useState(false);
    const [neededSpecimenTypes, setNeededSpecimenTypes] = useState<SpecimenType[]>([]);
    const [allTests, setAllTests] = useState<Test[]>([]);
    console.log(values)

    useEffect(() => {
        if (showAllSpecimens) {
            const getSpecimens = async () => {
                try {
                    let specs = await apiFetch('/specimentypes', 'GET')
                    specs = specs.map((s: any) => {
                        s.id = s.ID
                        return s
                    })
                    setNeededSpecimenTypes(specs)
                } catch (e) {
                    console.log(e)
                }
            }

            getSpecimens()
        }

        if (!showAllSpecimens) {

            console.log("use1");
            const parseTestsForSpecimenType = async () => {
                let reflexTests = [];
                if (values?.ReflexDetails && values?.ReflexDetails.length !== 0) {
                    reflexTests = await getTestsFromReflexes(values?.ReflexDetails);
                }

                let _allTests = [...reflexTests, ...values?.TestDetails];

                setAllTests(_allTests);

                determineNeededSpecimenTypes(_allTests).then((data) => {
                    const specimenTypes = data;
                    setNeededSpecimenTypes(specimenTypes);
                });
            };

            parseTestsForSpecimenType();
        }

    }, [values?.TestDetails, showAllSpecimens, values?.ReflexDetails]);

    useEffect(() => {
        getCollectorsByFacility(values?.Facility?.ID)
            .then((c) => {
                setAllCollectors(c)
                if (c.length === 1) {
                    setFieldValue("Collector", c[0])
                }
            });
    }, [values?.Facility?.ID, setFieldValue]);

    return (
        <>
            <Stack spacing={2} direction="column">
                <FormLabel component="legend">Select Collector</FormLabel>
                <Stack direction={{ sm: "column", md: "row" }} spacing={2}>
                    <FormControl sx={{ minWidth: 150, width: "220px" }}>
                        <Autocomplete
                            selectOnFocus
                            disablePortal
                            options={allCollectors}
                            isOptionEqualToValue={(option, value) =>
                                option.ID === value?.ID
                            }
                            getOptionLabel={
                                (option) => {
                                    if (!option) {
                                        return ""
                                    } else {
                                        return option.LastName + ", " + option.FirstName
                                    }
                                }
                            }
                            sx={{ width: "100%" }}
                            onChange={(_, e) => {
                                if (!e) {
                                    setFieldValue('Collector', null)
                                } else {
                                    setFieldValue('Collector', e)
                                }
                            }}
                            value={values?.Collector}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    InputProps={{
                                        ...params.InputProps,
                                        type: "search",
                                    }}
                                    size="small"
                                    label="Collector"
                                />
                            )}
                        />
                    </FormControl>
                    <FormControl sx={{ minWidth: 150, width: "230px" }}>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DateTimePicker
                                disableFuture
                                label="Date Collected"
                                value={dateCollected}
                                onChange={setDateCollected}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        required
                                        size="small"
                                    />
                                )}
                            />
                        </LocalizationProvider>
                    </FormControl>
                </Stack>
                <Stack direction="row" spacing={2}>
                    <FormGroup>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={fasting}
                                    onChange={(event) =>
                                        setFasting(event.target.checked)
                                    }
                                />
                            }
                            label="Patient is fasting"
                        />
                    </FormGroup>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={values?.Stat}
                                onChange={(e) => setFieldValue('Stat', e.target.checked)}
                                color="primary"
                            />
                        }
                        label="Stat - Order is urgent"
                    />
                </Stack>
                <FormLabel component="legend">
                    Select Collected Samples
                </FormLabel>
                <Typography variant="body1">
                    Based on the ordered tests, the following specimen types are
                    required:
                </Typography>
                <Stack direction="column" spacing={2}>
                    <OrderSpecimenList
                        neededSpecimenTypes={neededSpecimenTypes}
                        setSamples={(s) => setFieldValue('Samples', s)}
                        // @ts-ignore
                        samples={values?.Samples || []}
                        dateCollected={dateCollected}
                        fasting={fasting}
                        collectorId={values?.Collector?.ID}
                    />
                    <FormControlLabel label="View All Specimen Types" control={<Checkbox
                        onChange={(e) => setShowAllSpecimens(e.target.checked)}
                        checked={showAllSpecimens}
                    />} />
                    {!showAllSpecimens &&
                        <SpecimenRequiredVerification
                            sampleDetails={values?.Samples || []}
                            allTests={allTests}
                        />}
                </Stack>
            </Stack>
        </>
    );
}

export default CollectNowForm;
