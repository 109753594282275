export async function getNPPES(npi) {
        return fetch(`${process.env.REACT_APP_API_PROXY}/v1/providers/npi?npi=` + npi, {method: "GET", credentials: "include"})
        
}
export async function getProviders() {
        return fetch(`${process.env.REACT_APP_API_PROXY}/v1/providers`, {credentials: "include"})
        .then((response) => response.json())
}

export async function getProvidersByFacility(facilityID) {
        return fetch(`${process.env.REACT_APP_API_PROXY}/v1/providers/?facility=` + facilityID, {credentials: "include"})
        .then((response) => response.json())
}

export async function addProvider(provider, providerID) {
    let url = "";
    if (providerID) {
        url = `${process.env.REACT_APP_API_PROXY}/v1/providers/` + providerID;
    } else {
        url = `${process.env.REACT_APP_API_PROXY}/v1/providers`;
    }
    return fetch(url, {
        method: "POST",
            credentials: "include",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
        },
        body: JSON.stringify(provider),
    })
        .then((response) => {
                if (response.ok) {
                        return response.json()
                }
                return response.json().then((error) => {
                        console.log("throwing")
                        console.log(error.error);
                        throw new Error(error.error);
                })
        })
}
