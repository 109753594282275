import { DataGridPro, GridToolbar } from "@mui/x-data-grid-pro";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ErrorIcon from '@mui/icons-material/Error';
import { notificationEventIndex, FormatDateTime } from "../services/util";
import { useFetch, apiFetch } from "../services/fetch";


function GetFileLink({ params }) {
    const handleClick = async (uuid, filename) => {
        try {
            const data = await apiFetch(`/attachments/${uuid}`)

            const url = window.URL.createObjectURL(data);
            const link = document.createElement("a");
            link.href = url;
            link.download = filename;
            link.click();
            // It's necessary to revoke the object URL to avoid memory leaks
            window.URL.revokeObjectURL(url);
        } catch (error) {
            console.error("Fetch operation failed: " + error);
        }
    };
    return (
        <a
            href="#"
            onClick={() =>
                handleClick(params.row.Attachment.UUID, params.row.Attachment.Name)
            }
        >
            {params.row.Attachment.Name}
        </a>
    )
}


export default function BillingOutputsTable() {
    const { data: billingOutputs, isLoading, error } = useFetch("/billingOutput")


    const getCheckbox = (value) => {
        if (value.row?.Success) {
            return <CheckCircleIcon color="success" />;
        }
        return <ErrorIcon color="error" />;
    };

    const columns = [
        { field: "ID", headerName: "ID", width: 100 },
        { field: "CreatedAt", headerName: "Sent At", width: 175, valueGetter: row => FormatDateTime(row.row?.CreatedAt) },
        { field: "OrderID", headerName: "Order ID", width: 75 },
        { field: "File", headerName: "File", width: 400, renderCell: (params) => <GetFileLink params={params} /> },
        { field: "Success", headerName: "Success", width: 80, renderCell: getCheckbox },
    ];

    return (
        <>
            <DataGridPro
                getRowId={(row) => row.ID}
                columns={columns}
                loading={isLoading}
                rows={billingOutputs || []}
                error={error}
                initialState={{
                    sorting: {
                        sortModel: [{ field: "ID", sort: "desc" }],
                    },
                }}
                density="compact"
                sx={{
                    "& .MuiDataGrid-columnHeaderCheckbox .MuiDataGrid-columnHeaderTitleContainer":
                    {
                        display: "none",
                    },
                    height: "95%",
                }}
                components={{ Toolbar: GridToolbar }}
                componentsProps={{
                    toolbar: {
                        showQuickFilter: true,
                        quickFilterProps: { debounceMs: 500 },
                    },
                }}
                pageSize={100}
            />
        </>
    );
}
