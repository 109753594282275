import React, { useEffect, useState, useContext } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { Box, Paper, Grid, Stack, Button, Divider } from "@mui/material";
import {
    PictureAsPdf,
    ModeEdit,
    Pageview,
    Cancel,
    HealthAndSafety,
    WarningAmber,
} from "@mui/icons-material";


import Framework from "./Framework";
import Title from "./Title";
import { GetOrderSTATChip } from "./StatusChips";
import OrderMetadata from "./OrderDisplay/OrderMetadata";
import Patient from "./OrderDisplay/OrderPatient";
import FacilityMeta from "./OrderDisplay/OrderFacility";
import PatientInsurancesTable from "./InsurancesTable";
import AOEs from "./OrderDisplay/AOEs";
import Tests from "./OrderDisplay/OrderTests";

function OrderReview({ withNav, ID }) {
    let { orderID } = useParams();
    const navigate = useNavigate();

    const StyledPaper = (props) => (
        <Paper
            elevation={5}
            sx={{
                p: { xs: 1, md: 2 },
                m: { xs: 1, sm: 1, md: 2 },
            }}
        >
            {props.children}
        </Paper>
    );


    const Order = () => (
        <Box
            id="order-display-box"
            sx={{ p: { sm: 0, md: 1 } }}
            width="100%"
        >
            <Grid container spacing={{ xs: 0, md: 0 }}>
                <Grid item md={4} xs={12}>
                    <StyledPaper
                        sx={{
                            background: "#eceff1",
                        }}>
                        <OrderMetadata orderDetails={{
                            ID: 1,
                            Status: "Pending Approval",
                            CreatedAt: "2023-10-10",
                            UpdatedAt: "2023-10-10",
                            AddedBy: { Name: "Jared Simmons", Email: "jsimmons@animasoftware.io" },
                            PaymentMethod: 2,
                        }}
                        />

                    </StyledPaper>
                </Grid>
                <Grid item md={4} xs={12}>
                    <StyledPaper
                        sx={{
                            background: "#eceff1",
                        }}
                    >
                        <Stack
                            direction="row"
                            justifyContent="space-between"
                        >
                            <Title>Patient</Title>
                            <Stack
                                direction="row"
                                spacing={1}
                                divider={
                                    <Divider orientation="vertical" />
                                }
                            >
                                <Button
                                    onClick={() =>
                                        navigate(
                                            "/patients/" +
                                            1
                                        )
                                    }
                                    startIcon={<Pageview />}
                                >
                                    View
                                </Button>
                                <Button
                                    onClick={() =>
                                        navigate(
                                            "/patients/" + 1
                                            + "/edit"
                                        )
                                    }
                                    startIcon={<ModeEdit />}
                                >
                                    Edit
                                </Button>
                            </Stack>
                        </Stack>
                        <Patient orderDetails={{
                            Patient: {
                                FirstName: "John",
                                LastName: "Doe",
                                DOB: "2020-01-01",
                                Sex: "Male",
                                Address: {
                                    Address1: "123 Street",
                                    Address2: "",
                                    City: "Dallas",
                                    State: "Texas",
                                    ZipCode: "12345"
                                },
                                Phone: "864-555-5555",
                                Email: "jdoe@animasoftware.io"
                            }
                        }} />
                    </StyledPaper>
                </Grid>
                <Grid item md={4} xs={12}>
                    <StyledPaper
                        sx={{
                            background: "#eceff1",
                        }}
                    >
                        <Title>Facility</Title>
                        <FacilityMeta
                            orderDetails={{
                                Facility: { Name: "Tent 1" },
                                Provider: {
                                    FirstName: "Henry",
                                    LastName: "Jekyll",
                                    NPI: "1234567890"
                                }
                            }}
                            facility={{
                                Address: {
                                    Address1: "123 Street",
                                    Address2: "",
                                    City: "Dallas",
                                    State: "Texas",
                                    ZipCode: "12345"
                                },
                                Phone: "864-555-5555",
                            }} />
                    </StyledPaper>
                </Grid>
                <Grid item xs={12} md={12} lg={12}>
                    <StyledPaper>
                        <Title>Insurance Details</Title>
                        <PatientInsurancesTable
                            insurances={
                                [
                                    { ID: 1, Insurance: { Name: "MediCal" }, Primary: true, MemberNumber: "12345960", GroupNumber: "", DateEffective: "2023-01-01", DateDiscontinue: "", Relationship: "Self", EligibilityStatus: 2, EligibilityLastChecked: "2023-10-10" }
                                ]
                            }
                        />
                    </StyledPaper>
                </Grid>
                <Grid item xs={12} md={6} lg={6}>
                    <StyledPaper>
                        <Title>AOE</Title>
                        <AOEs aoes={[
                            { Question: "I have experienced symptoms in the last 14 days", Answer: "Yes" },
                            { Question: "In the last 14 days I have come in contact with someone who is confirmed to have or suspected to have COVID-19, Influenza, or RSV", Answer: "Yes" },
                            { Question: "I was referred by a healthcare provider", Answer: "Yes" },
                            { Question: "I am seeking a test due to high transmission rates and the difficulty of being able to main social distancing", Answer: "Yes" },
                            {
                                Question: "Select Current Symptoms", Answer: [
                                    "Cough",
                                    "Muscle aches",
                                    "Severe fatigue",
                                    "Loss of taste",
                                    "Loss of smell",
                                ]
                            }

                        ]} />
                    </StyledPaper>
                </Grid>
                <Grid item xs={12} md={6} lg={6}>
                    <StyledPaper>
                        <Title>Tests</Title>
                        <Tests orderDetails={{
                            Tests: [
                                { Code: "1001", Name: "COVID-19 PCR", CPTCode: "87635" },
                                { Code: "1002", Name: "FLU A", CPTCode: "87502" },
                                { Code: "1003", Name: "FLU B", CPTCode: "87502" },
                                { Code: "1004", Name: "RSV", CPTCode: "87634" },
                            ]
                        }} />
                    </StyledPaper>
                </Grid>
            </Grid>
            <Stack direction={"row"} justifyContent="space-between" sx={{ p: 2 }}>
                <Button variant="contained" color="error">Reject</Button>
                <Stack direction={"row"} spacing={1}>
                    <Button variant="contained" color="secondary" sx={{ color: "white" }}>Assign Diagnosis Codes</Button>
                    <Button variant="contained" color="success">Sign & Approve</Button>
                </Stack>
            </Stack>
        </Box>
    )

    return (
        <Framework><Order /></Framework>
    )

}

export default OrderReview;
