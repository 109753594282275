import { Box, Tabs, Tab, Button } from "@mui/material";
import * as Yup from 'yup';
import { TabContext, TabPanel } from "@mui/lab";
import { a11yProps } from "../components/TabPanel";
import CoriellTable from "../tables/CoriellTable";
import ButtonTray from "../components/ButtonTray";
import { Stack } from "@mui/system";
import { useEffect, useState } from "react";
import CoriellForm from "../components/CoriellForm";
import { apiFetch } from "../services/fetch";
import ErrorAlert from "../components/ErrorAlert";
import CoriellFileUploadDialog from "../components/CoriellFileUploadDialog";
import CoriellSampleSelect from "../components/CoriellSampleSelect";

function CoriellPage() {
    const [value, setValue] = useState(0);
    const [error, setError] = useState(null);
    const [uploadError, setUploadError] = useState(null);
    const [uploadFile, setUploadFile] = useState(false)
    const [initialValues, setInitialvalues] = useState({
        QcGroupID: "",
        GenerateInternalReport: true,
        InfectiousDiseaseURL: "",
        PGXURL: "",
        Panels: [],
        Templates: [],
    })
    const [foundSamples, setFoundSamples] = useState([])
    const [batchId, setBatchId] = useState(null)

    useEffect(() => {
        if (value !== 1) {
            return
        }
        const init = async () => {
            try {
                const response = await apiFetch(`/integrations/coriell`)
                setInitialvalues(response)
                setError(null)
            } catch (e) {
                setError("Failed to load coriell settings")
            }
        }

        init()
    }, [value])

    const handleChange = (_, newValue) => {
        setValue(newValue);
    };

    const handleFileUpload = async (values) => {
        var data = new FormData();
        data.append("File", values.File)

        try {
            const response = await apiFetch(`/integrations/3`, 'POST', null, false,
                {
                    headers: {
                        Accept: "application/json",
                    },
                    body: data
                })
            if (response?.sampleNames) {
                setFoundSamples(response.sampleNames)
            }
            setBatchId(response.batchId)
        } catch (e) {
            setUploadError(e.message)
        }
    }

    const handleSampleSubmit = async (values) => {
        console.log(values)

        var barcodes = [];
        for (let [k, v] of Object.entries(values?.Samples)) {
            if (v) {
                barcodes.push(k)
            }
        }

        const data = {
            Barcodes: barcodes,
            BatchId: batchId,
            Panel: values?.Panel,
            Template: values?.Template,
        }
        try {
            await apiFetch(`/integrations/3`, 'POST', data)
            setFoundSamples([])
            setUploadError(null)

        } catch (e) {
            setUploadError(e.message)
        } finally {
            setUploadFile(false)
        }
    }

    const validationSchema = Yup.object().shape({
        QcGroupID: Yup.number().required(),
        GenerateInternalReport: Yup.bool(),
        InfectiousDiseaseURL: Yup.string().url().required(),
        PGXURL: Yup.string().url().required(),
        Panels: Yup.array().of(Yup.string()).min(1).required(),
        Templates: Yup.array().of(Yup.string()).min(1).required(),
    })

    return (
        <>
            <TabContext value={value}>
                <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                    <Tabs value={value} onChange={handleChange}>
                        <Tab label="Log" {...a11yProps(0)} />
                        <Tab label="Setup" {...a11yProps(0)} />
                    </Tabs>
                </Box>
                <TabPanel value={0} sx={{ height: `calc(100% - 50px)` }}>
                    <CoriellFileUploadDialog
                        open={uploadFile}
                        setOpen={setUploadFile}
                        error={uploadError}
                        handleSubmit={handleFileUpload}
                    />
                    <CoriellSampleSelect
                        open={foundSamples.length > 0}
                        handleSubmit={handleSampleSubmit}
                        setOpen={() => setFoundSamples([])}
                        samples={foundSamples}
                        batchId={batchId}
                    />
                    <Stack direction="column" spacing={1} height="100%">
                        <ButtonTray>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={() => setUploadFile(true)}
                            >Send to Coriell</Button>
                        </ButtonTray>
                        <CoriellTable />
                    </Stack>
                </TabPanel>
                <TabPanel value={1} sx={{ height: `calc(100% - 50px)`, overflowY: 'auto' }}>
                    <ErrorAlert error={error} />
                    <CoriellForm
                        initialValues={initialValues}
                        validationSchema={validationSchema}
                        setError={setError}
                    />
                </TabPanel>
            </TabContext>
        </>
    )
}

export default CoriellPage;
