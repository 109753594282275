import React, { useEffect, useState } from "react";
import { DataGridPro, GridToolbar } from "@mui/x-data-grid-pro";
import { getProfiles } from "../services/profiles";
import { Divider, Typography } from "@mui/material";
import Stack from "@mui/material/Stack";

export default function ProfilesTable({
    profiles,
    setProfiles,
    profileDetails,
    checkboxes,
    facilityId,
}) {
    const [allProfiles, setAllProfiles] = useState([]);

    const columns = [
        { field: "Code", headerName: "ID", width: 50 },
        { field: "Name", headerName: "Name", width: 220 },
        { field: "Description", headerName: "Name", width: 420 },
    ];

    useEffect(() => {
        getProfiles().then((p) => {
            console.log(facilityId);
            if (facilityId !== undefined) {
                p = p.filter((profile) => profile.Facilities.includes(facilityId) || profile.Facilities.length === 0);
                setAllProfiles(p);
            } else {
                setAllProfiles(p);
            }
        });

    }, [facilityId]);

    const displayTests = (tests, profiles) => {
        if (
            profileDetails === undefined ||
            tests === undefined ||
            profiles === undefined
        ) {
            return;
        }
        return (
            <Stack
                direction="column"
                sx={{ p: 1, height: "100%", boxSizing: "border-box" }}
            >
                {profiles.length > 0 ? (
                    <>
                        <Typography variant="h6">SubProfiles</Typography>
                        <Stack direction="column" alignItems="center">
                            {profiles.map((p) => (
                                <Typography variant="body1" key={p.ID}>
                                    {p.Name}
                                </Typography>
                            ))}
                        </Stack>
                    </>
                ) : null}
                {profiles.length > 0 && tests.length > 0 ? (
                    <Divider sx={{ m: 3 }} />
                ) : (
                    <></>
                )}
                {tests.length > 0 ? (
                    <>
                        <Typography variant="h6">Tests</Typography>
                        <Stack direction="column" alignItems="center">
                            {tests.map((t) => (
                                <Typography variant="body1" key={t.ID}>
                                    {t.Name}
                                </Typography>
                            ))}
                        </Stack>
                    </>
                ) : null}
            </Stack>
        );
    };

    return (
        <DataGridPro
            disableColumnFilter
            disableColumnSelector
            disableDensitySelector
            hideFooterRowCount
            getRowId={(row) => row.ID}
            density="compact"
            sx={{
                "& .MuiDataGrid-columnHeaderCheckbox .MuiDataGrid-columnHeaderTitleContainer":
                {
                    display: "none",
                },
            }}
            getDetailPanelContent={(row) =>
                displayTests(row.row.Tests, row.row.SubProfiles)
            }
            getDetailPanelHeight={() => "auto"}
            components={{ Toolbar: GridToolbar }}
            componentsProps={{
                toolbar: {
                    showQuickFilter: true,
                    quickFilterProps: { debounceMs: 500 },
                },
            }}
            keepNonExistentRowsSelected
            onRowSelectionModelChange={setProfiles}
            rowSelectionModel={profiles}
            checkboxSelection={checkboxes}
            rows={allProfiles}
            columns={columns}
        />
    );
}
