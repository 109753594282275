import {
    Table,
    TableCell,
    TableRow,
    Typography,
    TableBody,
} from "@mui/material";

function sortByOrderNumber(array) {
    return array.sort((a, b) => a.OrderNumber - b.OrderNumber);
}


function DiagnosisTable({ diagnosisCodes }) {
    let sorted = sortByOrderNumber(diagnosisCodes);

    return (
        <>
            <Table size="small">
                <TableBody>
                    <TableRow>
                        <TableCell>Order</TableCell>
                        <TableCell> Code </TableCell>
                        <TableCell>Description</TableCell>
                    </TableRow>
                    {sorted.map((row, index) => (
                        <TableRow key={row.Code}>
                            <TableCell>{index + 1}</TableCell>
                            <TableCell>{row.Code}</TableCell>
                            <TableCell>{row.Description}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
            <Typography variant="caption">
                Diagnosis Codes are automatically ordered according to
                specificity
            </Typography>
        </>
    );
};

export default DiagnosisTable;
