import React, { useContext } from "react";
import { Box, FormControlLabel, InputLabel, Paper, Radio, RadioGroup, Stack, Typography } from "@mui/material";
import { SessionContext } from "../../hooks/getContext";
import OrderScheduleSampleCollection from "./OrderScheduleSampleCollection";
import { ScheduleCollectionForm } from "../ScheduleCollectionForm";
import OrderCollectNow from "./OrderCollectNow";
import { OrderValues } from "./OrderForm";
import { useFormikContext, FormikProps } from "formik";
import { ShipmentForm } from "../../pages/NewShipment";
import { validationSchema as shipmentSchema } from "../../pages/NewShipment";
import { Shipment } from "../../constants/types";

function CustPaper({ children }: { children: React.ReactNode }) {
    return (
        <Paper
            elevation={10}
            sx={{
                p: 2,
                m: 2,
                display: "block",
                flexDirection: "column",
                maxWidth: "100%",
            }}
        >
            {children}
        </Paper>
    );
}

type OrderSamplesStepProps = {
    shipmentFormRef: React.RefObject<FormikProps<Shipment>>,
    handleShipmentSubmit: (arg0: any) => void,
    shipmentValues: Shipment,
}

function OrderSamplesStep({ shipmentFormRef, handleShipmentSubmit, shipmentValues }: OrderSamplesStepProps) {
    const session = useContext(SessionContext);
    const { values, setFieldValue } = useFormikContext<OrderValues>();
    const sampleCollectionMethod = values?.SampleCollectionMethod;
    const setSampleCollectionMethod = (v: any) => setFieldValue("SampleCollectionMethod", v);
    const shipmentOption = values?.ShipmentOption;
    const setShipmentOption = (v: any) => setFieldValue("ShipmentOption", v);

    return (
        <Box key="sampleForm" sx={{ minWidth: "100%" }}>
            <CustPaper>
                <Stack direction="column" spacing={2}>
                    <OrderScheduleSampleCollection
                        disableScheduleCollection={false}
                        disableCollectNow={false}
                        sampleCollection={sampleCollectionMethod}
                        setSampleCollection={setSampleCollectionMethod}
                    />
                </Stack>
            </CustPaper>
            {sampleCollectionMethod === "scheduleCollection" ? (
                <CustPaper>
                    <ScheduleCollectionForm
                        requestedCollectionDate={values?.RequestedCollectionDate}
                        setRequestedCollectionDate={(v: any) => setFieldValue("RequestedCollectionDate", v)}
                        stat={values?.Stat}
                        setStat={(v: any) => setFieldValue("Stat", v)}
                    />
                </CustPaper>
            ) : (
                <>
                    <CustPaper>
                        <OrderCollectNow />
                    </CustPaper>
                    <CustPaper>
                        <InputLabel>Shipment Options</InputLabel>
                        <RadioGroup
                            aria-label="shipmentOption"
                            name="shipmentOption"
                            value={shipmentOption}
                            onChange={(e) => setShipmentOption(e.target.value)}
                        >
                            <FormControlLabel
                                value="noShipment"
                                sx={{ my: 1 }}
                                control={<Radio />}
                                label={
                                    <div>
                                        <Typography>
                                            Do Not Record Shipment
                                        </Typography>
                                        <Typography variant="caption">
                                            Select this option if the sample(s)
                                            will not be shipped immediately.
                                        </Typography>
                                    </div>
                                }
                            />
                            <FormControlLabel
                                value="createShipment"
                                sx={{ my: 1 }}
                                control={<Radio />}
                                label={
                                    <div>
                                        <Typography >
                                            Create Shipment
                                        </Typography>
                                        <Typography variant="caption">
                                            Choose this option if the sample(s)
                                            is ready for shipment and will be
                                            shipped immediately.
                                        </Typography>
                                    </div>
                                }
                            />
                            <FormControlLabel
                                value="receiveShipment"
                                disabled={session?.user?.Type !== "Lab"}
                                sx={{ my: 1 }}
                                control={<Radio />}
                                label={
                                    <div>
                                        <Typography >
                                            Create and Receive Shipment
                                        </Typography>
                                        <Typography variant="caption">
                                            Choose this option if the sample(s)
                                            has been shipped and will be
                                            received immediately.
                                        </Typography>
                                    </div>
                                }
                            />
                        </RadioGroup>
                        {shipmentOption !== "noShipment" &&
                            <Stack direction="column" spacing={2}>
                                <ShipmentForm
                                    shipment={shipmentValues}
                                    validationSchema={shipmentSchema}
                                    filter="Collected"
                                    handleSubmit={handleShipmentSubmit}
                                    innerRef={shipmentFormRef}
                                />
                            </Stack>}
                    </CustPaper>
                </>
            )}
        </Box>

    );
}

export default OrderSamplesStep;
