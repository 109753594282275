import { Stack, IconButton, Button, Dialog, DialogActions, DialogContent, DialogTitle, Table, TableBody, TableHead, TableCell, TableContainer, TableRow, TextField } from '@mui/material';
import React, { useState, useEffect, useRef } from 'react';
import { Sample } from '../../constants/types';
import { apiFetch } from '../../services/fetch';
import { FormatDateUTC, FormatDateTime } from "../../services/util";
import ErrorAlert from '../ErrorAlert';
import CloseIcon from "@mui/icons-material/Close";
import ReceiptIcon from "@mui/icons-material/Receipt";
import { GetSampleStatusChip } from '../StatusChips';
import SampleBarcodes, { BarcodeDialog } from '../SampleBarcodes';


type SampleReceiveDialogProps = {
    open: boolean;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

type BarcodeSample = {
    FirstName: string;
    LastName: string;
    DOB: string;
    Specimen: string;
    Container: string;
    Collected: string;
    Barcode: string;
}

function SampleReceiveDialog({ open, setOpen }: SampleReceiveDialogProps) {
    const [samples, setSamples] = useState<Sample[]>([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState("");
    const [input, setInput] = useState("");
    const inputRef = useRef<HTMLInputElement>(null);
    const [printBarcodes, setPrintBarcodes] = useState(false);
    const [sampleBarcodes, setSampleBarcodes] = useState<BarcodeSample[]>([]);

    useEffect(() => {
        if (samples && samples.length > 0) {
            console.log(samples)
            let sampleBs = [];
            for (let sample of samples) {
                for (let i = 0; i < sample?.SpecimenType?.QTY; i++) {
                    sampleBs.push({
                        FirstName: sample.Order.Patient.FirstName,
                        LastName: sample.Order.Patient.LastName,
                        DOB: FormatDateUTC(sample.Order.Patient.DOB),
                        Specimen: sample.SpecimenType.Specimen,
                        Container: sample.SpecimenType.Container,
                        Collected: FormatDateTime(sample.DateCollected),
                        Barcode: sample.Barcode,
                    })
                }
            }
            console.log(sampleBs)
            setSampleBarcodes(sampleBs.slice().reverse())
        } else {
            setSampleBarcodes([])
        }

    }, [samples]);

    const handleDelete = (index: number) => {
        setSamples(samples.slice().reverse().filter((_, i) => i !== index));
    }

    // on barcode enter
    const handleKeyDown = async (event: React.KeyboardEvent<HTMLDivElement>) => {
        console.log(event)
        if (event.key === 'Enter') {
            // Perform action when Enter key is pressed
            setLoading(true);
            try {
                // @ts-ignore
                const response = await apiFetch('/samples/find?barcode=' + event.target.value);
                if (response.ID !== 0) {
                    setSamples([...samples, response]);
                }
            } catch (e: any) {
                console.error(e);
                // @ts-ignore
                setError("Unable to find sample: " + event.target.value);
            } finally {
                setInput("");
                setLoading(false);
                setTimeout(() => inputRef.current?.focus(), 0);
            }

            // You can call a function or trigger an action here
        }
    };

    const receive = async () => {
        //filter out duplicate sample ids
        const s = samples.filter((v, i, a) => a.findIndex(t => (t.ID === v.ID)) === i);
        const sampleIds = s.map((sample) => sample.ID);

        try {
            setLoading(true)
            // @ts-ignore
            await apiFetch("/samples/receive", "POST", { SampleIDs: sampleIds })
            setOpen(false)
            setSamples([])
            setError("")
        } catch (e: any) {
            setError("Unable to receive samples. Please try again.");
        } finally {
            setLoading(false)
        }
    }

    const RenderOrderButton = ({ OrderID }: { OrderID: number }) => {
        return (
            <>
                <IconButton
                    href={`/orders/${OrderID}`}
                    target="_blank"
                >
                    <ReceiptIcon
                        sx={{ "&:hover": { color: "black" } }}
                        color="primary"
                    /></IconButton>
            </>
        );
    };
    // <UpdateSampleDialog
    // open={openSpecimen}
    // setOpen={setOpenSpecimen}
    // handleSave={handleSpecimenUpdate}
    // sampleId={sampleID}
    // callback={() => setRefresh(!refresh)}
    // />

    return (
        <>
            <Dialog open={open} maxWidth="lg">
                <DialogTitle>Receive Samples</DialogTitle>
                <DialogContent>
                    <TextField
                        autoFocus
                        size="small"
                        disabled={loading}
                        variant="filled"
                        onKeyDown={handleKeyDown}
                        inputRef={inputRef}
                        inputProps={{ maxLength: 30 }}
                        value={input}
                        onChange={(e) => setInput(e.target.value)}
                        name={"Barcode"}
                        label={"Barcode"}
                    />
                    <Stack direction="row" spacing={2} justifyContent="space-between">
                        {samples.length === 1 && <h3>{samples.length} Sample Found</h3>}
                        {samples.length > 1 && <h3>{samples.length} Samples Found</h3>}
                        {samples.length > 0 && (<Button
                            color="secondary"
                            variant="contained"
                            size="small"
                            sx={{ maxHeight: "35px", minWidth: "145px", color: "white" }}
                            onClick={() => setPrintBarcodes(true)}>Print Labels</Button>)}
                    </Stack>
                    <ErrorAlert error={error} />
                    <BarcodeDialog samples={sampleBarcodes} open={printBarcodes} setOpen={setPrintBarcodes} />
                    <TableContainer>
                        <Table>
                            <TableHead>
                                <TableCell size="small" sx={{ padding: "0" }}></TableCell>
                                <TableCell>Barcode</TableCell>
                                <TableCell>Status</TableCell>
                                <TableCell>Patient Last</TableCell>
                                <TableCell>Patient First</TableCell>
                                <TableCell>Patient DOB</TableCell>
                                <TableCell>Facility</TableCell>
                            </TableHead>
                            <TableBody>
                                {samples.slice().reverse().map((sample, index) => (
                                    <TableRow key={index}>
                                        <TableCell size="small" sx={{ padding: "0" }}>
                                            <Stack direction="row" spacing={0}>
                                                <Button onClick={() => handleDelete(index)}
                                                    size="small"
                                                    sx={{ minWidth: "12px" }}>
                                                    <CloseIcon color="error" />
                                                </Button>
                                                <RenderOrderButton OrderID={sample.OrderID} />
                                            </Stack>
                                        </TableCell>
                                        <TableCell style={{ whiteSpace: "nowrap" }}>{sample.Barcode}</TableCell>
                                        <TableCell style={{ whiteSpace: "nowrap" }}>{GetSampleStatusChip(sample.Status)}</TableCell>
                                        <TableCell style={{ whiteSpace: "nowrap" }}>{sample.Order.Patient.LastName}</TableCell>
                                        <TableCell style={{ whiteSpace: "nowrap" }}>{sample.Order.Patient.FirstName}</TableCell>
                                        <TableCell style={{ whiteSpace: "nowrap" }}>{FormatDateUTC(sample.Order.Patient.DOB)}</TableCell>
                                        <TableCell style={{ whiteSpace: "nowrap" }}>{sample.Order.Facility.Name}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </DialogContent>
                <DialogActions>
                    <Button variant="outlined" onClick={() => { setSamples([]); setError(""); setOpen(false) }}>Cancel</Button>
                    <Button variant="contained" onClick={() => receive()}>Receive</Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

export default SampleReceiveDialog;

