import React, { useEffect, useState } from "react";
import { DataGridPro } from "@mui/x-data-grid-pro";
import { GridToolbar } from "@mui/x-data-grid-pro";
import { getProfiles } from "../services/profiles";

export default function ProfilesTable({ profiles, setProfiles, checkboxes, refresh }) {
    const [allProfiles, setAllProfiles] = useState([]);

    const columns = [
        { field: "ID", headerName: "ID", width: 50 },
        { field: "Code", headerName: "Code", width: 75 },
        { field: "Name", headerName: "Name", width: 175 },
        { field: "Description", headerName: "Description", width: 275 },
        {
            field: "SubProfiles",
            headerName: "SubProfiles",
            width: 150,
            valueGetter: (row) => row.row.SubProfiles.length,
        },
        {
            field: "Tests",
            headerName: "Tests",
            width: 150,
            valueGetter: (row) => row.row.Tests.length,
        },
        {
            field: "Facilities",
            headerName: "Facilities",
            width: 150,
            valueGetter: (row) => { if (row.row.Facilities.length) { return row.row.Facilities.length } else { return "All" } },
        },
    ];

    useEffect(() => {
        getProfiles().then((t) => {
            setAllProfiles(t);
        });
    }, [refresh]);

    return (
        <DataGridPro
            getRowId={(row) => row.ID}
            density="compact"
            sx={{
                "& .MuiDataGrid-columnHeaderCheckbox .MuiDataGrid-columnHeaderTitleContainer":
                {
                    display: "none",
                },
            }}
            components={{ Toolbar: GridToolbar }}
            componentsProps={{
                toolbar: {
                    showQuickFilter: true,
                    quickFilterProps: { debounceMs: 500 },
                },
            }}
            keepNonExistentRowsSelected
            onRowSelectionModelChange={setProfiles}
            rowSelectionModel={profiles}
            checkboxSelection={checkboxes}
            rows={allProfiles}
            columns={columns}
        />
    );
}
