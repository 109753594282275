import React, { useState, useEffect } from "react";
import CollectorsTable from "../components/CollectorsTable";
import { getCollectors, getCollector } from "../services/collectors";
import { Button, Stack } from "@mui/material";
import CollectorInput from "../components/CollectorInput";
import ButtonTray from "./ButtonTray";
import TableFrame from "./TableFrame";
import { DataTableContainer } from "./TableContainer";

export default function CollectorSetup() {
    const initValues = {
        FirstName: "",
        LastName: "",
        Email: "",
        Phone: "",
        IsPhlebotomist: false,
        Facilities: [],
    }

    const [selectedCollector, setSelectedCollector] = useState({});
    const [editMode, setEditMode] = useState(false);
    const [error, setError] = useState(null);
    const [createMode, setCreateMode] = useState(false);
    const [refresh, setRefresh] = useState(false);
    const [initialValues, setInitialValues] = useState(initValues);

    useEffect(() => {
        if (createMode) {
            setInitialValues(initValues)
            return
        }

        if (selectedCollector.length > 0 && editMode) {

            getCollector(selectedCollector[0])
                .then((p) => {
                    if (!p.ok) {
                        throw new Error("Failed to load collector");
                    }
                    return p.json();
                })
                .then((p) => setInitialValues(p))
                .catch((e) => setError(e.message));
        }
    }, [selectedCollector, editMode, createMode]);

    const reset = () => {
        setRefresh(!refresh);
        setEditMode(false);
        setCreateMode(false);
        setSelectedCollector({});
    };

    if (editMode || createMode) {
        return (
            <CollectorInput
                collector={selectedCollector}
                initialValues={initialValues}
                reset={reset}
            />
        );
    }

    return (
        <>
            <TableFrame>
                <DataTableContainer>
                    <CollectorsTable
                        getCollectors={() => getCollectors()}
                        Title=""
                        setSelectedCollector={setSelectedCollector}
                        selectedCollector={selectedCollector}
                        refresh={refresh}
                    />
                </DataTableContainer>
                <ButtonTray>
                    <Button
                        variant="contained"
                        color="success"
                        onClick={() => setCreateMode(true)}
                    >
                        Create
                    </Button>
                    <Button
                        variant="contained"
                        color="primary"
                        disabled={Object.keys(selectedCollector).length === 0}
                        onClick={() => setEditMode(true)}
                    >
                        Edit
                    </Button>
                </ButtonTray>
            </TableFrame>
        </>
    );
}
