import React from "react";
import { DataGridPro, GridCallbackDetails, GridRowSelectionModel, GridToolbar } from "@mui/x-data-grid-pro";
import { DiagnosisCode } from "../constants/types";

const columns = [
    { field: "Code", headerName: "Code" },
    { field: "Description", headerName: "Description", width: 500 },
]

interface DiagnosisDataGridProps {
    diagnosisCodes: DiagnosisCode[];
    selected: GridRowSelectionModel;
    setSelected: (newSelection: GridRowSelectionModel, details: GridCallbackDetails<any>) => void;
}

function DiagnosisDataGrid({ diagnosisCodes, selected, setSelected }: DiagnosisDataGridProps) {
    return (
        <DataGridPro
            getRowId={(row) => row.Code}
            rows={diagnosisCodes || []}
            columns={columns}
            onRowSelectionModelChange={setSelected}
            rowSelectionModel={selected}
            checkboxSelection={true}
            components={{ Toolbar: GridToolbar }}
            componentsProps={{

                toolbar: {
                    showQuickFilter: true,
                    quickFilterProps: { debounceMs: 500 },
                },
            }}
        />
    )


}

export default DiagnosisDataGrid;
