import React, { useState, useEffect } from "react";
import ContactsTable from "../components/ContactsTable";
import { getContacts } from "../services/contacts";
import { Button, Stack } from "@mui/material";
import ContactInput from "../components/ContactInput";
import ButtonTray from "./ButtonTray";
import TableFrame from "./TableFrame";
import { DataTableContainer } from "./TableContainer";

export default function ContactSetup() {
    const initValues = {
        FirstName: "",
        LastName: "",
        Phone1: "",
        Phone2: "",
        Fax: "",
        Email: "",
        TextResults: false,
        FaxResults: false,
        EmailResults: false,
        Critical: false,
        Type: "",
        Notes: "",
        Facilities: [],
        Providers: [],
    };
    const [selectedContact, setSelectedContact] = useState({});
    const [editMode, setEditMode] = useState(false);
    const [createMode, setCreateMode] = useState(false);
    const [refresh, setRefresh] = useState(false);
    const [contact, setContact] = useState(initValues);

    useEffect(() => {
        if (createMode) {
            setContact(initValues);
            return
        }

        if (Object.keys(selectedContact).length > 0 && editMode) {
            const facilityIDs = [];
            const providerIDs = [];

            for (let f of selectedContact.Facilities) {
                facilityIDs.push(f.ID);
            }
            for (let p of selectedContact.Providers) {
                providerIDs.push(p.ID);
            }

            let c = { ...selectedContact };
            c.Providers = providerIDs;
            c.Facilities = facilityIDs;

            setContact(c);
        }
    }, [selectedContact, createMode, editMode]);

    const reset = () => {
        setEditMode(false);
        setCreateMode(false);
        setSelectedContact({});
        setRefresh(!refresh);
    };

    if (editMode || createMode) {
        return <ContactInput contact={contact} reset={reset} />;
    }

    return (
        <>
            <TableFrame>
                <DataTableContainer>
                    <ContactsTable
                        getContacts={() => getContacts()}
                        Title=""
                        setSelectedContact={setSelectedContact}
                        refresh={refresh}
                    />
                </DataTableContainer>
                <ButtonTray>
                    <Button
                        variant="contained"
                        color="success"
                        onClick={() => setCreateMode(true)}
                    >
                        Create
                    </Button>
                    <Button
                        variant="contained"
                        color="primary"
                        disabled={Object.keys(selectedContact).length === 0}
                        onClick={() => setEditMode(true)}
                    >
                        Edit
                    </Button>
                    <Button
                        variant="contained"
                        disabled={Object.keys(selectedContact).length === 0}
                        color="error"
                    >
                        Deactivate
                    </Button>
                </ButtonTray>
            </TableFrame>
        </>
    );
}
