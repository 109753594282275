import { Stack } from "@mui/system";
import { Alert, Button, FormControl, FormControlLabel, InputLabel, MenuItem, Select } from "@mui/material";
import { useState, useEffect } from "react";
import Box from "@mui/system/Box";
import * as Yup from "yup";
import { Formik, Form } from "formik";
import { TextField, Checkbox } from "@mui/material";
import {
    addLaboratory,
    updateLaboratory,
    getLaboratories,
    getLaboratory,
} from "../services/tests";
import { DataGridPro } from "@mui/x-data-grid-pro";
import { GridToolbar } from "@mui/x-data-grid-pro";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import TableFrame from "./TableFrame";
import { DataTableContainer } from "./TableContainer";
import ButtonTray from "./ButtonTray";
import { Timezones } from "../services/util";

export default function LaboratoryPanel({ }) {
    const initValues = {
        Name: "",
        Code: "",
        Address1: "",
        Address2: "",
        City: "",
        State: "",
        ZipCode: "",
        Director: "",
        Phone: "",
        Email: "",
        Fax: "",
        NPI: "",
        CLIA: "",
        Timezone: "America/New_York",
        ReportDisclaimer: "",
        PatientDisclaimer: "",
        Reference: false,
    };

    const [id, setId] = useState();
    const [laboratorys, setLaboratories] = useState([]);
    const [initialValues, setInitialValues] = useState(initValues);
    const [create, setCreate] = useState(false);
    const [error, setError] = useState(null);
    const [refresh, setRefresh] = useState(false);

    useEffect(() => {
        if (id) {
            fetchFormData();
        }
    }, [id]);

    const fetchFormData = async () => {
        getLaboratory(id)
            .then((p) => {
                if (!p.ok) {
                    throw new Error("Failed to load laboratory");
                }
                return p.json();
            })
            .then((p) => {
                console.log(p);
                setInitialValues({
                    Name: p.Name,
                    Code: p.Code,
                    Phone: p.Phone,
                    Fax: p.Fax,
                    Email: p.Email,
                    Director: p.Director,
                    Address1: p.Address.Address1,
                    Address2: p.Address.Address2,
                    City: p.Address.City,
                    State: p.Address.State,
                    ZipCode: p.Address.ZipCode,
                    Timezone: p.Timezone,
                    NPI: p.NPI,
                    CLIA: p.CLIA,
                    ReportDisclaimer: p.ReportDisclaimer,
                    PatientDisclaimer: p.PatientDisclaimer,
                    Reference: p.Reference,
                });
            });
    };

    useEffect(() => {
        getLaboratories()
            .then((p) => {
                if (!p.ok) {
                    throw new Error("Failed to load laboratories");
                }
                return p.json();
            })
            .then((p) => setLaboratories(p));
    }, [refresh]);

    const validationSchema = Yup.object().shape({
        Name: Yup.string().required("Name is required"),
        Code: Yup.string().required("Code is required"),
        Phone: Yup.string().required("Phone is required"),
        Email: Yup.string().required("Email is required"),
        Director: Yup.string(),
        ReportDisclaimer: Yup.string(),
        PatientDisclaimer: Yup.string(),
        Fax: Yup.string().required("Fax is required"),
        Address1: Yup.string().required("Address1 is required"),
        Address2: Yup.string(),
        City: Yup.string().required("City is required"),
        State: Yup.string().required("State is required"),
        ZipCode: Yup.string().required("ZipCode is required"),
        Timezone: Yup.string().oneOf(Timezones).required("Timezone is required"),
        NPI: Yup.string(),
        CLIA: Yup.string().required("CLIA is required"),
        Reference: Yup.boolean(),
    });

    const handleSubmit = (values) => {
        console.log(values);
        if (id) {
            updateLaboratory(id, values)
                .then((p) => {
                    if (!p.ok) {
                        throw new Error("Failed to load laboratories");
                    }
                    setError(null);
                    setRefresh((prevValue) => !prevValue);
                    setCreate(false);
                    return p.json();
                })
                .catch((e) => setError(e.message));
        } else {
            addLaboratory(values)
                .then((p) => {
                    if (!p.ok) {
                        throw new Error("Failed to load laboratories");
                    }
                    setError(null);
                    setRefresh((prevValue) => !prevValue);
                    setCreate(false);
                    return p.json();
                })
                .catch((e) => setError(e.message));
        }
    };

    const laboratoryForm = () => {
        return (
            <>
                <Box>
                    <Formik
                        initialValues={initialValues}
                        validationSchema={validationSchema}
                        onSubmit={handleSubmit}
                        enableReinitialize
                    >
                        {({
                            values,
                            handleChange,
                            handleBlur,
                            errors,
                            touched,
                        }) => (
                            <Form>
                                <Stack direction="column" spacing={2}>
                                    {console.log(errors)}
                                    <InputLabel>General</InputLabel>
                                    <Stack
                                        direction={{ sm: "column", md: "row" }}
                                        spacing={{ xs: 1, sm: 2, md: 4 }}
                                    >
                                        <TextField
                                            name="Code"
                                            value={values.Code}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            error={
                                                touched.Code &&
                                                Boolean(errors.Code)
                                            }
                                            label="Code"
                                            size="small"
                                            helperText={
                                                touched.Code && errors.Code
                                            }
                                            required
                                        />
                                        <TextField
                                            name="Name"
                                            value={values.Name}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            error={
                                                touched.Name &&
                                                Boolean(errors.Name)
                                            }
                                            label="Name"
                                            size="small"
                                            helperText={
                                                touched.Name && errors.Name
                                            }
                                            required
                                        />
                                        <TextField
                                            name="Director"
                                            value={values.Director}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            error={
                                                touched.Director &&
                                                Boolean(errors.Director)
                                            }
                                            label="Laboratory Director"
                                            size="small"
                                            helperText={
                                                touched.Director && errors.Director
                                            }
                                            required
                                        />
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={values.Reference}
                                                />
                                            }
                                            name="Reference"
                                            onChange={handleChange}
                                            label="Reference Lab"
                                        />
                                    </Stack>
                                    <InputLabel>General</InputLabel>
                                    <Stack
                                        direction={{ sm: "column", md: "row" }}
                                        spacing={{ xs: 1, sm: 2, md: 4 }}
                                    >
                                        <TextField
                                            name="Phone"
                                            value={values.Phone}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            error={
                                                touched.Phone &&
                                                Boolean(errors.Phone)
                                            }
                                            label="Phone"
                                            size="small"
                                            helperText={
                                                touched.Phone && errors.Phone
                                            }
                                        />
                                        <TextField
                                            name="Fax"
                                            value={values.Fax}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            error={
                                                touched.Fax &&
                                                Boolean(errors.Fax)
                                            }
                                            label="Fax"
                                            size="small"
                                            helperText={
                                                touched.Fax && errors.Fax
                                            }
                                        />
                                        <TextField
                                            name="Email"
                                            value={values.Email}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            error={
                                                touched.Email &&
                                                Boolean(errors.Email)
                                            }
                                            label="Email"
                                            size="small"
                                            helperText={
                                                touched.Email && errors.Email
                                            }
                                        />
                                        <FormControl>
                                            <InputLabel>Timezone</InputLabel>
                                            <Select
                                                variant="outlined"
                                                sx={{ minWidth: "200px" }}
                                                size="small"
                                                name="Timezone"
                                                value={values.Timezone}
                                                onChange={handleChange}
                                                label="Timezone">
                                                {Timezones.map((tz, i) => (
                                                    <MenuItem key={i} value={tz}>{tz}</MenuItem>))}
                                            </Select>
                                        </FormControl>
                                    </Stack>

                                    <InputLabel>Address</InputLabel>
                                    <Stack
                                        direction={{ sm: "column", md: "row" }}
                                        spacing={{ xs: 1, sm: 2, md: 4 }}
                                    >
                                        <TextField
                                            name="Address1"
                                            value={values.Address1}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            error={
                                                touched.Address1 &&
                                                Boolean(errors.Address1)
                                            }
                                            label="Address1"
                                            size="small"
                                            helperText={
                                                touched.Address1 &&
                                                errors.Address1
                                            }
                                            required
                                        />
                                        <TextField
                                            name="Address2"
                                            value={values.Address2}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            error={
                                                touched.Address2 &&
                                                Boolean(errors.Address2)
                                            }
                                            label="Address2"
                                            size="small"
                                            helperText={
                                                touched.Address2 &&
                                                errors.Address2
                                            }
                                        />
                                        <TextField
                                            name="City"
                                            value={values.City}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            error={
                                                touched.City &&
                                                Boolean(errors.City)
                                            }
                                            label="City"
                                            size="small"
                                            helperText={
                                                touched.City && errors.City
                                            }
                                            required
                                        />
                                    </Stack>
                                    <Stack
                                        direction={{ sm: "column", md: "row" }}
                                        spacing={{ xs: 1, sm: 2, md: 4 }}
                                    >
                                        <TextField
                                            name="State"
                                            value={values.State}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            error={
                                                touched.State &&
                                                Boolean(errors.State)
                                            }
                                            label="State"
                                            size="small"
                                            helperText={
                                                touched.State && errors.State
                                            }
                                            required
                                        />
                                        <TextField
                                            name="ZipCode"
                                            value={values.ZipCode}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            error={
                                                touched.ZipCode &&
                                                Boolean(errors.ZipCode)
                                            }
                                            label="ZipCode"
                                            size="small"
                                            helperText={
                                                touched.ZipCode &&
                                                errors.ZipCode
                                            }
                                            required
                                        />
                                    </Stack>
                                    <InputLabel>Credentials</InputLabel>
                                    <Stack
                                        direction={{ sm: "column", md: "row" }}
                                        spacing={{ xs: 1, sm: 2, md: 4 }}
                                    >
                                        <TextField
                                            name="NPI"
                                            value={values.NPI}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            error={
                                                touched.NPI &&
                                                Boolean(errors.NPI)
                                            }
                                            label="NPI"
                                            size="small"
                                            helperText={
                                                touched.NPI && errors.NPI
                                            }
                                        />
                                        <TextField
                                            name="CLIA"
                                            value={values.CLIA}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            error={
                                                touched.CLIA &&
                                                Boolean(errors.CLIA)
                                            }
                                            label="CLIA"
                                            size="small"
                                            helperText={
                                                touched.CLIA && errors.CLIA
                                            }
                                            required
                                        />
                                    </Stack>
                                    {!values?.Reference &&
                                        <>
                                            <InputLabel>Report Disclaimer</InputLabel>
                                            <TextField
                                                multiline
                                                inputProps={{ maxLength: 600 }}
                                                rows={2}
                                                name="ReportDisclaimer"
                                                label="Disclaimer"
                                                value={values.ReportDisclaimer}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                error={
                                                    touched.ReportDisclaimer &&
                                                    Boolean(errors.ReportDisclaimer)
                                                }
                                                helperText={
                                                    (touched.ReportDisclaimer && errors.ReportDisclaimer) || "600 character limit"
                                                }
                                            /></>}
                                    {!values?.Reference &&
                                        <>
                                            <InputLabel>Patient Disclaimer</InputLabel>
                                            <TextField
                                                multiline
                                                inputProps={{ maxLength: 1500 }}
                                                rows={2}
                                                name="PatientDisclaimer"
                                                label="Disclaimer"
                                                value={values.PatientDisclaimer}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                error={
                                                    touched.PatientDisclaimer &&
                                                    Boolean(errors.PatientDisclaimer)
                                                }
                                                helperText={
                                                    (touched.PatientDisclaimer && errors.PatientDisclaimer) || "1500 character limit"
                                                }
                                            /></>}
                                    {error !== null ? (
                                        <Alert severity="error">{error}</Alert>
                                    ) : null}
                                    <Button type="submit">
                                        {id ? "Save" : "Create"}
                                    </Button>
                                </Stack>
                            </Form>
                        )}
                    </Formik>
                </Box>
            </>
        );
    };

    const getCheckbox = (value) => {
        if (value.value) {
            return (
                <Stack direction="row" alignItems="center">
                    <CheckCircleIcon color="success" />
                </Stack>
            );
        }
        return (
            <Stack direction="row" alignItems="center" justifyContent="center">
                <CheckCircleIcon color="disabled" />
            </Stack>
        );
    };

    const formatAddress = (address1, address2, city, state, zipcode) => {
        let addressString = "";

        if (address1) {
            addressString += address1;
        }

        if (address2) {
            if (addressString) {
                addressString += ", " + address2;
            } else {
                addressString += address2;
            }
        }

        if (city) {
            if (addressString) {
                addressString += ", " + city;
            } else {
                addressString += city;
            }
        }

        if (state) {
            if (addressString) {
                addressString += ", " + state;
            } else {
                addressString += state;
            }
        }

        if (zipcode) {
            if (addressString) {
                addressString += " " + zipcode;
            } else {
                addressString += zipcode;
            }
        }

        return addressString;
    };

    const columns = [
        { field: "Code", headerName: "Code", width: 100 },
        { field: "Name", headerName: "Name", width: 175 },
        {
            field: "Reference",
            headerName: "Reference Lab",
            width: 125,
            renderCell: getCheckbox,
        },
        { field: "CLIA", headerName: "CLIA", width: 125 },
        { field: "NPI", headerName: "NPI", width: 125 },
        {
            field: "Address",
            headerName: "Address",
            width: 250,
            valueGetter: (row) =>
                formatAddress(
                    row.row.Address.Address1,
                    row.row.Address.Address2,
                    row.row.Address.City,
                    row.row.Address.State,
                    row.row.Address.ZipCode
                ),
        },
    ];

    if (create) {
        return laboratoryForm();
    }

    return <>
        <TableFrame>
            <DataTableContainer>
                <DataGridPro
                    getRowId={(row) => row.ID}
                    density="compact"
                    sx={{
                        "& .MuiDataGrid-columnHeaderCheckbox .MuiDataGrid-columnHeaderTitleContainer":
                        {
                            display: "none",
                        },
                    }}
                    components={{ Toolbar: GridToolbar }}
                    componentsProps={{
                        toolbar: {
                            showQuickFilter: true,
                            quickFilterProps: { debounceMs: 500 },
                        },
                    }}
                    keepNonExistentRowsSelected
                    onRowSelectionModelChange={setId}
                    rowSelectionModel={id}
                    rows={laboratorys}
                    columns={columns}
                />
            </DataTableContainer>
            <ButtonTray>
                <Button
                    variant="contained"
                    color="success"
                    onClick={() => {
                        setId(undefined);
                        setInitialValues(initValues);
                        setCreate(true);
                    }}
                >
                    Create
                </Button>
                <Button
                    disabled={!id}
                    variant="contained"
                    color="primary"
                    onClick={() => setCreate(true)}
                >
                    Edit
                </Button>
            </ButtonTray>
        </TableFrame>
    </>;
}
