import React, { useEffect, useState } from "react";

import {
    Divider,
    ListItemText,
    Autocomplete,
    Typography,
    TextField,
    CircularProgress,
} from "@mui/material";

import { getDiagnosisCodes } from "../services/orders";
import DiagnosisTable from "../tables/DiagnosisCode";

function DiagnosisCodeSearch({ diagnosisCodes, setDiagnosis, hideText, notRequired }) {
    const [allDiagnosisCodes, setAllDiagnosisCodes] = useState([]);
    const [searchString, setSearchString] = useState("");
    const [error, setError] = useState("");
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (searchString === "" || searchString.length < 2) {
            return;
        }
        setLoading(true);
        getDiagnosisCodes(searchString)
            .then((p) => {
                if (!p.ok) {
                    throw new Error("Failed to load diagnosis codes");
                }
                return p.json();
            })
            .then((p) => {
                setAllDiagnosisCodes(p.DiagnosisCodes);
                setLoading(false);
            })
            .catch((e) => setError(e.message));
    }, [searchString]);

    console.log('diagnosiscodes', diagnosisCodes)

    return (
        <>
            {!hideText &&
                <>
                    <Typography variant="h5" gutterBottom>
                        Diagnosis Codes
                    </Typography>
                    <ListItemText>
                        Please provide as many applicable diagnosis codes as
                        possible.
                    </ListItemText>
                    <Divider sx={{ m: 1 }} />
                </>
            }
            <Autocomplete
                multiple
                required={!notRequired}
                id="tags-standard"
                options={allDiagnosisCodes}
                onChange={setDiagnosis}
                onInputChange={(_, v) => setSearchString(v)}
                disableListWrap
                filterOptions={(x) => x}
                value={diagnosisCodes}
                noOptionsText={
                    searchString === ""
                        ? "Enter at least two characters to search"
                        : "No codes found"
                }
                loading={loading}
                getOptionLabel={(option) =>
                    option.Code + ": " + option.Description
                }
                isOptionEqualToValue={(option, value) =>
                    option.Code === value.Code
                }
                renderInput={(params) => (
                    <TextField
                        {...params}
                        variant="standard"
                        label="ICD-10 Codes"
                        placeholder="Search for ICD-10 code"
                        required={!notRequired}
                        InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                                <React.Fragment>
                                    {loading ? (
                                        <CircularProgress
                                            color="inherit"
                                            size={20}
                                        />
                                    ) : null}
                                    {params.InputProps.endAdornment}
                                </React.Fragment>
                            ),
                        }}
                    />
                )}
            />
            <Divider sx={{ m: 2 }} />
            {diagnosisCodes && diagnosisCodes.length > 0 && <DiagnosisTable diagnosisCodes={diagnosisCodes} />}
        </>
    );
};

export default DiagnosisCodeSearch;
