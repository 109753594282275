import {
    Dialog,
    DialogContent,
    DialogActions,
    Button,
    Stack,
    TextField,
    Input,
    Typography,
} from "@mui/material";
import SaveIcon from "@mui/icons-material/Save";

import { LoadingButton } from "@mui/lab";
import { Form, Formik } from "formik";
import ErrorAlert from "./ErrorAlert";
import * as Yup from "yup";

function CoriellFileUploadDialog({ open, setOpen, error, handleSubmit }) {
    const initialValues = { File: {} }
    const validationSchema = Yup.object().shape({
        File: Yup.mixed().required("File is required"),
    })

    return (
        <Dialog open={open}>
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
                enableReinitialize
            >
                {({
                    setFieldValue, isSubmitting, errors
                }) => (
                    <Form>
                        {console.log(errors)}
                        <DialogContent>
                            <Stack direction="column" spacing={2}>
                                <Typography variant="body1">Select result file to send to Coriell.</Typography>
                                <Stack direction="row" spacing={2}>
                                    <Input
                                        required
                                        type="file"
                                        inputProps={{ accept: ".csv" }}
                                        onChange={(e) => {
                                            console.log(e);
                                            setFieldValue("File", e.target.files[0]);
                                        }}
                                    />

                                </Stack>
                            </Stack>
                        </DialogContent>
                        <ErrorAlert error={error} />
                        <DialogActions>
                            <Button
                                color="primary"
                                variant="contained"
                                disabled={isSubmitting}
                                onClick={() => {
                                    setOpen(false);
                                }}
                            >
                                Cancel
                            </Button>
                            <LoadingButton
                                startIcon={<SaveIcon />}
                                color="success"
                                variant="contained"
                                loading={isSubmitting}
                                disabled={isSubmitting}
                                type="submit"
                            >
                                <span>Save</span>
                            </LoadingButton>
                        </DialogActions>
                    </Form>
                )}
            </Formik>
        </Dialog>
    )
}

export default CoriellFileUploadDialog;
