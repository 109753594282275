import React, { useEffect, useState } from "react";
import { Box, CircularProgress } from "@mui/material";
import PatientInsurancesTable from "../components/InsurancesTable";
import ErrorAlert from "../components/ErrorAlert";
import { apiFetch } from "../services/fetch";

type PatientInsurancesDetailProps = {
    patientId: number;
}

function PatientInsurancesDetail({ patientId }: PatientInsurancesDetailProps) {
    const [insRow, setInsRow] = useState([]);
    const [error, setError] = useState<null | string>(null);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const init = async () => {
            try {
                setLoading(true);
                const response = await apiFetch(`/patients/${patientId}/insurances`);
                setInsRow(response);

            } catch (error) {
                console.log(error);
                setError("Failed to load patient insurance")
            } finally {
                setLoading(false);
            }
        }

        init()
    }, [patientId])

    return (
        <Box>
            {loading ? <CircularProgress /> :
                <>
                    <PatientInsurancesTable
                        insurances={insRow}
                        setInsurances={setInsRow}
                    />
                    <ErrorAlert error={error} />
                </>
            }
        </Box>
    );
}

export default PatientInsurancesDetail;
