import React from "react";
import { DataGridPro } from "@mui/x-data-grid-pro";
import { GridToolbar } from "@mui/x-data-grid-pro";
import { financialClassIndex, FormatDate } from "../services/util";
import { Box } from "@mui/system";
import Button from "@mui/material/Button";

export default function FeeScheduleTable({
    feeSchedules = [],
    selected = [],
    setSelected = () => { },
    viewFeeSchedule = () => { },
    checkboxes = false,
}) {

    const renderViewButton = (params) => {
        return (
            <strong>
                <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    // style={{ marginLeft: 16 }}
                    onClick={() => {
                        viewFeeSchedule(params.id);
                    }}
                >
                    Details
                </Button>
            </strong>
        );
    };


    const columns = [
        {
            field: "Name",
            headerName: "Name",
            width: 125,
        },
        {
            field: "FinancialClass",
            headerName: "Financial Class",
            width: 175,
            valueGetter: (row) => financialClassIndex[row.row.FinancialClass],
        },
        {
            field: "Multiplier",
            headerName: "Default Multiplier",
            width: 175,
            valueGetter: (row) => row.row.Multiplier + "x",
        },
        {
            field: "DateEffective",
            headerName: "Date Effective",
            width: 175,
            valueGetter: (row) => FormatDate(row.row.DateEffective),
        },
        {
            field: "DateDiscontinue",
            headerName: "Date Discontinue",
            width: 175,
            valueGetter: (row) => FormatDate(row.row.DateDiscontinue),
        },
        {
            field: "AddedBy",
            headerName: "Added By",
            width: 175,
            valueGetter: (row) => row.row.AddedBy.Email,
        },
        {
            field: "View",
            headerName: "View",
            width: 225,
            renderCell: renderViewButton,
        },
    ];

    return (
        <DataGridPro
            getRowId={(row) => row.ID}
            density="compact"
            initialState={{
                sorting: {
                    sortModel: [{ field: "ID", sort: "asc" }],
                },
            }}
            sx={{
                "& .MuiDataGrid-columnHeaderCheckbox .MuiDataGrid-columnHeaderTitleContainer":
                {
                    display: "none",
                },
            }}
            components={{ Toolbar: GridToolbar }}
            componentsProps={{
                toolbar: {
                    showQuickFilter: true,
                    quickFilterProps: { debounceMs: 500 },
                },
            }}
            keepNonExistentRowsSelected
            onRowSelectionModelChange={setSelected}
            rowSelectionModel={selected || []}
            checkboxes={checkboxes}
            rows={feeSchedules || []}
            columns={columns}
        />
    );
}
