import { Typography, Box } from "@mui/material";
import { Stack } from "@mui/system";



function AOEs({ aoes }) {
    if (!aoes) {
        return <></>
    }

    return (
        <Stack direction="column" spacing={2}>
            {aoes.map((aoe) => (
                <Box>
                    <Typography variant="body1">Question: {aoe?.AOE?.Question}</Typography>
                    {Array.isArray(aoe?.Answer) ?
                        <>
                            <Typography variant="body2"><b>Answer:</b></Typography>
                            <ul style={{ margin: 4 }} >
                                {aoe?.Answer.map((ans) =>
                                    (<li>{ans}</li>))}
                            </ul>
                        </> :
                        <Typography variant="body2"><b>Answer: {aoe?.Answer}</b></Typography>}
                </Box>
            ))
            }
        </Stack >
    )

}

export default AOEs;
