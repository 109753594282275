import { Form, Formik } from "formik";
import AOEFields from "./AOEFields";


function PatientOrderAOEs({ aoes, registerSubmit, innerRef }) {
    const initValues = {
    }

    console.log(innerRef)

    for (let aoe of aoes) {
        if (aoe?.Type === 0) {
            initValues[aoe.ID] = false
        } else if (aoe?.Type === 1 || aoe?.Type === 2) {
            initValues[aoe.ID] = ""
        }
    }

    return (
        <Formik
            innerRef={innerRef}
            initialValues={initValues}
        >
            {({ submitForm }) => {
                registerSubmit(submitForm)
                return (
                    <Form>
                        <AOEFields aoes={aoes} />
                    </Form>
                )
            }}
        </Formik>
    )
}

export default PatientOrderAOEs;
