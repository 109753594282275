import React from 'react'
import { ClientBill, Facility, InsurancePay, NoBill, PaymentMethod, ProviderType, ProviderTypeOther, SelfPay } from '../../constants/types'
import { RadioGroup, FormControlLabel, Radio, Typography } from '@mui/material'

type OrderPaymentSelectionProps = {
    paymentMethod: PaymentMethod | null
    setPaymentMethod: (paymentMethod: number) => void
    providerType: ProviderType | undefined
    facility: Facility | null
    patientHasInsurance: boolean
}

function OrderPaymentSelection({
    paymentMethod,
    setPaymentMethod,
    providerType,
    facility,
    patientHasInsurance,
}: OrderPaymentSelectionProps) {

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setPaymentMethod(Number(e.target.value))
    }

    return (
        <RadioGroup
            aria-labelledby="payment_method"
            // defaultValue="insurance" // this should be set based on patient and facility
            value={paymentMethod}
            onChange={handleChange}
            name="payment_method"
        >
            <FormControlLabel
                value={InsurancePay}
                control={
                    <Radio
                        disabled={
                            providerType === ProviderTypeOther ||
                            !facility?.CanInsurance || !patientHasInsurance
                        }
                    />
                }
                label={
                    <div>
                        <Typography >
                            Insurance
                        </Typography>
                        {!facility?.CanInsurance && (
                            <Typography variant="body2">
                                Facility does not allow
                                insurance
                            </Typography>
                        )}
                        {!patientHasInsurance && (
                            <Typography variant="body2">
                                Patient must have at least one
                                insurance defined
                            </Typography>
                        )}
                    </div>
                }
            />
            <FormControlLabel
                value={SelfPay}
                control={
                    <Radio
                        disabled={!facility?.CanSelfPay}
                    />
                }
                label={
                    <div>
                        <Typography>
                            Self Pay
                        </Typography>
                        {!facility?.CanSelfPay ? (
                            <Typography variant="body2">
                                Facility does not allow
                                self pay
                            </Typography>
                        ) : null}
                    </div>
                }
            />
            <FormControlLabel
                value={ClientBill}
                control={
                    <Radio
                        disabled={
                            !facility?.CanClientBill
                        }
                    />
                }
                label={
                    <div>
                        <Typography>
                            Client Bill
                        </Typography>
                        {!facility?.CanClientBill ? (
                            <Typography variant="body2">
                                Facility does not allow
                                client bill
                            </Typography>
                        ) : null}
                    </div>
                }
            />
            <FormControlLabel
                value={NoBill}
                sx={{
                    display:
                        facility?.CanNoBill === true
                            ? "inline"
                            : "none",
                }}
                control={<Radio />}
                label="No Bill"
            />
        </RadioGroup>
    )
}

export default OrderPaymentSelection;
