import {
    FormControlLabel,
    TextField,
    useMediaQuery,
    MenuItem,
    InputLabel,
    Radio,
    RadioGroup,
    Autocomplete,
    Box,
    Stack,
    Button
} from "@mui/material";
import React, { useEffect } from "react";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import ContentCopy from "@mui/icons-material/ContentCopy";
import IconButton from "@mui/material/IconButton";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { financialClassIndex } from "../services/util";
import dayjs from "dayjs";
import utc from 'dayjs/plugin/utc';
import { InsuranceRelationships } from "../constants/constants";

dayjs.extend(utc);

export default function InsuranceInput({
    formik, insurances, hideDiscontinue, hideRemove
}) {
    const isMobile = useMediaQuery("(max-width: 600px)");

    // useEffect(() => {
    // if (!formik.values?.PatientInsurances || formik.values?.PatientInsurances.length < 1) {
    // return
    // }

    // for (let i = 0; i < formik.values?.PatientInsurances.length; i++) {
    // if (formik.values?.PatientInsurances[i].Relationship === "Self") {
    // copyPatientToSubscriber(i);
    // }
    // }


    // }, [formik.values?.FirstName, formik.values?.LastName, formik.values?.DOB])

    const removePatientInsurance = (setFieldValue, index, values) => {
        if (
            values.PaymentMethod === "2" &&
            values.PatientInsurances.length === 1
        ) {
            /// if paymentMethod is insurance - an insurance must be defined
            return;
        }
        const updatedInsurances = [...values.PatientInsurances];
        updatedInsurances.splice(index, 1);
        setFieldValue("PatientInsurances", updatedInsurances);
    };

    const copyPatientToSubscriber = (index) => {
        formik.setFieldValue(`PatientInsurances[${index}].Subscriber.FirstName`, formik.values?.FirstName)
        formik.setTouched({ ...formik.touched, [`PatientInsurances[${index}].Subscriber.FirstName`]: true })
        formik.setFieldValue(`PatientInsurances[${index}].Subscriber.LastName`, formik.values?.LastName)
        formik.setTouched({ ...formik.touched, [`PatientInsurances[${index}].Subscriber.LastName`]: true })
        formik.setFieldValue(`PatientInsurances[${index}].Subscriber.DOB`, formik.values?.DOB)
        formik.setTouched({ ...formik.touched, [`PatientInsurances[${index}].Subscriber.DOB`]: true })
    }



    return formik.values.PatientInsurances.map((_, index) => (
        <>
            <InputLabel>Insurance</InputLabel>
            <Box key={index}>
                <Stack direction="column" spacing={2}>
                    <RadioGroup
                        row
                        name={`PatientInsurances.${index}.Primary`}
                        sx={{ display: "block" }}
                        value={formik.values.PatientInsurances?.[
                            index
                        ]?.Primary.toString()}
                        onChange={(e) =>
                            formik.setFieldValue(
                                `PatientInsurances.${index}.Primary`,
                                e.target.value === "true"
                            )
                        }
                        error={
                            formik.touched.PatientInsurances?.[
                                index
                            ]?.Primary &&
                            Boolean(
                                formik.errors.PatientInsurances?.[
                                    index
                                ]?.Primary
                            )
                        }
                    >
                        <FormControlLabel
                            value={true}
                            control={<Radio />}
                            label="Primary"
                        />
                        <FormControlLabel
                            value={false}
                            control={<Radio />}
                            label="Secondary"
                            disabled={
                                formik.values.PatientInsurances
                                    .length === 1 || index === 0
                            }
                        />
                    </RadioGroup>
                    <Stack
                        direction={{
                            sm: "column",
                            md: "row",
                        }}
                        spacing={{ xs: 1, sm: 2, md: 4 }}
                    >
                        <Autocomplete
                            required
                            options={insurances}
                            autoFocus={true}
                            sx={{
                                minWidth: "223px",
                                maxWidth: "100%",
                            }}
                            getOptionLabel={(row) => row.Name || ""}
                            isOptionEqualToValue={(option, value) =>
                                option.id === value.id
                            }
                            value={
                                formik.values.PatientInsurances?.[
                                    index
                                ]?.Insurance
                            }
                            groupBy={(option) =>
                                financialClassIndex[
                                option.FinancialClass
                                ]
                            }
                            onChange={(_, value) => {
                                formik.setFieldValue(
                                    `PatientInsurances.${index}.InsuranceID`,
                                    value?.ID || ""
                                );
                                formik.setFieldValue(
                                    `PatientInsurances.${index}.Insurance`,
                                    value
                                );
                            }}
                            renderInput={(params) => {
                                return (
                                    <TextField
                                        required
                                        {...params}
                                        size="small"
                                        label="Insurance"
                                        helperText={
                                            formik.touched.PatientInsurances?.[
                                                index
                                            ]?.InsuranceID &&
                                            formik.errors.PatientInsurances?.[
                                                index
                                            ]?.InsuranceID
                                        }
                                        error={
                                            formik.touched.PatientInsurances?.[
                                                index
                                            ]?.InsuranceID &&
                                            Boolean(
                                                formik.errors
                                                    .PatientInsurances?.[index]
                                                    ?.InsuranceID
                                            )
                                        }
                                    />
                                );
                            }}
                        />
                        <TextField
                            name={`PatientInsurances.${index}.MemberNumber`}
                            id={`PatientInsurances.${index}.MemberNumber`}
                            label="Member Number"
                            size="small"
                            onChange={formik.handleChange}
                            error={
                                formik.touched.PatientInsurances?.[
                                    index
                                ]?.MemberNumber &&
                                Boolean(
                                    formik.errors
                                        .PatientInsurances?.[index]
                                        ?.MemberNumber
                                )
                            }
                            value={
                                formik.values.PatientInsurances?.[
                                    index
                                ]?.MemberNumber
                            }
                            onBlur={formik.handleBlur}
                            helperText={
                                formik.touched.PatientInsurances?.[
                                    index
                                ]?.MemberNumber &&
                                formik.errors.PatientInsurances?.[
                                    index
                                ]?.MemberNumber
                            }
                            required
                        />
                        <TextField
                            name={`PatientInsurances.${index}.GroupNumber`}
                            id={`PatientInsurances.${index}.GroupNumber`}
                            size="small"
                            label="Group Number"
                            onChange={formik.handleChange}
                            error={
                                formik.touched.PatientInsurances?.[
                                    index
                                ]?.GroupNumber &&
                                Boolean(
                                    formik.errors
                                        .PatientInsurances?.[index]
                                        ?.GroupNumber
                                )
                            }
                            value={
                                formik.values.PatientInsurances?.[
                                    index
                                ]?.GroupNumber
                            }
                            onBlur={formik.handleBlur}
                            helperText={
                                formik.touched.PatientInsurances?.[
                                    index
                                ]?.GroupNumber &&
                                formik.errors.PatientInsurances?.[
                                    index
                                ]?.GroupNumber
                            }
                        />
                        <LocalizationProvider
                            dateAdapter={AdapterDayjs}
                        >
                            <DatePicker
                                name="DateEffective"
                                label="Date Effective"
                                value={
                                    formik.values
                                        .PatientInsurances?.[index]
                                        ?.DateEffective
                                }
                                onChange={(date) => {
                                    const formattedDate = dayjs(date).format('YYYY-MM-DD');
                                    formik.setFieldValue(
                                        `PatientInsurances.${index}.DateEffective`,
                                        dayjs.utc(formattedDate)
                                    );
                                }}
                                helperText={
                                    formik.touched
                                        .PatientInsurances?.[index]
                                        ?.DateEffective &&
                                    Boolean(
                                        formik.errors
                                            .PatientInsurances?.[
                                            index
                                        ]?.DateEffective
                                    )
                                }
                                error={
                                    formik.touched
                                        .PatientInsurances?.[index]
                                        ?.DateEffective &&
                                    Boolean(
                                        formik.errors
                                            .PatientInsurances?.[
                                            index
                                        ]?.DateEffective
                                    )
                                }
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        size="small"
                                        helperText="mm/dd/yyyy"
                                    />
                                )}
                            />
                        </LocalizationProvider>
                        {!hideDiscontinue &&
                            <LocalizationProvider
                                dateAdapter={AdapterDayjs}
                            >
                                <DatePicker
                                    name="DateDiscontinue"
                                    label="Date Discontinue"
                                    value={
                                        formik.values
                                            .PatientInsurances?.[index]
                                            ?.DateDiscontinue
                                    }
                                    onChange={(date) => {
                                        const formattedDate = dayjs(date).format('YYYY-MM-DD');
                                        formik.setFieldValue(
                                            `PatientInsurances.${index}.DateDiscontinue`,
                                            dayjs.utc(formattedDate)
                                        );
                                    }}
                                    helperText={
                                        formik.touched
                                            .PatientInsurances?.[index]
                                            ?.DateDiscontinue &&
                                        Boolean(
                                            formik.errors
                                                .PatientInsurances?.[
                                                index
                                            ]?.DateDiscontinue
                                        )
                                    }
                                    error={
                                        formik.touched
                                            .PatientInsurances?.[index]
                                            ?.DateDiscontinue &&
                                        Boolean(
                                            formik.errors
                                                .PatientInsurances?.[
                                                index
                                            ]?.DateDiscontinue
                                        )
                                    }
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            size="small"
                                            helperText="mm/dd/yyyy"
                                        />
                                    )}
                                />
                            </LocalizationProvider>}

                        {!hideRemove && (!isMobile ? (
                            <IconButton
                                variant="outlined"
                                sx={{ alignItems: "flex-start" }}
                                color="error"
                                size="small"
                                type="button"
                                onClick={() =>
                                    removePatientInsurance(
                                        formik.setFieldValue,
                                        index,
                                        formik.values
                                    )
                                }
                            >
                                <RemoveCircleOutlineIcon />
                            </IconButton>
                        ) : (
                            <Button
                                variant="outlined"
                                color="error"
                                size="small"
                                startIcon={
                                    <RemoveCircleOutlineIcon />
                                }
                                type="button"
                                onClick={() =>
                                    removePatientInsurance(
                                        formik.setFieldValue,
                                        index,
                                        formik.values
                                    )
                                }
                            >
                                Remove
                            </Button>
                        ))}
                    </Stack>
                    <Stack
                        direction={{
                            sm: "column",
                            md: "row",
                        }}
                        spacing={{ xs: 1, sm: 2, md: 4 }}
                    >
                        <TextField
                            name={`PatientInsurances.${index}.Subscriber.FirstName`}
                            id={`PatientInsurances.${index}.Subscriber.FirstName`}
                            label="Subscriber First Name"
                            size="small"
                            onChange={formik.handleChange}
                            error={
                                formik.touched.PatientInsurances?.[
                                    index
                                ]?.Subscriber?.FirstName &&
                                Boolean(
                                    formik.errors
                                        .PatientInsurances?.[index]
                                        ?.Subscriber?.FirstName
                                )
                            }
                            value={
                                formik.values.PatientInsurances?.[
                                    index
                                ]?.Subscriber?.FirstName
                            }
                            onBlur={formik.handleBlur}
                            InputLabelProps={{ shrink: Boolean(formik.values.PatientInsurances[index].Subscriber?.FirstName) }}
                            helperText={
                                formik.touched.PatientInsurances?.[
                                    index
                                ]?.Subscriber?.FirstName &&
                                formik.errors.PatientInsurances?.[
                                    index
                                ]?.Subscriber?.FirstName
                            }
                            required
                        />
                        <TextField
                            name={`PatientInsurances.${index}.Subscriber.LastName`}
                            id={`PatientInsurances.${index}.Subscriber.LastName`}
                            label="Subscriber Last Name"
                            size="small"
                            onChange={formik.handleChange}
                            error={
                                formik.touched.PatientInsurances?.[
                                    index
                                ]?.Subscriber?.LastName &&
                                Boolean(
                                    formik.errors
                                        .PatientInsurances?.[index]
                                        ?.Subscriber?.LastName
                                )
                            }
                            value={
                                formik.values.PatientInsurances?.[
                                    index
                                ]?.Subscriber?.LastName
                            }
                            onBlur={formik.handleBlur}
                            InputLabelProps={{ shrink: Boolean(formik.values.PatientInsurances[index].Subscriber?.LastName) }}
                            helperText={
                                formik.touched.PatientInsurances?.[
                                    index
                                ]?.Subscriber?.LastName &&
                                formik.errors.PatientInsurances?.[
                                    index
                                ]?.Subscriber?.LastName
                            }
                            required
                        />
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                                name="Subscriber.DOB"
                                label="Subscriber Date of Birth"
                                InputLabelProps={{ shrink: Boolean(formik.values.PatientInsurances[index].Subscriber?.DOB) }}
                                value={formik.values.PatientInsurances[index]?.Subscriber?.DOB}
                                required
                                onChange={(e, _) => {
                                    if (e === null || e.toString() === "Invalid Date") {
                                        return
                                    }
                                    const formattedDate = dayjs(e).format('YYYY-MM-DD');
                                    formik.setFieldValue(`PatientInsurances[${index}].Subscriber.DOB`, dayjs.utc(formattedDate))
                                }}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        size="small"
                                        required
                                        helperText={
                                            "mm/dd/yyyy"
                                        }
                                    // error={
                                    // Boolean(formik?.errors?.PatientInsurances[index]?.Subscriber?.DOB)
                                    // }
                                    />
                                )}
                            />
                        </LocalizationProvider>
                        <TextField
                            required
                            select
                            name={`PatientInsurances[${index}].Relationship`}
                            label="Relationship to Subscriber"
                            variant="outlined"
                            sx={{ minWidth: "200px" }}
                            size="small"
                            value={formik.values.PatientInsurances[index].Relationship}
                            onChange={(e) => {
                                formik.setFieldValue(`PatientInsurances[${index}].Relationship`, e.target.value);
                            }}
                        >
                            {InsuranceRelationships.map((option) => (
                                <MenuItem value={option}>{option}</MenuItem>
                            ))}
                        </TextField>
                        {!isMobile &&
                            <IconButton sx={{ alignItems: "flex-start" }}>
                                <ContentCopy onClick={() => copyPatientToSubscriber(index)} />
                            </IconButton>}

                    </Stack>
                </Stack>
            </Box>
        </>
    ))
}
