import { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import ResultsTable from "../components/ResultsTable";
import { TextField, MenuItem, Dialog, DialogContent, Input, DialogActions, Alert, Typography } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import { Form, Formik } from "formik";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import * as Yup from "yup";
import TabPanel, { a11yProps } from "../components/TabPanel";
import Framework from "../components/Framework";
import PlateSetup from "../components/PlateSetup";
import { useTabState } from "../components/useTabState";
import InstrumentResultFilesTable from "../components/InstrumentResultFiles";
import { addInterfaceResultFile, getInstrumentInterfaces } from "../services/samples";
import { getInstruments } from "../services/methods";
import SaveIcon from "@mui/icons-material/Save";

export default function Results() {
    const [value, setValue] = useTabState(0);
    const [showUpload, setShowUpload] = useState(false);
    const [processing, setProcessing] = useState(false);
    const [interfaces, setInterfaces] = useState([]);
    const [instruments, setInstruments] = useState([]);
    const [error, setError] = useState(null);

    useEffect(() => {
        getInstruments()
            .then((p) => {
                if (!p.ok) {
                    throw new Error("Failed to load interfaces");
                }
                return p.json();
            })
            .then((p) => {
                setInstruments(p);
            })
            .catch(e => setError(e.message));

        getInstrumentInterfaces()
            .then((p) => {
                if (!p.ok) {
                    throw new Error("Failed to load interfaces");
                }
                return p.json();
            })
            .then((p) => {
                setInterfaces(p);
            })
            .catch(e => setError(e.message));
    }, [])
    const handleChange = (_, newValue) => {
        setValue(newValue);
    };

    const validationSchema = Yup.object().shape({
        File: Yup.mixed(),
        Interface: Yup.number(),
    })

    const initialValues = {
        File: "",
        Interface: "",
        InstrumentIdentifier: "",
    }

    const handleSubmit = (values) => {
        setProcessing(true)
        console.log(values);
        addInterfaceResultFile(values.Interface, values.File, { "InstrumentIdentifier": values?.InstrumentIdentifier })
            .then(p => {
                if (!p.ok) {
                    throw new Error("Failed to save results file")
                }
                setError(null)
                setShowUpload(false)
                return p.json()
            }).catch(e => setError(e.message))
            .finally(() => setProcessing(false))
    };

    return (
        <Framework table>
            <Dialog open={showUpload}>
                <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={handleSubmit}
                    enableReinitialize
                >
                    {({
                        values,
                        handleChange,
                        handleBlur,
                        errors,
                        setFieldValue,
                    }) => (
                        <Form>
                            <DialogContent>
                                <Stack direction="column" spacing={2}>
                                    <Typography variant="body1">Select result file and associated interface.</Typography>
                                    <Stack direction="row" spacing={2}>
                                        <Input
                                            required
                                            type="file"
                                            inputProps={{ accept: ".csv" }}
                                            onChange={(e) => {
                                                console.log(e);
                                                setFieldValue("File", e.target.files[0]);
                                            }}
                                        />

                                        <TextField
                                            required
                                            select
                                            size="small"
                                            value={values?.Interface}
                                            name="Interface"
                                            label="Interface"
                                            sx={{ minWidth: "200px" }}
                                            errors={errors?.Interface}
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                        >
                                            {interfaces.map((value, index) => (
                                                <MenuItem key={index} value={value.ID}>
                                                    {value.Name}
                                                </MenuItem>
                                            ))}
                                        </TextField>
                                        {[3, 11].includes(values?.Interface) && <TextField
                                            required
                                            select
                                            size="small"
                                            value={values?.InstrumentIdentifier}
                                            name="InstrumentIdentifier"
                                            label="Instrument"
                                            sx={{ minWidth: "200px" }}
                                            errors={errors?.InstrumentIdentifier}
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                        >
                                            {instruments.map((value, index) => (
                                                <MenuItem key={index} value={value.InstrumentIdentifier}>
                                                    {value.Name}
                                                </MenuItem>
                                            ))}
                                        </TextField>}
                                    </Stack>
                                </Stack>
                            </DialogContent>
                            {error !== null ? <Alert sx={{ mx: 2 }} severity="error">{error}</Alert> : null}
                            <DialogActions>
                                <Button
                                    color="primary"
                                    variant="contained"
                                    disabled={processing}
                                    onClick={() => {
                                        setShowUpload(false);
                                    }}
                                >
                                    Cancel
                                </Button>
                                <LoadingButton
                                    startIcon={<SaveIcon />}
                                    color="success"
                                    variant="contained"
                                    loading={processing}
                                    disabled={processing}
                                    type="submit"
                                >
                                    <span>Save</span>
                                </LoadingButton>
                            </DialogActions>
                        </Form>
                    )}
                </Formik>
            </Dialog>
            <Box sx={{ pl: 2, pr: 2, pt: 1 }}>
                <Typography variant="h5">Results</Typography>
                <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                    <Stack direction="row" justifyContent="space-between">
                        <Tabs
                            value={value}
                            onChange={handleChange}
                            aria-label="basic tabs example"
                            variant="scrollable"
                        >
                            <Tab label="Pending" {...a11yProps(0)} />
                            <Tab label="Plate Setup" {...a11yProps(1)} />
                            <Tab label="On the Way" {...a11yProps(2)} />
                            <Tab label="Not Performed" {...a11yProps(3)} />
                            <Tab label="Validated" {...a11yProps(4)} />
                            <Tab label="Result Files" {...a11yProps(5)} />
                        </Tabs>

                        <Button
                            onClick={() => setShowUpload(true)}
                            variant="contained"
                            color="primary"
                            sx={{ maxHeight: "35px", minWidth: "145px" }}
                            size="small"
                        >
                            Upload Results
                        </Button>
                    </Stack>
                </Box>
            </Box>
            <TabPanel value={value} index={0}>
                <ResultsTable filter="pending" />
            </TabPanel>
            <TabPanel value={value} index={1}>
                <PlateSetup />
            </TabPanel>
            <TabPanel value={value} index={2}>
                <ResultsTable filter="waiting_on_sample" />
            </TabPanel>
            <TabPanel value={value} index={3}>
                <ResultsTable filter="tnp" disableEdit={true} />
            </TabPanel>
            <TabPanel value={value} index={4}>
                <ResultsTable filter="validated" disableEdit={true} allowUnvalidate />
            </TabPanel>
            <TabPanel value={value} index={5}>
                <InstrumentResultFilesTable />
            </TabPanel>
        </Framework>
    );
}
