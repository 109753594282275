import React from "react";
import { Autocomplete, Button, Stack } from "@mui/material";
import TextField from "@mui/material/TextField";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import { useNavigate } from "react-router";
import { useFormikContext } from "formik";
import { FormatDateUTC } from "../services/util";

export default function PatientSearch({ patients, patient, setPatient }) {
    const { values } = useFormikContext()

    const navigate = useNavigate();
    // accepts facility id for searching
    //
    const handleAddPatient = () => {
        navigate("/newPatient")
    };

    const formatPatients = () => {
        //creates values for use in patient search box
        let patientOptions = [];
        for (let p of patients) {
            patientOptions.push({
                label:
                    p["LastName"] +
                    ", " +
                    p["FirstName"] +
                    " " +
                    FormatDateUTC(p["DOB"]),
                key: p["ID"],
                patient: p,
            });
        }
        return patientOptions;
    };

    const getValue = () => {
        if (patient && patient?.LastName) {
            return {
                label:
                    patient["LastName"] +
                    ", " +
                    patient["FirstName"] +
                    " " +
                    FormatDateUTC(patient["DOB"]),
                key: patient["ID"],
                patient: patient,
            }
        }
        return null

    }

    return (
        <>
            <Stack
                direction="column"
                spacing={2}
                width="100%"
                justifyContent="center"
            >
                <Autocomplete
                    selectOnFocus
                    disablePortal
                    options={formatPatients().sort((a, b) => a.patient?.LastName > b.patient?.LastName)}
                    disabled={!values.Facility?.ID}
                    isOptionEqualToValue={(option, value) =>
                        option.ID === value?.ID
                    }
                    sx={{ width: "100%" }}
                    onChange={(_, e) => {
                        if (!e?.patient) {
                            setPatient(null)
                        } else {
                            setPatient(e?.patient)
                        }
                    }}
                    value={getValue()}
                    renderOption={(props, option) => {
                        return (
                            <li {...props} key={option.key}>
                                {option.label}
                            </li>
                        );
                    }}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            InputProps={{
                                ...params.InputProps,
                                type: "search",
                            }}
                            label="Patient Search"
                        />
                    )}
                />
                <Button
                    size="small"
                    sx={{ float: "right" }}
                    color="success"
                    variant="contained"
                    onClick={handleAddPatient}
                    endIcon={<PersonAddIcon />}
                >
                    New Patient
                </Button>
            </Stack>
        </>
    );
}
