import React, { useEffect, useState } from "react";
import { DataGridPro } from "@mui/x-data-grid-pro";
import { GridToolbar } from "@mui/x-data-grid-pro";
import { getReflexes } from "../services/tests";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

export default function ReflexesTable({
    reflexes,
    setReflexes,
    reflexSource,
    setReflexDetails,
    checkboxes,
    refresh,
}) {
    const [allReflexes, setAllReflexes] = useState([]);

    const getCheckbox = (value) => {
        if (value.value) {
            return <CheckCircleIcon color="success" />;
        }
        return <CheckCircleIcon color="disabled" />;
    };


    const columns = [
        { field: "ID", headerName: "ID", width: 50 },
        { field: "AutoOrder", headerName: "Auto Order Enabled", width: 150, renderCell: getCheckbox },
        { field: "Description", headerName: "Description", width: 375 },
        {
            field: "Source",
            headerName: "Source",
            width: 175,
            valueGetter: (row) => (row.row.SourceTest?.Name || row.row.SourceProfile?.Name)
        },
        {
            field: "Dest",
            headerName: "Reflexes To",
            width: 175,
            valueGetter: (row) => (row.row.DestTest?.Name || row.row.DestProfile?.Name)
        },
        {
            field: "ReflexRangeType",
            headerName: "Reflexes When",
            width: 150,
        },
    ];

    const handleSelect = (v, e) => {
        if (setReflexDetails !== undefined) {
            let td = [];
            for (let ref of v) {
                for (let details of reflexes) {
                    if (details.ID === ref) {
                        td.push(details);
                    }
                }
            }
            setReflexDetails(td);
        }
        setReflexes(v);
    };

    useEffect(() => {
        if (reflexSource === undefined) {
            getReflexes().then((t) => {
                if (t.length > 0) {
                    setAllReflexes(t);
                }
            });
        } else {
            setAllReflexes(reflexSource);
        }
    }, [reflexSource, refresh]);

    return (
        <DataGridPro
            getRowId={(row) => row.ID}
            density="compact"
            sx={{
                "& .MuiDataGrid-columnHeaderCheckbox .MuiDataGrid-columnHeaderTitleContainer":
                {
                    display: "none",
                },
            }}
            components={{ Toolbar: GridToolbar }}
            componentsProps={{
                toolbar: {
                    showQuickFilter: true,
                    quickFilterProps: { debounceMs: 500 },
                },
            }}
            keepNonExistentRowsSelected
            onRowSelectionModelChange={handleSelect}
            rowSelectionModel={reflexes}
            checkboxSelection={checkboxes}
            rows={allReflexes}
            columns={columns}
        />
    );
}
