import React from "react";
import {
    Dialog,
    DialogContent,
    DialogActions,
    Button,
    Stack,
    Typography,
    Select,
    InputLabel,
    MenuItem,
    FormControl
} from "@mui/material";
import SaveIcon from "@mui/icons-material/Save";

import { LoadingButton } from "@mui/lab";
import { Form, Formik } from "formik";
import ErrorAlert from "./ErrorAlert";
import { CustomCheckboxField } from "./CustomTextField";
import { useEffect, useState } from "react";
import { apiFetch } from "../services/fetch";

function CoriellSampleSelect({ open, setOpen, error, handleSubmit, batchId, samples = [] }) {
    const initialValues = { Samples: {}, Template: "", Panel: "" }
    const [templates, setTemplates] = useState([]);
    const [panels, setPanels] = useState([]);

    useEffect(() => {
        const init = async () => {
            const coriellSetupReponse = await apiFetch('/integrations/coriell')
            setTemplates(coriellSetupReponse?.Templates)
            setPanels(coriellSetupReponse?.Panels)
        }
        init()

    }, [])

    console.log(samples)


    return (
        <Dialog open={open}>
            <Formik
                initialValues={initialValues}
                onSubmit={handleSubmit}
                enableReinitialize
            >
                {({
                    isSubmitting, errors, setFieldValue, values
                }) => (
                    <Form>
                        {console.log(errors)}
                        {console.log(values)}
                        <DialogContent>
                            <Stack direction="column" spacing={2}>
                                <Typography variant="body1">Successfully created batch: {batchId}</Typography>
                                <Typography variant="body1">The following valid samples were returned by Coriell. Please select the samples you want to generate reports for.</Typography>
                                {samples.sort().map((sample) => (
                                    <CustomCheckboxField name={`Samples.${sample}`} label={sample}
                                        checked={values?.Samples?.[`${sample}`] || false}
                                        onChange={(e) => setFieldValue(`Samples.${sample}`, e.target.checked)}
                                    />
                                ))}
                                <FormControl fullWidth>
                                    <InputLabel id="demo-simple-select-label">Template</InputLabel>
                                    <Select
                                        value={values?.Template}
                                        label="Template"
                                        onChange={(e) => setFieldValue('Template', e.target.value)}
                                    >
                                        {templates.sort().map((temp) => (
                                            <MenuItem value={temp}>{temp}</MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                                <FormControl fullWidth>
                                    <InputLabel id="demo-simple-select-label">Panel</InputLabel>
                                    <Select
                                        value={values?.Panel}
                                        label="Panel"
                                        onChange={(e) => setFieldValue('Panel', e.target.value)}
                                    >
                                        {panels.sort().map((panel) => (
                                            <MenuItem value={panel}>{panel}</MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>


                            </Stack>
                        </DialogContent>
                        <ErrorAlert error={error} />
                        <DialogActions>
                            <Button
                                color="primary"
                                variant="contained"
                                disabled={isSubmitting}
                                onClick={() => {
                                    setOpen(false);
                                }}
                            >
                                Cancel
                            </Button>
                            <LoadingButton
                                startIcon={<SaveIcon />}
                                color="success"
                                variant="contained"
                                loading={isSubmitting}
                                disabled={isSubmitting}
                                type="submit"
                            >
                                <span>Save</span>
                            </LoadingButton>
                        </DialogActions>
                    </Form>
                )}
            </Formik>
        </Dialog>
    )
}

export default CoriellSampleSelect;
