import { Autocomplete, TextField } from "@mui/material";
import React from "react";
import { OrderStatus, OrderStatuses } from "../../constants/constants";


type OrderStatusPickerProps = {
    status: OrderStatus[] | null;
    setOrderStatus: (status: OrderStatus[] | []) => void;
}

function OrderStatusPicker({ status, setOrderStatus, ...props }: OrderStatusPickerProps) {

    return (
        <Autocomplete
            multiple={true}
            options={OrderStatuses}
            value={status || []}
            isOptionEqualToValue={(option, value) =>
                option === value
            }
            onChange={(_, newValue) => {
                setOrderStatus(newValue)
            }}
            renderInput={(params) => {
                return (
                    <TextField
                        {...params}
                        label="Order Statuses"
                        size="small"
                        {...props}
                    />
                );
            }}
        />
    );
}

export default OrderStatusPicker;
