export async function getTests() {
    return fetch(`${process.env.REACT_APP_API_PROXY}/v1/tests`, {
        credentials: "include",
    }).then((data) => data.json());
}
export async function getOrderableTests() {
    return fetch(`${process.env.REACT_APP_API_PROXY}/v1/tests?filter=orderable`, {
        credentials: "include",
    }).then((data) => data.json());
}

export async function getTest(test_id) {
    return fetch(`${process.env.REACT_APP_API_PROXY}/v1/tests/` + test_id, {
        credentials: "include",
    }).then((data) => data.json());
}

export async function getTestByCode(code) {
    return fetch(`${process.env.REACT_APP_API_PROXY}/v1/tests?code=` + code, {
        credentials: "include",
    });
}


export async function addTest(test) {
    return fetch(`${process.env.REACT_APP_API_PROXY}/v1/tests`, {
        method: "POST",
        credentials: "include",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
        },
        body: JSON.stringify(test),
    });
}

export async function updateTest(test, test_id) {
    return fetch(`${process.env.REACT_APP_API_PROXY}/v1/tests/` + test_id, {
        method: "POST",
        credentials: "include",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
        },
        body: JSON.stringify(test),
    });
}

export async function getDepartment(id) {
    return fetch(`${process.env.REACT_APP_API_PROXY}/v1/departments/${id}`, {
        credentials: "include",
    })
}

export async function getDepartments() {
    return fetch(`${process.env.REACT_APP_API_PROXY}/v1/departments`, {
        credentials: "include",
    })
}

export async function addDepartment(data) {
    return fetch(`${process.env.REACT_APP_API_PROXY}/v1/departments`, {
        method: "POST",
        credentials: "include",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
    });
}

export async function updateDepartment(id, data) {
    return fetch(`${process.env.REACT_APP_API_PROXY}/v1/departments/${id}`, {
        method: "POST",
        credentials: "include",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
    });
}

export async function getLaboratory(id) {
    return fetch(`${process.env.REACT_APP_API_PROXY}/v1/laboratories/${id}`, {
        credentials: "include",
    });
}
export async function getLaboratories() {
    return fetch(`${process.env.REACT_APP_API_PROXY}/v1/laboratories`, {
        credentials: "include",
    });
}
export async function updateLaboratory(id, laboratory) {
    return fetch(`${process.env.REACT_APP_API_PROXY}/v1/laboratories/${id}`, {
        method: "POST",
        credentials: "include",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
        },
        body: JSON.stringify(laboratory),
    });
}

export async function addLaboratory(laboratory) {
    return fetch(`${process.env.REACT_APP_API_PROXY}/v1/laboratories`, {
        method: "POST",
        credentials: "include",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
        },
        body: JSON.stringify(laboratory),
    });
}

export async function getReflexes() {
    return fetch(`${process.env.REACT_APP_API_PROXY}/v1/reflexes`, {
        credentials: "include",
    }).then((data) => data.json());
}

export async function getReflex(reflexes_id) {
    return fetch(
        `${process.env.REACT_APP_API_PROXY}/v1/reflexes/` + reflexes_id,
        { credentials: "include" }
    ).then((data) => data.json());
}

export async function searchReflexes(testIds, profileIds) {
    let body = {TestIDs: testIds, ProfileIDs: profileIds}
    return fetch(
        `${process.env.REACT_APP_API_PROXY}/v1/reflexes/search`,
        { credentials: "include",
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json"
            },
            body: JSON.stringify(body)}
    ).then((data) => data.json());
}

export async function addReflex(reflex) {
    return fetch(`${process.env.REACT_APP_API_PROXY}/v1/reflexes`, {
        method: "POST",
        credentials: "include",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
        },
        body: JSON.stringify(reflex),
    }).then((response) => response.json());
}

export async function updateReflex(reflex, reflex_id) {
    return fetch(
        `${process.env.REACT_APP_API_PROXY}/v1/reflexes/` + reflex_id,
        {
            method: "POST",
            credentials: "include",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify(reflex),
        }
    ).then((response) => response.json());
}

export async function getCMSAllowable(cpt_code) {
    return fetch(
        `${process.env.REACT_APP_API_PROXY}/v1/tests/cmsAllowable?cpt_code=${cpt_code}`,
        { credentials: "include" }
    );
}

