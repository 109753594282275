import React, { useState, useEffect, useContext } from "react";
import * as Yup from "yup";
import { Formik, useFormik, Form, useFormikContext } from "formik";
import { useNavigate, useLocation } from "react-router";
import {
    Grid,
    Paper,
    Avatar,
    TextField,
    Button,
    Typography,
    Link,
} from "@mui/material";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import Logo from "../images/logo.png";
import { Box, Stack } from "@mui/system";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import { checkToken, login } from "../services/auth";
import { Alert } from "@mui/material";
import { decodeCookie, SessionContext } from "../hooks/getContext";
import mdTheme from "../components/Theme";
import { useMediaQuery } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import { CustomTextField } from "../components/CustomTextField";
import { getPatientResult, getPatientResultReport } from "../services/results";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import ViewPDF from "./ViewPDF";

export default function PatientResult() {
    const [error, setError] = useState("");
    const queryParameters = new URLSearchParams(window.location.search);
    const [disabled, setDisabled] = useState(false);
    const [loading, setLoading] = useState(false);
    const id = queryParameters.get("id");
    const [pdf, setPDF] = useState(null);

    const isMobile = useMediaQuery(mdTheme.breakpoints.down("sm"));

    useEffect(() => {
        if (id === "" || id === null || id === undefined) {
            setError("Link has expired.");
            setDisabled(true);
        }

        getPatientResult(id)
            .then((p) => {
                if (!p.ok) {
                    throw new Error("Link has expired");
                }
                return p.json();
            })
            .catch((e) => {
                setError(e.message);
                setDisabled(true);
            });
    }, [id]);

    const initialValues = {
        LastName: "",
        FirstName: "",
        DOB: null,
        DOC: null,
    };

    const validationSchema = Yup.object().shape({
        FirstName: Yup.string().required(),
        LastName: Yup.string().required(),
        DOB: Yup.date().required(),
        DOC: Yup.date().required(),
    });

    const handleSubmit = (values) => {
        setLoading(true);
        setDisabled(true);

        getPatientResultReport(id, values)
            .then((response) => {
                if (!response.ok) {
                    throw new Error("Authentication failed. Please try again.");
                }
                return response.blob();
            })
            .then(body => body.arrayBuffer())
            .then((buffer) => {
                var pd = new Uint8Array(buffer)
                setPDF(pd)
            })
            .catch((e) => setError("Authentication failed. Please try again."))
            .finally(() => { setLoading(false); setDisabled(false) });
    };

    const desktopPaper = {
        padding: 20,
        // height: 750,
        width: 480,
        margin: "20px auto",
    };

    const mobilePaper = {
        width: "100%",
        padding: 8,
        height: "100vh",
    };
    const paperStyle = isMobile ? mobilePaper : desktopPaper;

    if (pdf !== null) {
        return <ViewPDF patientReport={pdf} />
    }

    const avatarStyle = { backgroundColor: "#1bbd7e" };
    const btnstyle = { margin: "8px 0" };
    return (
        <ThemeProvider theme={mdTheme}>
            <CssBaseline />
            <Grid>
                <Paper elevation={10} style={paperStyle}>
                    <Grid align="center">
                        <img width={"85%"} src={Logo} />
                        <Avatar style={avatarStyle}>
                            <LockOutlinedIcon />
                        </Avatar>
                        <h2>Verify Identity</h2>
                    </Grid>
                    <Box>
                        <Formik
                            initialValues={initialValues}
                            validationSchema={validationSchema}
                            onSubmit={handleSubmit}
                            enableReinitialize
                        >
                            {({
                                values,
                                setFieldValue,
                                touched,
                                errors,
                            }) => (
                                <Form>
                                    <Stack direction="column" spacing={2}>
                                        <Typography variant="body2">
                                            To access your results report, kindly provide the requested details below. Once the provided information is verified and matches the details associated with the report, you will be granted access to view it.
                                        </Typography>
                                        <CustomTextField
                                            required
                                            name="FirstName"
                                            disabled={disabled}
                                            label="First Name"
                                        />
                                        <CustomTextField
                                            required
                                            name="LastName"
                                            disabled={disabled}
                                            label="Last Name"
                                        />
                                        <LocalizationProvider
                                            dateAdapter={AdapterDayjs}
                                        >
                                            <DatePicker
                                                disableFuture
                                                label="Date of Birth"
                                                name="DOB"
                                                value={values?.DOB}
                                                onChange={(date) =>
                                                    setFieldValue("DOB", date)
                                                }
                                                helperText={
                                                    touched?.DOB &&
                                                    errors?.DOB
                                                }
                                                error={
                                                    touched?.DOB &&
                                                    Boolean(errors?.DOB)
                                                }
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        required
                                                        size="small"
                                                        helperText="mm/dd/yyyy"
                                                    />
                                                )}
                                            />
                                        </LocalizationProvider>
                                        <LocalizationProvider
                                            dateAdapter={AdapterDayjs}
                                        >
                                            <DatePicker
                                                disableFuture
                                                label="Date Sample Was Collected"
                                                name="DOC"
                                                value={values.DOC}
                                                onChange={(date) =>
                                                    setFieldValue("DOC", date)
                                                }
                                                helperText={
                                                    touched.DOC &&
                                                    errors.DOC
                                                }
                                                error={
                                                    touched.DOC &&
                                                    Boolean(errors.DOC)
                                                }
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        required
                                                        size="small"
                                                        helperText="mm/dd/yyyy"
                                                    />
                                                )}
                                            />
                                        </LocalizationProvider>
                                        {error != "" ? (
                                            <Alert
                                                variant="filled"
                                                severity="error"
                                            >
                                                {error}
                                            </Alert>
                                        ) : (
                                            <></>
                                        )}
                                    </Stack>
                                    <LoadingButton
                                        type="submit"
                                        loading={loading}
                                        loadingIndicator="Logging in..."
                                        disabled={loading || disabled}
                                        color="primary"
                                        variant="contained"
                                        style={btnstyle}
                                        fullWidth
                                    >
                                        VIEW REPORT
                                    </LoadingButton>
                                </Form>
                            )}
                        </Formik>
                    </Box>
                </Paper>
            </Grid>
        </ThemeProvider>
    );
}
