import DataTable from "./DataTable";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

function AOEsTable({ aoes, selected, setSelected }) {

    const columns = [
        { field: "ID", headerName: "ID", width: 50 },
        { field: "Question", headerName: "Question", width: '1000' },
    ]

    return <DataTable
        selected={selected}
        setSelected={setSelected}
        data={aoes}
        columns={columns}
        checkboxSelection
    />



}

export default AOEsTable;
