import { useState } from "react";
import * as Yup from "yup";
import { Formik, Form } from "formik";
import { Alert, Button, MenuItem, InputLabel, TextField } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { Box, Stack } from "@mui/system";
import { CustomTextField } from "./CustomTextField";
import TestFeeInputTable from "./TestFeeInputTable";
import { apiFetch } from "../services/fetch";

function FeeScheduleForm({
    initValues,
    id = null,
    tests = [],
    reset = () => { },
}) {
    const [error, setError] = useState(null);
    const [copy, setCopy] = useState(0);

    console.log(initValues);

    const validationSchema = Yup.object().shape({
        Name: Yup.string().required("Name is required"),
        Multiplier: Yup.number().required("Default multiplier is required"),
        FinancialClass: Yup.number().required("Financial class is required"),
        DateEffective: Yup.date().required("Date effective is required"),
        DateDiscontinue: Yup.date().nullable(),
    });

    const financialClasses = [
        { label: "Client Bill", value: 0 },
        { label: "Self Pay", value: 1 },
        { label: "Commercial", value: 2 },
        { label: "Medicare", value: 3 },
        { label: "Medicaid", value: 4 },
    ];

    const handleSubmit = (values) => {
        console.log(values)
        let intFees = [];
        for (let fee of values?.Fees) {
            let intFee;
            try {
                intFee = parseInt(parseFloat(fee.Fee) * 100);
            } catch {
                continue;
            }
            intFees.push({ TestID: fee.TestID, Fee: intFee });
        }

        let postValues = { ...values }
        postValues.Fees = intFees;

        postValues.Multiplier = parseFloat(values.Multiplier)

        let url;
        switch (id) {
            case null:
                url = "/feeschedules"
                break;
            default:
                url = "/feeschedules/" + id
        }
        const post = async () => {
            try {
                await apiFetch(url, "POST", postValues);
                setError(null)
                reset()
            } catch (e) {
                setError("Failed to save fee schedule.")
                console.log(e)
            }
        };

        post();
    };

    return (
        <>
            <Box>
                <Formik
                    initialValues={initValues}
                    validationSchema={validationSchema}
                    onSubmit={handleSubmit}
                    enableReinitialize
                >
                    {({
                        values,
                        setFieldValue,
                        handleChange,
                        handleBlur,
                        errors,
                        touched,
                    }) => (
                        <Form>
                            <Stack direction="column" spacing={2}>
                                <InputLabel>General</InputLabel>
                                <Stack
                                    direction={{ sm: "column", md: "row" }}
                                    spacing={{ xs: 1, sm: 2, md: 4 }}
                                >
                                    <TextField
                                        name="Name"
                                        value={values.Name}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        error={
                                            touched.Name && Boolean(errors.Name)
                                        }
                                        label="Name"
                                        size="small"
                                        helperText={touched.Name && errors.Name}
                                        required
                                    />
                                    <TextField
                                        required
                                        size="small"
                                        onChange={handleChange}
                                        error={
                                            touched.FinancialClass &&
                                            Boolean(errors.FinancialClass)
                                        }
                                        value={values.FinancialClass}
                                        onBlur={handleBlur}
                                        helperText={
                                            touched.FinancialClass &&
                                            errors.FinancialClass
                                        }
                                        name="FinancialClass"
                                        sx={{
                                            minWidth: "223px",
                                            maxWidth: "100%",
                                        }}
                                        label="FinancialClass"
                                        variant="outlined"
                                        select
                                    >
                                        {financialClasses.map((option) => (
                                            <MenuItem
                                                key={option.value}
                                                value={option.value}
                                            >
                                                {option.label}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                    <CustomTextField
                                        name="Multiplier"
                                        label="Default Multiplier"
                                        required
                                        inputProps={{
                                            type: "number",
                                            step: 0.01,
                                            min: 0,
                                        }}
                                    />
                                    <LocalizationProvider
                                        dateAdapter={AdapterDayjs}
                                    >
                                        <DatePicker
                                            name="DateEffective"
                                            label="Date Effective"
                                            value={values?.DateEffective}
                                            onChange={(date) => {
                                                setFieldValue(
                                                    `DateEffective`,
                                                    date
                                                );
                                            }}
                                            helperText={
                                                touched?.DateEffective &&
                                                Boolean(errors?.DateEffective)
                                            }
                                            error={
                                                touched?.DateEffective &&
                                                Boolean(errors?.DateEffective)
                                            }
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    size="small"
                                                    helperText="mm/dd/yyyy"
                                                />
                                            )}
                                        />
                                    </LocalizationProvider>
                                    <LocalizationProvider
                                        dateAdapter={AdapterDayjs}
                                    >
                                        <DatePicker
                                            name="DateDiscontinue"
                                            label="Date Discontinue"
                                            value={values?.DateDiscontinue}
                                            onChange={(date) => {
                                                setFieldValue(
                                                    `DateDiscontinue`,
                                                    date
                                                );
                                            }}
                                            helperText={
                                                touched?.DateDiscontinue &&
                                                Boolean(errors?.DateDiscontinue)
                                            }
                                            error={
                                                touched?.DateDiscontinue &&
                                                Boolean(errors?.DateDiscontinue)
                                            }
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    size="small"
                                                    helperText="mm/dd/yyyy"
                                                />
                                            )}
                                        />
                                    </LocalizationProvider>
                                </Stack>
                                <Button
                                    onClick={() => setCopy(copy + 1)}
                                    variant="contained"
                                    sx={{ color: "white" }}
                                    color="secondary"
                                >
                                    Multiply Allowable
                                </Button>
                                <TestFeeInputTable tests={tests} copy={copy} fees={initValues?.Fees} />
                                {error !== null ? (
                                    <Alert severity="error">{error}</Alert>
                                ) : null}
                                <Stack direction="row" justifyContent="space-between">
                                    <Button onClick={reset} variant="contained" >Back</Button>
                                    <Button type="submit" variant="contained" color="success">
                                        {id ? "Save" : "Create"}
                                    </Button>
                                </Stack>
                            </Stack>
                        </Form>
                    )}
                </Formik>
            </Box>
        </>
    );
}

export default FeeScheduleForm;
