export async function getFeeSchedules() {
    return fetch(`${process.env.REACT_APP_API_PROXY}/v1/feeschedules`, {
        credentials: "include",
    })
}

export async function getFeeSchedule(feeSchedule_id) {
    return fetch(`${process.env.REACT_APP_API_PROXY}/v1/feeschedules/` + feeSchedule_id, {
        credentials: "include",
    }).then((data) => data.json());
}

export async function addFeeSchedule(feeSchedule) {
    return fetch(`${process.env.REACT_APP_API_PROXY}/v1/feeschedules`, {
        method: "POST",
        credentials: "include",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
        },
        body: JSON.stringify(feeSchedule),
    }).then((response) => response.json());
}
