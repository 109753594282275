export async function getInsurances() {
    return fetch(`${process.env.REACT_APP_API_PROXY}/v1/insurances`, {
        credentials: "include",
    });
}
export async function getInsurance(id) {
    return fetch(`${process.env.REACT_APP_API_PROXY}/v1/insurances/${id}`, {
        credentials: "include",
    });
}

export async function addInsurance(insurance) {
    return fetch(`${process.env.REACT_APP_API_PROXY}/v1/insurances`, {
        method: "POST",
        credentials: "include",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
        },
        body: JSON.stringify(insurance),
    });
}

export async function updateInsurance(id, insurance) {
    return fetch(`${process.env.REACT_APP_API_PROXY}/v1/insurances/${id}`, {
        method: "POST",
        credentials: "include",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
        },
        body: JSON.stringify(insurance),
    });
}

export async function getEligibilityPayers() {
    return fetch(`${process.env.REACT_APP_API_PROXY}/v1/eligibilityPayers`, {
        credentials: "include",
    });
}
