import { Alert, Stack } from "@mui/material"

const style = { m: 2 }

function ErrorAlert({ error }) {
    if (!error) {
        return <></>
    }

    return (
        <Alert sx={style} severity="error">{error}</Alert>
    )
}

function WarningAlert({ msg }) {
    if (!msg) {
        return <></>
    }

    return (
        <Alert sx={style} severity="warning">{msg}</Alert>
    )
}

function extractTouchedErrorMessages(errors, touched) {
    const errorMessages = [];

    function findErrors(errObj, touchObj, isTouched) {
        Object.keys(errObj).forEach(key => {
            const errorValue = errObj[key];
            // Determine if the current level or any parent level has been touched
            const currentTouched = isTouched || touchObj.hasOwnProperty(key);
            // If the value is a string and it's touched, add it to the array
            if (typeof errorValue === 'string' && currentTouched) {
                errorMessages.push(errorValue);
            } else if (typeof errorValue === 'object' && errorValue !== null) {
                // If it's an object (or array), dive deeper
                findErrors(errorValue, touchObj[key] || {}, currentTouched);
            }
        });
    }

    findErrors(errors, touched, false);

    return errorMessages;
}

function FormikErrors({ errors, touched }) {
    if (!errors || Object.keys(errors).length === 0) {
        return <></>
    }
    console.log(errors)
    console.log(touched)
    const errorStrings = extractTouchedErrorMessages(errors, touched)
    // only return errors that are in the touched obj
    return (
        <Stack direction="column" spacing={1} m={1}>
            {errorStrings.map((value, index) => {
                return <Alert key={index} severity="error">{value}</Alert>
            })}
        </Stack>
    )
}


export { WarningAlert, FormikErrors };
export default ErrorAlert;
