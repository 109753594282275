import React from "react";
import { alpha, styled, lighten } from "@mui/material/styles";
import {
	Stack,
	Tooltip,
	Typography
} from "@mui/material";
import {
	DataGridPro,
	gridClasses,
	GridRenderCellParams,
} from "@mui/x-data-grid-pro";
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import HealthAndSafetyIcon from "@mui/icons-material/HealthAndSafety";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import { Result } from "../constants/types";
import ReceiptIcon from "@mui/icons-material/Receipt";
import { NavigateFunction } from "react-router";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';


const ODD_OPACITY = 0.2;
export const StyledResultsDataGrid = styled(DataGridPro)(({ theme }) => ({
	[`& .${gridClasses.row}.abnormal`]: {
		backgroundColor: lighten(theme.palette.warning.main, 0.85),
		"&:hover, &.Mui-hovered": {
			backgroundColor: alpha(theme.palette.primary.main, ODD_OPACITY),
			"@media (hover: none)": {
				backgroundColor: "transparent",
			},
		},
		"&.Mui-selected": {
			backgroundColor: alpha(
				theme.palette.primary.main,
				ODD_OPACITY + theme.palette.action.selectedOpacity
			),
			"&:hover, &.Mui-hovered": {
				backgroundColor: alpha(
					theme.palette.primary.main,
					ODD_OPACITY +
					theme.palette.action.selectedOpacity +
					theme.palette.action.hoverOpacity
				),
				// Reset on touch devices, it doesn't add specificity
				"@media (hover: none)": {
					backgroundColor: alpha(
						theme.palette.primary.main,
						ODD_OPACITY + theme.palette.action.selectedOpacity
					),
				},
			},
		},
	},
	[`& .${gridClasses.row}.critical`]: {
		backgroundColor: lighten(theme.palette.error.main, 0.75),
		"&:hover, &.Mui-hovered": {
			backgroundColor: alpha(theme.palette.primary.main, ODD_OPACITY),
			"@media (hover: none)": {
				backgroundColor: "transparent",
			},
		},
		"&.Mui-selected": {
			backgroundColor: alpha(
				theme.palette.primary.main,
				ODD_OPACITY + theme.palette.action.selectedOpacity
			),
			"&:hover, &.Mui-hovered": {
				backgroundColor: alpha(
					theme.palette.primary.main,
					ODD_OPACITY +
					theme.palette.action.selectedOpacity +
					theme.palette.action.hoverOpacity
				),
				// Reset on touch devices, it doesn't add specificity
				"@media (hover: none)": {
					backgroundColor: alpha(
						theme.palette.primary.main,
						ODD_OPACITY + theme.palette.action.selectedOpacity
					),
				},
			},
		},
	},
	[`& .${gridClasses.row}.panel`]: {
		backgroundColor: lighten(theme.palette.secondary.main, 0.95),
		"&:hover, &.Mui-hovered": {
			backgroundColor: alpha(theme.palette.primary.main, ODD_OPACITY),
			"@media (hover: none)": {
				backgroundColor: "transparent",
			},
		},
		"&.Mui-selected": {
			backgroundColor: alpha(
				theme.palette.primary.main,
				ODD_OPACITY + theme.palette.action.selectedOpacity
			),
			"&:hover, &.Mui-hovered": {
				backgroundColor: alpha(
					theme.palette.primary.main,
					ODD_OPACITY +
					theme.palette.action.selectedOpacity +
					theme.palette.action.hoverOpacity
				),
				// Reset on touch devices, it doesn't add specificity
				"@media (hover: none)": {
					backgroundColor: alpha(
						theme.palette.primary.main,
						ODD_OPACITY + theme.palette.action.selectedOpacity
					),
				},
			},
		},
	},
}));

const GetAbnormal = React.memo(({ value }: { value: boolean }) => {
	return (
		<Tooltip title="Abnormal" placement="top-start">
			{value ? <WarningAmberIcon color="warning" /> :
				<WarningAmberIcon color="disabled" />}
		</Tooltip>)
});

const GetCritical = React.memo(({ value }: { value: boolean }) => {
	return (
		<Tooltip title="Critical" placement="top-start">
			{value ? <HealthAndSafetyIcon color="error" /> :
				<HealthAndSafetyIcon color="disabled" />}
		</Tooltip>
	)

});

const GetIsVisible = React.memo(({ value }: { value: boolean }) => {
	return (
		<Tooltip title="Visible" placement="top-start">
			{value ? <VisibilityIcon color="disabled" /> :
				<VisibilityOffIcon color="disabled" />}
		</Tooltip>
	)

});

const GetConsistent = React.memo(({ value }: { value: boolean }) => {
	return (
		<Tooltip title="Inconsistent" placement="top-start">
			{value ? <CheckCircleIcon color="disabled" /> :
				<ErrorIcon color="error" />}
		</Tooltip>
	)

});

export const GetFlags = React.memo(({ value }: { value: Result }) => {
	return (
		<Stack direction="row" spacing={1}>
			<GetCritical value={value?.Critical} />
			<GetAbnormal value={value?.Abnormal} />
			<GetConsistent value={value?.Consistent} />
			<GetIsVisible value={value?.IsVisible} />
		</Stack>
	)
});

export const RenderOrderButton = React.memo(
	({ params, navigate }: { params: GridRenderCellParams, navigate: NavigateFunction }) => {
		return (
			<Stack direction="row" spacing={1}>
				<ReceiptIcon
					sx={{ "&:hover": { color: "black" } }}
					color="primary"
					onClick={() => navigate("/orders/" + params.row.OrderID)}
				/>
				<Typography>{params.row?.OrderID}</Typography>
			</Stack>
		);
	},
);

