import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Table, TableCell, TableHead, TableRow } from '@mui/material';
import React from 'react';
import { apiFetch } from '../../services/fetch';
import { FormatDateTime } from "../../services/util";
import ErrorAlert from '../ErrorAlert';

type SampleNotesDialogProps = {
    sampleId: number;
    setSampleId: React.Dispatch<React.SetStateAction<number | null>>;
}

function SampleNotesDialog({ sampleId, setSampleId }: SampleNotesDialogProps) {
    const [notes, setNotes] = React.useState<any[]>([]);
    const [loading, setLoading] = React.useState(false);
    const [error, setError] = React.useState<null | string>(null);

    React.useEffect(() => {
        if (!sampleId) {
            return;
        }

        const init = async () => {
            setLoading(true)
            try {
                const response = await apiFetch(`/samples/${sampleId}/notes`)
                setNotes(response)
                setError(null)
            } catch (e: any) {
                setError(e?.message)
            } finally {
                setLoading(false)
            }
        }

        init()
    }, [sampleId]);

    if (!sampleId) {
        return (
            <></>
        )
    }

    return (
        <Dialog open={!!sampleId}>
            <DialogTitle>Note</DialogTitle>
            <DialogContent>
                {loading ? "Loading..." :
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Note</TableCell>
                                <TableCell>Date Added</TableCell>
                                <TableCell>Added By</TableCell>
                            </TableRow>
                            {notes.map((n) => {
                                return (
                                    <TableRow key={n.ID}>
                                        <TableCell>{n.Note}</TableCell>
                                        <TableCell>
                                            {FormatDateTime(n.CreatedAt)}
                                        </TableCell>
                                        <TableCell>
                                            {n.AddedBy.Email}
                                        </TableCell>
                                    </TableRow>
                                );
                            })}
                        </TableHead>
                    </Table>}
                <ErrorAlert error={error} />
            </DialogContent>
            <DialogActions>
                <Button onClick={() => setSampleId(null)}>Close</Button>
            </DialogActions>
        </Dialog>
    );
}

export default SampleNotesDialog;
