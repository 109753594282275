import { Box } from '@mui/material';
import { DataGridPro } from '@mui/x-data-grid-pro';
import React, { useEffect, useState } from 'react';
import { apiFetch } from '../services/fetch';
import Title from './Title';
import { GetOrderStatusChips } from "./StatusChips";
import dayjs from "dayjs";
import utc from 'dayjs/plugin/utc';
import { FormatDate } from '../services/util';
import ReceiptIcon from "@mui/icons-material/Receipt";
import { useNavigate } from "react-router";
import { Stack } from '@mui/system';

dayjs.extend(utc);

function PreviousPatientOrdersSummary({ patientID }: { patientID: number | undefined }) {
    const [orders, setOrders] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        if (!patientID) { return }

        const init = async () => {
            try {
                const response = await apiFetch(`/patients/${patientID}/recentOrders`);
                setOrders(response);
            } catch (e) {
                console.error(e);
                console.error("Failed to fetch previous orders for patient", patientID);
            }
        }

        init()

    }, [patientID]);

    if (!patientID) { return (<></>) }

    const renderOrderButton = (params: any) => {
        return (
            <Stack direction="row" spacing={1}>
                <ReceiptIcon
                    sx={{ "&:hover": { color: "black" } }}
                    color="primary"
                    onClick={() => navigate("/orders/" + params.id)}
                />
                <p>{params.id}</p>
            </Stack>
        );
    };

    const age = (date: any) => {
        const now = dayjs();
        const a = now.diff(date, 'd');
        return a === 0 ? "Today" : `${a} days ago`
    }

    const columns = [
        { field: 'ID', headerName: 'ID', width: 100, renderCell: renderOrderButton },
        {
            field: 'CreatedAt', headerName: 'Created At', width: 200,
            type: "date",
            valueGetter: (row: any) => dayjs.utc(row.row.CreatedAt).toDate(),
            valueFormatter: ({ value }: { value: any }) => { return `${FormatDate(value)} - ${age(value)}` },
        },
        {
            field: 'Status', headerName: 'Status', width: 200,
            renderCell: (row: any) => <GetOrderStatusChips order={row.row} />,
        },
    ]

    return (
        <>
            <Title>Patient Order History</Title>
            <Box height={400} width="100%">
                <DataGridPro
                    // @ts-ignore
                    getRowId={(row) => row?.ID}
                    rows={orders}
                    columns={columns}
                />
            </Box>
        </>
    )

}

export default PreviousPatientOrdersSummary;
