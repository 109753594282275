import { Form, Formik } from "formik";
import AOEFields from "./AOEFields";

function PatientOrderDiagnosisQuestions({ questions, registerSubmit, innerRef }) {
    if (!questions) {
        return <></>
    }

    const initValues = {}

    for (let q of questions) {
        initValues[q.ID] = false
        q.Type = 0
    }

    return (
        <Formik
            innerRef={innerRef}
            initialValues={initValues}
        >
            {({ submitForm }) => {
                registerSubmit(submitForm)
                return (
                    <Form>
                        <AOEFields aoes={questions} />
                    </Form>
                )
            }}
        </Formik>
    )
}

export default PatientOrderDiagnosisQuestions;
