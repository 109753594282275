import React, { useState, useContext } from "react";
import { Button, Box, Stack, Typography } from "@mui/material";
import OrdersTable from "../components/OrdersTable";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import TabPanel, { a11yProps } from "../components/TabPanel";
import Framework from "../components/Framework";
import { useTabState } from "../components/useTabState";
import PatientOrders from "../components/PatientOrders";
import { DataTableContainerNoButton } from "../components/TableContainer";
import BulkResultUpload from "../components/BulkResultUpload";
import mdTheme from "../components/Theme";
import { useMediaQuery } from "@mui/material";
import { SessionContext } from "../hooks/getContext";
import BulkEdit from "../components/BulkEdit";
import MainOrdersTable from "../tables/OrdersTable";
import Title from "../components/Title";

export default function Orders() {
    const [value, setValue] = useTabState(0)
    const [showUpload, setShowUpload] = useState(false)
    const [showBulkEdit, setShowBulkEdit] = useState(false)
    const isMobile = useMediaQuery(mdTheme.breakpoints.down("sm"));
    const session = useContext(SessionContext);

    const isProvider = session.user.IsProvider;

    const orderTabs = [
        {
            label: "Pending", component: <DataTableContainerNoButton>
                <Title>{"Pending Orders"}</Title>
                <MainOrdersTable
                    deletedColumns={false}
                    url="/orders?filter=Submitted,Sample(s) Collected,Sample(s) Received"
                />
            </DataTableContainerNoButton>
        },
        {
            label: "Submitted", component: <DataTableContainerNoButton>
                <Title>{"Submitted Orders"}</Title>
                <MainOrdersTable
                    deletedColumns={false}
                    url="/orders?filter=Submitted"
                />
            </DataTableContainerNoButton>
        },
        {
            label: "Completed", component: <DataTableContainerNoButton>
                <Title>Completed Orders</Title>
                <MainOrdersTable
                    deletedColumns={false}
                    url="/orders?filter=Completed"
                />
            </DataTableContainerNoButton>
        },
        {
            label: "Recollect Required", component: <DataTableContainerNoButton>
                <Title>Orders Require Recollection</Title>
                <MainOrdersTable
                    deletedColumns={false}
                    url="/orders?filter=Recollect Required"
                />
            </DataTableContainerNoButton>
        },
        {
            label: "Rejected", component: <DataTableContainerNoButton>
                <Title>Rejected Orders</Title>
                <MainOrdersTable
                    deletedColumns={false}
                    url="/orders?filter=Rejected"
                />
            </DataTableContainerNoButton>
        },
        {
            label: "All", component: <DataTableContainerNoButton>
                <Title>All Orders</Title>
                <MainOrdersTable
                    deletedColumns={false}
                    url="/orders"
                />
            </DataTableContainerNoButton>
        },
        {
            label: "Deleted", component: <DataTableContainerNoButton>
                <Title>Deleted Orders</Title>
                <MainOrdersTable
                    url="/orders?filter=Deleted"
                    deletedColumns={true}
                />
            </DataTableContainerNoButton>
        }
    ]

    if (isProvider) {
        orderTabs.splice(4, 0, {
            label: "Signature Required", component: <DataTableContainerNoButton>
                <MainOrdersTable
                    deletedColumns={false}
                    url="/orders?missingSignature=true"
                    checkboxes={true}
                />
            </DataTableContainerNoButton>
        })
    }

    return (
        <Framework table>
            <BulkResultUpload open={showUpload} setOpen={setShowUpload} />
            <BulkEdit open={showBulkEdit} setOpen={setShowBulkEdit} />
            <Box sx={{ pl: 2, pr: 2, pt: 1 }}>
                <Typography variant="h5">Orders</Typography>

                <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                    <Stack direction="row" justifyContent="space-between" alignItems="center">
                        <Tabs value={value} onChange={(_, v) => setValue(v)} variant="scrollable">
                            {orderTabs.map((tab, index) => (
                                <Tab label={tab.label} {...a11yProps(index)} />))
                            }
                        </Tabs>
                        <Stack direction="row" justifyContent="right" alignItems="center" spacing={2}>
                            {!isMobile && session.user.Roles.includes("Admin") && session.user.Type === "Lab" && <Button
                                onClick={() => setShowBulkEdit(true)}
                                variant="contained"
                                color="primary"
                                sx={{ maxHeight: "35px", minWidth: "100px" }}
                                size="small"
                            >
                                Bulk Edit
                            </Button>}
                            {!isMobile && session.user.Type === "Lab" && <Button
                                onClick={() => setShowUpload(true)}
                                variant="contained"
                                color="primary"
                                sx={{ maxHeight: "35px", minWidth: "140px" }}
                                size="small"
                            >
                                Result Upload
                            </Button>}
                        </Stack>
                    </Stack>
                </Box>
            </Box>
            {orderTabs.map((tab, index) => (
                <TabPanel value={value} index={index}>
                    {tab.component}
                </TabPanel>
            ))}
        </Framework >
    );
}
