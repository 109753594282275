import { useState, useLayoutEffect, useCallback } from 'react';

function useDataGridState(defaultValue, apiRef, key) {
    if (!key) {
        console.error("key is undefined")
    }

    const [initialState, setInitialState] = useState(() => {
        const stateFromLocalStorage = localStorage.getItem(key);
        return stateFromLocalStorage ? JSON.parse(stateFromLocalStorage) : defaultValue;
    });

    const saveSnapshot = useCallback(() => {
        if (apiRef?.current?.exportState && localStorage) {
            const currentState = apiRef.current.exportState();
            console.log('exporting state', currentState)
            localStorage.setItem(key, JSON.stringify(currentState));
        }
    }, [apiRef, key]);

    useLayoutEffect(() => {
        const stateFromLocalStorage = localStorage?.getItem(key);
        console.log('importing state', stateFromLocalStorage)
        setInitialState(stateFromLocalStorage ? JSON.parse(stateFromLocalStorage) : defaultValue);

        // handle refresh and navigating away/refreshing
        window.addEventListener('beforeunload', saveSnapshot);

        return () => {
            // in case of an SPA remove the event-listener
            window.removeEventListener('beforeunload', saveSnapshot);
            saveSnapshot();
        };
    }, [saveSnapshot, key]);


    return [initialState, setInitialState];
}

export { useDataGridState };

