import React, { useState, useEffect, useMemo, ReactNode, useCallback } from "react";

import {
	Box,
	Stack,
	TextField,
	Autocomplete,
	Button,
	FormControl,
	Checkbox,
	FormControlLabel,
	RadioGroup,
	Typography,
	Radio,
	Divider,
	FormLabel,
} from "@mui/material";
import { Facility, CommunityTrend, Medication, DrugClass } from "../constants/types";
import ErrorAlert from "../components/ErrorAlert";

import {
	GridToolbar,
	GridActionsCellItem,
	GridColDef,
	GridRowParams,
	useGridApiRef,
	GridRenderEditCellParams,
	DataGridPro,
} from "@mui/x-data-grid-pro";
import { Edit, Delete } from "@mui/icons-material";
import { createFilterOptions } from "@mui/material/Autocomplete";
import { apiFetch } from "../services/fetch";

const deleteRow = async (id: number, setError: React.Dispatch<React.SetStateAction<string | null>>, setRefresh: React.Dispatch<React.SetStateAction<boolean>>) => {
	try {
		await apiFetch(`/beechtree/community/${id}`, 'DELETE')
		setError(null);
		setRefresh((refresh) => !refresh);
	} catch (e) {
		console.log(e)
		setError("Failed to delete row");
	}
}


function CommunityTrends() {
	const apiRef = useGridApiRef()
	const initMapping = {
		MedicationID: null,
		DrugClassID: null,
	}

	const [refresh, setRefresh] = useState(false);
	const [error, setError] = useState<string | null>(null);
	const [medications, setMedications] = useState<Medication[]>([]);
	const [drugClasses, setDrugClasses] = useState<DrugClass[]>([]);
	const [addType, setAddType] = useState<string>("Medication");
	const [mappings, setMappings] = useState<CommunityTrend[]>([]);
	const [newMappingError, setNewMappingError] = useState({});
	const [newMapping, setNewMapping] = useState<CommunityTrend>(initMapping);

	useEffect(() => {
		const init = async () => {
			let response = await apiFetch("/beechtree/community");
			setMappings(response);
			response = await apiFetch("/medications");
			setMedications(response);
			response = await apiFetch("/drugclasses");
			setDrugClasses(response);
		};

		init();
	}, [refresh]);

	const addMapping = () => {
		if (newMapping.MedicationID === null && newMapping.DrugClassID === null) {
			setNewMappingError({
				...newMappingError,
				MedicationID: "Medication or drug class required",
			});
			return;
		}

		setNewMappingError({});

		const add = async () => {
			try {
				await apiFetch(
					`/beechtree/community`,
					'POST',
					/* @ts-ignore */
					newMapping
				);
				setError(null);
				setRefresh((refresh) => !refresh);
				setNewMapping(initMapping);
			} catch (error) {
				console.log(error)
				setError("Failed to add row");
			}
		};
		add();
	};

	const filterOptions = createFilterOptions({
		matchFrom: "start",
		stringify: (option: Medication | DrugClass) => option.Name,
	});


	const RenderMedicationValueCell = useCallback((params: GridRenderEditCellParams): ReactNode => {
		const { id, value, field } = params;
		const handleValueChange = (
			_: React.SyntheticEvent<Element, Event>,
			newValue: DrugClass | Medication | null,
		) => {
			console.log(newValue)
			console.log(field)
			apiRef.current.setEditCellValue({ id, field: "MedicationID", value: newValue });
		};

		return (
			<Autocomplete
				options={medications}
				filterOptions={filterOptions}
				getOptionLabel={(option) =>
					option.Name || String(option)
				}
				value={medications.find((m) => m.ID === value)}
				sx={{ minWidth: "200px", width: "100%" }}
				isOptionEqualToValue={(
					option,
					value
				) => option.ID === value.ID}
				onChange={handleValueChange}
				renderInput={(params) => (
					<TextField
						{...params}
					/>
				)}
			/>
		)
	}, [apiRef, medications]);


	const getActions = useCallback((params: GridRowParams) => {
		return [
			<GridActionsCellItem icon={<Delete />} onClick={() => deleteRow(Number(params.id), setError, setRefresh)} label="Delete" />
		]
	}, [apiRef])

	const columns: GridColDef[] = useMemo(() => [
		{ field: "Medication", headerName: "Medication", editable: true, width: 200, renderEditCell: RenderMedicationValueCell, renderCell: (params) => params.row.Medication?.Name, valueGetter: (params) => params.row.Medication?.Name },
		{ field: "DrugClass", headerName: "Drug Class", editable: true, width: 200, renderEditCell: RenderMedicationValueCell, renderCell: (params) => params.row.DrugClass?.Name, valueGetter: (params) => params.row.DrugClass?.Name },
		{ field: "actions", type: "actions", getActions: getActions },
	], [RenderMedicationValueCell, getActions])

	return <>
		<Stack direction="column" spacing={2}>
			<FormLabel>Select type you would like to add</FormLabel>
			<Divider orientation="horizontal" />
			<RadioGroup
				aria-labelledby="payment_method"
				// defaultValue="insurance" // this should be set based on patient and facility
				value={addType}
				onChange={(e) => setAddType(e.target.value)}
				name="payment_method"
				row
			>
				<FormControlLabel
					value={"Medication"}
					control={
						<Radio />
					}
					label={
						<div>
							<Typography >
								Medication
							</Typography>
						</div>
					}
				/>
				<FormControlLabel
					value={"DrugClass"}
					control={
						<Radio />
					}
					label={
						<div>
							<Typography >
								Drug Class
							</Typography>
						</div>
					}
				/>
			</RadioGroup>
			<Divider orientation="horizontal" />
			<Stack direction={{ md: "column", lg: "row" }} spacing={{ xs: 2, sm: 2, md: 2, lg: 2 }}>
				{addType === "Medication" ?
					<Autocomplete
						id="medication"
						key="medication"
						options={medications}
						getOptionLabel={(option) =>
							option.Name
						}
						filterOptions={filterOptions}
						sx={{ minWidth: "200px" }}
						value={medications.find((t) => t.ID === newMapping?.MedicationID)}
						size="small"
						onChange={(_, newValue) =>
							setNewMapping({
								...newMapping,
								MedicationID: newValue?.ID || null,
							})
						}
						renderInput={(params) => (
							<TextField
								label="Medication"
								{...params} />
						)}
					/> :
					<Autocomplete
						id="drugclass"
						key="drugclass"
						options={drugClasses}
						getOptionLabel={(option) =>
							option.Name
						}
						filterOptions={filterOptions}
						sx={{ minWidth: "200px" }}
						value={drugClasses.find((t) => t.ID === newMapping?.DrugClassID)}
						size="small"
						onChange={(_, newValue) =>
							setNewMapping({
								...newMapping,
								DrugClassID: newValue?.ID || null,
							})
						}
						renderInput={(params) => (
							<TextField
								label="Drug Class"
								{...params} />
						)}
					/>}
				<Button variant="contained" color="primary" onClick={() => addMapping()}>Add</Button>
			</Stack>
			<Box height={`calc(100vh - 425px)`}>
				<DataGridPro
					getRowId={(row) => row.ID}
					apiRef={apiRef}
					columns={columns}
					rows={mappings}
					editMode="row"
					onProcessRowUpdateError={(e) => { console.log(e); setError("Failed to update row") }}
					getRowClassName={(params) =>
						params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'
					}
					density="compact"
					slots={{ toolbar: GridToolbar }}
					slotProps={{
						toolbar: {
							showQuickFilter: true,
							quickFilterProps: { debounceMs: 500 },
						},
					}}
				/>
			</Box>
			<ErrorAlert error={error} />
		</Stack>
	</>;

}

export default CommunityTrends;
