import * as React from 'react';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import { Paper } from '@mui/material';
import Title from './Title';

function preventDefault(event) {
  event.preventDefault();
}

export default function SmallMetric({ value, subText, title }) {
  return (
                                <Paper sx={{
                                        p: 2,
                                        display: "flex",
                                        flexDirection: "column",
                                        height: 240,
                                    }}
                                >
      <Title>{title}</Title>
      <Typography component="p" variant="h4">
              {value}
      </Typography>
      <Typography color="text.secondary" sx={{ flex: 1 }}>
              {subText}
      </Typography>
      <div>
        <Link color="primary" href="#" onClick={preventDefault}>
        </Link>
      </div>
    </Paper>
  );
}
