import React, { useState, useEffect } from "react";
import { Formik, Form, useFormikContext } from "formik";
import * as Yup from "yup";
import Box from "@mui/material/Box";
import { Button, Grid, Select, Stack, TextField } from "@mui/material";
import Typography from "@mui/material/Typography";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import { addContact } from "../services/contacts";
import { getFacilities } from "../services/facilities";
import { getProviders } from "../services/providers";
import Alert from "@mui/material/Alert";
import { CustomTextField, CustomCheckboxField } from "./CustomTextField";

export default function ContactInput({ contact, setContact, reset }) {
    const [allFacilities, setAllFacilities] = useState([]);
    const [allProviders, setAllProviders] = useState([]);
    const [error, setError] = useState();

    useEffect(() => {
        getFacilities().then((p) => {
            setAllFacilities(p);
        });
        getProviders().then((p) => {
            setAllProviders(p);
        });
    }, []);

    console.log(contact);

    const validationSchema = Yup.object().shape({
        FirstName: Yup.string().required(),
        LastName: Yup.string().required(),
        Phone1: Yup.string().matches(
            /^(\+\d{1,2}\s?)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/,
            "Phone number is not valid"
        ),
        Phone2: Yup.string().matches(
            /^(\+\d{1,2}\s?)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/,
            "Phone number is not valid"
        ),
        Fax: Yup.string().matches(
            /^(\+\d{1,2}\s?)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/,
            "Fax number is not valid"
        ),
        Email: Yup.string().email(),
        TextResults: Yup.bool(),
        FaxResults: Yup.bool(),
        EmailResults: Yup.bool(),
        Critical: Yup.bool(),
        Type: Yup.string().required(),
        Notes: Yup.string(),
        Providers: Yup.array(),
        Facilities: Yup.array(),
    });

    const toTitle = (str) => {
        return str.value.toProperCase();
    };

    const handleSubmit = (values) => {
        if (contact.ID === undefined) {
            // create new facility
            console.log("createing", values);
            addContact(values)
                .then(p => {
                    if (!p.ok) {
                        throw new Error("Failed to save contact")
                    }
                    return p.json()
                })
                .catch((err) => setError(err))
                .finally(() => reset());
        } else {
            // update existing facility
            console.log("updating");
            addContact(values, contact.ID)
                .then(p => {
                    if (!p.ok) {
                        throw new Error("Failed to save contact")
                    }
                    return p.json()
                })
                .catch((err) => setError(err))
                .finally(() => reset());
        }
    };

    const facilityColumns = [
        {
            field: "Name",
            headerName: "Name",
            width: 200,
        },
    ];

    const providerColumns = [
        { field: "FirstName", headerName: "FirstName", width: 200 },
        { field: "LastName", headerName: "LastName", width: 200 },
        { field: "Type", headerName: "Type", width: 125, valueGetter: toTitle },
        { field: "NPI", headerName: "NPI", width: 125 },
    ];

    const showError = () => {
        if (error) {
            return (
                <Alert sx={{ m: 2 }} severity="error">
                    {error.message}
                </Alert>
            );
        }
    };

    return (
        <Box>
            <Formik
                initialValues={contact}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
                enableReinitialize
            >
                {({
                    values,
                    setFieldValue,
                    touched,
                    errors,
                    handleBlur,
                    handleChange,
                }) => (
                    <Form>
                        <Stack direction="column" spacing={2}>
                            <Typography
                                sx={{ my: 2 }}
                                variant="h5"
                                gutterBottom
                            >
                                Contact
                            </Typography>
                            <Stack
                                direction={{ sm: "column", md: "row" }}
                                spacing={2}
                            >
                                <FormControl
                                    sx={{ minWidth: 226 }}
                                    size="small"
                                >
                                    <TextField
                                        select
                                        required
                                        size="small"
                                        value={values?.Type}
                                        helperText={
                                            touched?.Type && errors?.Type
                                        }
                                        error={
                                            touched?.Type &&
                                            Boolean(errors?.Type)
                                        }
                                        label="Type"
                                        name="Type"
                                        onChange={handleChange}
                                    >
                                        <MenuItem value={"doctor"}>
                                            Doctor
                                        </MenuItem>
                                        <MenuItem value={"nurse"}>
                                            Nurse Practitioner
                                        </MenuItem>
                                        <MenuItem value={"other"}>
                                            Other
                                        </MenuItem>
                                    </TextField>
                                </FormControl>
                                <CustomTextField
                                    required
                                    name="FirstName"
                                    label="First Name"
                                />
                                <CustomTextField
                                    required
                                    name="LastName"
                                    label="Last Name"
                                />
                            </Stack>
                            <Stack
                                direction={{ sm: "column", md: "row" }}
                                spacing={2}
                            >
                                <CustomTextField
                                    name="Phone1"
                                    label="Phone 1"
                                />
                                <CustomTextField
                                    name="Phone2"
                                    label="Phone 2"
                                />
                                <CustomTextField name="Email" type="email" />
                                <CustomTextField name="Fax" />
                            </Stack>
                            <TextField
                                size="small"
                                onBlur={handleBlur}
                                rows={3}
                                value={values?.Notes}
                                onChange={handleChange}
                                helperText={
                                    touched?.Notes && errors?.Notes
                                }
                                error={
                                    touched?.Notes &&
                                    Boolean(errors?.Notes)
                                }
                                multiline
                                fullWidth
                                name="Notes"
                                label="Notes"
                                variant="outlined"
                            />
                            <CustomCheckboxField
                                name="Critical"
                                label="Critical Contact"
                            />
                            <InputLabel>Notification Methods</InputLabel>
                            <Stack direction="row" spacing={2}>
                                <CustomCheckboxField
                                    name="EmailResults"
                                    label="Email"
                                    disabled={values?.Email === "" || Boolean(errors?.Email)}
                                />
                                <CustomCheckboxField
                                    name="TextResults"
                                    label="Text"
                                    disabled={values?.Phone1 === "" || Boolean(errors?.Phone1)}
                                />
                                <CustomCheckboxField
                                    name="FaxResults"
                                    label="Fax"
                                    disabled={values?.Fax === "" || Boolean(errors?.Fax)}
                                />
                            </Stack>

                            {showError()}

                            <Typography
                                sx={{ my: 2 }}
                                variant="h5"
                                gutterBottom
                            >
                                Facilities
                            </Typography>
                            <Box height="400px">
                                <DataGrid
                                    getRowId={(row) => row.ID}
                                    density="compact"
                                    rows={allFacilities}
                                    columns={facilityColumns}
                                    pageSize={15}
                                    keepNonExistentRowsSelected
                                    components={{ Toolbar: GridToolbar }}
                                    componentsProps={{
                                        toolbar: {
                                            showQuickFilter: true,
                                            quickFilterProps: {
                                                debounceMs: 500,
                                            },
                                        },
                                    }}
                                    rowSelectionModel={values?.Facilities}
                                    onRowSelectionModelChange={(newValues) =>
                                        setFieldValue("Facilities", newValues)
                                    }
                                    pageSizeOptions={[15]}
                                    checkboxSelection
                                    disableRowSelectionOnClick
                                />
                            </Box>
                            <Typography
                                sx={{ my: 2 }}
                                variant="h5"
                                gutterBottom
                            >
                                Associated Providers
                            </Typography>
                            <Box height="400px">
                                <DataGrid
                                    getRowId={(row) => row.ID}
                                    density="compact"
                                    rows={allProviders}
                                    columns={providerColumns}
                                    pageSize={15}
                                    components={{ Toolbar: GridToolbar }}
                                    componentsProps={{
                                        toolbar: {
                                            showQuickFilter: true,
                                            quickFilterProps: {
                                                debounceMs: 500,
                                            },
                                        },
                                    }}
                                    rowSelectionModel={values?.Providers}
                                    onRowSelectionModelChange={(newValues) =>
                                        setFieldValue("Providers", newValues)
                                    }
                                    keepNonExistentRowsSelected
                                    pageSizeOptions={[15]}
                                    checkboxSelection
                                    disableRowSelectionOnClick
                                />
                            </Box>
                            <Stack
                                direction="row"
                                justifyContent="space-between"
                            >
                                <Button
                                    variant="outlined"
                                    sx={{ my: 2 }}
                                    onClick={reset}
                                >
                                    Back
                                </Button>
                                <Button
                                    type="submit"
                                    variant="contained"
                                    sx={{ my: 2 }}
                                >
                                    Save
                                </Button>
                            </Stack>
                        </Stack>
                    </Form>
                )}
            </Formik>
        </Box>
    );
}
