import React, { useEffect, useState, useCallback } from "react";
import { DataGridPro } from "@mui/x-data-grid-pro";
import { GridToolbar } from "@mui/x-data-grid-pro";
import { financialClassIndex, FormatDate, FormatDateUTC } from "../services/util";
import { Button, Stack } from "@mui/material";
import { apiFetch } from "../services/fetch";
import dayjs from "dayjs";
import utc from 'dayjs/plugin/utc';
import { CopyButtonWithText } from "../components/CopyButton";
import DisablePatientDialog from "../components/Dialogs/DisablePatientDialog";

dayjs.extend(utc);

export default function PatientsTable({ setPatient }) {
    const [allPatients, setAllPatients] = useState([]);
    const [disablePatient, setDisablePatient] = useState(null);
    const [refresh, setRefresh] = useState(false);
    const [rowCount, setRowCount] = useState(0);
    const [paginationModel, setPaginationModel] = useState({ pageSize: 500, page: 0 })
    const [queryOptions, setQueryOptions] = useState({ items: [], logicOperator: 'and', quickFilterExcludeHiddenColumns: false, quickFilterLogicOperator: 'and', quickFilterValues: [], sorts: [{ field: "ID", sort: "desc" }] });
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState("");

    useEffect(() => {
        const get = async () => {
            try {
                const response = await apiFetch(`/patients/search?limit=${paginationModel.pageSize}&page=${paginationModel.page}`, "POST", queryOptions)
                console.log(response)
                setAllPatients(response.Data)
                setRowCount(response.Count)
            } catch (e) {
                setError("Failed to load patients")

            } finally {
                setLoading(false)
            }
        }

        get()
    }, [queryOptions, paginationModel, refresh]);

    const enablePatient = async (id) => {
        setLoading(true)
        try {
            await apiFetch(`/patients/${id}/disable`, "DELETE")
            setError(null)
        } catch (e) {
            console.log(e)
            setError("Failed to disable patient")
        } finally {
            setLoading(false)
            setRefresh(!refresh)
        }
    }

    const now = new Date();
    console.log(now.getTimezoneOffset())

    const columns = [
        { field: "ID", headerName: "Patient ID", width: 75, type: "number" },
        { field: "Enabled", headerName: "Enabled", width: 75, type: "boolean" },
        {
            field: "FirstName", headerName: "First Name", width: 145,
            renderCell: ({ value }) => <CopyButtonWithText text={value} copyText={value} />
        },
        {
            field: "LastName", headerName: "Last Name", width: 145,
            renderCell: ({ value }) => <CopyButtonWithText text={value} copyText={value} />
        },
        {
            field: "DOB",
            headerName: "Date of Birth",
            width: 120,
            type: "date",
            valueGetter: ({ value }) => value && dayjs.utc(value).toDate(),
            valueFormatter: ({ value }) => value && FormatDateUTC(value),
            renderCell: ({ value }) => <CopyButtonWithText text={FormatDateUTC(value)} copyText={dayjs.utc(value).format('MM/DD/YYYY')} />,
        },
        { field: "Sex", headerName: "Sex", width: 75 },
        { field: "Phone", headerName: "Phone", width: 125 },
        { field: "Email", headerName: "Email", width: 125 },
        {
            field: "Facility",
            headerName: "Facility",
            width: 120,
            valueGetter: (row) => row.row.Facility.Name,
        },
        {
            field: "FinancialClass",
            headerName: "Financial Class",
            width: 140,
            valueGetter: (row) => financialClassIndex[row.row.FinancialClass],
        },
        { field: "DisabledReason", headerName: "Disabled Reason", width: 175 },
        {
            field: "Action",
            headerName: "Action",
            width: 200,
            renderCell: (row) => renderDetailsButton(row),
        },
    ];

    const renderDetailsButton = (params) => {
        return (
            <Stack direction="row" spacing={1}>
                {params.row.Enabled ? (
                    <Button
                        variant="contained"
                        color="warning"
                        size="small"
                        // style={{ marginLeft: 16 }}
                        onClick={() => {
                            setDisablePatient(params.id);
                        }}
                    >
                        Disable
                    </Button>
                ) : (
                    <Button
                        variant="contained"
                        color="success"
                        size="small"
                        // style={{ marginLeft: 16 }}
                        onClick={() => {
                            enablePatient(params.id);
                        }}
                    >
                        Enable
                    </Button>
                )}
                <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    // style={{ marginLeft: 16 }}
                    onClick={() => {
                        setPatient(params.id);
                    }}
                >
                    Details
                </Button>
            </Stack>
        );
    };

    const onFilterChange = useCallback((filterModel) => {
        setQueryOptions({ ...filterModel })
    }, [])

    const handleSortModelChange = useCallback((sortModel) => {
        setQueryOptions({ ...queryOptions, sorts: sortModel })
        console.log(sortModel)
    }, [queryOptions])

    return (
        <React.Fragment>
            <DisablePatientDialog patientId={disablePatient} setPatientId={(e) => { setDisablePatient(e); setRefresh(!refresh) }} />
            <DataGridPro
                getRowId={(row) => row.ID}
                loading={loading}
                error={error}
                density="compact"
                sx={{
                    "& .MuiDataGrid-columnHeaderCheckbox .MuiDataGrid-columnHeaderTitleContainer":
                    {
                        display: "none",
                    },
                }}
                components={{ Toolbar: GridToolbar }}
                componentsProps={{
                    toolbar: {
                        showQuickFilter: true,
                        quickFilterProps: { debounceMs: 500 },
                    },
                }}
                pageSizeOptions={[100, 250, 500, 100, 2500, 5000]}
                rowCount={rowCount}
                pagination
                paginationMode="server"
                paginationModel={paginationModel}
                onPaginationModelChange={setPaginationModel}
                sortingMode="server"
                onSortModelChange={handleSortModelChange}
                filterMode="server"
                onFilterModelChange={onFilterChange}

                pageSize={100}
                rows={allPatients}
                columns={columns}
            />
        </React.Fragment>
    );
}
