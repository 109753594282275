import { useState, useEffect, useRef } from "react";
import { Viewer } from "@react-pdf-viewer/core";
import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout";
import { Backdrop } from "@mui/material";
import { CircularProgress } from "@mui/material";
import { Worker } from "@react-pdf-viewer/core";
import { toolbarPlugin } from "@react-pdf-viewer/toolbar";
import { getFilePlugin } from "@react-pdf-viewer/get-file";
import { updateOrderView } from "../services/orders";

import "@react-pdf-viewer/default-layout/lib/styles/index.css";
import { useParams } from "react-router";
import { apiFetch } from "../services/fetch";

export default function ViewPDF({ pdfType, patientReport }) {
    const { orderID } = useParams();
    const { attachmentUUID } = useParams();
    const patient = useRef({});

    const defaultLayoutPluginInstance = defaultLayoutPlugin({
        toolbarPlugin: {
            getFilePlugin: {
                fileNameGenerator: (file) => {
                    console.log(file)
                    // Extract the original file name
                    const originalFileName = file.name.substring(file.name.lastIndexOf('/') + 1);

                    let customFileName = ""
                    // Generate the desired custom file name

                    if (patient.current?.FirstName && patient.current?.LastName) {
                        customFileName = `${patient.current.LastName.toUpperCase()}-${patient.current.FirstName.toUpperCase()}`;
                    } else {
                        customFileName = originalFileName;
                    }

                    if (pdfType === "order") {
                        customFileName += "-ORDER";
                    } else if (pdfType !== "order") {
                        customFileName += "-RESULT";
                    }

                    return customFileName;
                }
            }

        }
    });

    const [pdf, setPDF] = useState();
    const [loading, setLoading] = useState(true);
    useEffect(() => {
        if (attachmentUUID) {
            apiFetch(`/attachments/${attachmentUUID}`)
                .then((body) => body.arrayBuffer())
                .then((buffer) => {
                    var pd = new Uint8Array(buffer);
                    setPDF(pd);
                    setLoading(false);
                });
        } else if (patientReport) {
            setPDF(patientReport)
            setLoading(false)
        } else {
            apiFetch(`/orders/${orderID}`)
                .then((resp) => {
                    apiFetch(`/patients/${resp.Patient.ID}`).then((pat) => {
                        patient.current = pat
                    })
                })
            if (pdfType === "order") {
                apiFetch(`/orders/${orderID}/printOrder`)
                    .then((body) => body.arrayBuffer())
                    .then((buffer) => {
                        var pd = new Uint8Array(buffer);
                        setPDF(pd);
                        setLoading(false);
                        updateOrderView(orderID, true);
                    });
            } else {

                apiFetch(`/orders/${orderID}/resultReport`)
                    .then((body) => body.arrayBuffer())
                    .then((buffer) => {
                        var pd = new Uint8Array(buffer);
                        setPDF(pd);
                        setLoading(false);
                        updateOrderView(orderID, true);
                    });
            }
        }
    }, []);

    console.log(patient)


    if (loading) {
        return (
            <Backdrop
                sx={{
                    color: "#fff",
                    zIndex: (theme) => theme.zIndex.drawer + 1,
                }}
                open={loading}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        );
    } else {
        return (
            <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
                <Viewer defaultScale={1.50} fileUrl={pdf} plugins={[defaultLayoutPluginInstance]} />
            </Worker>
        );
    }
}
