import { Stack } from "@mui/system";
import { FormLabel } from "@mui/material";
import { CustomRadioGroup, CustomTextField } from "./CustomTextField";

function BooleanQuestion({ id, question }) {
    const options = [
        { label: "Yes", value: true },
        { label: "No", value: false }
    ]

    return (
        <CustomRadioGroup
            name={id.toString()}
            label={question}
            required={false}
            options={options}
        />
    )

}

function TextQuestion({ id, question }) {
    return (
        <>
            <FormLabel>{question}</FormLabel>
            <CustomTextField
                name={id.toString()}
                required={false}
                label={" "}
            />
        </>
    )

}


function AOEFields({ aoes }) {
    if (!aoes) {
        return <></>
    }

    return (
        <Stack direction="column" spacing={2}>
            {aoes.map((aoe, index) => {
                if (aoe?.Type === 0) {
                    return <BooleanQuestion key={index} question={aoe?.Question} id={aoe?.ID} />
                }
                if (aoe?.Type === 2) {
                    return <TextQuestion key={index} question={aoe?.Question} id={aoe?.ID} />
                }
                return <></>
            })}
        </Stack>
    )
}

export default AOEFields;
