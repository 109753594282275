import {
    Alert,
    Snackbar,
} from "@mui/material";
function ErrorSnackbar({ error, setError }) {
    return (
        <Snackbar open={error} onClose={() => setError(null)}>
            <Alert
                severity="error"
                onClose={() => setError(null)}
                width="75%"
                sx={{ m: 2 }}
                anchorOrigin={{ vertical: "top", horizontal: "right" }}
            >
                {error}
            </Alert>
        </Snackbar>
    )
}

export { ErrorSnackbar }
