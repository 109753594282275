import { useState, useContext, useRef } from "react";
import { SessionContext } from "../../hooks/getContext";
import { FormatDate } from "../../services/util";
import {
    Stack,
    TableContainer,
    Table,
    TableHead,
    TableRow,
    TableBody,
    TableCell,
    Button,
} from "@mui/material";
import ViewRejectionDialog from "../ViewRejectionDialog";
import CloseIcon from "@mui/icons-material/Close";
import { EditSharp } from "@mui/icons-material";
import ConfirmDialog from "../ConfirmDialog";
import { apiFetch } from "../../services/fetch";
import UpdateSampleDialog from "../UpdateSampleDialog";
import ErrorAlert from "../ErrorAlert";

function Samples({ orderDetails, isEditable, callback }) {
    const [viewRejection, setViewRejection] = useState(false);
    const [rejection, setRejection] = useState({});
    const [error, setError] = useState(null);
    const [confirmationDialog, setConfirmationDialog] = useState(false);
    const [updateDialog, setUpdateDialog] = useState(false);
    const session = useContext(SessionContext);
    const selectedSample = useRef(null);

    if (orderDetails.Samples === undefined) {
        return <></>;
    }

    const getStatus = (row) => {
        if (row.Status === "Rejected") {
            return (
                <Button
                    color="error"
                    variant="outlined"
                    onClick={() => {
                        setViewRejection(true);
                        setRejection(row.Rejection);
                    }}
                >
                    Rejected
                </Button>
            );
        }
        return row.Status;
    };

    const deleteSample = async () => {
        if (!selectedSample.current) {
            return
        }

        try {
            await apiFetch(`/samples/${selectedSample.current}`, "DELETE")
            selectedSample.current = null;
            setConfirmationDialog(false);
            callback()
        } catch (e) {
            setError('Failed to delete')
        }

    }

    const handleSampleDelete = (sampleId) => {
        selectedSample.current = sampleId;
        setConfirmationDialog(true);

    }

    const handleSampleEdit = (sampleId) => {
        selectedSample.current = sampleId;
        setUpdateDialog(true);
    }

    return (
        <>
            <ConfirmDialog
                title={"Are you sure?"}
                message={"You are about to delete this sample."}
                open={confirmationDialog}
                cancel={() => { setConfirmationDialog(false) }}
                confirm={() => deleteSample()}
            />
            <UpdateSampleDialog
                sampleId={selectedSample.current}
                open={updateDialog}
                setOpen={setUpdateDialog}
                callback={() => { selectedSample.current = null; callback() }}
            />
            <ViewRejectionDialog
                open={viewRejection}
                handleClose={() => setViewRejection(false)}
                rejection={rejection}
            />
            <TableContainer>
                <Table style={{ width: "auto", tableLayout: "auto" }}>
                    <TableHead>
                        <TableRow>
                            {session?.user?.Type === "Lab" &&
                                <TableCell size="small" sx={{ padding: "0" }}>
                                </TableCell>}
                            <TableCell width="10%">Barcode</TableCell>
                            <TableCell width="10%">Status</TableCell>
                            <TableCell width="10%">Fasting</TableCell>
                            <TableCell width="10%">
                                Date Collected
                            </TableCell>
                            <TableCell width="10%">Date Received</TableCell>
                            <TableCell width="10%">Container</TableCell>
                            <TableCell width="10%">Specimen</TableCell>
                            <TableCell
                                // width="300"
                                style={{
                                    width: "200px",
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                    whiteSpace: "nowrap",
                                }}
                            >
                                Directions
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {orderDetails.Samples.map((row) => (
                            <TableRow key={row.ID}>
                                {session?.user?.Type === "Lab" &&
                                    <TableCell size="small" sx={{ padding: "0" }}>
                                        <Stack direction="row">
                                            <Button onClick={() => handleSampleDelete(row.ID)}
                                                disabled={!isEditable}
                                                size="small"
                                                sx={{ minWidth: "12px" }}>
                                                <CloseIcon color={isEditable ? "error" : "disabled"} />
                                            </Button>
                                            <Button onClick={() => handleSampleEdit(row.ID)}
                                                disabled={!isEditable}
                                                size="small"
                                                sx={{ minWidth: "12px" }}>
                                                <EditSharp color={isEditable ? "primary" : "disabled"} />
                                            </Button>
                                        </Stack>
                                    </TableCell>}
                                <TableCell width="10%">
                                    {row.Barcode}
                                </TableCell>
                                <TableCell width="10%">
                                    {getStatus(row)}
                                </TableCell>
                                <TableCell width="10%">
                                    {row.Fasting ? "Yes" : "No"}
                                </TableCell>
                                <TableCell width="10%">
                                    {FormatDate(row.DateCollected)}
                                </TableCell>
                                <TableCell width="10%">
                                    {FormatDate(row.DateReceived)}
                                </TableCell>
                                <TableCell
                                    width="10%"
                                    style={{
                                        overflow: "hidden",
                                        textOverflow: "ellipsis",
                                        whiteSpace: "nowrap",
                                    }}
                                >
                                    {row.SpecimenType.Container}
                                </TableCell>
                                <TableCell width="10%">
                                    {row.SpecimenType.Specimen}
                                </TableCell>
                                <TableCell
                                    style={{
                                        overflow: "hidden",
                                        textOverflow: "ellipsis",
                                        whiteSpace: "nowrap",
                                    }}
                                >
                                    {row.SpecimenType.Directions}
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <ErrorAlert error={error} />
        </>
    );
};

export default Samples;
