export async function getContacts() {
    return fetch(`${process.env.REACT_APP_API_PROXY}/v1/contacts`, {
        credentials: "include",
    }).then((response) => response.json());
}

export async function addContact(Contact, ContactID) {
    let url = "";
    if (ContactID) {
        url = `${process.env.REACT_APP_API_PROXY}/v1/contacts/` + ContactID;
    } else {
        url = `${process.env.REACT_APP_API_PROXY}/v1/contacts`;
    }
    return fetch(url, {
        method: "POST",
        credentials: "include",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
        },
        body: JSON.stringify(Contact),
    });
}
