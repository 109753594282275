import React, { useContext } from "react";
import { useNavigate } from "react-router";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Cookies from "js-cookie";
import { SessionContext } from "../hooks/getContext";
import { Typography } from "@mui/material";
import SignatureSetup from "./SetupSignature";
import { apiFetch } from "../services/fetch";

export default function AppBarUser({ context }) {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const navigate = useNavigate();
    const [signatureOpen, setSignatureOpen] = React.useState(false)
    const session = useContext(SessionContext);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const handlelogout = async () => {
        await fetch(`${process.env.REACT_APP_API_PROXY}/user/logout`, { method: "POST", credentials: "include" })
        removeCookieFromDomain();
        context.setUser(null);
        navigate("/login");

    }

    if (context.user === null) {
        return <></>
    }

    const getMainDomain = () => {
        return "." + window.location.hostname;
    };

    const removeCookieFromDomain = () => {
        const domain = getMainDomain();
        Cookies.remove("jot", { path: "", domain: domain });
    }

    console.log(session)

    return (
        <div>
            <SignatureSetup open={signatureOpen} setOpen={setSignatureOpen} />
            <Button
                id="basic-button"
                aria-controls={open ? "basic-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
                onClick={handleClick}
            >
                <Typography color="white" variant="body1">{context.user.FirstName} {context.user.LastName}</Typography>
            </Button>
            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    "aria-labelledby": "basic-button",
                }}
            >
                {session?.user?.IsProvider && <MenuItem
                    onClick={() => setSignatureOpen(true)}>
                    Setup Signature
                </MenuItem>}

                <MenuItem
                    onClick={handlelogout}
                >
                    Logout
                </MenuItem>
            </Menu>
        </div>
    );
}
