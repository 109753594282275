import Title from "./Title";
import React, { useEffect, useState } from "react";
import { DataGridPro } from "@mui/x-data-grid-pro";
import { GridToolbar } from "@mui/x-data-grid-pro";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

export default function CollectorsTable({
    getCollectors,
    selectedCollector,
    setSelectedCollector,
    refresh,
}) {
    const [collectors, setCollectors] = useState([]);

    useEffect(() => {
        getCollectors().then((p) => {
            setCollectors(p);
        });
    }, [refresh]);

    const getCheckbox = (value) => {
        if (value.value) {
            return <CheckCircleIcon color="success" />;
        }
        return <CheckCircleIcon color="disabled" />;
    };

    const columns = [
        { field: "ID", headerName: "ID", width: 45 },
        { field: "FirstName", headerName: "First Name", width: 150 },
        { field: "LastName", headerName: "Last Name", width: 150 },
        { field: "Phone", headerName: "Phone", width: 150 },
        { field: "Email", headerName: "Email", width: 200 },
        {
            field: "IsPhlebotomist",
            headerName: "Is Phlebotomist",
            width: 160,
            renderCell: getCheckbox,
            align: "center",
        },
    ];

    return (
        <React.Fragment>
            {Title !== undefined ? <Title>{Title}</Title> : null}
            <DataGridPro
                hideFooterRowCount
                getRowId={(row) => row.ID}
                density="compact"
                sx={{
                    "& .MuiDataGrid-columnHeaderCheckbox .MuiDataGrid-columnHeaderTitleContainer":
                    {
                        display: "none",
                    },
                }}
                components={{ Toolbar: GridToolbar }}
                componentsProps={{
                    toolbar: {
                        showQuickFilter: true,
                        quickFilterProps: { debounceMs: 500 },
                    },
                }}
                rowSelectionModel={selectedCollector}
                onRowSelectionModelChange={setSelectedCollector}
                rows={collectors}
                columns={columns}
            />
        </React.Fragment>
    );
}
