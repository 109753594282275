import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    Box,
    IconButton,
    Typography,
} from "@mui/material";
import SaveIcon from '@mui/icons-material/Save';
import LoadingButton from '@mui/lab/LoadingButton';
import { Close } from "@mui/icons-material";

const ConfirmDialog = ({title, message, confirm, cancel, open, loading}) => {
    return (
        <Dialog open={open} maxWidth="sm" fullWidth>
            <DialogTitle>{title}</DialogTitle>
            <Box position="absolute" top={0} right={0}>
                <IconButton>
                    <Close />
                </IconButton>
            </Box>
            <DialogContent>
                <Typography>{message}</Typography>
            </DialogContent>
            <DialogActions>
                <Button disabled={loading} color="primary" variant="contained" onClick={cancel}>
                    Cancel
                </Button>
                <LoadingButton startIcon={<SaveIcon />} loadingPosition="start" loading={loading} color="success" variant="contained" onClick={confirm}>
                    <span>Confirm</span>
                </LoadingButton>
            </DialogActions>
        </Dialog>
    );
};

export default ConfirmDialog;
