import React from "react";
import {
    Stack,
    Alert,
    Table,
    TableCell,
    TableContainer,
    TableRow,
} from "@mui/material";
import { FetchLoader } from "./FetchLoader";
import { FormatDateTime } from "../services/util";
import { Download } from "@mui/icons-material";
import { apiFetch, useFetch } from "../services/fetch";
import TableFrame from "./TableFrame";
import { DataTableContainerNoButton } from "./TableContainer";

export default function BillingExports() {
    const { data = [], error, isLoading } = useFetch(
        `/orders/billing/export`
    );

    if (error !== null || isLoading) {
        return <FetchLoader error={error} isLoading={isLoading} />
    }

    const handleClick = async (uuid, filename) => {
        try {
            const data = await apiFetch(`/attachments/${uuid}`)

            const url = window.URL.createObjectURL(data);
            const link = document.createElement("a");
            link.href = url;
            link.download = filename;
            link.click();
            // It's necessary to revoke the object URL to avoid memory leaks
            window.URL.revokeObjectURL(url);
        } catch (error) {
            console.error("Fetch operation failed: " + error);
        }
    };

    return (
        <TableFrame>
            <DataTableContainerNoButton>
                <TableContainer sx={{ height: "100%" }}>
                    {error !== null ? <Alert severity="error">{error}</Alert> : null}
                    <Table>
                        <TableRow>
                            <TableCell>File</TableCell>
                            <TableCell>Created At</TableCell>
                            <TableCell>Created By</TableCell>
                        </TableRow>
                        {data.map((row) => (
                            <TableRow>
                                <TableCell>
                                    <Stack spacing={2} direction="row" alignItems="center">
                                        <a
                                            href="#"
                                            onClick={() =>
                                                handleClick(row.Export.UUID, row.Export.Name)
                                            }
                                        >
                                            <Download />
                                        </a>
                                        <a
                                            href="#"
                                            onClick={() =>
                                                handleClick(row.Export.UUID, row.Export.Name)
                                            }
                                        >
                                            {row.Export.Name}
                                        </a>
                                    </Stack>
                                </TableCell>
                                <TableCell>
                                    {FormatDateTime(row.Export.CreatedAt)}
                                </TableCell>
                                <TableCell>{row.Export.AddedBy.Email}</TableCell>
                            </TableRow>
                        ))}
                    </Table>
                </TableContainer>
            </DataTableContainerNoButton>
        </TableFrame >
    );
}
