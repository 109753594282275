import { Stack } from "@mui/system";
import {
    Alert,
    Button,
    FormControlLabel,
    InputLabel,
    Autocomplete,
} from "@mui/material";
import { useState, useEffect, forwardRef } from "react";
import Box from "@mui/system/Box";
import * as Yup from "yup";
import { Formik, Form } from "formik";
import { financialClassIndex } from "../services/util";
import { TextField, Checkbox, MenuItem } from "@mui/material";
import {
    addInsurance,
    updateInsurance,
    getInsurances,
    getInsurance,
    getEligibilityPayers,
} from "../services/insurances";
import { DataGridPro } from "@mui/x-data-grid-pro";
import { GridToolbar } from "@mui/x-data-grid-pro";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ButtonTray from "./ButtonTray";
import TableFrame from "./TableFrame";
import { DataTableContainer } from "./TableContainer";

export default function InsurancePanel({ }) {
    const initValues = {
        Name: "",
        Code: "",
        InNetwork: false,
        RequiresPreAuth: false,
        PVerifyCode: "",
        Phone: "",
        FinancialClass: 2,
    };

    const [id, setId] = useState();
    const [insurances, setInsurances] = useState([]);
    const [eligibilityPayers, setEligibilityPayers] = useState([]);
    const [initialValues, setInitialValues] = useState(initValues);
    const [create, setCreate] = useState(false);
    const [error, setError] = useState(null);
    const [refresh, setRefresh] = useState(false);

    useEffect(() => {
        if (id) {
            fetchFormData();
        }
    }, [id]);

    useEffect(() => {
        if (!create) {
            return;
        }

        getEligibilityPayers()
            .then((p) => {
                if (!p.ok) {
                    throw new Error("Failed to load eligibility payers");
                }
                return p.json();
            })
            .then((p) => {
                p = p.filter(
                    (row) => row.IsActive && row.IsSupportingEligibility
                );
                setEligibilityPayers(p);
            });
    }, [create]);

    const fetchFormData = async () => {
        getInsurance(id)
            .then((p) => {
                if (!p.ok) {
                    throw new Error("Failed to load insurance");
                }
                return p.json();
            })
            .then((p) => {
                console.log(p);
                setInitialValues({
                    Name: p.Name,
                    Code: p.Code,
                    InNetwork: p.InNetwork,
                    RequiresPreAuth: p.RequiresPreAuth,
                    Phone: p.Phone,
                    PVerifyCode: p.PVerifyCode,
                    FinancialClass: p.FinancialClass,
                });
            });
    };

    useEffect(() => {
        getInsurances()
            .then((p) => {
                if (!p.ok) {
                    throw new Error("Failed to load insurances");
                }
                return p.json();
            })
            .then((p) => setInsurances(p));
    }, [refresh]);

    const validationSchema = Yup.object().shape({
        Name: Yup.string().required("Name is required"),
        Code: Yup.string().required("Code is required"),
        FinancialClass: Yup.number().required("Financial class is required"),
        Phone: Yup.string().matches(
            /^(\+\d{1,2}\s?)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/,
            "Phone number is not valid"
        ),
        InNetwork: Yup.boolean(),
        RequiresPreAuth: Yup.boolean(),
    });

    const handleSubmit = (values) => {
        console.log(values);
        if (id) {
            updateInsurance(id, values)
                .then((p) => {
                    if (!p.ok) {
                        throw new Error("Failed to load insurances");
                    }
                    setError(null);
                    setRefresh((prevValue) => !prevValue);
                    setCreate(false);
                    return p.json();
                })
                .catch((e) => setError(e.message));
        } else {
            addInsurance(values)
                .then((p) => {
                    if (!p.ok) {
                        throw new Error("Failed to load insurances");
                    }
                    setError(null);
                    setRefresh((prevValue) => !prevValue);
                    setCreate(false);
                    return p.json();
                })
                .catch((e) => setError(e.message));
        }
    };

    const PhoneMaskCustom = forwardRef(function PhoneMaskCustom(props, ref) {
        const { inputRef, ...other } = props;

        return (
            <input
                {...other}
                ref={(ref) => {
                    if (inputRef) {
                        inputRef(ref);
                    }
                }}
                maxLength={12}
                pattern="\d{3}\d{3}\d{4}"
                placeholder="##########"
            />
        );
    });

    const formatPhoneNumber = (value) => {
        const phoneNumber = value.replace(/[^\d]/g, ""); // Remove non-digit characters

        const firstDashIndex = 3;
        const secondDashIndex = 6;

        let formattedPhoneNumber = "";

        if (phoneNumber.length < firstDashIndex) {
            return phoneNumber;
        }

        if (phoneNumber.length >= firstDashIndex) {
            formattedPhoneNumber += phoneNumber.slice(0, firstDashIndex) + "-";
        }
        if (
            phoneNumber.length >= firstDashIndex &&
            phoneNumber.length < secondDashIndex
        ) {
            return formattedPhoneNumber;
        }

        if (phoneNumber.length > secondDashIndex) {
            formattedPhoneNumber +=
                phoneNumber.slice(firstDashIndex, secondDashIndex) + "-";
        }
        // formattedPhoneNumber += phoneNumber.slice(secondDashIndex);

        return formattedPhoneNumber;
    };

    console.log(formatPhoneNumber("1"));

    const financialClasses = [
        { label: "Commercial", value: 2 },
        { label: "Medicare", value: 3 },
        { label: "Medicaid", value: 4 },
    ];

    const insuranceForm = () => {
        return (
            <>
                <Box>
                    <Formik
                        initialValues={initialValues}
                        validationSchema={validationSchema}
                        onSubmit={handleSubmit}
                        enableReinitialize
                    >
                        {({
                            values,
                            handleChange,
                            handleBlur,
                            errors,
                            touched,
                            setFieldValue,
                        }) => (
                            <Form>
                                <Stack direction="column" spacing={2}>
                                    <InputLabel>General</InputLabel>
                                    <Stack
                                        direction={{ sm: "column", md: "row" }}
                                        spacing={{ xs: 1, sm: 2, md: 4 }}
                                    >
                                        <TextField
                                            name="Code"
                                            value={values.Code}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            error={
                                                touched.Code &&
                                                Boolean(errors.Code)
                                            }
                                            label="Code"
                                            size="small"
                                            helperText={
                                                touched.Code && errors.Code
                                            }
                                            required
                                        />
                                        <TextField
                                            name="Name"
                                            value={values.Name}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            error={
                                                touched.Name &&
                                                Boolean(errors.Name)
                                            }
                                            label="Name"
                                            size="small"
                                            helperText={
                                                touched.Name && errors.Name
                                            }
                                            required
                                        />
                                        <TextField
                                            required
                                            size="small"
                                            onChange={handleChange}
                                            error={
                                                touched.FinancialClass &&
                                                Boolean(errors.FinancialClass)
                                            }
                                            value={values.FinancialClass}
                                            onBlur={handleBlur}
                                            helperText={
                                                touched.FinancialClass &&
                                                errors.FinancialClass
                                            }
                                            name="FinancialClass"
                                            sx={{
                                                minWidth: "223px",
                                                maxWidth: "100%",
                                            }}
                                            label="FinancialClass"
                                            variant="outlined"
                                            select
                                        >
                                            {financialClasses.map((option) => (
                                                <MenuItem
                                                    key={option.value}
                                                    value={option.value}
                                                >
                                                    {option.label}
                                                </MenuItem>
                                            ))}
                                        </TextField>
                                        <Autocomplete
                                            error={
                                                touched?.PVerifyCode &&
                                                Boolean(errors?.PVerifyCode)
                                            }
                                            options={eligibilityPayers}
                                            getOptionLabel={(option) => {
                                                if (
                                                    option?.PayerName ===
                                                    undefined
                                                ) {
                                                    return option;
                                                }
                                                return (
                                                    option?.PayerCode +
                                                    " - " +
                                                    option?.PayerName
                                                );
                                            }}
                                            isOptionEqualToValue={(
                                                option,
                                                value
                                            ) => {
                                                return (
                                                    option?.PayerCode === value
                                                );
                                            }}
                                            value={values?.PVerifyCode}
                                            onChange={(_, v) => {
                                                setFieldValue(
                                                    "PVerifyCode",
                                                    v === null ? "" : v.PayerCode
                                                );
                                            }}
                                            sx={{ width: "100%" }}
                                            name="PVerifyCode"
                                            renderInput={(params) => {
                                                return (
                                                    <TextField
                                                        {...params}
                                                        size="small"
                                                        label="Eligibility Code"
                                                    />
                                                );
                                            }}
                                        />
                                    </Stack>
                                    <Stack
                                        direction={{ sm: "column", md: "row" }}
                                        spacing={{ xs: 1, sm: 2, md: 4 }}
                                    >
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={values.InNetwork}
                                                />
                                            }
                                            name="InNetwork"
                                            onChange={handleChange}
                                            label="In Network"
                                        />
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={
                                                        values.RequiresPreAuth
                                                    }
                                                />
                                            }
                                            name="RequiresPreAuth"
                                            onChange={handleChange}
                                            label="Requires Prior Auth"
                                        />
                                        <TextField
                                            size="small"
                                            name="Phone"
                                            label="Phone"
                                            variant="outlined"
                                            onChange={handleChange}
                                            error={
                                                touched.Phone &&
                                                Boolean(errors.Phone)
                                            }
                                            value={values.Phone}
                                            onBlur={handleBlur}
                                            helperText={
                                                touched.Phone && errors.Phone
                                            }
                                        />
                                    </Stack>
                                    {error !== null ? (
                                        <Alert severity="error">{error}</Alert>
                                    ) : null}
                                    <Button type="submit">
                                        {id ? "Save" : "Create"}
                                    </Button>
                                </Stack>
                            </Form>
                        )}
                    </Formik>
                </Box>
            </>
        );
    };

    const getCheckbox = (value) => {
        if (value !== undefined && value) {
            return (
                <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="center"
                >
                    <CheckCircleIcon color="success" />
                </Stack>
            );
        }
        return (
            <div
                style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    height: "100%",
                }}
            >
                <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="center"
                >
                    <CheckCircleIcon color="disabled" />
                </Stack>
            </div>
        );
    };

    const columns = [
        { field: "Code", headerName: "Code", width: 100 },
        { field: "Name", headerName: "Name", width: 175 },
        {
            field: "FinancialClass",
            headerName: "Financial Class",
            width: 175,
            valueGetter: (row) => financialClassIndex[row.row.FinancialClass],
        },
        {
            field: "InNetwork",
            headerName: "In Network",
            width: 175,
            renderCell: (row) => getCheckbox(row.row.InNetwork),
        },
        {
            field: "PVerifyCode",
            headerName: "Eligibility Code",
            width: 175,
        },
        { field: "Phone", headerName: "Phone", width: 175 },
    ];

    if (create) {
        return insuranceForm();
    }

    return <>
        <TableFrame>
            <DataTableContainer>
                <DataGridPro
                    getRowId={(row) => row.ID}
                    density="compact"
                    sx={{
                        "& .MuiDataGrid-columnHeaderCheckbox .MuiDataGrid-columnHeaderTitleContainer":
                        {
                            display: "none",
                        },
                    }}
                    components={{ Toolbar: GridToolbar }}
                    componentsProps={{
                        toolbar: {
                            showQuickFilter: true,
                            quickFilterProps: { debounceMs: 500 },
                        },
                    }}
                    keepNonExistentRowsSelected
                    onRowSelectionModelChange={setId}
                    rowSelectionModel={id}
                    rows={insurances}
                    columns={columns}
                />
            </DataTableContainer>
            <ButtonTray>
                <Button
                    variant="contained"
                    color="success"
                    onClick={() => {
                        setId(undefined);
                        setInitialValues(initValues);
                        setCreate(true);
                    }}
                >
                    Create
                </Button>
                <Button
                    disabled={!id}
                    variant="contained"
                    color="primary"
                    onClick={() => setCreate(true)}
                >
                    Edit
                </Button>
            </ButtonTray>
        </TableFrame>
    </>;
}
