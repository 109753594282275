import { useState } from "react";
import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import Framework from "../components/Framework";
import { useLocation, useNavigate } from "react-router";
import PatientInput from "../components/PatientInput";

export default function NewPatient() {
    const state = useLocation().state;
    const navigate = useNavigate();
    const [patientDetails, setPatientDetails] = useState({
        FirstName: "",
        LastName: "",
        DOB: "",
        Sex: "",
        Address: {
            Address1: "",
            Address2: "",
            City: "",
            State: "",
            ZipCode: "",
        },
        FacilityID: "",
        Facility: { Name: "" },
        SSN: "",
        FinancialClass: 3,
        PatientInsurances: [],
        Ethnicity: "",
        Phone: "",
        Email: "",
    });

    let callback;

    if (state !== null) {
        if (state.callback === "/neworder") {
            callback = (patient) => {
                navigate("/neworder?patient=" + patient.ID);
            };
        }
    }

        console.log(callback);

    return (
        <Framework>
            <Box sx={{m:2}}>
            <Typography variant="h5">Add Patient</Typography>
            <PatientInput
                patient={patientDetails}
                setPatient={setPatientDetails}
                mode="new"
                callback={callback}
                back={() => {
                    navigate(-1);
                }}
            />
                </Box>
        </Framework>
    );
}
