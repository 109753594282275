import { useEffect, useState } from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { InputLabel, Box, Button } from "@mui/material";
import { CustomCheckboxField, CustomTextField } from "./CustomTextField";
import { apiFetch } from "../services/fetch";
import { FieldStack, FormStack } from "./FieldStack";
import TestsTable from "./TestsTable";
import ErrorAlert from "./ErrorAlert";
import ProvidersTable from "../tables/ProvidersTable";
import InsurancesTable from "../tables/InsurancesTable";

function ProviderNoteTriggerForm({ id, callback }) {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null)
    const [allProviders, setAllProviders] = useState([])
    const [allInsurances, setAllInsurances] = useState([])
    const [selectedTests, setSelectedTests] = useState([])
    const [selectedProviders, setSelectedProviders] = useState([])
    const [selectedInsurances, setSelectedInsurances] = useState([])

    const [initialValues, setInitialValues] = useState({
        ProviderID: "",
        FacilityID: "",
        AOEs: [],
        TestCodes: [],
        Enabled: true,
    })

    useEffect(() => {
        const getProviders = async () => {
            try {
                const providers = await apiFetch(`/providers`, "GET")
                setAllProviders(providers)

            } catch (e) {
                setError("failed to load providers")
            }
        }

        const getInsurances = async () => {
            try {
                const insurances = await apiFetch(`/insurances`, "GET")
                setAllInsurances(insurances)

            } catch (e) {
                setError("failed to load insurances")
            }
        }

        getProviders()
        getInsurances()
    }, [])


    useEffect(() => {
        if (!id) {
            return
        }

        const getLink = async () => {
            try {
                const response = await apiFetch(`/providers/noteTriggers/${id}`, "GET")
                setSelectedTests(response.TestCodes.map((t) => (t.ID)))
                setSelectedProviders(response.Providers.map((a) => (a.ID)))
                setSelectedInsurances(response.Insurances.map((a) => (a.ID)))
                let newObj = {
                    ...response,
                    TestIds: response.TestCodes.map((t) => (t.ID)),
                    ProviderIds: response.Providers.map((a) => (a.ID)),
                    InsuranceIds: response.Insurances.map((a) => (a.ID)),
                }
                setInitialValues(newObj)

            } catch (e) {
                setError("failed to load patient order link")
            }
        }

        getLink()
    }, [id])

    const validationSchema = Yup.object().shape({
        Enabled: Yup.boolean(),
    });

    const handleSubmit = (values) => {
        console.log(values)
        const data = {
            Description: values?.Description,
            Enabled: values?.Enabled,
            TestIds: selectedTests,
            ProviderIds: selectedProviders,
            InsuranceIds: selectedInsurances,
        }

        if (selectedInsurances.length < 1) {
            setError("At least one insurance must be selected.")
            return
        }
        if (selectedProviders.length < 1) {
            setError("At least one provider must be selected.")
            return
        }

        if (selectedTests.length < 1) {
            setError("At least one test must be selected.")
            return
        }

        const saveNoteTrigger = async () => {
            try {
                setLoading(true)
                if (id) {
                    await apiFetch(`/providers/noteTriggers/${id}`, "POST", data)
                } else {
                    await apiFetch("/providers/noteTriggers", "POST", data)
                }
                callback()
            } catch (e) {
                setError("Failed to save provider note trigger.")
            } finally {
                setLoading(false)
            }

        }

        saveNoteTrigger()
    }


    return (
        <>
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
                enableReinitialize
            >
                {({
                    values,
                    handleChange,
                    errors,
                    handleBlur,
                    touched,
                    setFieldValue,
                }) => (
                    <Form>
                        {console.log(values)}
                        <FormStack>
                            <FieldStack>
                                <CustomCheckboxField name="Enabled" />
                                <CustomTextField name="Description" />
                            </FieldStack>
                            <InputLabel>Providers</InputLabel>
                            <Box height="300px">
                                <ProvidersTable
                                    providers={allProviders}
                                    selected={selectedProviders}
                                    setSelected={setSelectedProviders}
                                />
                            </Box>
                            <InputLabel>Insurances</InputLabel>
                            <Box height="300px">
                                <InsurancesTable
                                    insurances={allInsurances}
                                    selected={selectedInsurances}
                                    setSelected={setSelectedInsurances}
                                />
                            </Box>
                            <InputLabel>Tests</InputLabel>
                            <Box height="300px">
                                <TestsTable
                                    setTest={setSelectedTests}
                                    test={selectedTests}
                                    checkboxes={true}

                                />
                            </Box>
                            <ErrorAlert error={error} />
                            <FieldStack>
                                <Button
                                    disabled={loading}
                                    variant="contained"
                                    type="submit"

                                >Save</Button>
                            </FieldStack>

                        </FormStack>
                    </Form>
                )}
            </Formik>

        </>
    )


}

export default ProviderNoteTriggerForm;
