import { useState } from "react";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { Alert, Button, TextField } from "@mui/material";
import SaveIcon from '@mui/icons-material/Save';
import LoadingButton from '@mui/lab/LoadingButton';
import ErrorAlert from "./ErrorAlert";

export default function NotesDialog({
    handleSave,
    handleClose,
    error,
    helperText,
    loading,
}) {
    const [Notes, setNotes] = useState("");
    return (
        <>
            <DialogTitle>Add Note</DialogTitle>
            <DialogContent>
                <DialogContentText>Please enter note.</DialogContentText>
                <TextField
                    multiline
                    maxRows={2}
                    sx={{ minWidth: "300px" }}
                    inputProps={{ maxLength: 255 }}
                    onBlur={(v) => setNotes(v.target.value)}
                    helperText={helperText}
                />
                <ErrorAlert error={error} />
            </DialogContent>
            <DialogActions>
                <Button disabled={loading} onClick={handleClose}>Cancel</Button>
                <LoadingButton loading={loading} onClick={() => handleSave(Notes)}><span>Save</span></LoadingButton>
            </DialogActions>
        </>
    );
}
