import React from "react";
import { Stack } from "@mui/system";
import { Button } from "@mui/material";

function DownloadButton({ onClick, disabled }) {
    const style = { color: "white" }
    return (
        <Button variant="contained" color="secondary" sx={style} onClick={onClick} disabled={disabled}>Download</Button>
    )
}

function ButtonTray({ children }) {

    const direction = { sm: "column", md: "row" }

    return (
        <Stack direction={direction} justifyContent="space-between" spacing={{ xs: 1, sm: 1, md: 1, lg: 1, xl: 1 }}>
            <Stack direction={direction} spacing={{ xs: 2, sm: 2, md: 2, lg: 2, xl: 2 }}>
                {React.Children.toArray(children).slice(0, 2)}
            </Stack>
            <Stack direction={direction} spacing={{ xs: 2, sm: 2, md: 2, lg: 2, xl: 2 }}>
                {React.Children.toArray(children).slice(2)}
            </Stack>
        </Stack>
    )

}

export { DownloadButton };
export default ButtonTray;
