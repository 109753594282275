import * as React from "react";
import { useFetch } from "../services/fetch";
import { CircularProgress } from "@mui/material";
import { Viewer } from "@react-pdf-viewer/core";
import ErrorAlert from "../components/ErrorAlert";
import { Worker } from "@react-pdf-viewer/core";
import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout";
import { PDFType, Patient } from "../constants/types";

const ViewPDFv2 = ({ pdfType, url, patient }: { pdfType: PDFType, url: string, patient: Patient | null }) => {
	const [pdf, setPDF] = React.useState<Uint8Array>(new Uint8Array());

	const { data, error, isLoading } = useFetch(url);

	const defaultLayoutPluginInstance = defaultLayoutPlugin({
		toolbarPlugin: {
			getFilePlugin: {
				fileNameGenerator: (file) => {
					const originalFileName = file.name.substring(file.name.lastIndexOf('/') + 1);

					let customFileName = ""

					if (patient?.FirstName && patient?.LastName) {
						customFileName = `${patient.LastName.toUpperCase()}-${patient.FirstName.toUpperCase()}`;
					} else {
						customFileName = originalFileName;
					}

					customFileName += `-${pdfType}`;

					return customFileName;
				}
			}

		}
	});


	React.useEffect(() => {
		if (!data || isLoading) {
			return
		}

		const init = async () => {
			const arrayBuffer = await (data as Blob).arrayBuffer();
			setPDF(new Uint8Array(arrayBuffer))
		}

		init()

	}, [data, isLoading])

	if (error) {
		return <ErrorAlert error={error} />
	}

	if (isLoading) {
		return <CircularProgress />
	}

	return (
		<Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js" >
			<Viewer defaultScale={1.50} fileUrl={pdf} plugins={[defaultLayoutPluginInstance]} />
		</Worker>
	);
}

export default ViewPDFv2;
