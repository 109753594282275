import React, { useState, useEffect } from "react";
import {
    Box,
    Button,
    FormControl,
    MenuItem,
    Select,
    Typography,
    Stack,
    InputLabel,
    SelectChangeEvent
} from '@mui/material';
import TestsTable from "../../tables/TestsTable";
import { apiFetch } from "../../services/fetch";
import { getOrderableTests } from "../../services/tests";
import { Facility } from "../../constants/types";

type OrderTestsTableProps = {
    facility: Facility | null,
    handleReset: () => void,
    height: number,
    setTests: () => void,
    tests: number[],
}

function OrderTestsTable({ facility, handleReset, height, setTests, tests }: OrderTestsTableProps) {
    const [categories, setCategories] = useState([])
    const [category, setCategory] = useState<null | string>(null)
    const [error, setError] = useState<null | string>(null)
    const [addEmptyCategoryOption, setAddEmptyCategoryOption] = useState(true)

    useEffect(() => {
        const getCategories = async () => {
            try {
                const response = await apiFetch(`/categories`)
                if (facility?.CategoryFilter && facility?.CategoryFilter.length > 0) {
                    const c = response.filter((x: any) => {
                        for (let category of facility?.CategoryFilter) {
                            if (category?.ID === x?.ID) {
                                return true
                            }
                        }
                        return false
                    })
                    console.log("category filter found", c)
                    setCategories(c)
                    setCategory(c[0].ID)
                    setAddEmptyCategoryOption(false)
                } else {
                    setCategories(response)
                }
            } catch (e) {
                console.log(e)
                setError("Failed to load categories")
            }
        }
        getCategories()


    }, [facility?.CategoryFilter])

    const handleCategoryFilter = (event: SelectChangeEvent) => {
        setCategory(event.target.value)
    }

    return (
        <>
            <Stack direction="row" justifyContent="space-between">
                <Typography variant="h6" gutterBottom>
                    Select Tests
                </Typography>
                <Stack direction="row" spacing={1} m={1}>
                    <FormControl>
                        <InputLabel id="my-id" shrink>Category</InputLabel>
                        <Select
                            notched
                            aria-pressed
                            labelId="my-id"
                            id="demo-simple-select-helper"
                            label="Category"
                            size="small"
                            value={category || ""}
                            sx={{ minWidth: "200px" }}
                            onChange={handleCategoryFilter}
                        >
                            {addEmptyCategoryOption && <MenuItem value=""><em>None</em></MenuItem>}
                            {categories.map((c: any, i) => (
                                <MenuItem key={i} value={c?.ID}>{c?.Name}</MenuItem>))}
                        </Select>
                    </FormControl>
                    <Button
                        variant="outlined"
                        size="small"
                        onClick={handleReset}
                    >
                        Reset Selection
                    </Button>
                </Stack>
            </Stack>
            <Box height="calc(100% - 59px)">
                <TestsTable
                    facility={facility}
                    tests={tests}
                    setTests={setTests}
                    getTests={getOrderableTests}
                    categoryFilter={category}
                    checkboxes={true}
                    specimenTypeFilter={null}
                    stabilityFilter={null}
                />
            </Box>
        </>
    )
}

export default OrderTestsTable;
