import React from "react";
import { IconButton, Stack } from "@mui/material";
import { CopyAll } from "@mui/icons-material";

function CopyButton({ copyText }: { copyText: string }) {
    return (
        <IconButton
            size="small"
            sx={{ color: "#d4d4d4" }}
            onClick={() => {
                navigator.clipboard.writeText(copyText);
            }}
        >
            <CopyAll sx={{ fontSize: "1rem" }} />
        </IconButton>
    )
}

function CopyButtonWithText({ text, copyText }: { text: string, copyText: string }) {
    return (
        <Stack direction="row" width="100%" spacing={0}>
            <CopyButton copyText={copyText} />
            <p>{text}</p>
        </Stack>
    )
}

export default CopyButton;
export { CopyButtonWithText };

