import { Stack } from "@mui/system";

function FieldStack({ children, ...props }) {
    return (
        <Stack
            direction={{ sm: "column", md: "row" }}
            spacing={{ xs: 1, sm: 2, md: 4 }}
            {...props}
        >
            {children}
        </Stack>
    )
}

function FormStack({ children, ...props }) {
    return (
        <Stack
            direction={{ sm: "column" }}
            spacing={{ xs: 1, sm: 2, md: 2 }}
            {...props}
        >
            {children}
        </Stack>
    )
}


export { FieldStack, FormStack };
