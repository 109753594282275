import { Alert, Typography } from "@mui/material";
import { useState, useEffect } from "react";
import TextField from "@mui/material/TextField";
import { FormControl, FormControlLabel } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import TestsTable from "../tables/TestsTable";
import ProfilesTable from "../tables/ProfilesTable";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import { Stack } from "@mui/system";
import { getTests } from "../services/tests";
import {
    addProfile,
    updateProfile,
    getProfileDetails,
} from "../services/profiles";
import FacilitiesTable from "./FacilitiesTable";
import { getFacilities } from "../services/facilities";

function saveProfile(profileDetails) {
    if (profileDetails.ID) {
        return updateProfile(profileDetails, profileDetails.ID);
    } else {
        profileDetails.Code = Number(profileDetails.Code);
        return addProfile(profileDetails);
    }
}

export default function ProfileForm({
    profileDetails,
    setProfileDetails,
    saved,
    profileID,
}) {
    const [error, setError] = useState("");
    console.log(profileID);

    useEffect(() => {
        if (profileID === undefined) {
            return;
        }
        getProfileDetails(profileID)
            .then((p) => {
                if (!p.ok) {
                    throw new Error("Failed to load profile");
                }
                return p.json();
            })
            .then((p) => {
                console.log(p);
                let profDef = {};
                profDef.ID = p.ID;
                profDef.Code = p.Code;
                profDef.Name = p.Name;
                profDef.Description = p.Description;
                profDef.Tests = [];
                profDef.Facilities = [];
                profDef.SubProfiles = [];

                for (let t of p.Tests) {
                    profDef.Tests.push(t.ID);
                }
                for (let f of p.Facilities) {
                    profDef.Facilities.push(f.ID);
                }
                for (let s of p.SubProfiles) {
                    profDef.SubProfiles.push(s.ID);
                }

                setProfileDetails({ ...profDef });
            })
            .catch((e) => setError(e.message));
    }, [profileID]);

    console.log(profileDetails);

    const [forAllFacs, setForAllFacs] = useState(
        profileDetails.Facilities === undefined
            ? true
            : profileDetails.Facilities.length === 0
    );

    const updateProfile = (event) => {
        console.log(profileDetails);
        setProfileDetails({
            ...profileDetails,
            [event.target.name]: event.target.value,
        });
    };
    return (
        <Box component="form" id="profile-form">
            <Stack direction="column" spacing={2}>
                {error != "" ? <Alert severity="error">{error}</Alert> : <></>}
                <Stack direction="row" spacing={2}>
                    <TextField
                        size="small"
                        onChange={updateProfile}
                        disabled={!!profileID}
                        value={profileDetails?.Code}
                        required
                        name="Code"
                        label="Code"
                        InputLabelProps={{ shrink: true }}
                        inputProps={{ type: "number" }}
                    />
                    <TextField
                        size="small"
                        onChange={updateProfile}
                        value={profileDetails?.Name}
                        required
                        name="Name"
                        InputLabelProps={{ shrink: true }}
                        label="Name"
                    />
                    <FormControlLabel
                        control={
                            <Checkbox
                                name="AllFacilities"
                                checked={forAllFacs}
                                onChange={(v) =>
                                    setForAllFacs(v.target.checked)
                                }
                            />
                        }
                        label="For All Facilities"
                    ></FormControlLabel>
                </Stack>
                <TextField
                    size="small"
                    onChange={updateProfile}
                    value={profileDetails?.Description}
                    required
                    InputLabelProps={{ shrink: true }}
                    name="Description"
                    label="Description"
                />
                <Typography variant="h5">Tests</Typography>
                <Box height="400px">
                    <TestsTable
                        tests={
                            profileDetails.Tests === undefined
                                ? []
                                : profileDetails.Tests
                        }
                        setTestDetails={() => { }}
                        getTests={getTests}
                        setTests={(v) =>
                            setProfileDetails({ ...profileDetails, Tests: v })
                        }
                        checkboxes={true}
                    />
                </Box>
                <Typography variant="h5">SubProfiles</Typography>
                <Box height="400px">
                    <ProfilesTable
                        profiles={
                            profileDetails.SubProfiles === undefined
                                ? []
                                : profileDetails.SubProfiles
                        }
                        setProfiles={(v) =>
                            setProfileDetails({ ...profileDetails, SubProfiles: v })
                        }
                        checkboxes={true}
                    />
                </Box>
                {!forAllFacs && (
                    <>
                        <Typography variant="h5">Facilities</Typography>
                        <Box height="400px">
                            <FacilitiesTable
                                getFacilities={getFacilities}
                                checkboxes={true}
                                selectedFacilities={
                                    profileDetails.Facilities === undefined
                                        ? []
                                        : profileDetails.Facilities
                                }
                                setSelectedFacilities={(v) =>
                                    setProfileDetails({
                                        ...profileDetails,
                                        Facilities: v,
                                    })
                                }
                            />
                        </Box>
                    </>
                )}
                <Stack direction="row" justifyContent="space-between">
                    <Button variant="outlined" color="primary" onClick={saved}>
                        Back
                    </Button>
                    <Button
                        variant="contained"
                        color="success"
                        onClick={(e) => {
                            e.preventDefault();
                            const ele = document.getElementById("profile-form");
                            const chk_status = ele.checkValidity();
                            ele.reportValidity();
                            if (chk_status) {
                                saveProfile(profileDetails)
                                    .then(() => saved());
                            }
                        }}
                    >
                        Save
                    </Button>
                </Stack>
            </Stack>
        </Box>
    );
}
