export async function login(email, password) {
    return fetch(`${process.env.REACT_APP_API_PROXY}/user/login`, {
        method: "POST",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
        },
        credentials: "include",
        body: JSON.stringify({ Email: email, Password: password }),
    });
}

export async function activate(password, token) {
    return fetch(`${process.env.REACT_APP_API_PROXY}/user/activate`, {
        method: "POST",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
        },
        credentials: "include",
        body: JSON.stringify({ Invitation: token, Password: password }),
    });
}

export async function checkPasswordStrength(password) {
    return fetch(`${process.env.REACT_APP_API_PROXY}/user/passwordStrength`, {
        method: "POST",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
        },
        body: JSON.stringify({ Password: password }),
    });
}

export async function checkToken() {

    return fetch(`${process.env.REACT_APP_API_PROXY}/v1/checkToken`, {
        method: "GET",
        credentials: "include"
    });
}

export async function getInvite(invite) {
    return fetch(`${process.env.REACT_APP_API_PROXY}/user/activate?invite=` + invite, {
        method: "GET",
    });
}
