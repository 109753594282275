import React, { useEffect } from "react";
import { Autocomplete, Divider, TextField } from "@mui/material";
import { Stack } from "@mui/system";
import { useFormikContext } from "formik";

export default function FacilityProviderSelect({
    facilities,
    providers,
}) {
    const { values, setFieldValue, setFieldTouched } = useFormikContext();

    useEffect(() => {
        if (facilities.length === 1 && !values?.Facility?.ID) {
            setFieldValue('Facility', facilities[0])
        }
    }, [facilities]);

    useEffect(() => {
        if (providers.length === 1) {
            setFieldValue('Provider', providers[0]);
        }

    }, [providers]);

    return (
        <>
            <Stack direction="column" spacing={2} divider={<Divider />}>
                <Autocomplete
                    required
                    options={facilities.sort((a, b) => (a?.Name > b?.Name))}
                    getOptionLabel={(row) => row.Name || ""}
                    value={values?.Facility || null}
                    isOptionEqualToValue={(option, value) =>
                        option.ID === value.ID
                    }
                    onChange={(_, newValue) => {
                        setFieldValue('Facility', newValue)
                        setFieldTouched('Facility')
                    }}
                    renderInput={(params) => {
                        return (
                            <TextField
                                required
                                {...params}
                                error={!values?.Facility}
                                label="Facility"
                            />
                        );
                    }}
                />
                <Autocomplete
                    required
                    disabled={!values?.Facility}
                    value={values?.Provider || null}
                    options={providers.sort((a, b) => (a?.FirstName < b?.FirstName))}
                    isOptionEqualToValue={(option, value) =>
                        option.ID === value.ID
                    }
                    getOptionLabel={(row) => row.FirstName + " " + row.LastName}
                    onChange={(_, newValue) => {
                        setFieldValue('Provider', newValue)
                        // setFieldTouched('Provider')
                    }}
                    renderInput={(params) => {
                        return (
                            <TextField
                                required
                                {...params}
                                error={!values?.Provider}
                                label="Provider"
                            />
                        );
                    }}
                />
            </Stack>
        </>
    );
}
