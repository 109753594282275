import React, { useState } from "react";
import {
    Button,
    FormControlLabel,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Dialog,
    Checkbox,
    FormGroup,
    Box,
} from "@mui/material";
import TestsTable from "../tables/TestsTable";
import { getOrderableTests } from "../services/tests";
import {
    addOrderNote,
    addOrderAddOn,
} from "../services/orders";
import { Sample } from "../constants/types";
import ErrorAlert from "./ErrorAlert";
import NotesDialog from "./NotesDialog";
import { apiFetch } from "../services/fetch";
import ReflexesTable from "../tables/ReflexesTable";
import ProfilesTable from "../tables/ProfilesTable";

type CustomDialogProps = {
    Title: string,
    Content: React.ReactNode,
    DisableAction: boolean,
    ConfirmAction: () => void,
    open: boolean,
    setOpen: (open: boolean) => void,
    RemoveActions?: boolean,
}

function CustomDialog(props: CustomDialogProps) {
    return (
        <Dialog open={props.open} maxWidth={"xl"} fullWidth>
            <DialogTitle>{props.Title}</DialogTitle>
            <DialogContent>
                {props.Content}
            </DialogContent>
            {!props.RemoveActions && <DialogActions>
                <Button onClick={() => props.setOpen(false)}>Cancel</Button>
                <Button disabled={props.DisableAction} onClick={props.ConfirmAction}>
                    Add
                </Button>
            </DialogActions>}
        </Dialog>
    );
}

/* @ts-ignore */
function AddNoteDialog({ orderId, open, setOpen, setRefresh }: { orderId: Number }) {
    const [error, setError] = useState("");
    const [loading, setLoading] = useState(false);

    /* @ts-ignore */
    const handleAddNote = (note) => {
        let body = { Note: note };
        setLoading(true);

        addOrderNote(orderId, body)
            /* @ts-ignore */
            .then((p) => {
                if (!p.ok) {
                    throw new Error("Failed to add note.");
                }
                return p.json();
            })
            .catch((e) => setError(e.message))
            /* @ts-ignore */
            .finally(() => { setOpen(false); setRefresh((refresh) => !refresh); setLoading(false) });
    };

    return (
        <Dialog open={open}>
            {/* @ts-ignore */}
            <NotesDialog
                handleSave={handleAddNote}
                handleClose={() => setOpen(false)}
                error={error}
                loading={loading}
            />
        </Dialog>
    )
}

/* @ts-ignore */
function AddOnDialog({ orderId, samples = [], setRefresh, open, setOpen }: { orderId: Number, samples: Sample[] }) {
    const [addOnTests, setAddOnTests] = useState([]);
    const [showAllAddons, setShowAllAddons] = useState(false);
    const [error, setError] = useState("");

    const handleAddOn = () => {
        addOrderAddOn(orderId, { Tests: addOnTests })
            .then((p) => {
                if (!p.ok) {
                    throw new Error("Failed to create add ons.");
                }
                return p.json();
            })
            .catch((e) => setError(e.message))
            /* @ts-ignore */
            .finally(() => { setOpen(false); setRefresh((refresh: boolean) => !refresh) });
    };

    const getSpecimenTypeIDs = () => {
        if (samples.length === 0) {
            return false;
        }
        // returns array of spec types
        const specTypes = samples.map(
            (samp) => samp.SpecimenTypeID
        );
        if (specTypes.length === 0) {
            return false;
        }
        return specTypes;
    };

    const getStabilityFilter = () => {
        if (samples.length === 0) {
            return false;
        }
        // create object with specimenType and remaining stability in hours
        // the first key is the sampleID the second is specimenType id
        // ex. { 1: { 1: 32}, 4: { 2: 24}}
        //
        let stab = {};

        for (let samp of samples) {
            // calculate remaining stability
            let now = new Date();
            // to get the remaining stability
            /* @ts-ignore */
            let stability = now - new Date(samp.DateCollected);

            let stabilityInHours = Math.floor(stability / 1000 / 60 / 60);

            /* @ts-ignore */
            stab[samp.ID] = { [samp.SpecimenTypeID]: stabilityInHours };
        }

        // if no samples exist on the order, return null so that the tests
        // table is not filtered
        if (Object.keys(stab).length === 0) {
            return false;
        }
        return stab;
    };


    return (
        <CustomDialog
            open={open}
            setOpen={setOpen}
            Title="Request Add On"
            ConfirmAction={handleAddOn}
            DisableAction={addOnTests.length === 0}
            Content={
                <>
                    <DialogContentText>
                        Please select tests to add.
                    </DialogContentText>
                    <Box maxHeight="70vh" overflow={"auto"}>
                        {/* @ts-ignore */}
                        <TestsTable
                            tests={addOnTests}
                            setTests={setAddOnTests}
                            getTests={getOrderableTests}
                            specimenTypeFilter={showAllAddons ? null : getSpecimenTypeIDs()}
                            stabilityFilter={showAllAddons ? null : getStabilityFilter()}
                            checkboxes={true}
                        />
                    </Box>
                    <FormGroup>
                        <FormControlLabel control={
                            <Checkbox checked={showAllAddons}
                                /* @ts-ignore */
                                onClick={(e) => setShowAllAddons(e.target.checked)}
                            />
                        } label="Show All Addons" />
                    </FormGroup>
                    <ErrorAlert error={error} />
                </>
            }
        />
    )
}

/* @ts-ignore */
function AddTestDialog({ orderId, setRefresh, open, setOpen }: { orderId: Number }) {
    const [tests, setTests] = useState([]);
    const [error, setError] = useState("");

    const handleAddTest = async () => {
        try {
            /* @ts-ignore */
            await apiFetch(`/orders/${orderId}/tests`, "POST", { TestIDs: tests })
            setOpen(false); setRefresh((refresh: boolean) => !refresh)
        } catch (e) {
            setError("Failed to add tests.")
        }
    };

    return (
        <CustomDialog
            open={open}
            setOpen={setOpen}
            Title="Add Test"
            ConfirmAction={handleAddTest}
            DisableAction={tests.length === 0}
            Content={
                <>
                    <DialogContentText>
                        Please select tests to add.
                    </DialogContentText>
                    <Box maxHeight="70vh" overflow={"auto"}>
                        {/* @ts-ignore */}
                        <TestsTable
                            tests={tests}
                            setTests={setTests}
                            getTests={getOrderableTests}
                            checkboxes={true}
                        />
                    </Box>
                    <ErrorAlert error={error} />
                </>
            }
        />
    )
}

/* @ts-ignore */
function AddReflexDialog({ orderId, setRefresh, open, setOpen }: { orderId: Number }) {
    const [reflexes, setReflexes] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState("");

    const handleAddReflex = async () => {
        setLoading(true);
        try {
            /* @ts-ignore */
            await apiFetch(`/orders/${orderId}/reflexes`, "POST", { ReflexIDs: reflexes })
            setOpen(false); setRefresh((refresh: boolean) => !refresh)
        } catch (e) {
            setError("Failed to add reflexes.")
        } finally {
            setLoading(false);
        }
    };

    return (
        <CustomDialog
            open={open}
            setOpen={setOpen}
            Title="Add Reflex"
            ConfirmAction={handleAddReflex}
            DisableAction={reflexes.length === 0 || loading}
            Content={
                <>
                    <DialogContentText>
                        Please select reflexes to add.
                    </DialogContentText>
                    <Box maxHeight="70vh" overflow={"auto"}>
                        {/* @ts-ignore */}
                        <ReflexesTable
                            reflexes={reflexes}
                            setReflexes={setReflexes}
                            checkboxes={true}
                        />
                    </Box>
                    <ErrorAlert error={error} />
                </>
            }
        />
    )
}

/* @ts-ignore */
function AddProfileDialog({ orderId, setRefresh, open, setOpen, facilityId }: { orderId: Number }) {
    const [profiles, setProfiles] = useState([]);
    const [error, setError] = useState("");

    const handleAddProfile = async () => {
        try {
            /* @ts-ignore */
            await apiFetch(`/orders/${orderId}/profiles`, "POST", { ProfileIDs: profiles })
            setOpen(false); setRefresh((refresh: boolean) => !refresh)
        } catch (e) {
            setError("Failed to add profiles.")
        }
    };

    return (
        <CustomDialog
            open={open}
            setOpen={setOpen}
            Title="Add Profile"
            ConfirmAction={handleAddProfile}
            DisableAction={profiles.length === 0}
            Content={
                <>
                    <DialogContentText>
                        Please select profiles to add.
                    </DialogContentText>
                    <Box maxHeight="70vh" overflow={"auto"}>
                        {/* @ts-ignore */}
                        <ProfilesTable
                            profiles={profiles}
                            setProfiles={setProfiles}
                            facilityId={facilityId}
                            checkboxes={true}
                        />
                    </Box>
                    <ErrorAlert error={error} />
                </>
            }
        />
    )
}
export { AddOnDialog, AddNoteDialog, AddTestDialog, AddReflexDialog, AddProfileDialog };
