import React, { useEffect, useState } from "react";
import { DataGridPro } from "@mui/x-data-grid-pro";
import { GridToolbar } from "@mui/x-data-grid-pro";
import { getMethods } from "../services/methods";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { HoursToHumanReadable } from "../services/util";

export default function MethodsTable({ methods, setMethods, checkboxes, refresh }) {
    const [allMethods, setAllMethods] = useState([]);

    const getCheckbox = (value) => {
        if (value.value) {
            return <CheckCircleIcon color="success" />;
        }
        return <CheckCircleIcon color="disabled" />;
    };

    const columns = [
        { field: "ID", headerName: "ID", width: 50 },
        {
            field: "Test",
            headerName: "Test",
            width: 150,
            valueGetter: (row) => row.row.Test.Name
        },
        {
            field: "Active", headerName: "Active", width: 75,

            renderCell: getCheckbox,
        },
        {
            field: "UOM",
            headerName: "UOM",
            width: 125,
        },
        {
            field: "InstrumentTestCode",
            headerName: "Instrument Test Code",
            width: 250,
        },
        {
            field: "Instruments",
            headerName: "Instruments",
            width: 250,
            valueGetter: (v) => {
                return v.value.map(u => u.Name).join(', ')
            }
        },
        {
            field: "Precision",
            headerName: "Precision",
            width: 125,
        },
        {
            field: "MinDetectionWindow",
            headerName: "Min Detection Window",
            width: 100,
            valueGetter: (row) => HoursToHumanReadable(row.row.MinDetectionWindow),
        },
        {
            field: "MaxDetectionWindow",
            headerName: "Max Detection Window",
            width: 100,
            valueGetter: (row) => HoursToHumanReadable(row.row.MaxDetectionWindow),
        },
        {
            field: "NumericRanges",
            headerName: "Numeric Ranges",
            width: 200,
            valueGetter: (params) => params?.row?.RefRangeNumeric.length
        },
        {
            field: "ListRanges",
            headerName: "List Ranges",
            width: 200,
            valueGetter: (params) => params?.row?.RefRangeList.length

        },
    ];

    useEffect(() => {
        getMethods()
            .then(t => {
                if (!t.ok) {
                    throw new Error("Failed to load methods.")
                }
                return t.json()
            })
            .then((t) => {
                setAllMethods(t);
            });
    }, [refresh]);

    return (
        <DataGridPro
            getRowId={(row) => row.ID}
            density="compact"
            sx={{
                "& .MuiDataGrid-columnHeaderCheckbox .MuiDataGrid-columnHeaderTitleContainer":
                {
                    display: "none",
                },
            }}
            components={{ Toolbar: GridToolbar }}
            componentsProps={{
                toolbar: {
                    showQuickFilter: true,
                    quickFilterProps: { debounceMs: 500 },
                },
            }}
            keepNonExistentRowsSelected
            onRowSelectionModelChange={setMethods}
            rowSelectionModel={methods}
            checkboxSelection={checkboxes}
            rows={allMethods}
            columns={columns}
        />
    );
}
