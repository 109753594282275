import { useState } from "react";
import { Form, Formik } from "formik";
import { CustomCheckboxField, CustomTextField } from "./CustomTextField";
import ItemListInput from "./ItemListInput";
import { Divider, Typography, Button, Grid, Switch, FormControlLabel } from "@mui/material";
import { Stack } from "@mui/system";
import { apiFetch } from "../services/fetch";


function CoriellForm({ initialValues, validationSchema, setError }) {
    const [edit, setEdit] = useState(false)

    const handleSubmit = async (values) => {
        console.log(values)
        const data = validationSchema.cast(values)
        console.log(data)
        try {
            await apiFetch("/integrations/coriell", "POST", data)
            setEdit(false)
            setError(null)
        } catch (e) {
            setError("Failed to save changes.")
        }
    }

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
            enableReinitialize
        >{({ isSubmitting, touched, errors, values, setFieldValue }) => (
            <Form>
                {console.log(errors)}
                {console.log(values)}
                <FormControlLabel control={<Switch checked={edit} onChange={(e) => setEdit(e.target.checked)} />} label="Edit" />
                <Stack direction="column" spacing={2}>
                    <CustomTextField
                        name="QcGroupID"
                        label="QC Group ID"
                        helperText={(touched?.QcGroupID && errors?.QcGroupID) || "This value is provided by Coriell"}
                        disabled={isSubmitting || !edit}
                    />
                    <CustomCheckboxField
                        name="GenerateInternalReport"
                        label="Generate Internal Report"
                        disabled={isSubmitting || !edit}
                    />
                    <CustomTextField
                        name="InfectiousDiseaseURL"
                        label="Infectious Disease Endpoint"
                        helperText={(touched?.InfectiousDiseaseURL && errors?.InfectiousDiseaseURL) || "Endpoint provided by Coriell for generating IF reports"}
                        disabled={isSubmitting || !edit}
                    />
                    <CustomTextField
                        name="PGXURL"
                        label="PGX Endpoint"
                        helperText={(touched?.PGXURL && errors?.PGXURL) || "Endpoint provided by Coriell for generating PGX reports"}
                        disabled={isSubmitting || !edit}
                    />
                    <Grid container spacing={2} justifyContent="space-between">
                        <Grid item sm={12} md={5}>
                            <Stack direction="column" spacing={2}>
                                <Typography variant="h6">Panels</Typography>
                                <Divider />
                                <ItemListInput
                                    disabled={isSubmitting || !edit}
                                    helperText={(touched?.Panels && errors?.Panels) || "Panels configured in Coriell"}
                                    label="Add Panel"
                                    items={values?.Panels || []}
                                    setItems={(panels) => setFieldValue("Panels", panels)}
                                />
                            </Stack>
                        </Grid>
                        <Grid item sm={12} md={5}>
                            <Stack direction="column" spacing={2}>
                                <Typography variant="h6">Templates</Typography>
                                <Divider />
                                <ItemListInput
                                    disabled={isSubmitting || !edit}
                                    helperText={(touched?.Templates && errors?.Templates) || "Templates configured in Coriell"}
                                    label="Add Template"
                                    items={values?.Templates || []}
                                    setItems={(templates) => setFieldValue("Templates", templates)}
                                />
                            </Stack>
                        </Grid>
                    </Grid>
                    <Button
                        type="submit"
                        disabled={isSubmitting || !edit}
                        variant="contained"
                    >Save</Button>
                </Stack>

            </Form>
        )
            }

        </Formik >
    )
}

export default CoriellForm;
