import DataTable from "../components/DataTable";
import { apiFetch, useFetch } from "../services/fetch"; import { FormatDateTime, FormatDate } from "../services/util";
import { GetCoriellStatusChip } from "../components/StatusChips";
import { Button, Divider, Stack } from "@mui/material";
import { useState } from "react";
import CoriellTemplatePanelSelect from "../components/CoriellTemplatePanelSelect";
import ErrorAlert from "../components/ErrorAlert";

function CoriellTable() {
    const { data, isLoading, error } = useFetch("/integrations/3")
    const [processing, setProcessing] = useState(false)
    const [showPanelSelect, setShowPanelSelect] = useState(false)
    const [generateError, setGenerateError] = useState(null)
    const [selectedId, setSelectedId] = useState(null)

    const generateReport = async (template, panel) => {
        console.log('generating', selectedId)
        if (!selectedId) {
            return
        }

        try {
            setProcessing(true)
            const data = { ID: selectedId, Template: template, Panel: panel }
            console.log(data)
            await apiFetch(`/integrations/3/generate`, 'POST', data)
        } catch (e) {
            setGenerateError("Failed to generate report")
        } finally {
            setProcessing(false)
        }

    }

    const handlePanelSelect = (values) => {
        console.log(values)
        return generateReport(values?.Template, values?.Panel)
    }

    const GetCoriellButtons = (id) => {
        return (
            <Stack direction="row" divider={<Divider orientation="vertical" />} spacing={1}>
                <Button
                    variant="contained"
                    color="secondary"
                    sx={{ color: "white" }}
                    size="small"
                    disabled={processing}>
                    Send Demo
                </Button>
                <Button
                    variant="contained"
                    color="warning"
                    size="small"
                    onClick={() => { setShowPanelSelect(true); setSelectedId(id) }}
                    disabled={processing}>
                    Generate Report
                </Button>
            </Stack>
        )
    }

    const columns = [
        { field: "id", headerName: "ID", width: 50 },
        {
            field: "CreatedAt",
            headerName: "Sent At",
            width: 175,
            valueGetter: (row) => FormatDateTime(row.row?.CreatedAt),
        },
        { field: "OrderID", headerName: "Order", width: 75 },
        {
            field: "Patient First",
            headerName: "Patient First",
            width: 100,
            valueGetter: (params) =>
                params?.row?.Patient?.FirstName
        },
        {
            field: "Patient Last",
            headerName: "Patient Last",
            width: 100,
            valueGetter: (params) =>
                params?.row?.Patient?.LastName
        },
        {
            field: "Patient",
            headerName: "Patient DOB",
            width: 150,
            valueGetter: (params) =>
                FormatDate(params?.row?.Patient?.DOB)
        },
        {
            field: "Status",
            headerName: "Status",
            width: 175,
            renderCell: (params) =>
                GetCoriellStatusChip(params?.row?.Status)
        },
        {
            field: "Actions",
            headerName: "Actions",
            width: 350,
            renderCell: (params) =>
                GetCoriellButtons(params?.id)
        },

    ]

    return (
        <>
            <ErrorAlert error={error} />
            <CoriellTemplatePanelSelect
                open={showPanelSelect}
                setOpen={setShowPanelSelect}
                handleSubmit={handlePanelSelect} />
            <DataTable
                data={data}
                loading={isLoading}
                error={error}
                columns={columns}
                getRowId={(row) => row.id} />
        </>
    )
}

export default CoriellTable;
