import { createTheme } from "@mui/material/styles";

const mdTheme = createTheme({
    palette: {
        primary: {
            main: "#008EE9",
        },
        secondary: {
            main: "#00CB9B",
        },
        // mode: "dark",
    },
});

export default mdTheme;
