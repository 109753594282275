import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Link,
} from "@mui/material";

const AllergysTable = ({ allergys }) => {
    if (allergys === undefined || allergys.length === 0) {
        return <></>;
    }
    console.log(allergys);

    return (
        <TableContainer>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>RxNorm ID</TableCell>
                        <TableCell>Allergy</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {allergys.map((med) => (
                        <TableRow key={med.RxNormID}>
                            <TableCell>
                                <Link
                                    href={`https://mor.nlm.nih.gov/RxNav/search?searchBy=RXCUI&searchTerm=${med.RxNormID}`}
                                    target="_blank"
                                >
                                    {med.RxNormID}
                                </Link>
                            </TableCell>
                            <TableCell>{med.Name}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

export default AllergysTable;
