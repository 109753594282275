import React, { useState, useEffect, useRef } from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    Button,
    Stack,
    Divider,
    FormControl,
    Autocomplete,
    TextField,
    InputLabel,
    Box
} from '@mui/material'
import SpecimenTypeTable from '../tables/SpecimenTypes';
import { getSpecimenTypes } from '../services/specimentypes';
import * as Yup from "yup";
import { Form, Formik, FormikContextType, FormikProps, useField, useFormikContext } from 'formik';
import { apiFetch } from '../services/fetch';
import ErrorAlert from './ErrorAlert';
import { CustomCheckboxField, CustomTextField } from './CustomTextField';
import { getCollectorsByFacility } from '../services/collectors';
import { Collector, Order } from '../constants/types';
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

type UpdateSampleDialogProps = {
    sampleId: number | null,
    open: boolean,
    setOpen: (open: boolean) => void,
    callback: () => void
}

type SampleUpdate = {
    Barcode: string,
    OrderID: number | null,
    DateCollected: string,
    SpecimenTypeID: number | null,
    CollectorID: number | null,
    Fasting: boolean,
}

const initValues: SampleUpdate = {
    Barcode: "",
    OrderID: null,
    DateCollected: "",
    CollectorID: null,
    SpecimenTypeID: null,
    Fasting: false,
}

const CollectorField = ({ name }: { name: string }) => {
    const { values: { OrderID, CollectorID }, setFieldValue, } = useFormikContext<SampleUpdate>();
    const [allCollectors, setAllCollectors] = useState<Collector[]>([]);

    React.useEffect(() => {
        let isCurrent = true;
        const init = async () => {
            try {
                const order = await apiFetch(`/orders/${OrderID}`);

                getCollectorsByFacility(order?.Facility?.ID)
                    .then((c) => {
                        setAllCollectors(c)

                        // if new list of collecters does not include existing collector on form, we remove it
                        if (!c.find((col: Collector) => col.ID === CollectorID)) {
                            setFieldValue("CollectorID", null)
                        }
                    });
            } catch (e) {
                console.log(e)
                // setError("Failed to load collectors")
            }
        }

        if (!!isCurrent && OrderID) {
            init()
        }

        return () => {
            isCurrent = false;
        };
    }, [OrderID, setFieldValue, name]);

    return (
        <Autocomplete
            selectOnFocus
            disablePortal
            options={allCollectors}
            isOptionEqualToValue={(option, value) =>
                option.ID === value?.ID
            }
            getOptionLabel={
                (option) => {
                    if (!option) {
                        return ""
                    } else {
                        return option.LastName + ", " + option.FirstName
                    }
                }
            }
            onChange={(_, e) => {
                setFieldValue('CollectorID', e?.ID || null)
            }}
            value={allCollectors.find((col) => col.ID === CollectorID) || null}
            renderInput={(params) => (
                <TextField
                    {...params}
                    required
                    InputProps={{
                        ...params.InputProps,
                        type: "search",
                    }}
                    size="small"
                    label="Collector"
                />
            )}
        />
    );
};

function UpdateSampleDialog({ sampleId, open, setOpen, callback }: UpdateSampleDialogProps) {
    const [specimenTypes, setSpecimenTypes] = useState([])
    const [selected, setSelected] = useState([])
    const [initialValues, setInitialValues] = useState(initValues)
    const [error, setError] = useState<string | null>(null)
    const formikRef = useRef<FormikProps<SampleUpdate>>(null)

    useEffect(() => {
        if (!open || !sampleId) {
            return;
        }

        const init = async () => {
            try {
                const response = await apiFetch(`/samples/${sampleId}`);
                setInitialValues(response);

            } catch (e) {
                console.log(e)
                setError("Failed to load sample")
            }
        }

        init()

        getSpecimenTypes()
            .then((p) => {
                if (!p.ok) {
                    throw new Error("Failed to load Specimen Types");
                }
                return p.json();
            })
            .then((p) => {
                setSpecimenTypes(p);
            })
            .catch((e) => setError(e.message));
    }, [open, sampleId]);

    const handleSubmit = async (values: SampleUpdate) => {
        console.log(validationSchema.cast(values))
        try {
            // @ts-ignore
            await apiFetch(`/samples/${sampleId}`, 'POST', validationSchema.cast(values));
            setError(null);
            setOpen(false);
            callback();
        } catch (e) {
            console.log(e)
            setError("Failed to update sample")
            // throw to reject the promise
            throw e;
        }

    }

    const validationSchema = Yup.object().shape({
        Collector: Yup.object().required("Collector is required"),
        DateCollected: Yup.date().required("Date collected is required"),
        OrderID: Yup.number().required("Order ID is required"),
        SpecimenTypeID: Yup.number().required("Specimen Type is required"),
        Barcode: Yup.string().required("Barcode is required"),
        Fasting: Yup.boolean().required("Fasting is required")
    })


    if (!sampleId) {
        return <></>
    }

    return (
        <Dialog open={open}>
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
                enableReinitialize
                innerRef={formikRef}
            >
                {({
                    values,
                    errors,
                    setFieldValue,
                    isSubmitting,
                    isValid
                }) => {
                    console.log('errors', errors); return (
                        <Form>
                            <DialogTitle>Update Sample</DialogTitle>
                            <DialogContent>
                                <DialogContentText>
                                    Please provide updated sample details.
                                </DialogContentText>
                                <Divider sx={{ m: 2 }} />
                                <Stack direction="column" spacing={2}>
                                    <Stack direction="row" spacing={2}>
                                        <CustomTextField name="OrderID"
                                            label="Order ID"
                                            required
                                            InputLabelProps={{ shrink: true }}
                                        />
                                        <CustomTextField name="Barcode"
                                            required
                                        />
                                    </Stack>
                                    <CustomCheckboxField name="Fasting" />

                                    <Stack direction="row" spacing={2}>
                                        <FormControl sx={{ minWidth: 150, width: "45%" }}>
                                            <CollectorField name="CollectorID" />
                                        </FormControl>
                                        <FormControl sx={{ minWidth: 150, width: "45%" }}>
                                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                <DateTimePicker
                                                    disableFuture
                                                    label="Date Collected"
                                                    value={values?.DateCollected}
                                                    onChange={(e) => setFieldValue('DateCollected', e)}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            required
                                                            size="small"
                                                        />
                                                    )}
                                                />
                                            </LocalizationProvider>
                                        </FormControl>
                                    </Stack>
                                    <InputLabel>Specimen Type</InputLabel>
                                    <Box height={350}>
                                        <SpecimenTypeTable
                                            showPriority={false}
                                            specimenTypes={[values?.SpecimenTypeID]}
                                            setSpecimenTypes={(s: any) => {
                                                if (!s) {
                                                    setFieldValue('SpecimenTypeID', null)
                                                } else if (s.length > 0) {
                                                    setFieldValue('SpecimenTypeID', s[0])
                                                }
                                            }}
                                            allSpecimenTypes={specimenTypes}
                                            checkboxs={false}
                                            sx={{}}
                                        />
                                    </Box>


                                </Stack>
                                <ErrorAlert error={error} />
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={() => setOpen(false)}>Cancel</Button>
                                <Button type="submit" disabled={isSubmitting || !isValid} >Save</Button>
                            </DialogActions>
                        </Form>)
                }}</Formik>
        </Dialog>
    )

}

export default UpdateSampleDialog;
