import { useState } from "react";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { Button, Stack, TextField, FormControl, FormControlLabel, Radio, FormLabel, RadioGroup, InputLabel } from "@mui/material";

export default function RejectionDialog({ handleSave, handleClose }) {
    const [rejection, setRejection] = useState({
        Reason: "",
        Recollect: false,
    });
    return (
        <>
            <DialogTitle>Reject Sample</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Please enter the reason for rejection.
                </DialogContentText>
                <Stack spacing={2} direction="column">
                    <TextField
                        multiline
                        maxRows={2}
                        sx={{ minWidth: "300px" }}
                        inputProps={{ maxLength: 255 }}
                        onBlur={(v) => setRejection((prev) => { return { ...prev, Reason: v.target.value } })}
                    />
                    <FormControl>
                        <FormLabel id="demo-row-radio-buttons-group-label">Recollect?</FormLabel>
                        <RadioGroup
                            onChange={(v) => setRejection((prev) => { return { ...prev, Recollect: v.target.value === "true" } })}
                            defaultValue={false}
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="row-radio-buttons-group"
                        >
                            <FormControlLabel value={true} control={<Radio />} label="Yes" />
                            <FormControlLabel value={false} control={<Radio />} label="No" />
                        </RadioGroup>
                    </FormControl>
                    <InputLabel>If you choose to recollect, the pending results will be updated to 'Waiting on Sample' and the order status updated to 'Recollect Required'.</InputLabel>

                </Stack>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>Cancel</Button>
                <Button onClick={() => handleSave(rejection)}>Save</Button>
            </DialogActions>
        </>)
}
