import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import MobileStepper from '@mui/material/MobileStepper';
import Button from '@mui/material/Button';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';

export default function ProgressMobileStepper({ step: activeStep, setStep: setActiveStep, steps, stepValidator = async () => true, disabled },) {
    const theme = useTheme();

    const handleNext = async () => {
        const canMoveToNextStep = await stepValidator(activeStep, activeStep + 1)
        setActiveStep((prevActiveStep) => {
            if (canMoveToNextStep) {
                return prevActiveStep + 1
            };
            return prevActiveStep;
        })
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    return (
        <MobileStepper
            variant="progress"
            steps={steps}
            position="bottom"
            activeStep={activeStep}
            sx={{ position: 'static', flexGrow: 1 }}
            nextButton={
                <Button size="small" onClick={handleNext} disabled={activeStep + 1 === steps || disabled}>
                    {activeStep + 2 === steps ? "Submit" : "Next"}
                    {theme.direction === 'rtl' ? (
                        <KeyboardArrowLeft />
                    ) : (
                        <KeyboardArrowRight />
                    )}
                </Button>
            }
            backButton={
                <Button size="small" onClick={handleBack} disabled={activeStep === 0 || disabled}>
                    {theme.direction === 'rtl' ? (
                        <KeyboardArrowRight />
                    ) : (
                        <KeyboardArrowLeft />
                    )}
                    Back
                </Button>
            }
        />
    );
}
