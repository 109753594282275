import { useEffect } from "react";
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from "@mui/material";
import { useFormikContext } from "formik";
import { useFetch } from "../services/fetch";
import TestFeeInput from "./TestFeeInput";

function TestFeeInputTable({ tests = [], copy = 0, fees = [] }) {
    const { setFieldValue, values } =
        useFormikContext();

    const { data: cmsAllowables = [] } = useFetch("/tests/cmsAllowable");

    useEffect(() => {
        if (!tests?.length || !cmsAllowables?.length) {
            return;
        }

        const allowableDict = cmsAllowables.reduce((acc, item) => {
            acc[item?.HCPCS] = item;
            return acc;
        }, {});

        let feeTestIds = [];

        // first get list of already defined fees
        let existingFees = fees.map((fee) => {
            let { Fee, TestID, CPTCode, Name } = { Fee: fee.Fee, TestID: fee.Test.ID, CPTCode: fee.Test.CPTCode, Name: fee.Test.Name };
            let Allowable;

            if (CPTCode in allowableDict) {
                Allowable = allowableDict[CPTCode]?.Rate;
            } else {
                Allowable = 0;
            }

            Fee = Fee / 100
            feeTestIds.push(TestID);
            return { Fee, TestID, Allowable, CPTCode, Name };
        })

        const filteredTests = tests.filter((t) => !feeTestIds.includes(t.ID))

        // add any missing tests
        let t = filteredTests.map((test) => {
            let newTest = { ...test };
            if (test.CPTCode in allowableDict) {
                newTest.Allowable = allowableDict[test.CPTCode]?.Rate;
            } else {
                newTest.Allowable = 0;
            }
            newTest.Fee = 0;
            return { Fee: newTest.Fee, TestID: newTest.ID, Allowable: newTest.Allowable, CPTCode: test.CPTCode, Name: test.Name };
        });

        console.log(t)
        console.log(existingFees)
        setFieldValue('Fees', [...t, ...existingFees]);
    }, [tests, cmsAllowables, fees, setFieldValue]);

    useEffect(() => {
        if (!values?.Fees.length || copy === 0) {
            return
        }

        values?.Fees.forEach((test, index) => {
            setFieldValue(`Fees.${index}.Fee`,
                ((parseFloat(test.Allowable) * 100) * values?.Multiplier / 100).toFixed(2))
            // setFieldValue(`Fees.${index}.TestID`, test.ID)
        })


    }, [copy, values.Multiplier, values.Fees, setFieldValue])

    return (
        <TableContainer>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>Name</TableCell>
                        <TableCell>CPT Code</TableCell>
                        <TableCell>Allowable</TableCell>
                        <TableCell>Fee</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {values.Fees.map((test, index) => (
                        <TableRow key={index}>
                            <TableCell>{test.Name}</TableCell>
                            <TableCell>{test.CPTCode}</TableCell>
                            <TableCell>$ {test.Allowable}</TableCell>
                            <TableCell>
                                <TestFeeInput test={test} index={index} />
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
}

export default TestFeeInputTable;
