import { Button, Dialog, DialogActions, DialogContent, TextField, Stack, Divider, FormLabel, Typography } from "@mui/material";
import React, { Dispatch, SetStateAction, useContext, useEffect, useRef, useState } from "react";
import { SessionContext } from "../hooks/getContext";
import SignatureCanvas from 'react-signature-canvas';
import Title from "./Title";
import ReactSignatureCanvas from "react-signature-canvas";
import { apiFetch } from "../services/fetch";
import ErrorAlert from "./ErrorAlert";

type SignatureSetupProps = {
    open: boolean,
    setOpen: Dispatch<SetStateAction<boolean>>,
    sig: string | null,
    setSig: Dispatch<SetStateAction<string | null>>
}

function PatientSignatureDialog({ open, setOpen, sig, setSig }: SignatureSetupProps) {
    const session = useContext(SessionContext);
    const [loading, setLoading] = useState(false);
    const [lab, setLab] = useState<any>(null);
    const [error, setError] = useState<null | string>(null)
    const ref = useRef<ReactSignatureCanvas | null>(null)

    useEffect(() => {
        if (!open) {
            return
        }

        const init = async () => {
            try {
                const response = await apiFetch(`/laboratories/1`, 'GET')
                setLab(response)
                setError(null)
            } catch (e) {
                console.log(e)
                setError("Failed to load signature details")
            }
        }

        init()
    }, [open])

    const handleSave = async () => {
        setOpen(false)
    }

    const disclaimer = <Typography variant="body1">{lab?.PatientDisclaimer}</Typography>


    return (
        <>
            <Dialog open={open}>
                <DialogContent>
                    <Stack direction="column" spacing={2}>
                        <Title>Sign order</Title>
                        {disclaimer}
                        <Divider />
                        <SignatureCanvas
                            ref={(r) => ref.current = r}
                            backgroundColor={'rgb(176, 176, 176)'}
                            canvasProps={{ width: 500, height: 200, className: 'sigCanvas' }}
                            onEnd={() => {
                                if (ref.current) {
                                    setSig(ref.current.toDataURL('image/png'))
                                }
                            }}
                        />
                        <Button onClick={() => { ref.current?.clear() }}>Clear</Button>
                        <ErrorAlert error={error} />
                    </Stack>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => { setSig(null); setOpen(false) }}>Cancel</Button>
                    <Button disabled={loading} onClick={handleSave} >Save</Button>
                </DialogActions>
            </Dialog>
        </>)



}

export default PatientSignatureDialog;


