import { useEffect, useState } from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { Autocomplete, InputLabel, Box, TextField, Button } from "@mui/material";
import { CustomCheckboxField } from "./CustomTextField";
import { apiFetch } from "../services/fetch";
import { getProvidersByFacility } from "../services/providers";
import { FieldStack, FormStack } from "./FieldStack";
import DiagnosisQuestionsTable from "./DiagnosisQuestionsTable";
import AOEsTable from "./AOEsTable";
import TestsTable from "./TestsTable";
import ErrorAlert from "./ErrorAlert";

function PatientOrderLinkForm({ id, callback }) {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null)
    const [allFacilities, setAllFacilities] = useState([])
    const [allProviders, setAllProviders] = useState([])
    const [facility, setFacility] = useState(null)
    const [diagnosisQuestions, setDiagnosisQuestions] = useState([])
    const [aoes, setAOES] = useState([])
    const [selectedTests, setSelectedTests] = useState([])
    const [selectedAOEs, setSelectedAOEs] = useState([]);
    const [provider, setProvider] = useState(null)
    const [selectedDiagQuestion, setSelectedDiagQuestion] = useState([])

    const [initialValues, setInitialValues] = useState({
        ProviderID: "",
        FacilityID: "",
        AOEs: [],
        TestCodes: [],
        Enabled: true,
    })

    useEffect(() => {
        if (facility) {
            getProvidersByFacility(facility.ID).then((p) => {
                setAllProviders(p);
                if (p.length === 1) {
                    setProvider(p[0])
                }
            });
        }

    }, [facility]);

    useEffect(() => {
        const getFacilities = async () => {
            try {
                const facilities = await apiFetch(`/facilities`, "GET")
                setAllFacilities(facilities)

            } catch (e) {
                setError("failed to load facilities")
            }
        }

        const getDiagnosisQuestions = async () => {
            try {
                const dQuestions = await apiFetch(`/orders/diagnosis/questions`, "GET")
                setDiagnosisQuestions(dQuestions)

            } catch (e) {
                setError("failed to load diagnosis questions")
            }

        }

        const getAOEs = async () => {
            try {
                const aoes = await apiFetch(`/orders/aoes`, "GET")
                setAOES(aoes)

            } catch (e) {
                setError("failed to load aoes")
            }

        }

        getAOEs()
        getFacilities()
        getDiagnosisQuestions()
    }, [])


    useEffect(() => {
        if (!id) {
            return
        }

        const getLink = async () => {
            try {
                const facilities = await apiFetch(`/facilities`, "GET")
                setAllFacilities(facilities)

                const response = await apiFetch(`/facilities/patientOrder/${id}`, "GET")
                setSelectedTests(response.TestCodes.map((t) => (t.ID)))
                setSelectedAOEs(response.AOEs.map((a) => (a.ID)))
                setSelectedDiagQuestion(response.DiagnosisQuestions.map((a) => (a.ID)))
                let newObj = {
                    ...response,
                    TestIds: response.TestCodes.map((t) => (t.ID)),
                    AOEs: response.AOEs.map((a) => (a.ID)),
                    DiagnosisQuestions: response.DiagnosisQuestions.map((a) => (a.ID)),
                }
                setInitialValues(newObj)

            } catch (e) {
                setError("failed to load patient order link")
            }
        }

        getLink()
    }, [id])

    const validationSchema = Yup.object().shape({
        Enabled: Yup.boolean(),
    });

    const handleSubmit = (values) => {
        console.log(values)
        const data = {
            FacilityID: values?.FacilityID,
            ProviderID: values?.ProviderID,
            TestIds: selectedTests,
            AOEs: selectedAOEs,
            DiagnosisQuestions: selectedDiagQuestion,
        }

        if (selectedAOEs.length < 1 && selectedDiagQuestion.length < 1) {
            setError("At least one AOE or Diagnosis Question must be selected.")
            return
        }

        if (selectedTests.length < 1) {
            setError("At least one test must be selected.")
            return
        }

        const saveOrderLink = async () => {
            try {
                setLoading(true)
                if (id) {
                    await apiFetch(`/facilities/patientOrder/${id}`, "POST", data)
                } else {
                    await apiFetch("/facilities/patientOrder", "POST", data)
                }
                callback()
            } catch (e) {
                setError("Failed to save patient order link.")
            } finally {
                setLoading(false)
            }

        }

        saveOrderLink()
    }


    return (
        <>
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
                enableReinitialize
            >
                {({
                    values,
                    handleChange,
                    errors,
                    handleBlur,
                    touched,
                    setFieldValue,
                }) => (
                    <Form>
                        {console.log(values)}
                        <FormStack>
                            <FieldStack>
                                <Autocomplete
                                    required
                                    options={allFacilities}
                                    getOptionLabel={(row) => row.Name}
                                    value={values?.Facility || null}
                                    isOptionEqualToValue={(option, value) =>
                                        option.id === value.id
                                    }
                                    sx={{ minWidth: "200px" }}
                                    onChange={(_, newValue) => { setFieldValue("Facility", newValue); setFieldValue("FacilityID", newValue.ID); setFacility(newValue) }}
                                    renderInput={(params) => {
                                        return (
                                            <TextField
                                                required
                                                {...params}
                                                size="small"
                                                error={
                                                    touched?.Facility &&
                                                    Boolean(errors?.Facility)
                                                }
                                                helperText={
                                                    touched?.Facility &&
                                                    errors?.Facility
                                                }
                                                label="Facility"
                                            />
                                        );
                                    }}
                                />
                                <Autocomplete
                                    required
                                    disabled={errors?.Facility}
                                    value={values?.Provider || null}
                                    options={allProviders}
                                    isOptionEqualToValue={(option, value) =>
                                        option.id === value.id
                                    }
                                    getOptionLabel={(row) => row.FirstName + " " + row.LastName}
                                    onChange={(_, newValue) => { setFieldValue('Provider', newValue); setFieldValue('ProviderID', newValue.ID) }}
                                    sx={{ minWidth: "200px" }}
                                    renderInput={(params) => {
                                        return (
                                            <TextField
                                                required
                                                {...params}
                                                error={
                                                    touched?.Provider &&
                                                    Boolean(errors?.Provider)
                                                }
                                                helperText={
                                                    touched?.Provider &&
                                                    errors?.Provider
                                                }
                                                size="small"
                                                label="Provider"
                                            />
                                        );
                                    }}
                                />
                                <CustomCheckboxField name="Enabled" />
                            </FieldStack>
                            <InputLabel>Diagnosis Questions</InputLabel>
                            <Box height="300px">
                                <DiagnosisQuestionsTable diagnosisQuestions={diagnosisQuestions}
                                    selected={selectedDiagQuestion}
                                    setSelected={setSelectedDiagQuestion}
                                />
                            </Box>

                            <InputLabel>AOEs</InputLabel>
                            <Box height="300px">
                                <AOEsTable aoes={aoes}
                                    selected={selectedAOEs}
                                    setSelected={setSelectedAOEs}
                                />
                            </Box>
                            <InputLabel>Tests</InputLabel>
                            <Box height="300px">
                                <TestsTable
                                    setTest={setSelectedTests}
                                    test={selectedTests}
                                    checkboxes={true}

                                />
                            </Box>
                            <ErrorAlert error={error} />
                            <FieldStack>
                                <Button
                                    disabled={loading}
                                    variant="contained"
                                    type="submit"

                                >Save</Button>
                            </FieldStack>

                        </FormStack>
                    </Form>
                )}
            </Formik>

        </>
    )


}

export default PatientOrderLinkForm;
