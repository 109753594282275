import React from "react";
import { Stack, Box, Chip, Divider, Typography } from "@mui/material";

function SpecimenRequiredVerification({ sampleDetails, allTests }: { sampleDetails: any, allTests: any }) {
    console.log(sampleDetails)
    console.log(allTests)
    // provide feedback to user so they know when enough specimenTypes
    // have been selected
    const coveredTests = allTests.filter((t: any) => {
        return t.SpecimenTypes.some((s: any) => {
            return sampleDetails?.some(
                (samp: any) => samp.Reference === t.Referenced && samp.SpecimenTypeID === s.ID
            );
        });
    });
    const awaitingTests = allTests.filter((t: any) => {
        return !t.SpecimenTypes.some((s: any) => {
            return sampleDetails?.some(
                (samp: any) => samp.Reference === t.Referenced && samp.SpecimenTypeID === s.ID
            );
        });
    });

    return (
        <Stack direction={{ sm: "column", md: "row" }} spacing={2} divider={<Divider orientation="vertical" variant="middle" flexItem />} justifyContent="space-evenly">
            <Stack direction="column" spacing={2} maxWidth={{ md: "50%", sm: "100%" }}>
                <Typography variant="h6">
                    Tests Awaiting Specimen Selection
                </Typography>
                <Stack direction="row" spacing={1} flexWrap="wrap" alignItems="center">
                    {awaitingTests.map((t: any) => (
                        <Box m="1">
                            <Chip color="warning" label={t.Name} />
                        </Box>
                    ))}
                    {awaitingTests.length === 0 ? <p>All tests covered!</p> : null}
                </Stack>
            </Stack>

            <Stack direction="column" spacing={2} maxWidth={{ md: "50%", sm: "100%" }}>
                <Typography variant="h6">
                    Tests Covered By Selected Specimen(s)
                </Typography>
                <Stack direction="row" spacing={1} flexWrap="wrap" alignItems="center">
                    {coveredTests.map((t: any) => (
                        <Box sx={{ m: 1 }}>
                            <Chip color="success" label={t.Name} />
                        </Box>
                    ))}
                    {coveredTests.length === 0 ? (
                        <p>No specimens selected.</p>
                    ) : null}
                </Stack>
            </Stack>
        </Stack>
    );
}
export default SpecimenRequiredVerification;
