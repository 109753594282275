export async function getPCRPlates() {
    return fetch(`${process.env.REACT_APP_API_PROXY}/v1/molecular/pcrPlate`, { credentials: "include" })
        .then((data) => data.json())
}
export async function addPCRPlate(pcrPlate) {
    return fetch(`${process.env.REACT_APP_API_PROXY}/v1/molecular/pcrPlate`, {
        method: "POST",
        credentials: "include",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
        },
        body: JSON.stringify(pcrPlate),
    }).then((response) => response.json());
}

export async function updatePCRPlate(pcrPlate, pcrPlateID) {
    return fetch(`${process.env.REACT_APP_API_PROXY}/v1/molecular/pcrPlate/` + pcrPlateID, {
        method: "POST",
        credentials: "include",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
        },
        body: JSON.stringify(pcrPlate),
    }).then((response) => response.json());
}

export async function getExtPlates() {
    return fetch(`${process.env.REACT_APP_API_PROXY}/v1/molecular/extPlate`, { credentials: "include", headers: { "Accept": "text/csv" } })
        .then((data) => data.json())
}

export async function addExtPlate(extPlate) {
    return fetch(`${process.env.REACT_APP_API_PROXY}/v1/molecular/extPlate`, {
        method: "POST",
        credentials: "include",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
        },
        body: JSON.stringify(extPlate),
    }).then((response) => response.json());
}

export async function updateExtPlate(extPlate, extPlateID) {
    return fetch(`${process.env.REACT_APP_API_PROXY}/v1/molecular/extPlate/` + extPlateID, {
        method: "POST",
        credentials: "include",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
        },
        body: JSON.stringify(extPlate),
    }).then((response) => response.json());
}

export const pcrPlateWells = [
    "A1",
    "B1",
]
export const extPlateWellsByColumn = [
    "A1",
    "B1",
    "C1",
    "D1",
    "E1",
    "F1",
    "G1",
    "H1",
    "A2",
    "B2",
    "C2",
    "D2",
    "E2",
    "F2",
    "G2",
    "H2",
    "A3",
    "B3",
    "C3",
    "D3",
    "E3",
    "F3",
    "G3",
    "H3",
    "A4",
    "B4",
    "C4",
    "D4",
    "E4",
    "F4",
    "G4",
    "H4",
    "A5",
    "B5",
    "C5",
    "D5",
    "E5",
    "F5",
    "G5",
    "H5",
    "A6",
    "B6",
    "C6",
    "D6",
    "E6",
    "F6",
    "G6",
    "H6",
    "A7",
    "B7",
    "C7",
    "D7",
    "E7",
    "F7",
    "G7",
    "H7",
    "A8",
    "B8",
    "C8",
    "D8",
    "E8",
    "F8",
    "G8",
    "H8",
    "A9",
    "B9",
    "C9",
    "D9",
    "E9",
    "F9",
    "G9",
    "H9",
    "A10",
    "B10",
    "C10",
    "D10",
    "E10",
    "F10",
    "G10",
    "H10",
    "A11",
    "B11",
    "C11",
    "D11",
    "E11",
    "F11",
    "G11",
    "H11",
    "A12",
    "B12",
    "C12",
    "D12",
    "E12",
    "F12",
    "G12",
    "H12",
];

export const extPlateWellsByRow = [
    "A1",
    "A2",
    "A3",
    "A4",
    "A5",
    "A6",
    "A7",
    "A8",
    "A9",
    "A10",
    "A11",
    "A12",
    "B1",
    "B2",
    "B3",
    "B4",
    "B5",
    "B6",
    "B7",
    "B8",
    "B9",
    "B10",
    "B11",
    "B12",
    "C1",
    "C2",
    "C3",
    "C4",
    "C5",
    "C6",
    "C7",
    "C8",
    "C9",
    "C10",
    "C11",
    "C12",
    "D1",
    "D2",
    "D3",
    "D4",
    "D5",
    "D6",
    "D7",
    "D8",
    "D9",
    "D10",
    "D11",
    "D12",
    "E1",
    "E2",
    "E3",
    "E4",
    "E5",
    "E6",
    "E7",
    "E8",
    "E9",
    "E10",
    "E11",
    "E12",
    "F1",
    "F2",
    "F3",
    "F4",
    "F5",
    "F6",
    "F7",
    "F8",
    "F9",
    "F10",
    "F11",
    "F12",
    "G1",
    "G2",
    "G3",
    "G4",
    "G5",
    "G6",
    "G7",
    "G8",
    "G9",
    "G10",
    "G11",
    "G12",
    "H1",
    "H2",
    "H3",
    "H4",
    "H5",
    "H6",
    "H7",
    "H8",
    "H9",
    "H10",
    "H11",
    "H12",
];

export const _384byRow = [
    "A1",
    "A2",
    "A3",
    "A4",
    "A5",
    "A6",
    "A7",
    "A8",
    "A9",
    "A10",
    "A11",
    "A12",
    "A13",
    "A14",
    "A15",
    "A16",
    "A17",
    "A18",
    "A19",
    "A20",
    "A21",
    "A22",
    "A23",
    "A24",
    "B1",
    "B2",
    "B3",
    "B4",
    "B5",
    "B6",
    "B7",
    "B8",
    "B9",
    "B10",
    "B11",
    "B12",
    "B13",
    "B14",
    "B15",
    "B16",
    "B17",
    "B18",
    "B19",
    "B20",
    "B21",
    "B22",
    "B23",
    "B24",
    "C1",
    "C2",
    "C3",
    "C4",
    "C5",
    "C6",
    "C7",
    "C8",
    "C9",
    "C10",
    "C11",
    "C12",
    "C13",
    "C14",
    "C15",
    "C16",
    "C17",
    "C18",
    "C19",
    "C20",
    "C21",
    "C22",
    "C23",
    "C24",
    "E1",
    "E2",
    "E3",
    "E4",
    "E5",
    "E6",
    "E7",
    "E8",
    "E9",
    "E10",
    "E11",
    "E12",
    "E13",
    "E14",
    "E15",
    "E16",
    "E17",
    "E18",
    "E19",
    "E20",
    "E21",
    "E22",
    "E23",
    "E24",
    "F1",
    "F2",
    "F3",
    "F4",
    "F5",
    "F6",
    "F7",
    "F8",
    "F9",
    "F10",
    "F11",
    "F12",
    "F13",
    "F14",
    "F15",
    "F16",
    "F17",
    "F18",
    "F19",
    "F20",
    "F21",
    "F22",
    "F23",
    "F24",
    "G1",
    "G2",
    "G3",
    "G4",
    "G5",
    "G6",
    "G7",
    "G8",
    "G9",
    "G10",
    "G11",
    "G12",
    "G13",
    "G14",
    "G15",
    "G16",
    "G17",
    "G18",
    "G19",
    "G20",
    "G21",
    "G22",
    "G23",
    "G24",
    "H1",
    "H2",
    "H3",
    "H4",
    "H5",
    "H6",
    "H7",
    "H8",
    "H9",
    "H10",
    "H11",
    "H12",
    "H13",
    "H14",
    "H15",
    "H16",
    "H17",
    "H18",
    "H19",
    "H20",
    "H21",
    "H22",
    "H23",
    "H24",
    "I1",
    "I2",
    "I3",
    "I4",
    "I5",
    "I6",
    "I7",
    "I8",
    "I9",
    "I10",
    "I11",
    "I12",
    "I13",
    "I14",
    "I15",
    "I16",
    "I17",
    "I18",
    "I19",
    "I20",
    "I21",
    "I22",
    "I23",
    "I24",
    "J1",
    "J2",
    "J3",
    "J4",
    "J5",
    "J6",
    "J7",
    "J8",
    "J9",
    "J10",
    "J11",
    "J12",
    "J13",
    "J14",
    "J15",
    "J16",
    "J17",
    "J18",
    "J19",
    "J20",
    "J21",
    "J22",
    "J23",
    "J24",
    "K1",
    "K2",
    "K3",
    "K4",
    "K5",
    "K6",
    "K7",
    "K8",
    "K9",
    "K10",
    "K11",
    "K12",
    "K13",
    "K14",
    "K15",
    "K16",
    "K17",
    "K18",
    "K19",
    "K20",
    "K21",
    "K22",
    "K23",
    "K24",
    "L1",
    "L2",
    "L3",
    "L4",
    "L5",
    "L6",
    "L7",
    "L8",
    "L9",
    "L10",
    "L11",
    "L12",
    "L13",
    "L14",
    "L15",
    "L16",
    "L17",
    "L18",
    "L19",
    "L20",
    "L21",
    "L22",
    "L23",
    "L24",
    "M1",
    "M2",
    "M3",
    "M4",
    "M5",
    "M6",
    "M7",
    "M8",
    "M9",
    "M10",
    "M11",
    "M12",
    "M13",
    "M14",
    "M15",
    "M16",
    "M17",
    "M18",
    "M19",
    "M20",
    "M21",
    "M22",
    "M23",
    "M24",
    "N1",
    "N2",
    "N3",
    "N4",
    "N5",
    "N6",
    "N7",
    "N8",
    "N9",
    "N10",
    "N11",
    "N12",
    "N13",
    "N14",
    "N15",
    "N16",
    "N17",
    "N18",
    "N19",
    "N20",
    "N21",
    "N22",
    "N23",
    "N24",
    "O1",
    "O2",
    "O3",
    "O4",
    "O5",
    "O6",
    "O7",
    "O8",
    "O9",
    "O10",
    "O11",
    "O12",
    "O13",
    "O14",
    "O15",
    "O16",
    "O17",
    "O18",
    "O19",
    "O20",
    "O21",
    "O22",
    "O23",
    "O24",
    "P1",
    "P2",
    "P3",
    "P4",
    "P5",
    "P6",
    "P7",
    "P8",
    "P9",
    "P10",
    "P11",
    "P12",
    "P13",
    "P14",
    "P15",
    "P16",
    "P17",
    "P18",
    "P19",
    "P20",
    "P21",
    "P22",
    "P23",
    "P24"]

export const _384byColumn = [
    "A1",
    "B1",
    "C1",
    "E1",
    "F1",
    "G1",
    "H1",
    "I1",
    "J1",
    "K1",
    "L1",
    "M1",
    "N1",
    "O1",
    "P1",
    "A2",
    "B2",
    "C2",
    "E2",
    "F2",
    "G2",
    "H2",
    "I2",
    "J2",
    "K2",
    "L2",
    "M2",
    "N2",
    "O2",
    "P2",
    "A3",
    "B3",
    "C3",
    "E3",
    "F3",
    "G3",
    "H3",
    "I3",
    "J3",
    "K3",
    "L3",
    "M3",
    "N3",
    "O3",
    "P3",
    "A4",
    "B4",
    "C4",
    "E4",
    "F4",
    "G4",
    "H4",
    "I4",
    "J4",
    "K4",
    "L4",
    "M4",
    "N4",
    "O4",
    "P4",
    "A5",
    "B5",
    "C5",
    "E5",
    "F5",
    "G5",
    "H5",
    "I5",
    "J5",
    "K5",
    "L5",
    "M5",
    "N5",
    "O5",
    "P5",
    "A6",
    "B6",
    "C6",
    "E6",
    "F6",
    "G6",
    "H6",
    "I6",
    "J6",
    "K6",
    "L6",
    "M6",
    "N6",
    "O6",
    "P6",
    "A7",
    "B7",
    "C7",
    "E7",
    "F7",
    "G7",
    "H7",
    "I7",
    "J7",
    "K7",
    "L7",
    "M7",
    "N7",
    "O7",
    "P7",
    "A8",
    "B8",
    "C8",
    "E8",
    "F8",
    "G8",
    "H8",
    "I8",
    "J8",
    "K8",
    "L8",
    "M8",
    "N8",
    "O8",
    "P8",
    "A9",
    "B9",
    "C9",
    "E9",
    "F9",
    "G9",
    "H9",
    "I9",
    "J9",
    "K9",
    "L9",
    "M9",
    "N9",
    "O9",
    "P9",
    "A10",
    "B10",
    "C10",
    "E10",
    "F10",
    "G10",
    "H10",
    "I10",
    "J10",
    "K10",
    "L10",
    "M10",
    "N10",
    "O10",
    "P10",
    "A11",
    "B11",
    "C11",
    "E11",
    "F11",
    "G11",
    "H11",
    "I11",
    "J11",
    "K11",
    "L11",
    "M11",
    "N11",
    "O11",
    "P11",
    "A12",
    "B12",
    "C12",
    "E12",
    "F12",
    "G12",
    "H12",
    "I12",
    "J12",
    "K12",
    "L12",
    "M12",
    "N12",
    "O12",
    "P12",
    "A13",
    "B13",
    "C13",
    "E13",
    "F13",
    "G13",
    "H13",
    "I13",
    "J13",
    "K13",
    "L13",
    "M13",
    "N13",
    "O13",
    "P13",
    "A14",
    "B14",
    "C14",
    "E14",
    "F14",
    "G14",
    "H14",
    "I14",
    "J14",
    "K14",
    "L14",
    "M14",
    "N14",
    "O14",
    "P14",
    "A15",
    "B15",
    "C15",
    "E15",
    "F15",
    "G15",
    "H15",
    "I15",
    "J15",
    "K15",
    "L15",
    "M15",
    "N15",
    "O15",
    "P15",
    "A16",
    "B16",
    "C16",
    "E16",
    "F16",
    "G16",
    "H16",
    "I16",
    "J16",
    "K16",
    "L16",
    "M16",
    "N16",
    "O16",
    "P16",
    "A17",
    "B17",
    "C17",
    "E17",
    "F17",
    "G17",
    "H17",
    "I17",
    "J17",
    "K17",
    "L17",
    "M17",
    "N17",
    "O17",
    "P17",
    "A18",
    "B18",
    "C18",
    "E18",
    "F18",
    "G18",
    "H18",
    "I18",
    "J18",
    "K18",
    "L18",
    "M18",
    "N18",
    "O18",
    "P18",
    "A19",
    "B19",
    "C19",
    "E19",
    "F19",
    "G19",
    "H19",
    "I19",
    "J19",
    "K19",
    "L19",
    "M19",
    "N19",
    "O19",
    "P19",
    "A20",
    "B20",
    "C20",
    "E20",
    "F20",
    "G20",
    "H20",
    "I20",
    "J20",
    "K20",
    "L20",
    "M20",
    "N20",
    "O20",
    "P20",
    "A21",
    "B21",
    "C21",
    "E21",
    "F21",
    "G21",
    "H21",
    "I21",
    "J21",
    "K21",
    "L21",
    "M21",
    "N21",
    "O21",
    "P21",
    "A22",
    "B22",
    "C22",
    "E22",
    "F22",
    "G22",
    "H22",
    "I22",
    "J22",
    "K22",
    "L22",
    "M22",
    "N22",
    "O22",
    "P22",
    "A23",
    "B23",
    "C23",
    "E23",
    "F23",
    "G23",
    "H23",
    "I23",
    "J23",
    "K23",
    "L23",
    "M23",
    "N23",
    "O23",
    "P23",
    "A24",
    "B24",
    "C24",
    "E24",
    "F24",
    "G24",
    "H24",
    "I24",
    "J24",
    "K24",
    "L24",
    "M24",
    "N24",
    "O24",
    "P24"]

