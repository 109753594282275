import React from "react";
import { useFormikContext } from "formik";
import { TextField, FormControlLabel, Checkbox, RadioGroup, FormLabel, Radio } from "@mui/material";

interface CustomTextFieldProps<T> {
    name: keyof T;
    label?: string;
    required?: boolean;
    [key: string]: any; // For additional props
}

const CustomTextField = <T extends {}>({ name, label, required, ...props }: CustomTextFieldProps<T>) => {
    const { handleBlur, setFieldValue, errors, touched, values } = useFormikContext<T>();

    return (
        <TextField
            required={required}
            name={String(name)}
            label={label || String(name)}
            helperText={touched?.[name] && String(errors?.[name] || "")}
            error={touched?.[name] && Boolean(errors?.[name])}
            size="small"
            onBlur={handleBlur}
            onChange={(e) => setFieldValue(String(name), e.target.value)}
            value={values?.[name]}
            {...props}
        />
    );
};

interface CustomCheckboxProps<T> {
    name: keyof T;
    label?: string;
    [key: string]: any; // For additional props
}


const CustomCheckboxField = <T extends {}>({ name, label, ...props }: CustomCheckboxProps<T>) => {
    const { handleChange, values } = useFormikContext<T>();
    return (
        <FormControlLabel
            control={
                <Checkbox
                    name={String(name)}
                    checked={Boolean(values?.[name])}
                    onChange={handleChange}
                    {...props}
                />
            }
            label={label || String(name)}
        />
    );
};

interface CustomRadioProps<T> {
    name: keyof T;
    label?: string;
    required?: boolean;
    options: any;
    [key: string]: any; // For additional props
}

interface RadioGroupOption {
    label: string;
    value: any;
}

const CustomRadioGroup = <T extends {}>({ name, label, required, options, ...props }: CustomRadioProps<T>) => {
    const { setFieldValue, values } = useFormikContext<T>();

    return (
        <>
            <FormLabel>{label}</FormLabel>
            <RadioGroup
                row={true}
                name={String(name)}
                value={values?.[name] || false}
                onChange={(_, value) => setFieldValue(String(name), value === "true")}
                {...props}
            >
                {options.map((option: RadioGroupOption, index: number) => (
                    <FormControlLabel
                        key={index}
                        value={option?.value}
                        control={<Radio

                        />}
                        label={option?.label}
                    />
                ))}
            </RadioGroup>
        </>
    );
};

export { CustomTextField, CustomCheckboxField, CustomRadioGroup };
