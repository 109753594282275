export function getProfileDetails(profileID) {
    if (profileID === undefined) {
        return;
    }
    return fetch(
        `${process.env.REACT_APP_API_PROXY}/v1/profiles/` + profileID,
        { method: "GET", credentials: "include" }
    );
}

export async function getProfiles() {
    return fetch(`${process.env.REACT_APP_API_PROXY}/v1/profiles`, {
        credentials: "include",
    }).then((data) => data.json());
}

export async function addProfile(profile) {
    return fetch(`${process.env.REACT_APP_API_PROXY}/v1/profiles`, {
        method: "POST",
        credentials: "include",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
        },
        body: JSON.stringify(profile),
    }).then((response) => response.json());
}

export async function updateProfile(profile, profileID) {
    return fetch(
        `${process.env.REACT_APP_API_PROXY}/v1/profiles/` + profileID,
        {
            method: "POST",
            credentials: "include",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify(profile),
        }
    ).then((response) => response.json());
}
