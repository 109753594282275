import { Stack } from "@mui/system";


function TableFrame({ children }) {
    return (
        <Stack direction="column" spacing={2}>
            {children}
        </Stack >
    )
}

export default TableFrame;
