import Title from "./Title";
import React, { useEffect, useState } from "react";
import { DataGridPro } from "@mui/x-data-grid-pro";
import { GridToolbar } from "@mui/x-data-grid-pro";
import NotificationImportantIcon from "@mui/icons-material/NotificationImportant";

String.prototype.toProperCase = function() {
    return this.replace(/\w\S*/g, function(txt) { return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase(); });
};

export default function ContactsTable(props) {
    const [contacts, setContacts] = useState([]);
    const [selectedContact, setSelectedContact] = useState([]);

    useEffect(() => {
        props.getContacts().then((p) => {
            setContacts(p);
        });
    }, [props.refresh]);

    const getCheckbox = (value) => {
        if (value.value) {
            return <NotificationImportantIcon color="error" />;
        }
        return <NotificationImportantIcon color="disabled" />;
    };

    const toTitle = (str) => {
        return str.value.toProperCase();
    }


    const columns = [
        { field: "ID", headerName: "ID", width: 45 },
        {
            field: "Critical",
            headerName: "Critical Contact",
            width: 110,
            renderCell: getCheckbox,
            align: "center"
        },
        { field: "FirstName", headerName: "First Name", width: 150 },
        { field: "LastName", headerName: "Last Name", width: 150 },
        { field: "Phone1", headerName: "Phone 1", width: 125 },
        { field: "Phone2", headerName: "Phone 2", width: 125 },
        { field: "Fax", headerName: "Fax", width: 125 },
        { field: "Email", headerName: "Email", width: 125 },
        { field: "Type", headerName: "Type", width: 125, valueGetter: toTitle },
    ];

    return (
        <React.Fragment>
            <Title>{props.Title}</Title>
            <DataGridPro
                hideFooterRowCount
                getRowId={(row) => row.ID}
                density="compact"
                sx={{
                    "& .MuiDataGrid-columnHeaderCheckbox .MuiDataGrid-columnHeaderTitleContainer":
                    {
                        display: "none",
                    },
                }}
                components={{ Toolbar: GridToolbar }}
                componentsProps={{
                    toolbar: {
                        showQuickFilter: true,
                        quickFilterProps: { debounceMs: 500 },
                    },
                }}
                rowSelectionModel={selectedContact}
                onRowSelectionModelChange={(newSelection) => {
                    for (const f of contacts) {
                        if (f.ID === newSelection[0]) {
                            setSelectedContact(newSelection);
                            props.setSelectedContact(f);
                        }
                    }
                }}
                rows={contacts}
                columns={columns}
            />
        </React.Fragment>
    );
}
