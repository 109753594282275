import React from "react";
import { Stack } from "@mui/system";
import Chip from "@mui/material/Chip";
import { Order } from "../constants/types";
import { VerifierIndex } from "../constants/constants";

const ChipStack = ({ children }: { children: React.ReactNode }) => {
    return (
        <Stack direction="row" spacing={1}>
            {children}
        </Stack>
    );
};

const GetOrderSTATChip = ({ size }: { size: "small" | "medium" | undefined }) => (
    <Chip
        size={size}
        color="error"
        label="STAT"
    />
);

// colorTypes.ts
export type ChipColor = 'default' | 'primary' | 'secondary' | 'error' | 'info' | 'success' | 'warning';


const GetOrderStatusChip = ({ status }: { status: string }) => {
    let color: ChipColor = "info";
    switch (status) {
        case "Submitted":
            color = "info";
            break;
        case "Sample(s) Collected":
            color = "secondary";
            break;
        case "Sample(s) Received":
            color = "primary";
            break;
        case "Recollect Required":
            color = "warning";
            break;
        case "Completed":
            color = "success";
            break;
        case "Approved":
            color = "success";
            break;
        case "Rejected":
            color = "error";
            break;
        default:
            color = "info";
    }
    return (
        <Chip
            size="small"
            sx={{ color: "white" }}
            color={color}
            label={status}
        />
    );
};

const GetOrderApprovalChip = ({ approved }: { approved: boolean }) => {
    let status = "Pending Review";
    let color: ChipColor = "info";
    switch (approved) {
        case true:
            status = "Approved"
            color = "success"
            break
        case false:
            status = "Rejected"
            color = "error"
            break
        default:
            status = "Pending Review"
    }

    return (
        <Chip
            size="small"
            sx={{ color: "white" }}
            color={color}
            label={status}
        />
    );

}

const GetCoriellStatusChip = (status: string) => {
    let color: ChipColor = "info";
    switch (status) {
        case "Error":
            color = "error";
            break;
        case "Demographics Sent":
            color = "secondary";
            break;
        case "Report Received":
            color = "success";
            break;
        default:
            return <></>
    }
    return (
        <Chip
            size="small"
            sx={{ color: "white" }}
            color={color}
            label={status}
        />
    );

}

const GetOrderStatusChips = ({ order }: { order: Order }) => {
    return (
        <ChipStack>
            {order?.Stat && <GetOrderSTATChip size="small" />}
            <GetOrderStatusChip status={order?.Status} />
        </ChipStack>
    );
};

const GetOrderVerificationChips = ({ verifiers }: { verifiers: string }) => {
    if (verifiers === "" || verifiers === undefined) return <Chip size="small" sx={{ color: "white" }} label="Verified" color="success" />;

    return verifiers.split(',').map((v: string) => <Chip
        size="small"
        sx={{ color: "white", mr: 1 }}
        color="warning"
        // @ts-ignore
        label={VerifierIndex[v]}
    />)

}

const GetShipmentStatusChip = (status: string) => {
    let color: ChipColor = "info";
    switch (status) {
        case "In Transit":
            color = "secondary";
            break;
        case "Partially Received":
            color = "primary";
            break;
        case "Received":
            color = "success";
            break;
        default:
            color = "info";
    }
    return (
        <Chip
            size="small"
            sx={{ color: "white" }}
            color={color}
            label={status}
        />
    );
};

const GetSampleStatusChip = (status: string) => {
    let color: ChipColor = "info";
    switch (status) {
        case "In Transit":
            color = "secondary";
            break;
        case "Received":
            color = "success";
            break;
        case "Transferred":
            color = "warning";
            break;
        case "Rejected":
            color = "error";
            break;
        default:
            color = "info";
    }
    return (
        <Chip
            size="small"
            sx={{ color: "white" }}
            color={color}
            label={status}
        />
    );
};

const GetCriticalStatusChip = (status: string) => {
    let color: ChipColor = "info";
    switch (status) {
        case "New":
            color = "error";
            break;
        case "Updated":
            color = "primary";
            break;
        case "Completed":
            color = "success";
            break;
        default:
            color = "info";
    }
    return (
        <Chip
            size="small"
            sx={{ color: "white" }}
            color={color}
            label={status}
        />
    );
};

const GetResultStatusChip = React.memo(({ status }: { status: string }) => {
    let color: ChipColor = "info";
    switch (status) {
        case "Pending":
            color = "secondary";
            break;
        case "Updated":
            color = "primary";
            break;
        case "Validated":
            color = "success";
            break;
        case "Waiting on Sample":
            color = "info";
            break;
        case "Rejected":
            color = "error";
            break;
        case "Test not performed":
            color = "error";
            break;
        default:
            color = "info";
    }
    return (
        <Chip
            size="small"
            sx={{ color: "white" }}
            color={color}
            label={status}
        />
    );
});


const GetBillingStatusChip = (status: string) => {
    let color: ChipColor = "info";
    switch (status) {
        case "Ready To Bill":
            color = "secondary";
            break;
        case "Not Ready To Bill":
            color = "warning";
            break;
        case "No Bill":
            color = "error";
            break;
        case "Billed":
            color = "success";
            break;
        default:
            color = "info";
    }
    return (
        <Chip
            size="small"
            sx={{ color: "white" }}
            color={color}
            label={status}
        />
    );
};

const GetEligibilityStatusChip = (status: string) => {
    let color: ChipColor = "info";
    switch (status) {
        case "Not Checked":
            color = "info";
            break;
        case "Check Failed":
            color = "warning";
            break;
        case "Active":
            color = "success";
            break;
        case "Active - Pending Investigation":
            color = "warning";
            break;
        case "Inactive":
            color = "error";
            break;
    }
    return (
        <Chip
            size="small"
            sx={{ color: "white" }}
            color={color}
            label={status}
        />
    );
};

export {
    GetOrderStatusChips,
    GetOrderStatusChip,
    GetOrderApprovalChip,
    GetOrderSTATChip,
    GetShipmentStatusChip,
    GetSampleStatusChip,
    GetCriticalStatusChip,
    GetResultStatusChip,
    GetCoriellStatusChip,
    GetBillingStatusChip,
    GetEligibilityStatusChip,
    GetOrderVerificationChips,
    ChipStack,
};
