import React, { useState, useEffect } from "react";
import {
    Box,
    FormControl,
    Grid,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableRow,
    TextField,
    Typography
} from "@mui/material";
import { useFormikContext } from 'formik';
import OrderPaymentSelection from "./OrderPaymentSelection";
import {
    Answer,
    AOE,
    InsurancePay,
    Test,
    DiagnosisQuestion,
    DiagnosisCode,
} from "../../constants/types";
import { DiagnosisValidityTable } from "../DiagnosisValidityTable";
import { apiFetch } from "../../services/fetch";
import DiagnosisQuestions from "./OrderDiagnosisQuestions";
import DiagnosisCodeSearch from "../DiagnosisCodeSelect";
import { WarningAlert } from "../ErrorAlert";
import { OrderValues } from "./OrderForm";
import { validateDiagnosis } from "../../services/orders";
import OrderAOEs from "./OrderAOEs";

const includesDiagnosisCode = (arr: DiagnosisCode[], item: DiagnosisCode) => {
    for (let a of arr) {
        if (a?.Code === item?.Code) {
            return true
        }
    }
    return false
}


function OrderPaymentMethodStep() {
    const { values, setFieldValue } = useFormikContext<OrderValues>();
    const setTestDetails = (v: any) => setFieldValue('TestDetails', v)
    const [diagnosisValidity, setDiagnosisValidity] = useState<any>([])
    const [diagnosisQuestions, setDiagnosisQuestions] = useState<DiagnosisQuestion[]>([])
    const [aoes, setAOEs] = useState<AOE[]>([])

    const numOfDiagnosticCodes = values?.DiagnosisCodes?.length || 0
    const numOfTestCodes = values?.TestDetails?.length || 0
    const setDiagnosis = (v: any) => setFieldValue('DiagnosisCodes', v)
    const setDiagnosisAnswers = (v: any) => setFieldValue('DiagnosisAnswers', v)
    const setAOEAnswers = (v: any) => setFieldValue('AOEAnswers', v)

    const paymentMethod = values?.PaymentMethod
    let patientHasInsurance = false;
    if (values?.Patient?.PatientInsurances && values?.Patient?.PatientInsurances?.length > 0) {
        patientHasInsurance = true;
    }

    // load test dependences
    useEffect(() => {
        const init = async () => {
            try {
                const resp: Test[] = await apiFetch("/tests")
                setTestDetails(resp.filter((test: Test) => values?.Tests?.includes(test.ID)))
            } catch (e) {
                console.log(e)
            }
        }

        init()

    }, [values?.Tests])

    useEffect(() => {
        if (values?.TestDetails?.length > 0) {
            const cptCodes = [];
            for (let t of values?.TestDetails) {
                cptCodes.push(t.CPTCode);
            }

            const dG = [];
            for (let d of values?.DiagnosisCodes) {
                dG.push(d.Code);
            }

            validateDiagnosis(cptCodes, dG)
                .then((p) => {
                    if (!p.ok) {
                        throw new Error("Failed to validate diagnosis");
                    }
                    return p.json();
                })
                .then((p) => setDiagnosisValidity(p))
        }
    }, [values?.DiagnosisCodes, values?.TestDetails])

    useEffect(() => {
        if (paymentMethod !== InsurancePay) {
            return
        }

        let testCodes: number[] = [];
        for (let test of values?.TestDetails) {
            testCodes.push(test.Code)
        }

        const getQuestions = async () => {
            try {

                const data = {
                    TestCodes: testCodes
                }

                // @ts-ignore
                let response = await apiFetch("/orders/diagnosis/questions", "POST", data)
                setDiagnosisQuestions(response)
                // @ts-ignore
                response = await apiFetch("/orders/aoes", "POST", data)
                setAOEs(response)
            } catch (e: any) {
                console.log(e.message)
            }
        }

        console.log(paymentMethod, values?.TestDetails, values?.Provider?.ID, values?.Patient?.PatientInsurances)
        getQuestions()
    }, [paymentMethod, values?.TestDetails])

    useEffect(() => {
        if (paymentMethod !== InsurancePay) {
            return
        }

        let testCodes: number[] = [];
        for (let test of values?.TestDetails) {
            testCodes.push(test.Code)
        }

        const getNoteTrigger = async () => {
            try {
                const data = {
                    TestCodes: testCodes,
                    ProviderId: values?.Provider?.ID,
                    InsuranceId: values?.Patient?.PatientInsurances[0].InsuranceID
                }

                // @ts-ignore
                const response = await apiFetch("/providers/noteTriggers/search", "POST", data)
                setFieldValue("ChartNotesRequired", response)
            } catch (e: any) {
                console.log(e.message)
            }

        }

        getNoteTrigger()

    }, [paymentMethod, values?.TestDetails, values?.Provider?.ID, values?.Patient?.PatientInsurances])

    const handleDiagnosisQuestion = (answers: Answer[], dCodes: DiagnosisCode[]) => {
        console.log(dCodes)
        let newCodes = [];
        for (let newCode of dCodes) {
            // @ts-ignore
            if (!includesDiagnosisCode(values?.DiagnosisCodes, newCode)) {
                newCodes.push(newCode)
            }
        }
        setDiagnosisAnswers([...answers])
        setDiagnosis([...values?.DiagnosisCodes, ...newCodes])
    }

    console.log("AOEs", values.AOEAnswers)

    return (
        <>
            <Grid container>
                <Grid item xs={12} md={12} lg={4}>
                    <Paper
                        sx={{
                            p: 2,
                            display: "flex",
                            flexDirection: "row",
                            m: 2,
                        }}
                    >
                        <Box component="form" sx={{ minWidth: "100%" }}>
                            <FormControl>
                                <Typography variant="h5" gutterBottom>
                                    Payment Method
                                </Typography>
                                <OrderPaymentSelection
                                    paymentMethod={values?.PaymentMethod}
                                    facility={values?.Facility}
                                    providerType={values.Provider?.Type}
                                    setPaymentMethod={(v) => setFieldValue("PaymentMethod", v)}
                                    patientHasInsurance={patientHasInsurance}
                                />
                            </FormControl>
                        </Box>
                    </Paper>
                </Grid>
                {paymentMethod === InsurancePay && (
                    <>
                        <Grid item xs={12} md={12} lg={8}>
                            <DiagnosisValidityTable
                                testDetails={values?.TestDetails}
                                diagnosisValidity={diagnosisValidity}
                                setDiagnosis={handleDiagnosisQuestion} />
                        </Grid>
                        <Grid item xs={12} md={5} lg={4}></Grid>
                    </>
                )}
                {paymentMethod === InsurancePay && diagnosisQuestions.length > 0 && (
                    <>
                        <Grid item xs={12} md={12} lg={8}>
                            <Paper
                                sx={{
                                    p: 2,
                                    display: "flex",
                                    flexDirection: "column",
                                    m: 2,
                                }}
                            >
                                <Typography variant="h5">
                                    Diagnosis Questions
                                </Typography>
                                <hr />
                                <DiagnosisQuestions
                                    diagnosisQuestions={diagnosisQuestions}
                                    setDiagnosis={handleDiagnosisQuestion} />
                            </Paper>
                        </Grid>
                        <Grid item xs={12} md={5} lg={4}></Grid>
                        {aoes.length > 0 && (
                            <>
                                <Grid item xs={12} md={12} lg={8}>
                                    <Paper
                                        sx={{
                                            p: 2,
                                            display: "flex",
                                            flexDirection: "column",
                                            m: 2,
                                        }}
                                    >
                                        <Typography variant="h5">
                                            Order Entry Questions
                                        </Typography>
                                        <OrderAOEs
                                            aoes={aoes}
                                            answers={values?.AOEAnswers}
                                            setAnswers={setAOEAnswers}
                                        />
                                    </Paper>
                                </Grid>
                                <Grid item xs={12} md={5} lg={4}></Grid>
                            </>
                        )}
                    </>
                )}

                <Grid item xs={12} md={12} lg={8}>
                    <Paper
                        sx={{
                            p: 2,
                            display: "flex",
                            flexDirection: "column",
                            m: 2,
                        }}
                    >
                        {paymentMethod === InsurancePay ? (
                            <DiagnosisCodeSearch
                                hideText={false}
                                notRequired={false}
                                diagnosisCodes={values?.DiagnosisCodes}
                                setDiagnosis={(_: any, v: DiagnosisCode[]) => setDiagnosis(v)} />

                        ) : (
                            <>
                                <Typography variant="h5">
                                    Available Payment Methods
                                </Typography>
                                <Table>
                                    <TableBody>
                                        <TableRow>
                                            <TableCell> Insurance </TableCell>
                                            <TableCell>
                                                {" "}
                                                The patient's insurance will be
                                                billed for the rendered
                                                services. The patient may be
                                                responsible for deductible or
                                                co-insurance amounts.{" "}
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell> Self Pay </TableCell>
                                            <TableCell>
                                                {" "}
                                                The patient will be billed
                                                directly for rendered services.{" "}
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell> Client Bill </TableCell>
                                            <TableCell>
                                                {" "}
                                                The ordering facility will be
                                                billed directly for rendered
                                                services.{" "}
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </>
                        )}
                    </Paper>
                </Grid>
                {values?.ChartNotesRequired &&
                    <>
                        <Grid item xs={12} md={5} lg={4}></Grid>
                        <Grid item xs={12} md={12} lg={8}>
                            <Paper
                                sx={{
                                    p: 2,
                                    display: "flex",
                                    flexDirection: "column",
                                    m: 2,
                                }}
                            >
                                <Typography variant="h5" gutterBottom>
                                    Additional Required Information
                                </Typography>
                                <WarningAlert msg="The patient's insurance requires chart notes for the selected tests. Please enter them below, be as descriptive as possible." />
                                <TextField
                                    variant="outlined"
                                    multiline
                                    label="Chart Notes"
                                    value={values?.ChartNotes}
                                    onChange={(e) => setFieldValue("ChartNotes", e.target.value)}
                                />
                            </Paper>
                        </Grid>
                    </>
                }
            </Grid>
        </>
    );

}


export default OrderPaymentMethodStep;
