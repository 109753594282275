import React, { useState } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import SamplesTable from "../components/SamplesTable";
import {
    getCollectedSamples,
    getRejectedSamples,
    getTransferredSamples,
    getReceivedSamples,
    getInTransitSamples,
    rejectSample,
    addSampleNote,
    restoreSample,
    getAllSamples,
} from "../services/samples";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import TabPanel, { a11yProps } from "../components/TabPanel";
import RejectionDialog from "../components/RejectionDialog";
import Dialog from "@mui/material/Dialog";
import { Alert } from "@mui/material";
import NotesDialog from "../components/NotesDialog";
import ConfirmDialog from "../components/ConfirmDialog";
import Framework from "../components/Framework";
import { useTabState } from "../components/useTabState";
import UpdateSampleDialog from "../components/UpdateSampleDialog";
import { apiFetch } from "../services/fetch";
import { DataTableContainerNoButton } from "../components/TableContainer";
import SampleNotesDialog from "../components/Dialogs/SampleNotesDialog";

export default function Samples() {
    const [value, setValue] = useTabState(0);
    const [openReject, setOpenReject] = useState(false);
    const [openNotes, setOpenNotes] = useState(false);
    const [notesSampleId, setNotesSampleId] = useState(null);
    const [sampleID, setSampleID] = useState();
    const [error, setError] = useState(null);
    const [refresh, setRefresh] = useState(0);
    const [openSpecimen, setOpenSpecimen] = useState(false);
    const [openRestore, setOpenRestore] = useState(false);

    const handleChange = (_, newValue) => {
        setValue(newValue);
    };

    const handleAddNote = (notes) => {
        if (notes === "") {
            setError("Notes cannot be empty.");
            return;
        }

        addSampleNote(sampleID, notes)
            .then((p) => {
                if (!p.ok) {
                    throw new Error("Failed to add note. Please try again.");
                } else {
                    setError(undefined);
                    setOpenNotes(false);
                    setRefresh(refresh + 1);
                }
            })
            .catch((e) => setError(e.message));
    };

    const handleViewNotes = (sampleId) => {
        setNotesSampleId(sampleId);
    };

    const handleSpecimenUpdate = (newSpecimenType) => {
        const getSample = async () => {
            try {
                let response = await apiFetch(`/samples/${sampleID}`, "GET")
                response.SpecimenTypeID = newSpecimenType[0]

                response = apiFetch(`/samples/${sampleID}`, "POST", response)
            } catch (e) {
                console.log(e)
            } finally {
                setRefresh(!refresh)
            }
        }

        getSample()
    }

    const handleRestore = () => {
        restoreSample(sampleID)
            .then((p) => {
                if (!p.ok) {
                    throw new Error("Failed to restore. Please try again.");
                } else {
                    setError(undefined);
                    setOpenRestore(false);
                    setRefresh(refresh + 1);
                }
            })
            .catch((e) => setError(e.message));
    };

    const handleRejection = (rejection) => {
        if (rejection.Reason === "") {
            setError("Note cannot be empty.");
            return;
        }

        rejectSample(sampleID, rejection)
            .then((p) => {
                if (!p.ok) {
                    throw new Error(
                        "Failed to reject sample. Please try again."
                    );
                } else {
                    setError(undefined);
                    setOpenReject(false);
                    setRefresh(refresh + 1);
                }
            })
            .catch((e) => setError(e.message));
    };

    return (
        <Framework table name="Samples">
            <SampleNotesDialog
                sampleId={notesSampleId}
                setSampleId={setNotesSampleId}
            />
            <ConfirmDialog
                title="Confirm Action"
                message="Are you sure you want to restore this sample?"
                confirm={handleRestore}
                cancel={() => setOpenRestore(false)}
                open={openRestore}
            />
            <Dialog
                open={openNotes}
                onClose={() => setOpenNotes(false)}
                maxWidth="xl"
            >
                <NotesDialog
                    error={error}
                    handleClose={() => setOpenNotes(false)}
                    handleSave={handleAddNote}
                />
                {error !== null ? (
                    <Alert severity="error">{error}</Alert>
                ) : (
                    <></>
                )}
            </Dialog>
            <Dialog
                open={openReject}
                onClose={() => setOpenReject(false)}
                maxWidth="xl"
            >
                <RejectionDialog
                    handleClose={() => setOpenReject(false)}
                    handleSave={handleRejection}
                />
                {error !== null ? (
                    <Alert severity="error">{error}</Alert>
                ) : (
                    <></>
                )}
            </Dialog>
            <UpdateSampleDialog
                open={openSpecimen}
                setOpen={setOpenSpecimen}
                handleSave={handleSpecimenUpdate}
                sampleId={sampleID}
                callback={() => setRefresh(!refresh)}
            />
            <Box sx={{ pl: 2, pr: 2, pt: 1 }}>
                <Typography variant="h5">Samples</Typography>
                <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                    <Tabs value={value} onChange={handleChange} variant="scrollable">
                        <Tab label="Incoming" {...a11yProps(0)} />
                        <Tab label="Received" {...a11yProps(0)} />
                        <Tab label="Transferred" {...a11yProps(0)} />
                        <Tab label="Rejected" {...a11yProps(0)} />
                        <Tab label="Collected" {...a11yProps(0)} />
                        <Tab label="All" {...a11yProps(0)} />
                    </Tabs>
                </Box>
            </Box>
            <TabPanel value={value} index={0}>
                <DataTableContainerNoButton>
                    <SamplesTable
                        viewNote={(sampleId) => handleViewNotes(sampleId)}
                        refresh={refresh}
                        getSamples={getInTransitSamples}
                        Title="Samples"
                        addNote={(sampleID) => {
                            setSampleID(sampleID);
                            setOpenNotes(true);
                        }}
                        reject={(sampleID) => {
                            setOpenReject(true);
                            setSampleID(sampleID);
                        }}
                        update={(sampleID) => {
                            setOpenSpecimen(true);
                            setSampleID(sampleID);
                        }}
                    />
                </DataTableContainerNoButton>
            </TabPanel>
            <TabPanel value={value} index={1}>
                <DataTableContainerNoButton>
                    <SamplesTable
                        viewNote={(notes) => handleViewNotes(notes)}
                        refresh={refresh}
                        getSamples={getReceivedSamples}
                        addNote={(sampleID) => {
                            setSampleID(sampleID);
                            setOpenNotes(true);
                        }}
                        reject={(sampleID) => {
                            setOpenReject(true);
                            setSampleID(sampleID);
                        }}
                        update={(sampleID) => {
                            setOpenSpecimen(true);
                            setSampleID(sampleID);
                        }}
                        Title="Samples"
                    />
                </DataTableContainerNoButton>
            </TabPanel>
            <TabPanel value={value} index={2}>
                <DataTableContainerNoButton>
                    <SamplesTable
                        viewNote={(notes) => handleViewNotes(notes)}
                        refresh={refresh}
                        getSamples={getTransferredSamples}
                        addNote={(sampleID) => {
                            setSampleID(sampleID);
                            setOpenNotes(true);
                        }}
                        reject={(sampleID) => {
                            setOpenReject(true);
                            setSampleID(sampleID);
                        }}
                        update={(sampleID) => {
                            setOpenSpecimen(true);
                            setSampleID(sampleID);
                        }}
                        Title="Samples"
                    />
                </DataTableContainerNoButton>
            </TabPanel>
            <TabPanel value={value} index={3}>
                <DataTableContainerNoButton>
                    <SamplesTable
                        viewNote={(notes) => handleViewNotes(notes)}
                        restore={(sampleID) => {
                            setSampleID(sampleID);
                            setOpenRestore(true);
                        }}
                        refresh={refresh}
                        getSamples={getRejectedSamples}
                        addNote={(sampleID) => {
                            setSampleID(sampleID);
                            setOpenNotes(true);
                        }}
                        reject={(sampleID) => {
                            setOpenReject(true);
                            setSampleID(sampleID);
                        }}
                        update={(sampleID) => {
                            setOpenSpecimen(true);
                            setSampleID(sampleID);
                        }}
                        Title="Samples"
                    />
                </DataTableContainerNoButton>
            </TabPanel>
            <TabPanel value={value} index={4}>
                <DataTableContainerNoButton>
                    <SamplesTable
                        viewNote={(notes) => handleViewNotes(notes)}
                        restore={(sampleID) => {
                            setSampleID(sampleID);
                            setOpenRestore(true);
                        }}
                        refresh={refresh}
                        getSamples={getCollectedSamples}
                        addNote={(sampleID) => {
                            setSampleID(sampleID);
                            setOpenNotes(true);
                        }}
                        reject={(sampleID) => {
                            setOpenReject(true);
                            setSampleID(sampleID);
                        }}
                        update={(sampleID) => {
                            setOpenSpecimen(true);
                            setSampleID(sampleID);
                        }}
                        Title="Samples"
                    />
                </DataTableContainerNoButton>
            </TabPanel>
            <TabPanel value={value} index={5}>
                <DataTableContainerNoButton>
                    <SamplesTable
                        viewNote={(notes) => handleViewNotes(notes)}
                        restore={(sampleID) => {
                            setSampleID(sampleID);
                            setOpenRestore(true);
                        }}
                        refresh={refresh}
                        getSamples={getAllSamples}
                        addNote={(sampleID) => {
                            setSampleID(sampleID);
                            setOpenNotes(true);
                        }}
                        reject={(sampleID) => {
                            setOpenReject(true);
                            setSampleID(sampleID);
                        }}
                        update={(sampleID) => {
                            setOpenSpecimen(true);
                            setSampleID(sampleID);
                        }}
                        Title="Samples"
                    />
                </DataTableContainerNoButton>
            </TabPanel>
        </Framework>
    );
}
