import { useContext, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { SessionContext } from "../hooks/getContext";
import { CircularProgress, Dialog, DialogContent } from "@mui/material";

const ProtectedRoute = ({ children, requiredRoles, excludedRoles }) => {
    const { user, loading } = useContext(SessionContext);
    console.log(user, loading)
    const location = useLocation();
    const navigate = useNavigate();
    const currentPath = location.pathname;

    useEffect(() => {
        if (!loading && !user) {
            console.log("redirecting to login")
            navigate("/login", { state: { from: currentPath } });
            return
        }

        if (!loading && user && requiredRoles !== undefined) {
            let isAuthorized =
                (user.Roles && user.Roles.some(role => requiredRoles.includes(role))) ||
                requiredRoles.length === 0;

            if (excludedRoles && user.Roles && user.Roles.some(role => excludedRoles.includes(role))) {
                isAuthorized = false
            }

            if (!isAuthorized) {
                console.log("attempted unauthorized access. redirecting")
                navigate("/");
                return
            }
        }
    }, [loading, user, currentPath]);

    if (loading) {
        return (
            <Dialog open={true}>
                <DialogContent>
                    <CircularProgress color="inherit" />
                </DialogContent>
            </Dialog>
        );
    }

    if (!user || (requiredRoles !== undefined && !user.Roles)) {
        return null;
    }

    if (requiredRoles === undefined) {
        console.log("You must pass in requiredRoles for this route.");
        return null;
    }

    return children;
};

export { ProtectedRoute };

