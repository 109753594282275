import React, { useEffect, useState } from "react";

import ErrorAlert from "./ErrorAlert";
import {
    Button,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Dialog,
    Typography
} from "@mui/material";
import { apiFetch } from "../services/fetch";

import DiagnosisCodeSearch from "./DiagnosisCodeSelect";
import { LoadingButton } from "@mui/lab";

function ChangeDiagnosisCodesDialog({ orderId, paymentMethod, open, setOpen, callback = () => { } }) {
    const [diagnosisCodes, setDiagnosisCodes] = useState([])
    const [error, setError] = useState()
    const [saving, setSaving] = useState(false)

    const updateOrder = () => {
        setSaving(true)
        const save = async () => {
            try {
                let data = {
                    PaymentMethod: paymentMethod,
                    DiagnosisCodes: diagnosisCodes,
                }

                await apiFetch(`/orders/${orderId}/payment`, "POST", data)
                setSaving(false)
                setOpen(false)
                callback()
            } catch (e) {
                setError("Failed to update order.")
                setSaving(false)
            }
        }

        save()
    }

    return (
        <Dialog open={open} >
            <DialogTitle>Change Diagnosis Codes</DialogTitle>
            <DialogContent>
                <>
                    <DiagnosisCodeSearch diagnosisCodes={diagnosisCodes}
                        setDiagnosis={(_, v) => setDiagnosisCodes(v)}
                    />
                </>

                <ErrorAlert error={error} />
            </DialogContent>
            <DialogActions>
                <Button onClick={() => setOpen(false)}>Cancel</Button>
                <LoadingButton
                    loading={saving}
                    loadingIndicator="Updating..."
                    disabled={diagnosisCodes.length < 1}
                    onClick={() => updateOrder()}>
                    Change

                </LoadingButton>
            </DialogActions>
        </Dialog>
    )


}

export default ChangeDiagnosisCodesDialog;
