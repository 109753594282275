import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import * as Yup from "yup";
import { Formik, Form, useFormikContext, FormikHelpers } from "formik";
import { Button, Stack, TextField } from "@mui/material";
import Typography from "@mui/material/Typography";
import { DataGrid, GridToolbar, GridColDef } from "@mui/x-data-grid";
import MenuItem from "@mui/material/MenuItem";
import { getFacilities } from "../services/facilities";
import Alert from "@mui/material/Alert";
import NotificationImportantIcon from "@mui/icons-material/NotificationImportant";
import { getContacts } from "../services/contacts";
import { getNPPES } from "../services/providers";
import { CustomTextField } from "./CustomTextField";
import { DataRow, Provider } from "../constants/types";
import { apiFetch } from "../services/fetch";

export default function ProviderInput({ initialValues, reset }: { initialValues: Provider, reset: () => {} }) {
    const [allFacilities, setAllFacilities] = useState([]);
    const [allContacts, setAllContacts] = useState([]);
    const [error, setError] = useState<string | null>();

    useEffect(() => {
        getFacilities().then((p) => {
            setAllFacilities(p);
        });
        getContacts().then((p) => {
            setAllContacts(p);
        });
    }, []);

    const handleSubmit = async (values: Provider) => {
        let url = '/providers'
        if (values.ID) {
            url = `/providers/${values.ID}`
        }


        try {
            // @ts-ignore
            await apiFetch(url, 'POST', values)
        } catch (e) {
            setError("Failed to update provider")

        } finally {
            reset()
        }
    };

    const validationSchema = Yup.object().shape({
        FirstName: Yup.string().required("First name is required"),
        LastName: Yup.string().required("Last name is required"),
        NPI: Yup.string().length(10).required(),
        Type: Yup.string().oneOf(["doctor", "nurse", "other"]).required(),
        SignatureOnFile: Yup.bool(),
        SignatureImage: Yup.mixed(),
        Facilities: Yup.array(),
        Contacts: Yup.array(),
    });

    const facilityColumns = [
        {
            field: "Name",
            headerName: "Name",
            width: 200,
        },
    ];

    const getCheckbox = (value: any) => {
        if (value.value) {
            return <NotificationImportantIcon color="error" />;
        }
        return <NotificationImportantIcon color="disabled" />;
    };

    const contactColumns: GridColDef[] = [
        { field: "FirstName", headerName: "First Name", width: 125 },
        { field: "LastName", headerName: "Last Name", width: 125 },
        { field: "Type", headerName: "Type", width: 125 },
        { field: "Phone1", headerName: "Phone1", width: 125 },
        { field: "Email", headerName: "Email", width: 175 },
        {
            field: "Critical",
            headerName: "Critical Contact",
            width: 150,
            renderCell: getCheckbox,
            align: "center",
        },
    ];

    const showError = () => {
        if (error) {
            return (
                <Alert sx={{ m: 2 }} severity="error">
                    {error}
                </Alert>
            );
        }
    };

    const ProviderWarning = ({ values }: { values: Provider }) => {
        if (values.Type !== "other") {
            return <></>;
        }

        return (
            <Alert sx={{ m: 2 }} severity="warning">
                This provider type is inelligble for insurance orders
            </Alert>
        );
    };

    const GetNPIData = () => {
        const { setFieldValue, values }: { setFieldValue: FormikHelpers<Provider>['setFieldValue'], values: Provider } = useFormikContext<Provider>();

        useEffect(() => {

            if (values?.NPI.length !== 10) {
                return
            }
            getNPPES(values?.NPI)
                .then((p) => {
                    if (!p.ok) {
                        throw new Error("failed to fetch");
                    }
                    return p.json();
                })
                .then((p) => {
                    // if results === 1 then lookup was successful
                    if (p.result_count === 1) {
                        // check if response is for individual and not organization
                        if (p.results[0].enumeration_type !== "NPI-1") {
                            return;
                        }

                        setFieldValue("FirstName", p.results[0].basic.first_name)
                        setFieldValue("LastName", p.results[0].basic.last_name)
                        // setProvider({ ...provider, LastName: p.results[0].basic.last_name, FirstName: p.results[0].basic.first_name });
                    }
                })
                .catch((e) => console.log(e));
            return;
        }, [values?.NPI, setFieldValue]);

        return <></>

    }

    return (
        <Box>
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
                enableReinitialize
            >
                {({
                    values,
                    handleChange,
                    errors,
                    touched,
                    setFieldValue,
                }) => (
                    <Form>
                        <GetNPIData />
                        <Stack direction="column" spacing={2}>
                            <Typography sx={{ my: 2 }} variant="h5" gutterBottom>
                                Provider
                            </Typography>
                            <Stack direction={{ sm: "column", md: "row" }} spacing={{ xs: 2, sm: 2, md: 2, lg: 2, xl: 2 }}>
                                <CustomTextField name="NPI" />
                                <CustomTextField name="FirstName" label="First Name" />
                                <CustomTextField name="LastName" label="Last Name" />
                                <TextField
                                    required
                                    select
                                    size="small"
                                    value={values?.Type}
                                    label="Type"
                                    helperText={touched?.Type && errors?.Type}
                                    sx={{ minWidth: "200px" }}
                                    error={touched?.Type && Boolean(errors?.Type)}
                                    name="Type"
                                    onChange={handleChange}
                                >
                                    <MenuItem value={"doctor"}>Doctor</MenuItem>
                                    <MenuItem value={"nurse"}>
                                        Nurse Practitioner
                                    </MenuItem>
                                    <MenuItem value={"other"}>Other</MenuItem>
                                </TextField>
                            </Stack>
                            <ProviderWarning values={values} />
                            {showError()}
                            <Typography sx={{ my: 2 }} variant="h5" gutterBottom>
                                Facilities
                            </Typography>
                            <Box height="400px">
                                <DataGrid
                                    getRowId={(row: DataRow) => row.ID}
                                    density="compact"
                                    keepNonExistentRowsSelected
                                    rows={allFacilities}
                                    columns={facilityColumns}
                                    components={{ Toolbar: GridToolbar }}
                                    componentsProps={{
                                        toolbar: {
                                            showQuickFilter: true,
                                            quickFilterProps: { debounceMs: 500 },
                                        },
                                    }}
                                    rowSelectionModel={values?.Facilities}
                                    onRowSelectionModelChange={
                                        (newValues) => {
                                            setFieldValue("Facilities", newValues)
                                        }}
                                    checkboxSelection
                                    disableRowSelectionOnClick
                                />
                            </Box>
                            <Typography sx={{ my: 2 }} variant="h5" gutterBottom>
                                Associated Contacts
                            </Typography>
                            <Box height="400px">
                                <DataGrid
                                    getRowId={(row: DataRow) => row.ID}
                                    keepNonExistentRowsSelected
                                    density="compact"
                                    rows={allContacts}
                                    columns={contactColumns}
                                    components={{ Toolbar: GridToolbar }}
                                    componentsProps={{
                                        toolbar: {
                                            showQuickFilter: true,
                                            quickFilterProps: { debounceMs: 500 },
                                        },
                                    }}
                                    rowSelectionModel={values?.Contacts}
                                    onRowSelectionModelChange={(newValues) => {
                                        setFieldValue("Contacts", newValues)
                                    }}
                                    checkboxSelection
                                    disableRowSelectionOnClick
                                />
                            </Box>
                            <Stack direction="row" justifyContent="space-between">
                                <Button variant="outlined" sx={{ my: 2 }} onClick={reset}>
                                    Back
                                </Button>
                                <Button
                                    type="submit"
                                    variant="contained"
                                    sx={{ my: 2 }}
                                >
                                    Save
                                </Button>
                            </Stack>
                        </Stack>
                    </Form>
                )}
            </Formik>
        </Box>
    );
}
