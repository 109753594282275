import { Box, Button, Divider, FormControl, FormLabel, InputLabel, MenuItem, Select, Stack, TextField, Typography } from '@mui/material';
import React, { useState, useContext } from 'react';
import { SessionContext } from "../hooks/getContext";
import Framework from '../components/Framework';
import dayjs from "dayjs";
import utc from 'dayjs/plugin/utc';
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { apiFetch } from '../services/fetch';
import ErrorAlert from '../components/ErrorAlert';
import FacilityPicker from '../components/Pickers/FacilityPicker';
import OrderStatusPicker from '../components/Pickers/OrderStatusPicker';
import SampleStatusPicker from '../components/Pickers/SampleStatusPicker';

dayjs.extend(utc);

type Report = {
	ID: number,
	Name: string,
	Filters: JSX.Element[],
	GroupByOptions: string[],
	Description: string,
}

const CustDatePicker = ({ date, setDate, label }: { date: Date | null, setDate: (arg0: dayjs.Dayjs) => void, label: string }) => {
	return (
		<LocalizationProvider dateAdapter={AdapterDayjs}>
			<DatePicker
				disableFuture
				label={label}
				value={date || null}
				onChange={(date) => {
					const formattedDate = dayjs(date).format('YYYY-MM-DD');
					setDate(dayjs.utc(formattedDate));
				}
				}
				renderInput={(params) => (
					<TextField
						{...params}
						size="small"
					/>
				)}
			/>
		</LocalizationProvider>

	)
}

const ControlStack = ({ children }: { children: JSX.Element[] }) => {
	return (<Stack direction="row" spacing={2}>{children}</Stack>)
}

function Reports() {
	const [loading, setLoading] = useState(false)
	const [report, setReport] = useState<number | null>(null)
	const [meta, setMeta] = useState<any>({})
	const [error, setError] = useState<string | null>(null)
	const session = useContext(SessionContext);
	const userRoles = session?.user?.Roles;

	const disclaimer = "All dates utilize your current timezone as determined by your browser. Current timezone: " + Intl.DateTimeFormat().resolvedOptions().timeZone;

	let reports: Report[] = []

	// @ts-ignore
	if (userRoles.includes("Sample Handler") || userRoles.includes("Sample Handler Manager")) {
		reports = [
			{
				ID: 6,
				Name: "Order & Sample Details",
				Filters: [
					<ControlStack>
						<CustDatePicker label="Date Submitted From" date={meta?.DateSubmittedFrom} setDate={(d) => handleDateChange(d, "DateSubmittedFrom")} />
						<CustDatePicker label="Date Submitted To" date={meta?.DateSubmittedTo} setDate={(d) => handleDateChange(d, "DateSubmittedTo")} />
					</ControlStack>,
					<ControlStack>
						<CustDatePicker label="Date Reported From" date={meta?.DateReportedFrom} setDate={(d) => handleDateChange(d, "DateReportedFrom")} />
						<CustDatePicker label="Date Reported To" date={meta?.DateReportedTo} setDate={(d) => handleDateChange(d, "DateReportedTo")} />
					</ControlStack>,
					<ControlStack>
						<FacilityPicker
							facility={meta?.Facility}
							setFacility={(f) => setMeta({ ...meta, Facility: f, FacilityID: f?.ID })}
							// @ts-ignore
							sx={{ width: 290 }}
						/>
						<OrderStatusPicker
							status={meta?.Statuses}
							setOrderStatus={(s) => setMeta({ ...meta, Statuses: s })}
							// @ts-ignore
							sx={{ width: 290 }}
						/>
					</ControlStack>
				],
				GroupByOptions: [],
				Description: "Provides detailed information about orders. If an order contains multiple dates of service or performing laboratories, the order will have multiple rows in the report."
			},

		]

	} else {

		reports = [
			{
				ID: 1,
				Name: "Order Details",
				Filters: [
					<ControlStack>
						<CustDatePicker label="Date Submitted From" date={meta?.DateSubmittedFrom} setDate={(d) => handleDateChange(d, "DateSubmittedFrom")} />
						<CustDatePicker label="Date Submitted To" date={meta?.DateSubmittedTo} setDate={(d) => handleDateChange(d, "DateSubmittedTo")} />
					</ControlStack>,
					<ControlStack>
						<CustDatePicker label="Date Reported From" date={meta?.DateReportedFrom} setDate={(d) => handleDateChange(d, "DateReportedFrom")} />
						<CustDatePicker label="Date Reported To" date={meta?.DateReportedTo} setDate={(d) => handleDateChange(d, "DateReportedTo")} />
					</ControlStack>,
					<ControlStack>
						<FacilityPicker
							facility={meta?.Facility}
							setFacility={(f) => setMeta({ ...meta, Facility: f, FacilityID: f?.ID })}
							// @ts-ignore
							sx={{ width: 290 }}
						/>
						<OrderStatusPicker
							status={meta?.Statuses}
							setOrderStatus={(s) => setMeta({ ...meta, Statuses: s })}
							// @ts-ignore
							sx={{ width: 290 }}
						/>
					</ControlStack>
				],
				GroupByOptions: [],
				Description: "Provides detailed information about orders. If an order contains multiple dates of service or performing laboratories, the order will have multiple rows in the report. The tests column provides billable tests only, TNP'd tests will not be included in the report."
			},
			{
				ID: 2,
				Name: "Sample Details",
				Filters: [
					<ControlStack>
						<CustDatePicker label="Date Order Submitted From" date={meta?.DateSubmittedFrom} setDate={(d) => handleDateChange(d, "DateSubmittedFrom")} />
						<CustDatePicker label="Date Order Submitted To" date={meta?.DateSubmittedTo} setDate={(d) => handleDateChange(d, "DateSubmittedTo")} />
					</ControlStack>,
					<ControlStack>
						<CustDatePicker label="Date Collected From" date={meta?.DateCollectedFrom} setDate={(d) => handleDateChange(d, "DateCollectedFrom")} />
						<CustDatePicker label="Date Collected To" date={meta?.DateCollectedTo} setDate={(d) => handleDateChange(d, "DateCollectedTo")} />
					</ControlStack>,
					<ControlStack>
						<CustDatePicker label="Date Received From" date={meta?.DateReceivedFrom} setDate={(d) => handleDateChange(d, "DateReceivedFrom")} />
						<CustDatePicker label="Date Received To" date={meta?.DateReceivedTo} setDate={(d) => handleDateChange(d, "DateReceivedTo")} />
					</ControlStack>,
					<ControlStack>
						<CustDatePicker label="Date Order Reported From" date={meta?.DateReportedFrom} setDate={(d) => handleDateChange(d, "DateReportedFrom")} />
						<CustDatePicker label="Date Order Reported To" date={meta?.DateReportedTo} setDate={(d) => handleDateChange(d, "DateReportedTo")} />
					</ControlStack>,
					<ControlStack>
						<OrderStatusPicker
							status={meta?.OrderStatuses}
							setOrderStatus={(s) => setMeta({ ...meta, OrderStatuses: s })}
							// @ts-ignore
							sx={{ width: 290 }}
						/>
						<SampleStatusPicker
							status={meta?.SampleStatuses}
							setSampleStatus={(s) => setMeta({ ...meta, SampleStatuses: s })}
							// @ts-ignore
							sx={{ width: 290 }}
						/>
					</ControlStack>,
					<FacilityPicker
						facility={meta?.Facility}
						setFacility={(f) => setMeta({ ...meta, Facility: f, FacilityID: f?.ID })}
						// @ts-ignore
						sx={{ width: 290 }}
					/>
				],
				GroupByOptions: [],
				Description: "Provides detailed information about samples."
			},
			{
				ID: 3,
				Name: "Order Count By Facility",
				Filters: [
					<ControlStack>
						<CustDatePicker label="Date Submitted From" date={meta?.DateSubmittedFrom} setDate={(d) => handleDateChange(d, "DateSubmittedFrom")} />
						<CustDatePicker label="Date Submitted To" date={meta?.DateSubmittedTo} setDate={(d) => handleDateChange(d, "DateSubmittedTo")} />
					</ControlStack>,
					<ControlStack>
						<CustDatePicker label="Date Reported From" date={meta?.DateReportedFrom} setDate={(d) => handleDateChange(d, "DateReportedFrom")} />
						<CustDatePicker label="Date Reported To" date={meta?.DateReportedTo} setDate={(d) => handleDateChange(d, "DateReportedTo")} />
					</ControlStack>,
					<OrderStatusPicker
						status={meta?.Statuses}
						setOrderStatus={(s) => setMeta({ ...meta, Statuses: s })}
						// @ts-ignore
						sx={{ width: 290 }}
					/>
				],
				GroupByOptions: ["Date Submitted", "Date Reported"],
				Description: "Provides order counts by facility."
			},
			{
				ID: 4,
				Name: "Sample Count By Facility",
				Filters: [
					<ControlStack>
						<CustDatePicker label="Date Collected From" date={meta?.DateCollectedFrom} setDate={(d) => handleDateChange(d, "DateCollectedFrom")} />
						<CustDatePicker label="Date Collected To" date={meta?.DateCollectedTo} setDate={(d) => handleDateChange(d, "DateCollectedTo")} />
					</ControlStack>,
					<ControlStack>
						<CustDatePicker label="Date Received From" date={meta?.DateReceivedFrom} setDate={(d) => handleDateChange(d, "DateReceivedFrom")} />
						<CustDatePicker label="Date Received To" date={meta?.DateReceivedTo} setDate={(d) => handleDateChange(d, "DateReceivedTo")} />
					</ControlStack>,
					<SampleStatusPicker
						status={meta?.Statuses}
						setSampleStatus={(s) => setMeta({ ...meta, Statuses: s })}
						// @ts-ignore
						sx={{ width: 290 }}
					/>
				],
				GroupByOptions: ["Date Collected", "Date Received"],
				Description: "Provides sample counts by facility."
			},
			{
				ID: 5,
				Name: "Billing Detail",
				Filters: [
					<ControlStack>
						<CustDatePicker label="Date Collected From" date={meta?.DateCollectedFrom} setDate={(d) => handleDateChange(d, "DateCollectedFrom")} />
						<CustDatePicker label="Date Collected To" date={meta?.DateCollectedTo} setDate={(d) => handleDateChange(d, "DateCollectedTo")} />
					</ControlStack>,
					<ControlStack>
						<CustDatePicker label="Date Reported From" date={meta?.DateReportedFrom} setDate={(d) => handleDateChange(d, "DateReportedFrom")} />
						<CustDatePicker label="Date Reported To" date={meta?.DateReportedTo} setDate={(d) => handleDateChange(d, "DateReportedTo")} />
					</ControlStack>,
					<FacilityPicker
						facility={meta?.Facility}
						setFacility={(f) => setMeta({ ...meta, Facility: f, FacilityID: f?.ID })}
						// @ts-ignore
						sx={{ width: 290 }}
					/>
				],
				GroupByOptions: [],
				Description: "Provides detailed order information, grouped via date collected and performing laboratory as per HCFA 1500 guidelines."
			},
		]
	}

	const selectedReport = reports.find((r) => r.ID === report)

	const handleReportChange = (e: any) => {
		// get groups to set default group by
		let m = {};

		const r = reports.find((r) => r.ID === e.target.value)
		if (r) {
			const groups = r.GroupByOptions
			if (groups.length > 0) {
				m = { GroupBy: groups[0] }
			}
		}

		setReport(e.target.value as number);
		setMeta({ ...m })
	}

	const handleDateChange = (v: dayjs.Dayjs, name: string) => {
		setMeta({ ...meta, [name]: v })
	}

	const handleGenerate = async () => {
		const data = { ...meta }
		const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
		data["Timezone"] = timezone;

		for (let key of Object.keys(data)) {
			if (key.toLowerCase().includes("date")) {
				data[key] = data[key]?.toJSON()
			}
		}

		try {
			setLoading(true)
			const response = await apiFetch(`/reports/${report}`, 'POST', data)
			const url = window.URL.createObjectURL(response);
			const link = document.createElement("a");
			link.href = url;
			link.download = "report.csv";
			link.click();
			// It's necessary to revoke the object URL to avoid memory leaks
			window.URL.revokeObjectURL(url);
			setError(null)

		} catch (e) {
			console.log(e)
			setError("Failed to generate report")
		} finally {
			setLoading(false)
		}

	}
	console.log(meta);

	return (
		<Framework>
			<Box sx={{ pl: 2, pr: 2, pt: 1 }}>
				<Typography variant="h5">Reports</Typography>
			</Box>

			<Box sx={{ pl: 2, pr: 2, pt: 1 }}>
				<Stack direction="column" spacing={2} mb={2}>
					<FormControl>
						<InputLabel variant="outlined">Select Report</InputLabel>
						<Select
							fullWidth
							label="Select Report"
							size="small"
							value={report}
							onChange={handleReportChange}>

							{reports.map((report, index) => (
								<MenuItem key={index} value={report.ID}>{report.Name}</MenuItem>
							))}
						</Select>
					</FormControl>

					{selectedReport && <FormLabel>{selectedReport.Description}</FormLabel>}

					<Typography variant="subtitle2">{disclaimer}</Typography>

					<Typography variant="h6">Filters</Typography>
					<Divider />
					{selectedReport && selectedReport.Filters.map((control) => (
						control
					))}
					{selectedReport && selectedReport.GroupByOptions.length > 0 && (

						<>
							<Typography variant="h6">Group</Typography>
							<Divider />
							<FormControl>
								<InputLabel variant="outlined">Group By</InputLabel>
								<Select
									fullWidth
									label="Group By"
									size="small"
									value={meta?.GroupBy}
									onChange={(e) => { setMeta((m: any) => { return { ...m, GroupBy: e.target.value } }) }}>

									{selectedReport.GroupByOptions.map((groupBy, index) => (
										<MenuItem key={index} value={groupBy}>{groupBy}</MenuItem>
									))}
								</Select>
							</FormControl>
						</>
					)}

					{report !== null && <Button variant="contained" color="primary" onClick={handleGenerate} disabled={loading}>{loading ? "Generating..." : "Generate Report"}</Button>}
					<ErrorAlert error={error} />
				</Stack>
			</Box>

		</Framework>
	)

}

export default Reports;
