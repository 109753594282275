import { CircularProgress, Alert, Box, InputLabel, Stack } from "@mui/material";

function FetchLoader({ error, isLoading, label }) {
    if (error) {
        return <div><Alert severity="error">{error}</Alert></div>;
    }

    if (isLoading) {
        return (
            <Box sx={{ display: 'flex', width: "100%", height: "100%", alignItems: "center", justifyContent: "center" }}>
                <Stack direction="column" spacing={2} alignItems="center">
                    <CircularProgress color="secondary" />
                    {label && <InputLabel>{label}</InputLabel>}
                </Stack>
            </Box>
        );
    }
}

export { FetchLoader };
